@import "../../../styleguide/contant.scss";

.list-popup-wrapper {
    position: relative;

    .show-more {
        background: $primary-blue-dark 0% 0% no-repeat padding-box;
        width: 28px;
        height: 18px;
        border-radius: 14px;
        font: normal normal bold #{$fs-10}/#{$fs-13} IBM Plex Sans;
        color: $primary-white;
        padding: 5px 10px;
        margin-left: 10px;
        cursor: pointer;
    }

    .pop-up {
        display: inline-block;
    }

   
    .account-list {
        li {
            font: normal normal medium #{$fs-12}/89px Roboto;
            color: $secondary-black;
            margin: 5px 0;
        }
    }
}


.mav-show-more {
    width: 20px;
    height: 20px;
    background: $primary-blue-dark 0% 0% no-repeat padding-box;
    border-radius: 10px;
    display: inline-block;
    color: $primary-white;
    text-align: center;
    font: normal normal normal #{$fs-12}/#{$fs-21} Roboto;
    font-weight: 500;
    margin-left: 5px;
}