@import "./contant.scss";
@import "../main.scss";
@import "../Components/CK-Auto/main.scss";
@import "../Components/CK-Lens/main.scss";
@import "../Components/CK-tuner/main.scss";
@import "./components/costbreakup.scss";
@import "./components/common.scss";
@import "./components/costexplorer.scss";
@import "./components/potential-savings.scss";
@import "./components/missed-savings.scss";
@import "./components/riops.scss";
@import "./components/cudosdatatransfer.scss";

.dashboard_internal_wrapper {
  margin: 20px;
  border-radius: 4px;
  box-shadow: $global-shadow;
  border: solid 1px $primary-border-color;
  background-color: $primary-white;
  position: relative;
  min-height: 40vh;
}

.custom_recmd_wrapper {
  display: flex;
  justify-content: space-between;
  background-color: $heading-bg-color;
  padding: $heading-padding;
  font-weight: bold;
  line-height: normal;
  text-align: $heading-alignment;
  border-bottom: solid 1px $primary-border-color;

  .main_heading {
    font-size: $heading-font-size;
    color: $heading-txt-color;
    letter-spacing: $heading-letter-spacing;
  }
}

.flex {
  display: flex !important;
}

.p-0 {
  padding: 0 !important;
}

.space_btw {
  display: flex;
  justify-content: space-between;
}

.disabled {
  pointer-events: none;
}

// -------------React tabs module css----------------
.react-tabs__tab-list {
  margin-bottom: 0 !important;
  border-bottom: 0 !important;

  .react-tabs__tab {
    padding: 10px 20px;
  }
}

.react-tabs__tab {
  border: 1px solid #e6e6e6 !important;
  border-bottom: none !important;
  font-size: 12px;
  font-weight: 400;
  min-width: 100px;
  padding: 10px 20px !important;
  position: relative !important;
  text-align: center;
  transition: all 0.2s ease;
}

.react-tabs__tab--selected {
  box-shadow: -2px -3px 9px 0 rgb(0 0 0 / 8%) !important;
  border: solid 1px $primary-border-color !important;
  // background-color: $global-white !important;
  cursor: not-allowed !important;
  font-weight: 700;
}

.no_data_found {
  color: $primary-blue-dark;
  font-weight: 700;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: $fs-12;
}

.btn-primary {
  &.Mui-disabled {
    background-color: $primary-grey-light !important;
    color: $global-white !important;
  }
}

@mixin createFont($font-size: "14px", $font-weight: 500, $align: "left", $color: "#424a58") {
  font-family: $font-family-roboto;
  font-size: $font-size;
  font-weight: $font-weight;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: $align;
  color: $color;
}

.breadcrums_wrapper {
  li {
    a {
      svg {
        overflow: visible;
      }
    }
  }
}

.MuiTabs-flexContainer {
  a {
    &:has(.selected-tab) {
      cursor: unset !important;
    }
  }
}

.selected-tab {
  pointer-events: none !important;
  cursor: initial !important;
}

.selected-tab {
  border-bottom: 3px solid #337ab7 !important;
  font-weight: 500 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #000 !important;
}

table {
  th,
  td {
    &:first-child {
      text-align: left;
    }
  }

  th {
    text-transform: capitalize;
  }

  .tableBodyIcons {
    button {
      justify-content: center;
    }
  }
}

.d_flex {
  display: flex !important;
}

.box-styling {
  border-radius: 4px;
  box-shadow: -7px 14px 38px 0 rgba(52, 58, 64, 0.08);
  border: solid 1px #e6e6e6;
  background-color: $global-white;

  &.Graph_table_wrapper {
    background-color: $primary-white;
  }
}

.Graph_table_wrapper {
  .Graph_table_heading {
    padding: 8px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .heading_wrapper {
    align-items: center;

    .sub_heading {
      margin-left: 10px;
    }
  }

  .data_renderer {
    min-height: 150px;
    position: relative;

    .switch_toggler {
      position: absolute;
      right: 20px;
      top: 2px;
      z-index: 1;

      p.MuiTypography-root.MuiTypography-body1 {
        font-size: $fs-12 !important;
        color: $primary-blue-dark !important;
        font-weight: 400;
        letter-spacing: 0;

        &.grayColor {
          color: $muted-color !important;
        }
      }
    }
  }

  // .table_container_wrapper {
  .table_wrapper {
    overflow: auto;

    table {
      tbody td:first-child {
        color: black;
        font-weight: normal !important;
        font-size: 0.7em;
      }

      thead {
        tr {
          th {
            height: 42px;
          }
        }
      }

      tr {
        td,
        th {
          border-top: 1px solid #fff;
          border-right: 1px solid #fff;
          border-bottom: 0;
          vertical-align: middle;

          &:last-of-type {
            border-right: 0;
            min-width: 64px;
          }
        }
      }

      tfoot {
        tr {
          td {
            font-size: $fs-12;
            // background-image: linear-gradient(
            //   to bottom,
            //   $secondary-blus,
            //   #f6fbff 50%,
            //   $primary-white
            // );
            background-color: #f0f9ff;
            border-top: solid 1px $secondary-blueish-grey !important;
            border-bottom: 0 !important;
            border-left: solid 1px $secondary-blueish-grey !important;
            // &:last-of-type {
            //   background: #eaf4fc;
            // }
          }
        }
      }
    }
  }

  padding: 14px 0 0 0;

  table {
    th {
      padding: 10.5px 0px;
      background-color: #f8f8f8;

      &:first-child {
        padding-left: 10px;
      }
    }

    td {
      &:first-child {
        white-space: nowrap;
        padding: 8.9px;
      }

      font-size: 0.7em;
      padding: 8.9px 0;
      min-width: 62.8px;
    }
  }

  // }

  tfoot td:first-child {
    color: $heading-txt-color;
    font-weight: bold !important;
    font-size: $fs-12;
    background-color: #e9f4fd;
    border: none !important;
  }

  tfoot td:not(:first-child) {
    border: none;
    color: $primary-blue-dark;
    font-size: $fs-12;
    font-weight: bold;
    background-color: #e9f4fd;
  }
}

.border-bottom-grey {
  border-bottom: solid 1px $secondary-blueish-grey;
}

.s3GrandTotal_container {
  .react-tabs__tab-panel--selected {
    padding: 0;
  }
}

.table_header_download {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px !important;
  margin-bottom: 16px;

  button {
    text-transform: uppercase !important;
    padding: 4px 13px !important;
    max-width: 70px;
    max-height: 30px;

    svg {
      margin-right: 5px;
      display: flex;
      align-items: center;
    }
  }

  button.downloadxBtn {
    padding: 0 !important;
  }

  p {
    font-size: $fs-16;
    font-weight: 500 !important;
  }

  span {
    font-weight: 400;
    line-height: 1;
    color: #777;
    font-size: $fs-14;
    padding-left: 3px;
  }
}

.alert_wrapper {
  position: relative;
  height: 74px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: solid 1px #cfdde5;
  background-color: #f6faff;

  .total_cost {
    position: absolute;
    z-index: 10;
    left: 14px;
    top: 16px;

    .total_value {
      font-weight: 700;
      color: $primary-white;
      font-size: $fs-18;
      margin-bottom: 0;
    }

    .total_column {
      font-weight: 700;
      color: $primary-white;
      font-size: $fs-16;
    }
  }

  // .grand_total_column label{
  //   margin-top: $fs-13 !important;
  // }
  .savings_wrapper {
    margin-left: 140px;
    display: flex;
    padding-top: 6px;
    height: 100%;
    align-items: center;
    position: relative;
    padding-left: 0;
    overflow: auto;
  }
}

.heading:not(.main-text-bold) {
  font-size: $fs-22;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.44px;
  text-align: left;
  color: $primary-grey-dark;
  font-weight: 900;
  margin-bottom: 5px;
  font-family: $font-family-roboto;
  text-align: left;
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 0px 20px 10px 10px;
}

.billing_summary {
  table {
    width: 100%;

    thead {
      th {
        background-color: #f8f8f8;
      }
    }
  }
}

.BA_table {
  table {
    width: 100%;

    td {
      background-color: #ffffff;
    }
  }
}

.MuiList-root {
  overflow: auto;
  max-height: 400px;
}

.riCoverage.chart_view_type {
  .chart_tab {
    height: 25px;
  }
}

.rounded {
  border-radius: 4px !important;
}

.modal-header {
  padding: 11px 16px;

  svg {
    top: -15px;
    right: -10px;
  }
}

#display_none {
  display: none;
}

.potential_savings_tooltip_wrapper {
  .potential_saving {
    background-color: #f8f8f8;
    border-bottom: 1px solid $secondary-blueish-grey;
  }

  .potential_saving_right_wrapper_tooltip {
    color: $primary-blue-dark !important;

    label {
      font-size: $fs-11;
      font-weight: 400 !important;
      max-width: none !important;
      display: inline !important;
      text-overflow: none !important;
    }

    b {
      font-weight: 700 !important;
    }
  }

  table {
    thead {
      tr {
        th {
          background-color: $primary-white !important;
          color: #707070 !important;
          font-weight: 400;
          padding: 9px !important;
        }
      }
    }
  }

  .tooltip_table_body {
    // &:nth-child(2) {
    // position: relative;
    // &::after {
    //   content: "";
    //   position: absolute;
    //   width: 90%;
    border-top: 1px dashed #dbdbdb;

    // top: 0;
    // left: 5%;
    // z-index: 9;
    // }
    // }
    td {
      color: $secondary-black !important;
      font-weight: 500 !important;
      padding: 8px 14px !important;

      &:first-child {
        min-width: 60px !important;
        font-weight: 700 !important;
      }
    }
  }
}

.monthly_view_wrapper {
  min-height: 300px;
}

.calender-wrapper,
.dropdown-wrapper,
.customer-invoice-wrapper {
  padding-left: 0 !important;
}

p.text-field-primary {
  margin-bottom: 0;
}

.leftBoxShadow,
.rightBoxShadow {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.partner-settings-form .createForm .upload-input-wrapper.manage-logos {
  width: 100%;
  margin-top: 10px;

  .image-container {
    background: #f8f8f8 0 0 no-repeat padding-box;
    border: 0.8000000119px dashed #898a90;
    height: 114px;
    width: 199px;
  }

  .info-container .icon-info svg {
    bottom: 3px;
    left: 0;
  }
}

div,
p,
label {
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.text-\[12px\] {
  font-size: 12px !important;
}

.switch-role-container {
  .modal-header {
    padding: 12px 16px;

    svg {
      top: unset;
      right: -7px;
      bottom: 1.5rem;
    }
  }

  .react-tabs__tab {
    padding: 8px 20px !important;
  }

  .switchRole_innerCont {
    .react-tabs__tab {
      height: 34.12px;
    }
  }
}

.costBreakup {
  .react-tabs__tab {
    height: 35.34px;
  }
}

// Tooltip global fix post lazy loading for FC

.fusion_chart_wrapper {
  // margin-top: 1px;
  padding: 10px;

  .fc__tooltip.fusioncharts-div {
    max-height: 300px;
    overflow-y: auto !important;
    z-index: 999;

    & > .tooltip_wrapper {
      flex-direction: column;

      .headingCont {
        position: static;
      }

      & > span {
        display: flex;
        justify-content: space-between;
        padding: 6px 14px;
      }
    }

    & > div {
      display: flex;
      position: relative;

      & > div:nth-child(1) {
        position: absolute;
        bottom: 8.5px;
        left: 14px;
        height: 8px;
        width: 8px;
        line-height: 8px;
        text-indent: -999px;
        background: currentColor;
      }

      & > div:nth-child(2) {
        flex: 1;

        .tooltip_wrapper {
          position: relative;

          & > span {
            display: flex;
            justify-content: space-between;
            padding: 6px 14px;
            border-bottom: solid 1px $secondary-blueish-grey;
            padding-left: 31px;
            font-size: $fs-10;
            color: $primary-grey-light;
            font-weight: 500;

            label {
              display: block;
              max-width: calc(100% - 50px);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              min-width: 75px;
            }

            b {
              font-weight: bold;
            }
          }
        }
      }

      &:last-of-type > div {
        .tooltip_wrapper {
          span {
            border-bottom: 0;
          }
        }
      }
    }

    & > div:not(:first-of-type) {
      & > div {
        .tooltip_wrapper {
          .headingCont {
            display: none;
          }
        }
      }

      & > div:nth-child(2) {
      }
    }
  }

  & > .fc__tooltip.fusioncharts-div {
    & > div {
      background: $secondary-black;

      .tooltip_wrapper {
        .headingCont {
          display: none;
        }
      }

      &:first-of-type {
        .tooltip_wrapper {
          .headingCont {
            display: block;
          }
        }
      }
    }
  }

  .legend-container {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    position: relative;
    bottom: 25px;

    .legend {
      display: flex;
      column-gap: 7px;
      align-items: center;

      .color {
        height: 7px;
        width: 7px;
      }

      .key {
        font-size: $fs-9;
      }
    }
  }
}

.tooltip_wrapper {
  & > span {
    font-size: $fs-10;
    color: $primary-grey-light;
    font-weight: 500;

    b {
      font-weight: bold;
    }

    label {
      display: block;
      max-width: calc(100% - 50px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.partner-settings-form {
  .createForm {
    .upload-input-wrapper {
      &.manage-logos {
        .image-container {
          .image-alt {
            background-color: #fff;

            span {
              background-color: #fff;
              border: 1px solid #dedede;
              color: #898a90;
              font-size: 11px;
              padding: 15px 20px;
            }

            &.Favicon {
              border: 1px solid #dedede;
              height: 16px;
              position: relative;
              width: 16px;

              &::after {
                bottom: -28px;
                color: #898a90;
                content: "Favicon";
                font-size: 11px;
                left: -10px;
                position: absolute;
              }
            }
          }
        }
      }
    }
  }
}

.hide {
  display: none !important;
}

.support-dialog {
  &.creation-form {
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;

    .dialog-header {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    svg#ic-cancel {
      top: -9px;
    }
  }
}

div.MuiPaper-root:has(.custom_select) ul.MuiList-root {
  margin-bottom: 58px !important;
  overflow-y: auto !important;
  position: static;
  border-radius: 0 0 4px 4px;
  border: 1px solid #e6e6e6;

  li {
    padding: 5px;

    & > div {
      text-align: left;
      margin-left: 5px;
    }

    & > span {
      svg {
        height: 17px;
        margin-right: 5px;
        width: 17px;
      }
    }
  }

  .multiButtons {
    button {
      &:last-child {
        color: #fff;
      }
    }
  }
}

span.dropDown-container {
  position: relative;
}

.download_Wrapper {
  position: relative;
}

.dashboard_wrapper {
  &.customer-management-wrapper {
    .list-wrapper {
      & > .bg-white {
        padding: 20px;

        > .refresh-btn {
          position: relative;
          top: 8px;
        }

        .customer_table {
          box-shadow: none;

          &.Striped-Table {
            border: 1px solid #e7ecf5 !important;
            border-radius: 4px;
          }
        }
      }
    }

    svg {
      overflow: visible;
    }

    .table-wrapper-V2 {
      .table-container {
        .Striped-Table {
          table {
            .table-header th {
              min-width: 150px;
            }
          }
        }
      }
    }
  }
}

button {
  &.v3 {
    cursor: pointer;
    padding: 10px 15px;
    width: auto;
    height: auto;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    border: 1px solid #0a3ca2;
    line-height: 12px;
    &.outlined {
      color: #0a3ca2;
      background-color: #fff;
      &:hover {
        background-color: #f2f9ff;
        box-shadow: 0px 5px 5px rgba(10, 60, 162, 0.1019607843);
        border: 1px solid #0a3ca2;
      }
    }
    &.filled {
      background-color: #0a3ca2;
      color: #fff;
    }
    &:disabled {
      cursor: not-allowed;
      color: #aaa;
      background: #eee;
      border: none;
      &:hover {
        background: #eee;
        border: none;
        box-shadow: none;
      }
    }
  }
  &.v3-link {
    cursor: pointer;
    font-size: 11px;
    font-weight: 500;
    text-decoration: underline;
    color: #0a3ca2 !important;
    &[disabled] {
      cursor: not-allowed;
      color: #aaa !important;
      text-decoration: none;
    }
  }
}
