
    .billing_console_header.wrapper {
      .Mui-disabled {
          color: #0A3CA2 !important;
          opacity: 0.7 !important;
        }
  
        .month-dropdownCont > label{
          z-index: 2;
        }
  
        .disabled{
          cursor: default;
        }
  }
  
  .billing_console_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 17px;
    gap: 10px;
    button.MuiButtonBase-root{
      background: #fff;
      box-shadow: none!important;
      font-family: Roboto,sans-serif;
      font-size: 12px!important;
      font-weight: 400;
      height: 34px !important;
      letter-spacing: inherit;
      margin-left: 10px;
      margin-right: 0!important;
      outline: none;
      padding: 0 11px;
      transition: .2s;
    }
  }
  .costBreakup{
    .billing_console_header{
      button.MuiButtonBase-root{
        padding: unset;
      }
    }
  }
  
  .wrapper {
    padding: 25px 20px;
    &.billing_console_header{
      padding-top: 20px;
      padding-bottom: 17px;
    }
  }
  
  .month-dropdownCont {
    position: relative;
    & > label {
      font-weight: 300;
      left: 10px;
      padding: 2px 5px;
      top: -7px;
      line-height: 1;
      text-align: left;
      color: #0a3ca2;
      position: absolute;
      font-size: 11px;
      background: #fff;
      z-index: 1;
    }
    .custom_selectbox {
      padding: 7.6px 5px 7.6px 14px;
      font-size: 13px;
      font-weight: 500;
      border: 0;
      border-radius: 4px;
      background: #ffffff;
      min-width: 158px;
      padding-right: 5px;
      border-right: 10px solid transparent;
      outline: solid 1px #dddfe7;
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance : none;
      option {
        // font-size: 14px;
        background: #fff;
        padding: 10px 0;
        display: block;
      }
    }
    &:after {
      content:"";
      background: url(../../Assets/icons/dropdown_arrow.svg) no-repeat ;
      background-size: auto;
      position: absolute;
      right: -4px;
      width: 20px;
      height: 18px;
      top: 65%;
      pointer-events: none;
      transform: translate(-50%, -50%);
    }
  }
  
  //cost breakup : 
  .child_table {
    position: relative;
    min-height: 25vh;
    background: #ffffff;
    .background_child_table {
      min-height: unset;
    }
  }
  .dataTransferSummary_heading {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 12px;
  }
  .costBreakup .breadcrums_wrapper li:not(:first-child) {
    pointer-events: none;
  }
  
  .accountwise .breadcrums_wrapper li:not(:first-child) {
    pointer-events: none;
  }
  .billingConsole .breadcrums_wrapper li:not(:first-child) {
    pointer-events: none;
  }
  .notification .breadcrums_wrapper li:not(:first-child) {
    pointer-events: none;
  }
  
  .tagReport .breadcrums_wrapper li:not(:first-child) {
    pointer-events: none;
  }
  .reservation .breadcrums_wrapper li:not(:first-child) {
    pointer-events: none;
  }
  
  #pointer_none {
    pointer-events: none;
    cursor: not-allowed;
  }
  .new-background {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 0 4px 4px 4px;
    box-shadow: 0 7px 7px 0 rgba(0,0,0,.06);
    margin-bottom: 20px;
    min-height: 60vh;
    position: relative;
    padding: 0px !important;
    .cost_breakup_wrapper {
      margin: 2px 0 !important;
      td,
      th {
        padding: 8px 10px!important;
      }
    }
    td,
    th {
      padding: 8px 10px !important;
    }
  }
  .costBreakup {
    .react-tabs {
      padding: 5px 20px !important;
    }
  }
  .costBreakup_storage {
    table th:first-child,
    table td:first-child {
      text-align: left;
      font-weight: 500;
      // width: 30% !important;
    }
  }
  
  .new-background.no_min_height{
    min-height: 40vh;
  }

  .costBreakup {
    .new-background{
      min-height: 100px;
      &:has(.no_data_found),&:has(.loader_wrapper){
        min-height: 40vh;
      }
    }
  }
  
  .react-tabs {
    -webkit-tap-highlight-color: transparent;
  
    &__tab-list {
      padding: 0;
      font-size: 13px;
    }
  
    &__tab {
      display: inline-block;
      bottom: -1px;
      position: relative;
      list-style: none;
      margin: 0;
      padding: 8px 20px;
      font-size: 12px;
      color: #555b6c;
      font-weight: 400;
      min-width: 100px;
      outline: none;
      background: #ffffff;
      margin-right: 5px;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      text-align: center;
      border: 1px solid #e6e6e6;
      border-bottom: none;
      cursor: pointer;
      transition: all 0.2s ease;
  
      &--selected {
        background: #fff;
        border-color: #e6e6e6;
        color: black;
        border-radius: 5px 5px 0 0;
        box-shadow: -2px -3px 9px 0 rgb(0 0 0 / 8%);
        font-weight: bold;
        position: relative;
        &::after {
          position: absolute;
          content: "";
          width: 100%;
          height: 1px;
          background: white;
          bottom: 0px;
          left: -0.2px;
          z-index: 2;
        }
      }
      &:hover {
        background: #fff;
        border-color: #e6e6e6;
        color: black;
        box-shadow: -2px -3px 9px 0 rgb(0 0 0 / 8%);
        position: relative;
      }
  
      &--disabled {
        color: GrayText;
        cursor: default;
      }
  
      &:focus {
        background: #fff;
        border-color: #e6e6e6;
        color: black;
        outline: none;
        box-shadow: -2px -3px 9px 0 rgb(0 0 0 / 8%);
  
        // &:after {
        //   content: "";
        //   position: absolute;
        //   height: 5px;
        //   left: -4px;
        //   right: -4px;
        //   bottom: -5px;
        //   background: #fff;
        // }
      }
    }
    padding: 20px;
    &__tab-panel {
      display: none;
  
      &--selected {
        display: block;
        padding: 20px 20px 0 20px;
      }
    }
  }

  #tabs-cont{
    .react-tabs__tab:focus{
     &::after{
      content: "";
      position: absolute;
      height: 5px;
      left: 0 !important;
      right: 0 !important;
      bottom: -2px !important;
      background: #fff;
     }
    }
  }
  
  .utilTabPanel {
    position: relative;
    box-shadow: 0 7px 7px 0 rgb(0 0 0 / 6%);
    background-color: #ffffff;
    border-radius: 0 4px 4px 4px;
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;
    padding: 0px;
    padding-top: 0;
    min-height: 500px;
    .reservation-utilization-table-wrapper {
      overflow: auto;
      position: relative;
      min-height: 250px;
      table.utilization-table-wrapper{
        margin-top: 0;
      }
    }
    .daterangepicker {
      z-index: 10;
    }
  }
  .utilization_banner {
    display: flex;
    border-bottom: dashed 1.6px #b2bdd1;
    padding: 20px;
    padding-top: 0;
  
    .utilization_banner_left {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      padding-right: 20px;
      max-width: calc(100% - 173px);
      .utilization_banner_graph {
        height: 100%;
        border-radius: 4px;
        border: solid 1px #e6e6e6;
        position: relative;
      }
    }
  
    .utilization_banner_right {
    }
  }
  .graph_heading {
    border-bottom: 1.2px solid #e6e6e6;
    line-height: 1;
    padding: 9px 15px 9px 18px;
    font-size: 12px;
    display: flex;
    align-items: center;
    .chart_view_type {
      margin-left: auto;
    }
  }
  .chart_view_type {
    display: flex;
    height: 25px;
    .chart_tab {
      width: 30px;
      height: 25px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      border: solid 1px #e6e6e6;
      cursor: pointer;
      color: #000;
      &:first-of-type {
        border-radius: 4px 0 0 4px;
      }
      &:last-of-type {
        border-radius: 0 4px 4px 0;
      }
    }
    .chart_tab.active {
      background: #eef6fe;
      border-color: #e2edf7;
      color: #1c50bc;
    }
  }
  .utilization_banner_left_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 18px;
    .utilization_banner_left_heading_text {
      font-size: 16px;
      font-weight: bold;
      margin: 0;
    }
    .utilization_banner_right_heading{
      display: flex;
      align-items: center;
      .info_tooltip{
        color:#1c50bc;
        font-size: 11px;
        width: 285px;
        margin-right: 8px;
        text-align: right;
        display: none;
        line-height: normal;
      }
      .infoIcon{
        margin-right: 10px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        &:hover{
        .info_tooltip{
          display: block !important;
        }
      }}
    }
  }
  .reservation_wrapper {
    width: 100%;
    clear: both;
    .react-tabs__tab-list {
      margin-bottom: 0;
    }
  }
  #dateRangePicker {
    margin-right: 193px;
    .clear_selection{
      top:54px;
      right:20px;
    }
  }
  button.dateRange_pickerBtn {
    border-radius: 4px;
    border: solid 1px #dddfe8;
    background-color: #fff;
    line-height: 1;
    cursor: pointer;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    text-align: left;
    color: #000;
    height: 31px;
    padding: 0;
    outline: none;
    label.addOn {
      background: #f0f1f5;
      width: 34px;
      height: 28px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      cursor: pointer;
    }
    .dateText {
      padding: 0 10px;
      font-size: 11px;
      font-weight: 500;
      font-family: "Roboto", sans-serif;
    }
  }
  .utlization_table_heading {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    p {
      color: #333333;
      font-size: 14px;
      font-weight: bold;
    }
    Button {
      font-size: 12px;
      font-weight: 400;
    }
  }
  
  .disable-date{
    pointer-events: none;
    button{
      opacity: 0.3;
    }
  }

  #expand_height{
    height: unset;
  }
  
  .costBreakup{
    .react-tabs__tab--selected{
      font-weight: 700;
      background: $primary-white !important;
      border-radius: 5px 5px 0 0;
      color: #000;
      padding: 8px 20px !important;
    }
  }
