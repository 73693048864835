.v2_label_style {
    color: #0A3CA2;
    font-family: Roboto;
    font-size: 10px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
}

.download_button_style {
    border-radius: 4px;
    border: 1px solid var(--Blue, #0A3CA2);
}

.v2_custom_select {
    padding-top: 9px !important;
    padding-bottom: 5px !important;
    min-width: 128.118px !important;
}

.no-margin-right {
    margin-right: 0px !important;
}

div.lens-filter-style {
    .month-dropdownCont {
        label {
            display: none;

        }

        &::after {
            right: -9px !important;
        }

        select {
            padding: 8px 8px 8px 12px !important;
            color: #2B2B2B !important;
            outline: 1px solid #E6ECF1 !important;
            font-family: Inter !important;
            line-height: 14.52px !important;
            min-height: 32px !important;
            border-radius: 5px !important;
            min-width: 140px !important;
        }
    }

    button.MuiButtonBase-root:nth-child(2) {
        margin-left: 0px !important;
        border: 1px solid #E6ECF1 !important;
        color: #2B2B2B !important;
        padding: 8px 8px 8px 12px !important;
        font-family: Inter !important;
        line-height: 14.52px !important;
        border-radius: 5px !important;

        &:hover {
            background-color: white !important;
            color: #4698D3 !important;
            border: 1px solid #4698D3 !important;

            &::before {
                background: url('../../../../../Assets/icons/dropdownDownloadActive.svg') no-repeat center;

            }

        }

        &::before {
            content: "";
            background: url('../../../../../Assets/icons/dropdownDownload.svg') no-repeat center;
            background-size: contain;
            width: 10px;
            height: 13px;
            display: inline-block;
            margin-right: 7px;
            /* Space between text and image */
            vertical-align: middle;
        }

        svg {
            display: none;
        }
    }
}