@import "../../../../styleguide/contant.scss";

.Calendar_V2{
    .daterangepicker {
        border: solid 1.2px #dbdbdb;
        border-radius: 5px;
        padding: 11px 15px;
        box-shadow: 0px 2px 9px 0px #0F1F4066;
        cursor: default;
        min-width: 455px;
        th.month {
          text-transform: none !important;
          select.monthselect,
          select.yearselect {
            padding: 0 !important;
            border: 0;
            width: auto;
            margin: 0;
            background: transparent;
            outline: none;
            font-size: 10px;
            font-weight: 600;
          }
        }
        .drp-buttons {
          padding: 0;
          padding-top: 10px;
          border-top: 1px solid #F2F2F2;
      
          .btn {
            width: auto;
            padding: 6px 8px;
            line-height: 1;
            outline: none;
            cursor: pointer;
            font-size: 11px;
            font-weight: 400;
          }
          button[disabled] {
            opacity: 0.65;
            cursor: not-allowed;
          }
          .btn.applyBtn {
            color: rgb(255, 255, 255);
            background: rgb(10, 60, 162);
            border-radius: 5px;
            border: 1px solid rgb(10, 60, 162);
          }
          .btn.cancelBtn {
            color: rgb(10, 60, 162);
            border-color: rgb(10, 60, 162);
            background: rgb(255, 255, 255);
            border-radius: 5px;
            border: 1px solid rgb(10, 60, 162);
          }
          .drp-selected {
            display: none;
          }
        }
        td.active:not(.off.ends),
        td.active:not(.off.ends):hover {
          color: #ffffff;
          -webkit-box-shadow: -5px 6px 9px 0 rgba(51, 122, 183, 0.32);
          box-shadow: -5px 6px 9px 0 rgba(51, 122, 183, 0.32);
          background-image: -o-linear-gradient(134deg, #337ab7 85%, #338ddb 39%);
          background-image: linear-gradient(316deg, #337ab7 85%, #338ddb 39%);
        }
        td.in-range {
          color: rgb(0, 0, 0);
          box-shadow: inherit;
          background: #B0CEFF99 !important;
        }
        td.in-range:hover {
          color: rgb(0, 0, 0);
          background: rgb(204, 227, 246);
        }
        td.start-date:not(.off.ends),
        td.end-date:not(.off.ends) {
            box-shadow: unset !important;
          color: rgb(255, 255, 255) !important;
          background: #0061FF !important;
        }
        td.off,
        td.off.start-date,
        td.off.end-date {
          color: #ffffff;
        }
    }
    
    .daterangepicker {
      padding-top: 100px !important;
      &.show-calendar{
        .calendar-table table {
          margin-bottom: 60px;
        }
      }
    }

    button.dateRange_pickerBtn{
      .dateText {
        padding: 10px ;
      }
    }

    .daterangepicker.show-calendar {
        .ranges {
          margin-top: 0;
          width: 100%;
          position: absolute;
          bottom: 45px;
          left: 0;
          ul {
            display: block !important;
            width: 100%;
            padding-left: 0;
            padding: 10px 15px;
            padding-top: 25px;
            position: relative;
            // border-top:1px solid ;
            &::before {
                position: absolute;
                content: "Select Range";
                left: 17px;
                top: 15px;
                transform: translateY(-50%);
                color: #000;
                font-size: 10px;
                font-weight: 500;
            }
            &:after{
                content: "";
                position: absolute;
                top: 0px;
                left: 13px;
                background-color: #F2F2F2;
                width: 94%;
                height: 1px;
            }
            li {
                margin-right: 0;
                cursor: pointer;
                width: auto;
                height: auto;
                border-radius: 0;
                border: none;
                background-color: transparent;
                font-size: 10px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: #171717;
                text-transform: none;
                padding: 8px;
              &:hover {
                background-color: #EFF4FF;
                color: #0a3ca2;
                border-radius: 4px;
              }
              &:last-of-type {
                display: none;
              }
            }
            li#customActive {
              border-radius: 4px;
              box-shadow: unset;
              border: 0;
              background-color: #eef6fe;
              color: #0a3ca2;
              font-weight: bold;
              padding: 8px;
            }
          }
        }
        .drp-calendar {
          display: block;
          padding: 0px !important;
          width: 50% !important;
          max-width: 100%;
          border: 0;
          border-radius: 4px;
          margin-left: 0 !important;
          margin-right: 0 !important;
          margin-bottom: 0;
          min-width: 205px;
        //   position: relative;
        //   &:after{
        //     content: "";
        //     position: absolute;
        //     bottom: 50px;
        //     margin: 5px 0;
        //     width: 100%;
        //     height: 1px;
        //     left: 0;
        //   }
        }
        .drp-calendar.left {
          padding-right: 7.5px !important;
          border-left: 0 !important;
        }
        .drp-calendar.right {
          padding-left: 7.5px !important;
        }
        // .drp-selected {
        //   display: inline-block;
        //   font-size: 12px;
        //   padding-right: 8px;
        // }
        .calendar-table {
          padding: 0 !important;
          font-family: "Roboto", sans-serif;
          table {
            // border-spacing: 0;
            // border-collapse: separate;
            padding: 0 6px;
            border: none;
            border-radius: 5px;
            max-width: 205px;
            background-color: #F8F9FC;
            border-collapse: initial;
            td:last-child,
            th:last-child {
              font-size: 10px;
              color: currentColor !important;
              background-color: transparent;
            }
            td {
              font-size: 10px;
              border-radius: 50%;
              font-weight: 400 !important;
              color: #1F1F1F !important;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            td.active:not(.off.ends) {
              color: #fff !important;
            }
            td.disabled {
              color: #b9becc !important;
              font-weight: normal !important;
              background-color: transparent;
            }
            tbody {
              tr {
                padding: 2px 4px;
                &:first-of-type {
                  padding-top: 10px;
                }
                &:last-of-type {
                  padding-bottom: 10px;
                }
              }
            }
            tr {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-pack: justify;
              -ms-flex-pack: justify;
              justify-content: space-between;
              width: 100%;
            }
            th,
            td {
              height: 18px;
              max-width: 18px;
              max-height: 18px;
              width: 18px;
              padding: 0;
              min-width: inherit;
              color: #555b6c;
            }
            thead {
              tr {
                th.next,
                th.prev {
                  padding: 0;
                  -webkit-box-pack: center;
                  -ms-flex-pack: center;
                  justify-content: center;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  color: rgb(53, 93, 177);
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  font-size: 12px !important;
                  background: rgb(224, 241, 255);
                  border-width: 1px;
                  border-style: solid;
                  border-color: rgb(53, 93, 177);
                  -o-border-image: initial;
                  border-image: initial;
                  border-radius: 50%;
                  span {
                    margin-left: -1px;
                    padding: 2px;
                    border-color: #000;
                    margin-top: 0px;
                  }
                }
                th.month {
                  height: 23px;
                  text-transform: uppercase;
                  color: rgb(0, 0, 0);
                  font-weight: 500;
                  font-size: 12px !important;
                  -webkit-box-flex: 1;
                  -ms-flex: 1 1 0%;
                  flex: 1 1 0%;
                  max-width: inherit;
                  background: transparent;
                  margin-top: -2px;
                  align-items: center;
                  display: flex;
                  justify-content: center;
                }
              }
              tr:first-of-type {
                background-image: none;
                padding: 10px 0 6px 0;
                border-radius: 0;
                border-bottom: 0.5px solid #C5CBD8;
                th {
                  background: transparent !important;
                }
                th.prev {
                  padding-left: 3px !important;
                  background: #fff !important;
                }
                th.next {
                  padding-right: 1px !important;
                  background: #fff !important;
                }
                th.next.available,
                th.prev.available {
                    background: #B4BCCB !important;
                    border-color: transparent;
                    color: #000;
                }
                th:first-of-type,
                th:last-of-type {
                  height: 16px;
                  max-width: 16px !important;
                  width: 16px !important;
                }
              }
              tr:last-of-type {
                padding: 4px ;
                border-bottom:none;
                th {
                    text-transform: uppercase;
                    margin-bottom: 0;
                    color: #1F1F1F !important;
                    background: transparent !important;
                    font-size: 10px !important;
                    font-weight: 600 !important;
                    text-transform: capitalize;
                  &:hover {
                    color: #000 !important;
                  }
                }
              }
            }
          }
        }
      }
      .off.ends.available {
        color: black !important;
      }
      
      td.off {
        visibility: hidden !important;
      }
      td.off.ends.off.disabled {
        visibility: hidden !important;
      }
      
      td.off.disabled {
        visibility: unset !important;
      }
      .clear_selection{
        svg{
            display: none;
        }
      }
      .daterangepicker:after {
        top: -6px;
        border-right: 5px solid transparent;
        border-bottom: 8px solid #fff;
        border-left: 5px solid transparent;
    }
    
    .daterangepicker.opensleft:before,.daterangepicker.opensleft:after {
        right: 3.5%;
    }
    
    .daterangepicker:before {
        top: -7px;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-bottom: 7px solid #ccc;
    }

    .clear_selection {
        right: 15px !important;
        p {
            color: #0a3ca2;
            font-size: 10px;
            font-weight: 700;
            margin-right: 5px;
        }
        &.calendar_heading{
            right: unset;
            left: -110%;
            top: 54px !important;
            border-bottom: 1px solid #F2F2F2;
            padding-bottom: 10px;
            cursor: default;
            pointer-events: none;
            p{
                color: #000;
                font-size: 12px;
                margin-left: 5px;
            }
        }
    }
    .drp-inputForm{
      position: absolute;
      top: 90px;
      z-index: 9999;
      left: -108.5%;
      display: none;
      pointer-events: none;
      .createForm{
        &>div{
          display: flex;
          .input-field-container{
            width: 205px;
            padding-right: 3px;
            margin-right: 12px;
            input{
              height: 23px;
              pointer-events: auto;
              padding: 5px !important;
              font-size: 10px;
              font-weight: 400;
              margin: 3px 0 6px 0 !important;
              &::placeholder{
                font-size: 10px;
                font-weight: 400;
              }
            }
            .error_message{
              font-size: 9px !important;
            }
            span {
              font-size: 10px;
              font-weight: 400;
              color: #000;
            }
          }
        }
      }
    }
}

@media screen and (max-width: 1024px) {
  .Calendar_V2 {
    display: inline-block !important; /* New styles for iPad-sized screens */
  }
}
  .drp-calendar {
    margin-bottom: 14px !important;
  }

  .daterangepicker {
    min-width: auto;
  }
  li#customActive {
    border-radius: 6px;
    box-shadow: -4px 7px 17px 0 rgb(10 60 162 / 25%);
    border: solid 1px $primary-blue-dark;
    background-color: #eef6fe;
    color: $primary-blue-dark;
    font-weight: bold;
    padding: 0;
  }