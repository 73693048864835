.status-message {
  * {
    font-family: "Inter", "sans-serif" !important;
  }

  background-color: #fff;

  .no-response {
    width: 134.25px;
    height: 100px;
  }

  .cloud-loader {
    width: 132px;
  }

  h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: #23324E;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: #23324E;



  }
}

.status-message.error {
  p {
    a {
      color: #4398d7;

      &:hover {
        color: #4398d7;
      }
    }
  }
}