@import "../../../styleguide/contant.scss";

.resend-email-wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: auto;
  background-color: $primary-white;
  padding: 30px 0 40px;
  justify-content: center;
  position: relative;

  .logo-wrapper {
    width: 100%;
    text-align: center;
    min-height: 50px;
    max-height: 90px;
    img {
      margin: auto;
      max-width: 160px;
    }
  }

  .resend-body-wrapper {
    // text-align: center;
    width: 45%;
    // margin: auto;
    .resend-heading {
      .primary-heading {
        font-size: $fs-22;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #222;
        margin: 0;
        width: 100%;
        text-align: center;
      }
      .secondary-heading {
        letter-spacing: normal;
        word-spacing: 1.5px;
        font-size: $fs-16 !important;
        font-weight: 300;
        line-height: 2.25;
        margin: 0;
        color: #626262;
        width: 100%;
        text-align: center;
      }
    }

    .image {
      width: 158px;
      height: 136px;
      margin: 60px 0 47px;
      width: 100%;
      img {
        margin: auto;
      }
    }
    .body-foter {
      p {
        font-size: $fs-14;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: center;
        color: #626262;
        margin: 10px 0;
      }
    }
  }
  .resend-footer {
    // padding-bottom: 60px;
    // width: 100%;
    text-align: center;
    .resend-button {
      padding: 13px 30px;
      // margin-bottom: 13px;
      font-size: $fs-14 !important;
      border: none;
      font-family: "Roboto", sans-serif;
      height: 52px;
      font-weight: 500;
      outline: none;
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 0.52px;
      border-radius: 4px;
      background-color: $primary-blue-dark;

      &:hover {
        // background: transparent linear-gradient(90deg, $primary-blue-dark 0%, #0a3da2 45%, #0a50a2 100%) 0% 0%
        //   no-repeat padding-box;
        box-shadow: -6px 13px 15px rgba(10, 60, 162, 0.2901960784);
      }
    }
    .resend-button.resend-disable {

      background-color: #626262;
      &:hover {
        // background: transparent #626262 0% 0%
        // no-repeat padding-box; ;
        box-shadow: none;
      }
    }
    .countdown {
      height: 20px;
      margin-top: 12px;
      font-size: $fs-14;
      color: #174ea2;
      font-weight: 700;
    }
  }
}


.bold-text {
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.ck-auto-wrapper {
  padding-top: 0 !important;
  padding-bottom: 52px !important;
  .logo-wrapper {
    min-height: 70px;
    max-height: 90px;
  }
  .resend-footer {
    .resend-button {
      background: #4398d7 !important;
      color: $primary-white;
      margin-bottom: 20px;
      &:hover {
        box-shadow: -6px 13px 15px #0a3ca24a;
      }

    }
  }
}
