@import "../../../../styleguide/contant.scss";

.Graph_table_wrapper {
  .Graph_table_heading {
    padding: 8px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .heading_wrapper {
    align-items: center;
    .sub_heading {
      margin-left: 10px;
    }
  }
  .data_renderer {
    min-height: 150px;
    position: relative;

    .switch_toggler{
      position: absolute;
      right: 20px;
      top: 2px;
      z-index: 1;
      p.MuiTypography-root.MuiTypography-body1{
        font-size: $fs-12 !important;
        color: $primary-blue-dark !important;
        font-weight: 400;
        letter-spacing: 0;
        &.grayColor{
        color: $muted-color !important;
      }
      }

    }
  }
  .table_container_wrapper {
    .table_wrapper {
      overflow: auto;
      table {
        tbody td:first-child {
          color: black;
          font-weight: normal !important;
          font-size: 0.7em;
        }
        thead {
          tr {
            th {
              height: 42px;
            }
          }
        }
        tr {
          td,
          th {
            border-top: 1px solid $secondary-blueish-grey;
            border-right: 1px solid $secondary-blueish-grey;
            border-bottom: 0;
            vertical-align: middle;
            &:last-of-type {
              border-right: 0;
              min-width: 64px;
            }
          }
        }
        tfoot {
          tr {
            td {
              font-size: $fs-12;
              // background-image: linear-gradient(
              //   to bottom,
              //   $secondary-blus,
              //   #f6fbff 50%,
              //   $primary-white
              // );
              background-color: #F0F9FF;
              border-top: solid 1px $secondary-blueish-grey !important;
              border-bottom: 0 !important;
              border-left: solid 1px $secondary-blueish-grey !important;
              // &:last-of-type {
              //   background: #eaf4fc;
              // }
            }
          }
        }
      }
    }
    padding: 14px 0 0 0;
    table {
      th {
        padding: 10.5px 0px;
        &:first-child {
          padding-left: 10px;
        }
      }
      td {
        &:first-child {
          white-space: nowrap;
          padding: 8.9px;
        }
        font-size: 0.7em;
        padding: 8.9px 0;
        min-width: 62.8px;
      }
    }
    &>.table_wrapper.lens_table {
      max-height: calc(100vh - 362px);

      table {
        border-collapse: separate;

        thead th {
          position: sticky;
          top: 0;
          z-index: 2;
          border-bottom: solid 1px $secondary-blueish-grey !important;
        }

        tr:first-child {
          td {
            border-top: 0px;
          }
        }

        tfoot td {
          position: sticky;
          bottom: 0;
          z-index: 2;
        }
      }
    }
  }

  tfoot td:first-child {
    color: $heading-txt-color;
    font-weight: bold !important;
    font-size: $fs-12;
    background-color: #e9f4fd;
    border: none !important;
  }

  tfoot td:not(:first-child) {
    border: none !important;
    color: $primary-blue-dark;
    font-size: $fs-12;
    font-weight: bold !important;
    background-color: #e9f4fd;
  }
}
  table {
    // border-collapse: collapse;
    width: 100%;
    tfoot {
      tr {
        td {
          height: 32px;
        }
      }
    }
  }
  
  table td,
  table th {
    padding: 8px;
    right: none;
    font-size: 0.75em;
    text-align: center;
    padding: 12px;
  }
  
  table th {
    color: $heading-txt-color;
    font-size: 0.7em;
    text-transform: capitalize;
    font-weight: 500 !important;
    padding: 15px 10px;
    background-color: #f8f8f8 !important;
  }
  
  table th:first-child,
  table td:first-child {
    text-align: left;
    font-weight: 500;
    // width: 40%;
  }
  
  table th:last-child,
  table td:last-child {
    // font-weight: 500 !important;
    // border: 1px solid #f8f8f8;
    // border-left: 2px dashed #f8f8f8 !important;
    // color: $primary-blue-dark;
    // border-right: 0;
    // font-size: $fs-12 !important;
  }
  table td:last-child{
   // background-color: $primary-white;
  }
  
  .data_table_wrapper {
    //   height: 77vh;
    overflow: auto;
    margin-top: 25px;
  }
  
  .green {
    color: $accent-green-dark;
    background-color: #f4fcf5;
    font-weight: 500;
  }
  .circle.green {
    background: #5cb85c;
  }
  .circle.white {
    background: $primary-white;
    border: solid 1px #c3c3c3;
  }
  .circle.amber {
    background: $accent-dark-orange;
  }
  .circle.red {
    background: $accent-red;
  }
   .blue {
    background-color: #f4faff;
    color: $primary-sky-blue;
    font-weight: 500;
  }
   .amber {
    background-color: #fffaf4;
    color: $accent-dark-orange;
    font-weight: 500;
  }
  .white {
    color: $heading-txt-color;
  }
  .red {
    color: $accent-red;
    background-color: #fff8f8;
    font-weight: 500;
  }
  
  .colorCodes_container {
    justify-content: flex-end;
    margin-right: 20px;
    h4{
      font-size: 12px;
      color: $primary-grey-light;
      font-weight: 400;
    &.container_v2 {
      justify-content: space-between;
    }
  }
  .colorCodes {
    align-items: center;
    font-size: 0.75em;
    color: $primary-grey-light;
    white-space: nowrap;
    margin: 0;
    margin-left: 21px;
  }
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }
  }
