.welcome-container{
    width: 100%;
    height: 100vh;
    .page-content{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    
    .logo{
        width: 300px;
    }
}