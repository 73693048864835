.lens-plus-customer-button {
    &.MuiButtonBase-root {
        margin-left: 0px !important;
        border: 1px solid #E6ECF1 !important;
        color: #2B2B2B !important;
        padding: 8px 8px 8px 12px !important;
        background-color: white !important;

        &:hover {
            background-color: white !important;
            color: #4698D3 !important;
            border: 1px solid #4698D3 !important;

            &::before {
                background: url('../../../../Assets/icons/dropdownDownloadActive.svg') no-repeat center;
            }
        }

        &::before {
            content: "";
            background: url('../../../../Assets/icons/dropdownDownload.svg') no-repeat center;
            background-size: contain;
            width: 10px;
            height: 13px;
            display: inline-block;
            margin-right: 7px;
            /* Space between text and image */
            vertical-align: middle;
        }

        svg {
            display: none;
        }
    }
}