@import "../../../../../styleguide/contant.scss";

.multi-level-table-wrapper {
  overflow: auto;

  .info-span{
    svg{
      margin-right: 5px;
      vertical-align: middle;
    }
    color:$primary-white;
    font-size: $fs-13;
    text-align: center;
    background-color: $primary-blue-dark;
    padding: 8px;
    margin-left:-13px;
    margin-right: -13px;
  }
  .utilization-table-wrapper {
    margin: 10px;
    white-space: nowrap;
    border: 1px solid $secondary-blueish-grey;
    box-sizing: border-box;
    width: 100%;
    table th:first-child,
    table td:first-child {
      text-align: left;
      font-weight: 500;
      width: auto;
    }

    .utilization-table-head {
      // width: 100% !important;
      padding: 8px 10px;
      
      .utilization-table-heading {
        background-color: #f8f8f8;
        padding: 8px 10px;
        height: 33px;
        font-weight: 500;
        // border-bottom: 1px solid $secondary-blueish-grey;
        font-size: $fs-12;
        white-space: nowrap;
        cursor: pointer;
        // min-width: 100px;
        // text-align: center;
        // height: 49px;

        &:first-child {
          // text-align: left;
          // min-width: 200px;
        }
        th:last-child,
        th {
          color: $heading-txt-color !important;
        }
        & > svg {
          width: 12px;
          height: 12px;
          padding-left: 5px;
        }
      }
    }

    .utilization-first-level {
      // width: 100%;
      font-size: $fs-11;
      color: $heading-txt-color;
      min-width: 100px;
      border-bottom: 1px solid $secondary-blueish-grey;
      padding: 11px 13px;
      text-align: center;
      background-color: $primary-white;
      &:last-child{
        color: #000;
        border-left: 1px solid #FFFFFF !important;
      }
    }
    
    .display-none {
      height: 0;
      display: none;
    }

    .utilization-reservation-V2 {
      background: linear-gradient(
        to right,
        rgba(255, 0, 0, 0) 70%,
        $secondary-blueish-grey 160%
      );
      font-size: $fs-11;
      width: 100%;
      padding: 11px 13px;
      border-bottom: 1px solid $secondary-blueish-grey;
      font-weight: 500;
      cursor: pointer;
      // max-width: 200px;
      overflow: hidden;
      &:first-child {
        text-align: left;
        width: 200px;
      }
      & > svg {
        width: 8px;
        height: 8px;
        margin-top: -2px;
        margin-right: 8px;
        fill: $heading-txt-color;
        margin-left: 4px;
        transition: all 0.2s ease;
      }
    }
    .utilization-reservation.expand {
      & > svg {
        transform: rotate(0deg);
        transition: all 0.2s ease;
      }
      &:first-child {
        color: $primary-blue-dark;
      }
    }

    .utilization-switch {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 2px 10px 7px 0;

      p {
        font-size: $fs-12;
        font-weight: bold;
        line-height: 1.17;
        letter-spacing: -0.24px;
        text-align: left;
        color: $primary-blue-dark;
      }
    }

    .innerdiv {
      // margin: 10px;
      margin-bottom: 19px;
      overflow: auto;

      .innerdiv-container {
        border: 1px solid lightgrey;
        border-radius: 4px;
        // margin: 0 10px;
        flex-wrap: wrap;
        display: flex;
        margin: 0 10px;
        padding: 10px 20.5px 0px 20px;
        background-color: #f8f8f8;
        margin: 0;
      }
      .innerdiv-data {
        padding: 4px 0;
        display: flex;
        align-items: center;
        border-bottom: 1px dashed #cfdde5;
        width: 25%;
        .innerdiv-data-left {
          font-size: $fs-10;
          padding: 10px 20px 10px 10px;
          width: 140px;
          color: $secondary-black !important;
          font-weight: 500;
        }
        .innerdiv-data-right {
          font-size: $fs-11;
          font-weight: 700;
          width: 100px;
          // width: 33.3%;
          color: black !important;
        }
      }
    }
  }

  .noDisableSwitch{
    .MuiSwitch-root {
      span.MuiSwitch-thumb{
        background:#144188 !important;
      }
      span.MuiSwitch-track{  
        background: #c3d6ea !important;
      }
    }
    .disable{
      color: $muted-color !important;
    }
  }
  .utilization-hours {
    background-color: #F0F1F3 !important;
    color: black;
    font-weight: 600;
    font-size: $fs-11 !important;
    border-bottom: 1px dashed #cfdde5;
    border-right: 1px dashed #cfdde5;
  }
  .utilization-innerTable-data {
    color: black !important;
    font-size: $fs-11 !important;
    border: 1px dashed #cfdde5;
    border-top: none;
  }
  .utilization-table-head {
    font-size: $fs-12 !important;
    color: $common-grey !important;
    border-bottom: 1px solid $secondary-blueish-grey;
    // border-top: 1px solid $secondary-blueish-grey;
    &:last-of-type{
      color: $primary-blue-dark !important;
    }
  }
  .utilization-inner-footer {
    &:first-child {
      color: $heading-txt-color !important;
      font-weight: bold;
    }
    font-size: $fs-12 !important;
    //background-image: linear-gradient(to bottom, $secondary-blus, #f6fbff 50%, $primary-white);
    border-top: 1px solid #cfdde5;
    background-color: #EEF6FE;
    color: #2052a7;
  }
  table th:last-child {
    font-size: $fs-12 !important;
    color: $heading-txt-color !important;
    font-weight: 500 !important;
  }
  table td:last-child {
    font-size: $fs-11 !important;
    color: $heading-txt-color !important;
    font-weight: 500 !important;
  }
  // .display {
  //   height: 150px;
  //   position: relative;
  // }
  .display.expand-bottom {
    height: 100px;
    position: relative;
    border-bottom: 1px solid $primary-blue-dark;
    border-left: 1px solid $primary-blue-dark;
    border-right: 1px solid $primary-blue-dark;
    &>td{
      div{
        .utilization-table-wrapper{
          width: calc(100% + 26px);
          margin-left: -13px;
          margin-bottom: 0;
          tr{
            th{
              &:first-child{
                width: 170px;
              }
            }
          }
        }
      }
    }
  }
}

.data.expand-top {
  border-top: 2px solid $primary-blue-dark;
  border-left: 2px solid $primary-blue-dark;
  border-right: 2px solid $primary-blue-dark;
}

.cost_breakup_wrapper{
  .utilization-first-level{
    &:last-child{
      color: #000000 !important;
    }
  }
}

.cost_breakup_wrapper.Striped-Table{
  table{
    tr{
      border-bottom: 1px solid #FFFFFF !important;
    }
    .inner-row-border{
      td{
        &:first-child{
          // text-align-last: end;
          padding-left: 15px !important;
          &::after{
            content: "";
            position: absolute;
            top: 0;
            border-right: 1px solid #FFFFFF !important;
            right: 1px;
          }
        }
        &:last-child{
          border-right: 0 !important;
        }
      }
    }
    .expand-top-V2{
      &:last-child{
        border-right: 1px solid #c3d8ff !important;
      }
      .utilization-first-level{
        background-color: #C3D8FF !important;
      }
      .utilization-reservation-V2{
        background-color: #C3D8FF !important;
      }
    }
  }
}

.utilization-outer-table{
  tr{
  border-bottom: 1px solid #FFFFFF !important;
  }
}

.data{
  td{
    &:first-child{
      &::after{
        position: absolute;
        border-right: 1px solid #FFFFFF !important;
        content: "";
      }
    }
    &:last-child{
        border-left: 1px solid #FFFFFF !important;
        color: #000000 !important;
      }
    }
  }

.data.expand-top-V2 {
  border-top: 1.5px solid #C3D8FF !important;
  border-left: 1.5px solid #C3D8FF !important;
  border-right: 1.5px solid #C3D8FF !important;
  td{
    background-color: #C3D8FF !important;
    &:last-child{
      border-left: 1px solid #FFFFFF !important;
      border-right: 1px solid #c3d8ff !important;
    }
  }
}

.utilization-disable {
  pointer-events: none;
}

.utilizationSwitch{
  border-left: 1.5px solid #C3D8FF !important;
  border-right: 1.5px solid #C3D8FF !important;
  border-bottom: 1.5px solid #C3D8FF !important;
  padding: 8px !important;
  background-color: #F9FDFF;
  .loader_wrapper{
    position: relative;
    transform: inherit;
    padding: 20px;
    text-align: center;
  }
  .btn_group{
    width: 144px !important;
    padding: 2px !important;
  }
  .utilization-table-head{
    background-color: #C3DAF2 !important;
    width:113px;
    height: 24px;
    padding: 5px;
    min-width: 37px;
  }
}

.dotted-border{
  border-bottom: dashed 1px #CFD9ED;
  margin-bottom: 10px;
}

.row-cell {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Vertically center items */
}

.icon {
  padding-top: 4px;
  padding-bottom: 4px;
  margin-right: 8px; /* Adjust the margin as needed */
  cursor: pointer;
}

.header-icon{
  margin-left: 8px;
  cursor: pointer;
}

.inner_table_container{
  background-color: #F9FDFF;
  .utilization-table-wrapper{
    z-index: 0;
    tr:hover {
      background-color: #cccccc; /* Gray background on hover for all rows */
    }
    tr:nth-child(odd){
      background-color: #ffffff !important;
    }
    tr:nth-child(even){
      background-color: #F7F7F7 !important;
    }
    tr{
      th{
        &:first-child{
          &::after{
            right: 0px !important;
          }
        }
        &:last-child{
          color: #FFFFFF !important;
        }
      }
      td{
        &:first-child{
          background-color: #F0F1F3;
          border-right: 1px solid #FFFFFF !important;
          &::after{
            right: 0px !important;
          }
        }
        &:last-child{
          border-left: 1px solid #FFFFFF !important;
        }
        border-bottom: 1px solid #FFFFFF !important;
      }
    }
  }
}

.usedPercentage {
  .MuiBox-root{
    max-width: none !important;
    span{
      background: none 0 0 no-repeat padding-box;
      height: 8px;
      span{
        background-color: #51A335;
      }
    }
  }
  p {
      color: #3C3C3C;
      font-size: 11px;
      font-weight: 400;
      margin-left: 4px;
  }
}

.sticky-right {
  position: sticky;
  right: 0;
}

.inner-table-lastitem{
  background-color: #EAF1FF !important;
  min-width: 51px !important;
}

.inner-table-Average-header{
  background-color: #0A3CA2 !important;
  color: #FFF !important;
  min-width: 51px !important;
}

.decrement-in-utilization-font-color{
  color: #DB1104 !important;
  font-weight: 400 !important;
}

.inner-row-data-no-data{
  background-color: #C7D3EB !important;
}

.inner-row-with-data{
  background-color: #C3D8FF !important;
}

.inner-row-border{
  border-left: 1.5px solid #C3D8FF;
  border-right: 1.5px solid #C3D8FF;
  pointer-events: none !important;
  td{
    border-top: 1px solid #FFFFFF !important;
    &:last-child{
      border-left: 1px solid #FFFFFF !important;
    }
    &:first-child{
      &::before{
        border-right: 1px solid #C3D8FF !important;
      }
    }
  }
}

.inner-table-last-column{
  th{
    &:last-child{
      background-color: #0A3CA2 !important;
      color: #FFFFFF !important;
    }
  }
}

.table-scroller{
  border-radius: 2px;
  width: 100%;
}

.utilization-inner-footer-V2{
  tr{
    td{
      &:first-child{
        background-color: #0A3CA2 !important;
        color: #FFFFFF !important;
      }
    }
  }
}

