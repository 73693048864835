@import "../../../Constants/contant.scss";

.invoice-customer-wrapper {
    position: initial;
    width: 36%;
    margin-bottom: 11px;

    .multi-select-wrapper {
        position: absolute;
        min-width: 347px;
        z-index: 9999;
        background: white;
        box-shadow: -7px 5px 19px #00000040;
        border-radius: 0px 4px 3px 3px;
        margin: 0;

        .search-wrapper {
            background: white;

            .search-bar {
                max-width: 30%;
                min-width: 220px;
            }
        }


        .multi-select-dropdown {
            max-height: 100px;
            overflow-y: auto;
            flex-wrap: wrap;
            overflow-x: auto;
            flex-direction: row;
            display: block;


            .option {
                width: fit-content;
                text-overflow: ellipsis;

                .MuiCheckbox-root {
                    padding: 0px 5px 0px 15px;
                }
            }

            &>div:first-child .MuiFormControlLabel-label {
                font-size: 12px;
                color: #000000;
            }
        }
    }
}

// .search-bar {
//     // max-width: 30%;
//     // min-width: 220px;
//     input {
//         margin-bottom: 0 !important;
//         border-radius: unset !important;
//         border: unset !important;
//     }
// }

.invoice-customer-wrapper.v2 {
    .search-wrapper {

        .search-bar {
            min-width: 100%;
            max-width: 100%;
            input {
                border: none;
                margin-bottom: unset;
            }
        }
    }

    .multi-select-dropdown {
        min-height: 100px !important;
        max-height: 250px !important;
    }

    .multi-select-dropdown {
        flex-wrap: nowrap;
        overflow: auto;

    }
}

.chip-v2 {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0px 4px !important;
    height: 16px !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    border: 1px solid #86AEDD !important;
    border-radius: 2px !important;
    margin-right: 5.5px !important;
    color: #000000 !important;
    background-color: #EFF4FF !important;

    .chip-icon-v2 {
        display: inline-block !important;
        margin-left: 8px !important;
        padding: 0px !important;

        svg {
            height: 7px !important;
            width: 6px !important;

            path {
                fill: #849BC1 !important;
            }
        }
    }

    &:hover {
        border: 1px solid #86AEDD !important;

        .chip-icon-v2 {
            background-color: #86AEDD !important;
            border-radius: 50% !important;

            svg {
                path {
                    fill: $global-white !important;
                }
            }
        }
    }
}

.chip-text-v2{
    color: #000000 !important;
}

.chip-counter {
    border-radius: 4px;
    padding: 0px 12px;
    height: 16px;
    font-size: 10px;
    font-weight: 700;
    color: #151E33;
    background-color: #EFF4FF;
    line-height: 11px;
    position: relative;
    border: 1px solid #86AEDD;

    .popupList {
        display: none;
        padding: 13px 15px;
        background-color: $global-white;
        border: 1px solid #DDDFE8;
        position: absolute;
        top: 22px;
        left: 0;
        z-index: 10000;
        width: 187px;
        border-radius: 4px;

        .popupListItem {
            font-size: 12px;
            font-weight: 400;
            color: $secondary-black;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &:hover {
        .popupList {
            display: block;
        }
    }
}

.invoice-customer-footer {
    border-top: 1px solid #ebebeb;
}

.multiSelectSearch {
    margin: 0px;
    width: 100%;
    border: 0px;
    caret-color: #40A9FF;
    color: #000000;
}

.multiSelectSearch::placeholder {
    color: #00000040;
    font-size: 12px;
    font-weight: 400;
    opacity: 1; /* Firefox */
  }

.requiredClass {
    color: red !important;
    margin-top: 0px;
}
.chipWrapClass{
    flex-wrap: wrap;
    row-gap: 8px;
    width: 100%;
    align-items: flex-start;
    padding: 5px 5px !important;
}

.multiSelectInput{
    height: 16px;
    flex-basis: 48px;
    -webkit-box-flex: 1;
    webkit-flex: 1 1;
    flex: 1 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
}

.multiChipHeader{
    height: auto !important;
    padding: 0 !important;
    min-height: 40px;
    border: 1px solid #D9D9D9 !important;
    border-radius: 2px !important;
    margin-top: 4px !important
}

.wrapper_readmode{
    margin-bottom: 4px;
    width: 49.5%;
}

.placeholderPadding{
    height: 28px;
    padding: 0 8px;
}

.placeholderWidth{
    height: 28px;
    
}
