@import "../../../../../../styleguide/contant.scss";

.multiselect_list_render{
    max-height: 160px;
    overflow-y: auto;
    border: 1px solid $secondary-blueish-grey;
    border-radius: $border-radius;
    p{
        font-size: $fs-11;
        padding: 6px 10px;
        color: $disable-text-color;
        margin-bottom: 0;
    }
    .selected{
        background-color: $secondary-grey;
        color: $secondary-black;
    }
}