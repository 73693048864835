.resetPassword{
    .login-form-wrapper {
        .login_form {
            h1 {
                margin: 10px 0 0px 0;
            }
            .text-secondary {
                margin-top: 5px;
            }
            form{
                margin-top: 10px;
            }
            .input-field-container {
                &:first-child{
                    padding-bottom: 31px;
                }
            }
        }
    }
}