.missed-savings {
    line-height: 1.42857143;
    .react-tabs__tab{
        padding: 8px 20px !important;
    }
    .heading{
        margin: 0 !important;
    }
    span.resetDeviderLeft {
        border-left: initial!important;
        border-right: 1px solid #e6e6e6;
        margin-right: 10px;
        padding-right: 10px;
    }
    .download_Wrapper {
        position: relative;
        button.download-icon-btn {
            max-width: 85px;
            max-height: 35px;
            padding: 0 !important;
            border: 0.5px solid #529F87 !important;
            background-color: #64AC56 !important;
            width: 68px;
            height: 34px;
            display: inline-flex;
            align-items: center;
            transition: all .3s;
            padding: 0;
            min-width: inherit;
            box-shadow: none;
            color: $primary-white;
            border-radius: 4px;
          
            span {
              height: 100%;
              display: inline-flex;
              align-items: center;
              justify-content: center;
            }
          
            span.xcelIcon {
              width: 60%;
            }
          
            span.xcelDownloadIc {
              width: 40%;
              &::before{
                left: 38px;
              }
              svg{
                height: 13px;
                width: 13px;
              }
            }
          }
      }
    .filter_table{
      th{
        padding: 0 12px;
        height: 38px;
      }
      td{
        &:first-child{
          .MuiButton-textSizeMedium{
            svg{
              width: 11px;
              height: 11px;
            }
          }
        }
      }
    }
}