@import "./contant.scss";

@mixin createFont($font-size: "14px", $font-weight: 500, $align: "left", $color : "#424a58", $line-height : 1.6) {
  font-family: $font-family-roboto;
  font-size: $font-size;
  font-weight: $font-weight;
  font-stretch: normal;
  font-style: normal;
  line-height: $line-height;
  letter-spacing: normal;
  text-align: $align;
  color: $color;
}

@mixin createSeprator($width : "100%", $height : "1px", $background-color :#DDDFE8, $top : 0, $left : 0) {
  content: "";
  width: $width;
  height: $height;
  background: $background-color;
  position: absolute;
  top: $top;
  left: $left;
}


@mixin resetBtn {
  display: flex;
  align-content: center;
  flex-flow: wrap;
  gap: 5px;
  margin-top: 2px;
  padding-left: 13px;
  position: relative;
  align-items: baseline;

  &::before {
    content: "";
    background: $secondary-grey;
    width: 1px;
    position: absolute;
    left: 0;
    height: 18px;
    bottom: -2px;
  }

  span {
    color: $primary-blue-dark !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;
  }

  &[disabled] {
    >span {
      color: $primary-grey-light !important;
    }

    svg {
      path {
        fill: $primary-grey-light;
      }
    }
  }
}

@mixin editableHeading {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: $primary-grey-dark;
  padding-right: 13px;
}

@mixin showMore {
    background: #D8EAFF;
    padding: 4px 10px 4px 10px;
    border-radius: 4px;
    color: #0A3CA2;
    border: 1px solid #0A3CA2;
}