@import "../../../../Constants/contant.scss";

.group_btn {
  background-color: $global-white;
  width: 140px;
  font-size: 11px;
  background: transparent;
  color: $primary-grey-light;
  outline: none;
  line-height: unset;
  padding: 5px;
  gap: 10px;
  margin: 0 !important;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  font-family: "roboto";
  border-radius: 18px;
  color: $primary-blue-dark;
  white-space: nowrap;

  &:last-child {
    background-color: $global-white;
  }
}

.group_btn.active {
  border-radius: 18px;
  background-color: $primary-blue-dark;
  color: $global-white;
  border-color: $primary-blue-dark;
}

.btn_group {
  padding: 10px;
  display: flex;
  justify-content: center;
}

.btn_group_border {
  margin-right: 10px;
  padding: 2px !important;
  width: max-content;
  border-radius: 28px;
  border: 1px solid var(--Blue, $primary-blue-dark);
  background: $global-white;
}

.lens_tab {
  border: 1px solid #D5DDEC;
  border-radius: 5px;
  overflow: hidden;
  height: 33px;

  .group_btn {
    padding: 9px 20px;
    background-color: white;
    font-family: Inter !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    color: black;
    border-radius: 0px;


    &:not(:last-child) {
      border-right: 1px solid #D5DDEC;
    }
  }

  .group_btn.active {
    background: #E8F1FF;
    font-weight: 600;
    color: #253E66;

  }
}