@import "../../../../styleguide/contant.scss";

.login-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #F5F5F5;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 52px;
  padding: 18px 35px;

  .login-left {
    font-size: $fs-14;
    color: $common-grey;
    line-height: 17px;
    font-family: metropolis !important;

    a {
      color: #4398d7;
      display: inline-block;
      font-family: metropolis !important;
    }
  }

  .login-right {
    background-color: #f5f5f5;
    font-size: $fs-12;
    color: #707070;
    font-family: metropolis !important;
  }
}

  .single-content {
    justify-content: center !important;
  }

  .non-auto-module-footer{
    background-color: #F5F5F5 !important;
  }