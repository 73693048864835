@import "../../../../../styleguide/contant.scss";

.invoice-customer-wrapper {
    position: initial;

    .multi-select-wrapper {
        position: absolute;
        min-width: 347px;
        z-index: 9999;
        background: white;
        box-shadow: -7px 5px 19px #00000029;
        border: 1px solid $secondary-grey;
        border-radius: 0px 4px 3px 3px;
        margin: 0;

        .search-wrapper {
            background: white;

            .search-bar {
                max-width: 30%;
                min-width: 220px;
            }
        }


        .multi-select-dropdown {
            max-height: 100px;
            display: flex;
            flex-direction: column;
            overflow-y: hidden;
            flex-wrap: wrap;
            overflow-x: auto;


            .option {
                width: fit-content;
                text-overflow: ellipsis;

                .MuiCheckbox-root {
                    padding: 0px 5px 0px 15px;
                }
            }

            &>div:first-child .MuiFormControlLabel-label {
                font-size: $fs-12;
                color: $secondary-black;
            }
        }
    }
}

.invoice-customer-wrapper.v2 {
    .search-wrapper {

        .search-bar {
            min-width: 100%;
            max-width: 100%;

            input {
                border: none;
                margin-bottom: unset;
            }
        }
    }

    .multi-select-dropdown {
        min-height: 100px !important;
        max-height: 250px !important;
    }

    .multi-select-dropdown {
        flex-wrap: nowrap;
        overflow: auto;

    }
}

.multiselect_disabled {
    .invoice-customer {
        background-color: #F1F2F6;
        margin-top: 0;

        .chip {
            background-color: #ffffff;

            .chip-icon {
                display: none;
            }
        }

        .header {
            pointer-events: none !important;

            .chip-counter {
                pointer-events: all !important;
            }
        }

        img {
            display: none !important;
        }
    }

    .multi-select-wrapper {
        display: none;
        top: 50px;
    }

    .header {
        border: 1px solid #dddfe8 !important;
    }

}

.chip {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 6px;
    height: 22px;
    font-size: 10px;
    border: 1px solid #dddfe8;
    border-radius: 3px;
    margin-right: 6px;
    color: $primary-grey-dark;
    max-width: 120px;

    .chip-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .chip-icon {
        display: inline-block;
        margin-left: 6px;
        padding: 3px;

        svg {
            height: 8px;
            width: 8px;

            path {
                fill: $primary-grey-dark;
            }
        }
    }

    &:hover {
        border: 1px solid $primary-blue-dark;

        .chip-icon {
            background-color: $primary-blue-dark;
            border-radius: 50%;

            svg {
                path {
                    fill: $global-white;
                }
            }
        }
    }
}
