@import "../../styleguide/contant.scss";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  35% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.custom-domain {
  .Mui-error {
    margin-left: 20px !important;
  }
}

.login_wrapper.ck_auto_module{
  min-height: 100vh;
  margin: unset !important;
}

.login_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 40px;
  width: 100%;
  min-height: calc(100vh - 52px);
  background-color: #f2f2f2;
  font-family: "Roboto", sans-serif;
  // row-gap: 60px;

  .formErrors {
    background: #ff0700;
    padding: 3px 10px;
    width: 100%;
    margin: 10px 0 0;
    text-align: center;
    border-radius: 4px;
    font-size: $fs-13;
    line-height: 30px;
    margin-bottom: 0;
  }

  .login-form-wrapper {
    position: relative;

    .account {
      color: #888a93;
      text-align: center;
      font-size: 0.85em;
      font-weight: 500;
      width: 100%;
    }

    .signupLink {
      color: $primary-blue-dark;
      cursor: pointer;
    }

    .login_form {
      box-sizing: border-box;
      width: 471px;
      margin: 0 auto;
      min-height: 317px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 27px;
      color: $global-white;
      background: $primary-white;
      border-radius: 8px;
      animation: 0.5s ease-out 0s 1 fadeIn;
      margin-left: 0px;
      box-shadow: 0 2px 36px 0 rgb(160 158 158 / 34%);
      position: relative;

      .showHide_password {
        top: 9px !important;
      }

      .input-field-container {
        input {
          height: 54px !important;
          margin-bottom: 0 !important;
          border: 1px solid #e5e5e5;
          padding-left: 16px !important;

          &.filled {
            font: normal normal bold #{$fs-16}/#{$fs-30} Roboto;
            letter-spacing: 0.18px;
          }

          &::placeholder {
            font: normal normal normal #{$fs-16}/#{$fs-30} Roboto;
            color: #C3C3C3;
          }

          &:focus {
            border: solid 1px $primary-blue-dark;
          }
        }

        .icon {
          top: 21px;
        }

        .input-label {
          font: normal normal normal #{$fs-12}/#{$fs-16} Roboto;
          letter-spacing: 0.13px;
          color: $disable-text-color !important;
          margin-top: 6px;
        }

        &:first-child {
          margin-top: 8px;
        }

        .error_message {
          // position: unset !important;
          // font: normal normal normal #{$fs-12}/#{$fs-16} Roboto !important;
          // letter-spacing: 0.13px;
          margin-top: 4px;
        }
      }
    }

    form {
      display: flex;
      flex-direction: column;
      width: 100%;

      .createForm {
        div {
          width: 100%;
        }
      }

      .remember-me-container {
        .MuiFormControlLabel-label {
          font: normal normal normal #{$fs-12}/#{$fs-18} Roboto;
          letter-spacing: 0.14px;
          color: $secondary-black;
        }

        .MuiFormControlLabel-root {
          .MuiCheckbox-root {
            padding: 5px;
            padding-left: 10px;
          }

          svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
            width: 19px;
            height: 19px;
            fill: $primary-blue-dark;

            path {
              box-shadow: -3px 4px 8px #337ab736;
            }
          }
        }
      }

    }

    h1 {
      font: normal normal bold #{$fs-24}/#{$fs-32} Roboto;
      letter-spacing: -0.24px;
      color: $secondary-black;
    }

    .login-btn {
      padding: 13px;
      margin-bottom: 13px;
      font-size: $fs-14 !important;
      border: none;
      font-family: "Roboto", sans-serif;
      height: 52px;
      font-weight: 500;
      outline: none;
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 0.52px;

      &:disabled {
        background-color: $primary-grey-light !important;
        color: $muted-color;
        cursor: not-allowed;
        box-shadow: none;
      }

      &.btn-filled {
        box-shadow: none;
      }

      &:hover {
        background: transparent linear-gradient(90deg, $primary-blue-dark 0%, #0A3DA2 45%, #0A50A2 100%) 0% 0% no-repeat padding-box;
        box-shadow: -6px 13px 15px #0A3CA24A;
      }
    }

    .tooltipClass {
      position: absolute;
      top: 13px;
      width: 100%;
      left: 208px;

      .icon-info {
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  a {
    cursor: pointer;
    font-size: $fs-12;
    font-weight: 500;
    letter-spacing: 0px;
    color: $primary-blue-dark;

    &:hover {
      text-decoration: underline !important;
    }
  }

  .footer-text {
    font: normal normal normal #{$fs-14}/#{$fs-19} Roboto;
    letter-spacing: -0.14px;
    color: #7c7c7c;
    margin-top: 90px;
    text-align: center;
  }
}

.logIn-mainContainer {
  width: 100%;
  height: 100%;
  // display: flex;
  // align-items: center;
  justify-content: center;
  min-height: 100vh;
  // padding: 60px 40px;
  background: #f2f2f2;

  .login_wrapper {
    width: auto;
    padding: 0;
    margin: auto;

    .logo-container {
      margin-bottom: 30px;

      .logo {
        width: 100%;
        transform: inherit;
        max-width: 280px;
        margin: auto;
        min-height: 50px;

        img {
          // max-width: 480px;
        }
        &.newUILogo{
          max-width: 240px !important;
          img{
            margin: auto !important;
          }
        }
      }
    }

    .login_form {
      .input-field-container:first-child {
        padding-bottom: 36px;

        .error_message {
          position: absolute !important;
        }
      }
      .remember-me-container{
        margin-top: 20px;
      }
    }
  }
}

.login_wrapper_Without_bg_without_boxshadow {
  background-color: $primary-white !important;
  position: relative;

  .login_wrapper {
    width: 50%;
    padding: 0 40px;
    background: $primary-white !important;

    h1 {
      margin-bottom: 35px;
    }

    .logo-container {
      padding: unset !important;

      .logo {
        max-width: 160px !important;
        &.newUILogo{
          max-width: 240px !important;
          img{
            margin: auto !important;
          }
        }
      }
    }

    .login_form {
      box-shadow: unset !important;

      .login-btn {
        font-family: "metropolis" !important;
        font: normal normal medium #{$fs-16}/#{$fs-19} "metropolis" !important;
        background: #4398D7 !important;
        margin-top: 36px !important;
        height: 46px !important;
        color: $primary-white;
      }

      .account{
        span{
          font-family: Metropolis !important;
          font-weight: 400;
          font-size: $fs-14;
          color: #707070;
        }
      }

      .signupLink,
      .forgot-password {
        color: #4398D7 !important;
        font-weight: 500 !important;
      }
    }
  }
}

.login_wrapper_Without_bg {
  .login-form-wrapper .login_form {


    h1 {
      font: normal normal bold #{$fs-21}/#{$fs-21} metropolis !important;
      color: #253E66 !important;
      margin: 15px 0 5px 0;
    }

    p {
      font-family: metropolis !important;
    }

    .input-field-container {
      font-family: 'metropolis' !important;

      .input-label {
        color: $common-grey !important;
        font: normal normal normal 14px 'metropolis' !important;
        margin-bottom: 9px;
      }

      input {
        height: 43px !important;
        border: 1px solid #DBDBDB;


        &::placeholder {
          font: normal normal #{$fs-14}/#{$fs-30} metropolis;
        }

        &.filled {
          font: normal normal bold #{$fs-14}/#{$fs-30} metropolis;
        }
      }
    }
  }

  background-color: $primary-white !important;
  position: relative;
  min-height: calc(100vh - 52px);

  .logo-container {
    .logo {
      max-width: 160px !important;
    }
  }

  .login_wrapper {
    background: $primary-white !important;
    min-height: 100%;

    .login_form {
      .login-btn {
        font-family: "metropolis" !important;
        font: normal normal medium #{$fs-16}/#{$fs-19} "metropolis" !important;
        background: #4398D7 !important;
        height: 46px !important;
        color: $primary-white;
      }

      .signupLink,
      .forgot-password,
      .return-signin {
        span {
          font-family: metropolis !important;
        }

        >a {
          font-family: metropolis !important;
          color: #4398D7 !important;

        }
      }
    }
  }

  .login-footer {
    bottom: -52px;
  }

  .login_wrapper.reset_password .login-form-wrapper .login_form .showHide_password {
    top: 42px !important;

    svg {
      color: #4398D7;
    }
  }

  &.without-box-shadow{
    .logo-container {
      margin-bottom: 0 !important;
    }

    .login_form{
      box-shadow: none !important;
      .input-field-container{
        &:first-child{
          padding-bottom: 20px !important;
        }
      }
    }
  }
}

#reload_href {
  display: none;
}

.captcha-container {
  margin-bottom: 8px;

  .captcha-label {
    font: normal normal normal #{$fs-12}/#{$fs-16} Roboto;
    letter-spacing: 0.13px;
    color: $disable-text-color;
  }

  .reload {
    position: relative;
    top: 10px;

    // left: 2px;
    svg {
      width: 16px;
      height: 16px;
      cursor: pointer;

      path {
        fill: #868686;
      }
    }
  }

  #canv,
  .input {
    height: 54px;
    width: 176px;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
  }

  .input {
    color: $secondary-black;
    padding: 15px;

    &.filled {
      font: normal normal bold #{$fs-16}/#{$fs-30} Roboto;
      letter-spacing: 0.18px;
    }
  }
}


.showHide_password {
  position: absolute;
  right: 5px;
  top: 16px;
  background: transparent;
  padding: 0;
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 7px;
  box-shadow: none !important;
  width: auto !important;

  &:hover {
    background: none;
  }

  svg {
    width: 18px;
    height: 18px;
    color: $primary-grey-light;
  }

  .MuiTouchRipple-root {
    display: none;
  }
}

.logo-container {
  position: relative;

  .logo {
    max-height: 100px;
    animation: 0.7s ease-out 0s 1 fadeIn;

    img {
      max-width: 100%;
      max-height: 100px;
      margin: auto;
      // min-height: 50px;
      // mix-blend-mode: darken;
    }
  }
}

.page-not-found-wrapper {
  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: url("../../Assets/images/pagenotfound/bg-background.png") no-repeat;
  background-size: cover;

  p {
    font: normal normal bold #{$fs-20}/#{$fs-26} Roboto;
    color: $secondary-black;
  }

  .icon-wrapper {
    margin-bottom: 30px;
  }

  &.publicPage {
    min-height: 100vh;
  }
}

.login_wrapper {
  &.reset_password {
    .login-form-wrapper {
      .login_form {
        .showHide_password {
          top: 30px !important;

          svg {
            color: $primary-blue-dark;
          }

          &.disabled {
            svg {
              color: $disable-text-color;
            }
          }
        }
      }
    }

  }
}

@media (max-width: 1366px) {
  .logIn-mainContainer .login_wrapper .logo-container {
    margin-bottom: 30px;
  }

  .login_wrapper .footer-text {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 1100px) {
  .login_wrapper .login-form-wrapper {
    padding-left: 0;
  }

  .logo-container .logo {
    left: 50%;
  }
}

@media only screen and (max-width: 900px) {
  .account {
    font-size: $fs-12;
  }

  .logo-container .logo {
    left: auto;
    width: 80%;
    right: 0;
    transform: translate(0px, -50%);
  }
}

.reset_password {
  .MuiFormControl-root {
    width: unset;
    margin-top: 20px;
    margin-bottom: 0 !important;
  }

  .text-secondary {
    margin-bottom: unset;
  }
}

.MuiTooltip-tooltip.MuiTooltip-tooltipArrow:has(.list-disc) {
  background: #e5f3ff !important;
  padding: 24px !important;

  span.MuiTooltip-arrow {
    &:before {
      background: #e5f3ff !important;
    }
  }

  li.py-1 {
    line-height: 1.4;
  }
}

.LoginLeftHTML-Wrapper {
  width: 50%;
  p{
    margin-bottom: 0 !important;
  }
  h1{
    &+p{
      font-weight: 200 !important;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .leftHTML {
    .login_wrapper {


      .login-form-wrapper {
        width: 80%;

        .login_form {
          width: 100%;
        }
      }
    }

    // css for extrnal HTML on login/signup page
    .LoginLeftHTML-Wrapper {
      &>div {
        &>div {
          margin: 0 auto;
          width: 80% !important;

          &>div {
            &>div:not(:first-child) {
              justify-content: space-between !important;
              // gap: 15px !important;
            }
          }
        }
        &>div:last-child{
          &>div{
            &>div:first-child{
              &>div{
                &:not(:last-child){
                  padding-right: 10px !important;
                }
                &:not(:first-child){
                  padding-left: 10px !important;
                }
              }
            }
            &>div:last-child{
              justify-content: center!important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {

  .logIn-mainContainer,
  .Register-mainContainer {
    .login_wrapper {
      width: 100% !important;
      padding: 0;

      .login-form-wrapper {
        width: 80%;

        .login_form {
          width: 100%;
          padding: 20px;

          h1 {
            margin-bottom: 5px;
          }

          .input-field-container input {
            padding: 10px !important;
            height: 45px !important;
          }
        }

        .login-btn {
          height: 45px;
          margin-top: 20px !important;
        }
      }
    }
  }

  // css for extrnal HTML on login/signup page
  .leftHTML {
    flex-direction: column-reverse;

    .LoginLeftHTML-Wrapper {
      width: 100% !important;

      &>div {
        padding: 50px 0;
        margin-bottom: 50px;
      }
    }

    .login-footer {
      align-items: center;
      flex-direction: column;
      height: auto !important;
      padding: 0 !important;
      padding-bottom: 10px !important;

      .login-left,
      .login-right {
        padding-top: 15px;
      }
    }
  }

  //media closing
}

//microsoft section css

.SSO_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10px;
  position: relative;

  hr {
    width: 100%;
    margin-bottom: 26px;
  }

  .option_divider {
    padding: 3px 6px;
    background-color: #fff;
    position: absolute;
    top: 1px;
    color: #707070;
    font-size: 12px;
  }

  .sso_button{
    color: #000;
    border: 1px solid #DBDBDBBD;
    width: 100%;
    height: 41px;
    border-radius: 2px;
    font-size: 15px !important;
    font-weight: 600;
    margin-bottom: 12px;
    &:last-of-type{
      margin-bottom: 32px;
    }
    div{
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
}