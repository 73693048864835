.modal_table_container {
  * {
    font-family: Inter !important;
  }
    width: 100%;
    .MuiDialog-container {
      align-items: center !important;
      h2.MuiDialogTitle-root{
        padding-top: 12px;
        padding-bottom: 10px;
        font-size: 16px;
        font-weight: 700;
        color: #2B2B2B;
      }
      .MuiPaper-root {
        min-width: 1000px;
        position: relative;
        .delete-dialog-body {
          width: 97%;
          justify-content: flex-start;
          margin: 10px auto;
          min-height: 190px; 
          margin-top: 12px;
          p {
            width: 100%;
            margin-bottom: 0;
            margin-top: 0 !important;
            align-items: flex-start;
            overflow: auto !important;
            >div:has(.no_data){
              width: 100%;
            }
            svg{
              margin-bottom: 0 ;
            }
            .table-modal-header{
              display: flex;
              justify-content: space-between;
              width: 100%;
              align-items: center;
              margin-bottom: 17px;
            }
          }
          .rds-table{
            width: 100%;
            table{
              thead{
                th{
                  background-color: #0A3CA2 !important;
                  color: #fff;
                  border-right: 1px solid #E0F1FF;
                  padding: 5px 8px;
                  line-height: 1;
                  min-width: 65px;
                  text-align: right;
                  font-size: 11px;
                  font-weight: 600 !important;
                  white-space: nowrap;
                  &:first-child{
                    min-width: 150px;
                    max-width: 250px;
                    border-top-left-radius: 5px;
                    text-align: left;
                  }
                  &:last-child{
                    border-top-right-radius: 3px;
                  }
                  span{
                    margin-left: 0 !important;
                  }
                }
              }
              tr{
                &:nth-child(even){
                  td{
                  background-color: #F8F7FC;
                  }
                  background-color: #F8F7FC;
                  &:hover {
                    background-color: #F8F7FC;;
                  }
                }
                &:hover {
                  background-color: inherit;
                }
              }
              td{
                background-color: #ffffff;
                padding: 6px 8px;
                line-height: 1;
                text-align: right;
                font-size: 11px;
                &:first-child{
                  text-align: left;
                }
              }
            }
          }
        }
      }
      .MuiDialogTitle-root {
        justify-content: space-between !important;
        padding-left: 16px !important;
        align-items: center;
        border-bottom: 1px solid #e6ecf1;
      }
      .table_container {
        padding: 20px;
        p {
          text-align: left;
        }
        .table_wrapper {
          max-width: 1000px;
          overflow: auto;
        }
      }
    }
    &.graph_modal{
      .MuiPaper-root{
        .delete-dialog-body{
          p{
            overflow: visible !important;
          }
        }
      }
      .fusion_chart_wrapper{
        .fc__tooltip.fusioncharts-div{
          .tooltip_wrapper{
            span{
              label{
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    button.downloadxBtn{
      &.download-disabled-icon{
        border-color: #898a90 !important;
        .xcelIcon{
          svg{
            g{
              path:last-child{
                fill: #898a90;
              }
            }
          }
        }
        .xcelDownloadIc{
          border-color: #e1e1e1;
        }
      }
    }
  }

  