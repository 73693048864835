// .tooltip {
//   background-color: black;
//   padding: 20px;
//   width: auto;
// }

@import "../../../../styleguide/contant.scss";

.shankey{
  .fc__tooltip.fusioncharts-div {
    div{
      border-radius: 6px !important;
      box-shadow: 0 3px 4px 0 rgb(0 0 0 / 11%) !important;
      border: solid 0.7px $secondary-blueish-grey !important;
      background-color: $primary-white !important;
      padding : 8px 12px !important;
      color : $secondary-black;
      font-size: $fs-12;
      font-weight: 500;
      span{
        margin-top: -1px !important;
        margin-right: 5px;
        margin-left: 5px;
        position: relative;
        &:nth-child(2){
          margin-left: 6px;
          &::before{
            content: "";
            background: url("../../../../Assets/icons/right-tooltip-arrow.png") $primary-white;
            background-repeat: no-repeat;
            position: absolute;
            left: -18px;
            color: $secondary-black;
            font-size: $fs-30;
            height: 8px;
            width: 15px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

.fc__tooltip.fusioncharts-div {
  border-radius: 6px !important;
  box-shadow: 0 3px 4px 0 rgb(0 0 0 / 11%) !important;
  border: solid 0.7px $secondary-blueish-grey !important;
  background-color: $primary-white !important;
  padding: 0 !important;
  padding: 2px !important;
  color: #2b2b2b  !important;
  font-size: 10px !important;
  .headingCont {
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding: 0px 14px;
    border-radius: 4px 4px 0 0;
    background-image: linear-gradient(
      to top,
      $heading-bg-color,
      #f8f8f8 100%,
      #f9f9f9 68%,
      $primary-white
    );
    display: block;
    align-items: center;
    letter-spacing: -0.26px;
    text-align: left;
    color: $secondary-black;
    font-size: $fs-13 !important;
    font-weight: 500;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    border-bottom: solid 1px $secondary-blueish-grey !important;
    margin: 0 !important;
    padding-bottom: 0;
  }
  .tooltipItem {
    display: flex;
    align-items: center;
    font-size: $fs-12;
    font-weight: 500;
    color: $primary-grey-light;
    padding: 10px 14px;
    & > b {
      margin-left: auto;
      font-weight: bold;
    }
  }
  .colorSquare{
    height: 8px;
    width: 8px;
    background-color: $accent-new-blue;
    margin-right: 5px;
  }
  // span.tltipSquare {
  //   width: 8px;
  //   height: 8px;
  //   margin-right: 10px;
  //   box-shadow: -1px 2px 4px -2px currentColor;
  //   background: red;
  // }
  &>div:nth-child(2){
    .potential_saving{
      display: none !important;
    }   
  }
  .potential_saving{
    //style to be done in after for square
    position: relative;
    color: $secondary-black !important; 
    font-size: $fs-11 !important;
    // &::before{
    //   content: '';
    //   position: absolute;
    //   height: 8px;
    //   width: 8px;
    //   display: block;
    //   background-color: #aaa;
    //   left: 14px;
    //   bottom: 8.5px;
    // }
  }
}
