.loader_wrapper{
    position: absolute;
    z-index: 99;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &.loader-backdrop {
      backdrop-filter: blur(3px);
      background-color: rgba(0,0,30,0.4);
      right: 0;
      bottom: 0;
      top: 0;
      left: 0;
      height: 100%;
      position: fixed;
      width: 100%;
      z-index: 9999;
      transform: inherit;

      .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        top: 40%;
        position: relative;
      }
    }

    .loader-logo {
      height: 64px;
      width: 64px;
    }
  }