@import "../../../styleguide/contant.scss";

.multipleSelectCheckmarks {
  display: flex;
  flex-direction: column;

  .MuiChip-deleteIcon {
    margin: 0 !important;
  }

  label[data-shrink="true"] {
    display: none;
  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: $fs-12;
    line-height: 8px;
    /* identical to box height */

    padding: 10px;
    color: $primary-grey-dark;
  }

  .MuiChip-deleteIcon {
    color: $primary-blue-dark !important;
    margin-left: 5px !important;
    font-size: $fs-18 !important;
  }
}

.multiButtons {
  width: 100%;
  margin-top: 10px;
  padding: 15px;
  position: relative;
  border-top: 1px solid $modal-footer-background;
  display: flex;
  justify-content: flex-end;
  background: $modal-footer-background;
  border-top: 1px solid #e8e6e6;

  button {
    height: 32px;
    margin-left: 10px;
  }

  #close-filter {
    background: $primary-white;
  }
}

.MuiPaper-root
  ul.MuiList-root[aria-labelledby="demo-multiple-checkbox-label"]
  li
  .MuiListItemText-root
  span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: $fs-12 !important;
  line-height: 14px;
  color: $secondary-black;
}

ul.MuiList-root[aria-labelledby="demo-multiple-checkbox-label"].MuiList-padding.MuiMenu-list {
  padding: 5px 0;

  li {
    padding-left: 12px !important;
  }
}

div#menu- .MuiPaper-elevation8.MuiPaper-rounded {
  border: 0;
  border-radius: 0 !important;
  box-shadow: unset !important;
}

.custom_multiselect {
  &:has(.MuiSelect-iconOpen) {
    .multicheck {
      &:before {
        transform: rotate(180deg);
      }
    }
  }
  .multicheck {
    position: relative;

    .MuiChip-root.MuiChip-filled.optext-length {
      padding: 3px 8px !important;
      &::after {
        background: none !important;
      }
    }

    &:before {
      content: "";
      position: absolute;
      background: url("../../../Assets/icons/dropdown_arrow.svg");
      height: 7px;
      width: 12px;
      right: 13px;
      top: 10px;
    }
  }
  svg.MuiSelect-icon {
    display: none;
  }
}

.custom_select.Mui-selected {
  background-color: $primary-white !important;

  .MuiTypography-root,
  .Mui-checked .MuiTypography-root {
    font-weight: 500 !important;
    font-size: $fs-12;
    line-height: 14px;
    color: $secondary-black !important;
  }

  span.MuiCheckbox-root svg {
    fill: $primary-blue-dark !important;
  }
}

.custom_select {
  .Mui-checked {
    svg {
      path {
        fill: $primary-blue-dark !important;
      }
    }
  }
  .MuiTypography-root {
    color: $disable-text-color !important;
  }

  .Mui-checked + .MuiListItemText-root {
    .MuiTypography-root {
      font-weight: 500 !important;
      font-size: $fs-12;
      line-height: 14px;
      color: $secondary-black !important;
    }

    span.MuiCheckbox-root svg {
      fill: $primary-blue-dark;
    }
  }
}

.no_multiCheckListMessage {
  text-align: center !important;
  font-weight: 500 !important;
  color: $secondary-black !important;
  padding: 20px;
}

div.MuiPaper-root {
  &:has(.custom_select) {
    overflow-y: unset !important;
    position: relative !important;

    ul.MuiList-root {
      position: unset;
      overflow-y: auto !important;
      height: 340px;
      margin-bottom: 58px;
      position: unset;
    }
    .multiButtons {
      position: absolute;
      bottom: 0;
      border: solid 1px #e6e6e6;
      // border-top: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
div.MuiPaper-root {
  &:has(.columnManager-custom_select) {
    overflow-y: unset !important;
    position: relative !important;

    ul.MuiList-root {
      position: unset;
      overflow-y: auto !important;
      height: 340px;
      margin-bottom: 58px !important;
      position: unset;
    }
    .multiButtons {
      position: absolute;
      bottom: 0;
      border: solid 1px #e6e6e6;
      // border-top: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

/* Column manager CSS */
.columnManager {
  min-width: 150px !important;
  z-index: 1;
  border: none !important;
  position: relative;
  margin-top: 1px;
  .MuiFormControl-root {
    margin-left: 0 !important;
    margin-top: 0 !important;
    margin: 0 !important;
    max-width: 141px;
  }
  &::before {
    // display: none !important;
    background: url("../../../Assets/icons/column-management.svg") no-repeat !important;
    right: unset !important;
    left: 30px;
    height: 16px !important;
    width: 16px !important;
    top: 9px !important;
  }
  &:has(.MuiSelect-iconOpen):before {
    transform: unset !important;
  }

  #multichips {
    display: none;
  }
  .multipleSelectCheckmarks.custom_multiselect {
    min-width: 200px !important;
    border: none !important;
  }
}

.columnManager.MuiFormControl-root,
.columnManager.MuiFormControl-root .Mui-focused {
  fieldset.MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
}

.resetEnabled {
  .MuiSelect-select {
    position: relative;

    &::before {
      content: "";
      right: 12px !important;
      height: 7px !important;
      width: 7px !important;
      background-color: #33b770;
      border-radius: 50%;
      position: absolute;
    }
  }
}

#columnSelector-parent {
  .MuiSelect-select {
    padding: 5px 13px 5px 10px !important;
    border: 1px solid #0a3ca2;
    border-radius: 3px;

    &::after {
      content: "Column Manager";
      background: url("../../../Assets/icons/column-management.svg") no-repeat !important;
      height: 14px !important;
      width: 16px !important;
      color: #0a3ca2;
      font-weight: 500;
      padding-left: 20px;
      font-size: 11px;
      display: flex;
      align-items: center;
    }

    &:hover {
      box-shadow: -2px 4px 8px rgba(10, 60, 162, 0.2431372549);
    }
  }

  .resetEnabled .MuiSelect-select::before {
    right: 22px !important;
    margin: -10px;
  }

  fieldset {
    border: none !important;
  }
}
.columnManager-custom_select {
  background-color: transparent !important;
  opacity: 0.5 !important;
  .MuiCheckbox-root {
    padding: 0 !important;
  }
  .MuiTypography-root {
    font-weight: 400 !important;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    margin: 8px;
    // text-transform: capitalize;
  }

  .auto-renew-tooltip-wrapper {
    .show-more{
      padding: 0 !important;
    }
  }

  
  &:first-child {
    padding-bottom: 0px!important;
  }

  &:last-of-type{
    padding-bottom: 10px!important;
  }

  > span {
    > svg {
      path {
        fill: #0A3CA2;
      }
    }
  }
  

}
.columnManager-custom_select.Mui-selected {
  opacity: 1 !important;
}
.columnManager-custom_select + .Mui-disabled {
  span {
    color: #898a90;
  }
}
.MuiList-root.MuiList-padding.MuiMenu-list:has(.columnManager-custom_select) {
  border: 1px solid #dddfe8 !important;
  border-radius: 4px !important;
}

.multiButtons {
  #close-filter {
    &.columnManager_Disabled {
      border: 1px solid #8c92a2 !important;
      color: #8c92a2 !important;
      pointer-events: none;
      background-color: #fff!important;
    }
  }
}
