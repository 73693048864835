@import "../../../../../../styleguide/commonclass.scss";

.ck-cudos-slider_container {
  display: flex;
  align-items: center;
  position: fixed;
  right: 0px;
  top: 50%;
  transform: translate(70px, -50%);
  z-index: 999;
  transition: transform 0.4s ease-in-out;

  &:has(.toggle-icon-right) {
    transform: translate(0, -50%);
  }

  .granulity_container {
    box-shadow: 0px 2px 6.3px 0px #5a627280;
    border-radius: 4px 0px 0px 4px;
    background: #f7fcff;
    position: relative;

    .active {
      background-color: #d3edff;
      pointer-events: none;
      cursor: none;
    }

    .ck-cudos-granualarity-heading {
      font-weight: 400;
      font-size: 9px;
      color: #0a3ca2;
      text-align: center;
      padding-top: 3px;
      position: relative;
      height: 19px;
      p {
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #f7fcff;
        padding: 0 2px;
        white-space: nowrap;
        line-height: 1.2;
      }
      &::before {
        content: "";
        position: absolute;

        left: 0;
        min-width: 66px;
        top: 50%;
        transform: translateY(-50%);
        height: 1px;
        background: #c7dcee;
        z-index: 1;
      }
    }

    .ck-cudos-granualarity-value {
      text-align: center;
      font-size: 10px;
      font-weight: 400;
      color: #2b2b2b;
      padding: 10px 15px 8px 15px;

      svg {
        width: 100%;
      }
    }
    .ck-cudos-granualarity-value[disabled] {
      pointer-events: none;
      cursor: none !important;
    }
  }
}
