.activation {
  * {
    font-family: "metropolis", sans-serif !important;
  }
  background-color: #fff;
  height: calc(100vh - 43px);
  .logo_img {
    width: 159px;
    height: 35px;
  }
  .content {
    h2 {
      font-size: 21px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0em;
      color: #253e66;
    }
    p {
      font-family: "Roboto", sans-serif !important;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
      color: #898a90;
    }
    table {
      width: 100%;
      tr {
        &:nth-child(even) {
          background: #f2f9fd;
        }
        td {
          position: relative;
          padding: 12px 10px 12px 20px;
          font-size: 14px;
          font-weight: 600;
          line-height: 14px;
          letter-spacing: 0em;
          &:not(:first-child) {
            text-align: right;
            padding: 12px 20px 12px 10px;
            font-family: "Roboto", sans-serif !important;
            font-size: 14px;
            font-weight: 400;
            // line-height: 26px;
            letter-spacing: 0em;
          }
          &:first-child{
            width: 30%;
          }
        }
      }
    }
  }
  input {
    width: 100%;
    margin-bottom: 0;
    // text-align: right;
    background: unset;
    height: 30px;
    padding: 0 10px;
    &.disabled{
      border-radius: unset;
      border: none;
    }
  }
  .button-wrapper {
    button {
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0em;
      background: #4398d7;
      color: #fff;
      padding: 16px 51px;
      &.Mui-disabled {
        color: #fff !important;
        cursor: not-allowed !important;
        pointer-events: inherit !important;
      }
    }
  }
  .error_message {
    // position: relative;
    // text-align-last: right;
      position: absolute;
      bottom: -4px;
      left: 10px;
      text-align: left !important;
  }

  .footer {
    justify-content: center;
  }
}
