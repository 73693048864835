@import "../../../styleguide/contant.scss";

.setup_card_wrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.add_aws_account_container{
    position: relative;
    width: 100%; 
    background: #f5f7f9;
    height: 100vh;
    .card_heading{
        color:$secondary-black;
    }
    .small_blue_text {
        font-size: $fs-16;
        font-weight: 500;
    }
    .small_grey_text {
        color: #707070;
        font-size: $fs-13;
        margin-bottom: 21px;
        max-width: 514px;
        text-align: center;
        margin: 0 auto 21px;
    }
    .sub_heading_black_text {
        font-size: $fs-20;
        margin-bottom: 60px;
    }
}
.automated_aws_account{
  min-height: 100%;
  background: #f1f4f6;
  .form_footer{
    button{
    //   height: 28px;
    padding: 4px 18px !important;
      font-size: $fs-12 !important;
    }
  }
}

.add_aws_account{
    max-width: 720px;
    width: 720px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 150px 0 50px;
    margin: 0 auto;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    .add_aws_icon{
        margin-bottom: 20px;
    }
}

.blue_link{
    color: $primary-blue-dark;
    font-size: 1em;
    font-weight: 500;
    margin: 0 5px;
    // border-bottom: 1px solid $primary-blue-dark;
    text-decoration: underline !important;
    // line-height: 1.2;
}
.blue_link_no_underline {
    color: $primary-blue-dark;
    font-size: 1em;
    font-weight: 500;
    margin: 0 5px;
    text-decoration: none;
}
.form_details{
    // display: flex;
    // margin: 25px 35px;
    margin-bottom: 15px;
    margin-left: 37px;
    .form_detail_individual{
        margin-right: 35px;
        margin-bottom: 20px;
        .form_detail_label{
            color: #707070;
            font-size: 0.9em;
            // margin-left: 10px;
        }
        .form_detail_value{
            color: $secondary-black;
            font-size: 0.8em;
            margin-top: 14px;
            font-weight: 500;
        }
        .checkbox_container{
            display: flex;
            align-items: center;
            margin-left: -10px;
        }
        .form_detail_checkbox_label{
            color: $secondary-black;
            font-size: 0.8em;
            font-weight: 500;
        }
    }
}

.bold{
    font-weight: 500;
    margin-left: 5px;
}

.small_text{
    font-size: 0.9em;
    margin-bottom: 8px;
}
.copy_btn{
  .MuiSvgIcon-fontSizeMedium{
  width: 0.8em !important;
  height: 0.8em !important;
  }
}

.radio_btn{
    margin-bottom: 12px;
}

.radio_button_header{
    margin-bottom: 15px;
}

.form_details{
    .MuiFormControlLabel-label{
        color: $secondary-black;
    }
}
.automated_aws_account {
    .form_wrapper_common {
        .form_footer {
            .Mui-disabled {
              background-color: $primary-grey-light !important;
              border: 1px solid #626262 !important;
              color: $muted-color !important;
              font-weight: 500;
              pointer-events: inherit !important;
              cursor: not-allowed !important;
            }
        }
    }
}
.form_body .MuiTextField-root{
    .Mui-focused{
        .MuiOutlinedInput-notchedOutline{
            border-color:rgb(25,118,210) !important;
            border-width: 2px !important;
        }
    }

    .MuiOutlinedInput-root:not(.Mui-error){
        &:hover{
           .MuiOutlinedInput-notchedOutline {
            border-color: rgba(0, 0, 0, 0.87) !important;
           }
        }
    }
}

.non_aispl_aws_account {
    .sub_heading_black_text {
        font-size: $fs-14 ;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 50px !important;
        color: $heading-txt-color ;
    }
}

@media (max-height: 768px) {
    .add_aws_account_container {
        height: auto;
    }
    .aws-reseller-registration{
        height: auto !important;
    }
}