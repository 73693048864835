@import "../../../../../../../styleguide/commonclass.scss";

.doughnut-chart-wrapper-elastic {
  border-radius: 4px;
  width: 45%;
  background: $primary-white;

  .heading-bottom-container {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 8px;
    color: #2b2b2b;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 8px;
    .coloredDiv {
      height: 12px !important;
    }
  }

  .bottom-container {
    width: 100%;
    margin: 12px;
    background: #f7f8fa;
    padding: 8px 10px;
    border-radius: 5px;
    margin-top: 0px;
    .no_data {
      min-height: 140px;
    }
  }

  .coloredDiv {
    width: 100%;
    height: 12px;
    position: relative;
    margin-bottom: 12px;
  }

  .coloredDiv::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: var(--percentage, 50%);
    height: 100%;
    background-color: #4592ff !important;
  }

  .coloredDiv::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - var(--percentage, 50%));
    height: 100%;
    background-color: #ffa3ab !important;
  }

  .ri-coverage-donut-chart-wrapper {
    padding-left: 23px;
    padding-right: 16px;
    width: 100%;
    .fusion_chart_wrapper {
      padding: 0px !important;
      margin-left: 10px;
    }
  }

  .ri-coverage-header {
    width: 100%;
    border-bottom: 1px solid #d9dadb;
    padding: 15px 16px;
  }
}

.tooltip-container {
  box-shadow: 0px 4px 6px 2px #0000001a;
  background-color: $primary-white;
  width: calc(100% + 16px);
  margin-left: -6px;
  .tool_heading {
    font-family: $font-family-roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.41px;
    text-align: left;
    padding: 8px 16px;
    margin: 0px;
    background-color: #f7f7f7;
    color: #2b2b2b;
    border-bottom: 1px solid rgb(238, 238, 238);
  }
  .item {
    padding: 4px 16px;
    justify-content: space-between;
    border-bottom: 1px solid rgb(238, 238, 238);
  }

  .total {
    padding: 8px 16px;
  }
}
