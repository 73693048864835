@import "../../../../styleguide/contant.scss";

.text-wrapper{
    display: flex;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) #{$fs-13}/var(--unnamed-line-spacing-13) var(--unnamed-font-family-roboto);
    text-align: left;
    letter-spacing: 0px;
    color: $primary-blue-dark;
    opacity: 1;
    
    p {
        margin-right: 5px;
    }
    .edit-icon {
        visibility: hidden;
        &.editing {
          visibility: visible;
        }
       
    }
    &:hover {
        .edit-icon{
            visibility: unset;
        }
    }
}
.edit-mav-modal{
    margin: 20px;
    .modal-heading{
        text-align: left;
        font: normal normal bold #{$fs-14}/#{$fs-30} Roboto;
        letter-spacing: 0px;
        color: $secondary-black;
        opacity: 1;
        line-height: 1;
        margin-bottom: 20px;
    }
   
    .input-heading{
        text-align: left;
        font: normal normal normal #{$fs-11}/#{$fs-30} Roboto;
        letter-spacing: 0px;
        color: $primary-grey-dark;
        text-transform: capitalize;
        opacity: 1;
        line-height: 1;
        margin-bottom: 5px;
    }
    svg{
        background: $primary-blue-dark 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #0a3ca240;
        border-radius: 3px;
        opacity: 1;
        width: 35px;
        height: 35px;
        color: white;
        margin: 0 2px;
        cursor: pointer;
        &:first-of-type {
            background: white;
            color: $primary-blue-dark;
        }
        &:first-of-type:hover {
          g{
            fill: $primary-blue-dark;
          }
          path{
            fill: $primary-white;
            stroke: $primary-white;
          }
        }
    }
   .disabled-save {
      cursor: not-allowed;
        rect {
          fill: #747474;
      }
    }
    input {
        background: $primary-white 0% 0% no-repeat padding-box;
        border: 1px solid $secondary-grey;
        border-radius: 3px;
        opacity: 1;
        padding: 5px 11px;
        margin-right: 8px;
        height: 35px;
        width: 348px;
    }
}
#simple-popover-mav{
  .MuiPopover-paper{
    margin-left: -50px;
    margin-top: 10px;
    overflow: inherit !important;
    &::before{
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      border: solid 1px $secondary-blueish-grey;
      top: -4px;
      left: 50px;
      background: $primary-white;
      transform: rotate(45deg);
      border-right: 0;
      border-bottom: 0;
    }
  }
}