.cudos-multi-level-table-container {
    overflow: auto;
    margin: 0;
    border-radius: 5px !important;
    border: 1px solid #E6ECF1;

    table {
        * {
            font-family: Inter !important;
        }

        th {
            background: #0a3ca2 !important;
            color: #fff;
            border-left: 1px solid #E6ECF1;
            border-bottom: 1px solid #E6ECF1;
            white-space: nowrap;
            padding: 2px 8px !important;
            font-weight: 600 !important;
            font-size: 11px;
            line-height: 13.31px;
            height: 24px !important;

            &:first-child {
                border-left: none !important;

                &::after {
                    content: "";
                    background: #E6ECF1;
                    position: absolute;
                    height: 100%;
                    width: 1px;
                    right: 0px;
                    top: 0px
                }
            }

            &:nth-child(2) {
                border-left: none !important;

            }

            &:last-child {
                &.\!sticky {
                    border-left: none !important;

                    &::after {
                        content: "";
                        background: #E6ECF1;
                        position: absolute;
                        height: 100%;
                        width: 1px;
                        left: 0px;
                        top: 0px
                    }
                }
            }
        }

        thead {
            tr:not(:first-child) {
                th:first-child {
                    border-left: 1px solid #E6ECF1 !important;
                }
            }
        }

        .row {
            border-bottom: 1px solid #e6ecf1;

            &:nth-child(even) {
                background: #f8f7fc;

                td {
                    background: #f8f7fc;
                }
            }

            &:nth-child(odd) {
                background: #fff;

                td {
                    background: #fff;
                }
            }

            td {
                border-left: 1px solid #e6ecf1;
                padding: 4px 8px !important;
                font-size: 11px;
                font-weight: 500;
                white-space: nowrap;
                color: #2B2B2B;
                line-height: 14px;

                &:first-child {
                    border-left: none !important;

                    &::after {
                        content: "";
                        background: #e6ecf1;
                        position: absolute;
                        height: 100%;
                        width: 1px;
                        right: 0px;
                        top: 0px
                    }
                }

                &:nth-child(2) {
                    border-left: none !important;

                }

                &:last-child {
                    color: #151E33 !important;
                    font-weight: 600 !important;
                    width: 75px;
                    position: relative;
                    line-height: 13.31px;

                    &.\!sticky {

                        border-left: none !important;

                        &::after {
                            background: #e6ecf1;
                            box-shadow: -2px 0 4px 0 rgba(12, 71, 126, .349);
                            width: 1px !important;
                            content: "";
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }

                &:nth-last-child(2) {
                    border-right: none !important;

                }

                &:not(:first-child):not(:last-child) {
                    font-weight: 400 !important;
                }
            }
        }

        tbody.no-shadow {
            tr.row {
                td:last-child {
                    &::after {
                        display: none;
                    }

                    font-weight: 400 !important;
                    color: #2B2B2B !important;
                }
            }
        }

        tfoot {
            td {
                background-color: #e4f4fb !important;
                color: #2b2b2b !important;
                font-size: 11px !important;
                font-weight: 600 !important;
                line-height: 13.31px !important;
                padding: 2px 8px !important;
                height: 24px;

                &:first-child {
                    padding: 2px 10px !important;
                }

                &:last-child.\!sticky.left-border {

                    border-left: none !important;

                    &::after {
                        background: #e6ecf1;
                        box-shadow: -2px 0 4px 0 rgba(12, 71, 126, .349);
                        width: 1px !important;
                        content: "";
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }

            &.no-shadow {
                td:last-child {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    .left-border {
        border-left: 1px solid #e6ecf1 !important;
    }
}