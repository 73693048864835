@import "../../../styleguide/contant.scss";

.table-container {
  box-shadow: -7px 14px 38px #343a4015;
  border: 1px solid $secondary-blueish-grey;
  border-radius: 4px 4px 4px 4px;
  margin-top: 10px;
  padding: 20px;
  width: max-content;
  clear: both;

  .action_data {
    .sorting-data {
      cursor: unset;
    }
  }

  .heading {
    text-align: left;
    font: normal normal bold 16px/#{$fs-36} Roboto;
    letter-spacing: 0px;
    color: $primary-grey-dark;
    opacity: 1;

    &:empty {
      display: none;
    }
  }

  overflow-x: auto;
  max-width: 100%;

  .table {
    width: auto;
    margin: 0;
    border: 1px solid $secondary-blueish-grey;
    border-radius: 4px;
    border-collapse: separate !important;
    // border-bottom: none;

    .table-header {
      background: #f8f8f8 0% 0% no-repeat padding-box;

      th {
        height: 30px;
        padding: 0px 12px;
        font: normal normal normal #{$fs-12}/16px Roboto;
        letter-spacing: 0px;
        color: $heading-txt-color;
        border-bottom: unset;
        vertical-align: middle;
        background-color: inherit;

        &.sub-legend-header-configure {
          position: relative;
          z-index: unset !important;
          min-width: 85px;
          vertical-align: bottom;
          white-space: nowrap;
          padding: 30px 12px 10px 12px;

          .header-name {
            position: absolute;
            bottom: 4px;
            left: 50%;
            transform: translateX(-50%);
          }

          .sub-legends-wrapper {
            position: absolute;
            width: 130%;
            top: 2px;
            left: 30%;
            z-index: 999999;

            .sub-legends-content-wrapper {
              width: 100%;
              height: 20px;

              span {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                z-index: 1;
                padding: 0 10px;
                background-color: #f8f8f8;
                color: $primary-sky-blue;
                font-style: italic;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 85%;
              }

              .horizontal-line {
                position: absolute;
                top: 5px;
                width: 100%;
                border-top: 1px solid $primary-sky-blue;
                border-left: 1px solid $primary-sky-blue;
                border-right: 1px solid $primary-sky-blue;
                height: 5px;
              }
            }
          }
        }
      }

      &:first-child {
        text-align: left;
      }
    }

    .table-body-row {

      &.new-item {
        background: #EFF6FF 0% 0% no-repeat padding-box;

        input[type="text"] {
          font-weight: normal;

          &::placeholder {
            color: $disable-text-color;
          }
        }
      }

      height: 35px;

      .tableBodyIcons {
        display: flex;
        align-items: center;
        width: max-content;

        .input_icon {
          height: 15px !important;
          position: absolute;
          top: 4px;
          cursor: pointer;
        }

        svg {
          height: unset !important;
          width: unset !important;
          margin: 0 4px;

          &.plus_icon,
          &.minus_icon,
          &.up_icon,
          &.down_icon,
          {
          height: 15px !important;
          width: 15px !important;
        }

        &.up_icon,
        &.down_icon,
        {

        &:hover,
        &:focus {
          background-color: #abc4e6;
        }
      }
    }

    &>span {
      svg {
        cursor: pointer;
      }
    }

    .MuiSwitch-thumb {
      width: 11px !important;
      height: 11px !important;
      background: linear-gradient(158deg, #1c50bc 0%, #051e51 100%);
      box-shadow: 0px 3px 6px #0000002c;
    }

    .MuiSwitch-root span.MuiSwitch-track {
      background: #c3d6ea !important;
      opacity: 1 !important;
    }

    .MuiSwitch-track {
      width: 21px !important;
      height: 6px !important;
    }

    .switch-icon {
      &.disable {
        .MuiSwitch-thumb {
          background: transparent linear-gradient(329deg, #6e7383 0%, $primary-grey-light 100%);
          box-shadow: 0px 3px 6px #0000002c;
        }

        .MuiSwitch-track {
          background-color: #e8e7e7;
        }
      }
    }

    &:has(.save-icon, .cancel-icon) {
      flex-direction: row-reverse;
    }

    .promote-icon {
      svg {
        color: #0A3CA2;
      }

      &.disabled {
        pointer-events: unset;
        cursor: not-allowed;

        svg {
          color: #C9C9C9;
          pointer-events: none;
        }
      }
    }
  }

  td {
    padding: 0px 12px;
    font: normal normal normal #{$fs-11}/#{$fs-13} Roboto;
    letter-spacing: 0px;
    color: $secondary-black;
    vertical-align: middle;

    img.logo-image {
      // height: 20px; 
      height: 40px;
      max-width: 70px;
      margin: auto;
      // padding: 3px 0;
      object-fit: contain;
      max-height: 100%;

    }


    &.email {
      color: $primary-blue-dark;
      // text-transform: lowercase;
    }

    &.lastLoginTime {
      font-size: $fs-10;
    }

    .edit-icon,
    .delete-icon,
    .switch-icon {
      color: $primary-blue-dark;
      cursor: pointer;
      margin-right: 15px;

      svg {
        height: 18px;
        width: 18px;
      }

      &:last-child {
        margin-right: 0;
      }

      &.disable {
        color: $disable-text-color;
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }

  &:last-child {
    td {
      border-bottom: 1px solid $secondary-blueish-grey;
    }
  }

  &:first-child {
    text-align: left;
  }

  &.disabled-row {
    background-color: #f5f6f9;

    input,
    .MuiCheckbox-root {
      pointer-events: none;
    }

    td {
      color: $disable-text-color;
      background-color: #f5f6f9;

      &.email {
        color: $disable-text-color;
      }

      .tableBodyIcons {
        .switch-icon {
          .MuiSwitch-thumb {
            background: transparent linear-gradient(329deg, #6e7383 0%, $primary-grey-light 100%);
            box-shadow: 0px 3px 6px #0000002c;
          }

          .MuiSwitch-track {
            background-color: #bebebe;
          }
        }

        span {
          color: $disable-text-color;
        }
      }
    }
  }

  .warning-icon {
    color: #f44336;
    margin-left: 2px;
    margin-bottom: 5px;
    font-size: $fs-19;
  }
}

&.table-grid-ui {
  .multi-select-checkbox {

    &.indeterminate {
      position: absolute;
      width: 14px;
      height: 14px;

      input[type=checkbox] {
        z-index: 999999;
      }

      .columnHoverDiv {
        background: none;
      }
    }
  }

  .table-header {
    height: 48px;

    th {
      position: sticky;
      top: 0px;
      z-index: 99;

      .MuiButtonBase-root {
        padding: 4px 5px 0 5px !important;

        svg {
          height: 18px;
          width: 18px;
        }
      }

      .MuiButtonBase-root:not(.Mui-checked, .Mui-disabled) {
        .MuiIconButton-label {
          position: relative;

          &::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            background: $primary-white;
            left: 3px;
            top: 3px;
            z-index: -1;
            border-radius: 2px;
          }
        }

        svg {
          height: 19px;
          width: 19px;
        }
      }

      color: black;
      font-size: $fs-12 !important;
      border-right: 1px solid #f1f1f1;
      border-bottom: 1px solid #f1f1f1;
      max-width: 125px;
      min-width: 92px;
      padding: 4px 10px;
      font-weight:500 !important;

      &:first-child {
        // font-size: $fs-11 !important;
        // border-right: 1px solid #e0e0e0;
        min-width: 180px;
        // box-shadow: 0 -1px 6px 0 rgb(52 58 64 / 16%);
        padding-left: 14px;
      }

      &:last-child {
        // font-weight: 500 !important;
        // border-right: unset;
        border-radius: 0 4px 0 0;

        .columnHoverDiv {
          border-radius: 0 4px 0 0;
        }
      }

      &.hover-style {
        // background: transparent linear-gradient(24deg, $modal-footer-background 0%, $primary-white 100%) 0% 0%
        //   no-repeat padding-box;
        // box-shadow: -5px 6px 36px #0a3ca21d;
        // color: $primary-blue-dark;
        // padding: 5px 9.5px;
        border: solid 1px $primary-blue-dark !important;
        // border-top: solid 2px $primary-blue-dark !important;

        .MuiButtonBase-root {
          color: #DEDEDE;
        }

      }

      .columnHoverDiv {
        // border: 1px solid $primary-blue-dark;
        // border-top: 2px solid $primary-blue-dark;
        width: 101%;
        height: 102%;
        left: -1px;
        top: -1px;
        transform: none;

        svg.short-icon {
          width: 8px;
          height: 12px;
          margin-left: 8px;
        }

      }

      .columnWise {
        .MuiButtonBase-root {
          padding-top: 0 !important;
        }
      }

      .text-field>span:not(.multi-select-checkbox) {
        // min-width: 80px;
      }
    }

    th:not(:first-child) {
      border: solid 1px transparent;
      // border-top: solid 1px transparent;
      // padding: 0;
      border-right: 1px solid #f1f1f1;
      border-bottom: 1px solid #f1f1f1;
      transition: all .1s ease-in-out;
    }
  }

  tbody {
    tr {
      height: 41px;

      &:last-child {
        .hover-style {
          border-bottom: 0.8999999881px solid $primary-blue-dark;
        }

        td:last-child {
          border-radius: 0 0 4px 0;
        }

        td:first-child {
          border-radius: 0 0 0 4px;

          .columnHoverDiv.rowWise {
            border-radius: 0 0 0 4px;
          }
        }
      }

      td {
        border-top: 1px solid #f1f1f1;
        border-right: 1px solid #f1f1f1;
        border-left: solid 1px transparent;
        padding: 5px 12px;
        text-align: center;
        border-bottom: solid 1px transparent;
        font-size: $fs-12;
        font-weight: 500;
        transition: all .1s ease-in-out;

        &:first-child {
          // border-right: 1px solid #e0e0e0;
          text-align: left;
          font-weight: 500;
          border-left: solid 1px transparent;
          max-width: 200px;
          word-break: normal;
          // box-shadow: 0 4px 6px 0 rgb(52 58 64 / 16%);
        }

        &:last-child {
          border-right: solid 1px transparent;
        }

        .text-field {
          column-gap: 8px;
          min-height: 28px;
        }

        &.hover-style {
          border-left: 1px solid $primary-blue-dark;
          border-right: 1px solid $primary-blue-dark;
        }

        .MuiButtonBase-root {
          color: #DEDEDE;

          &.Mui-disabled {
            color: #525252 !important;
          }
        }

        .unselected-checkbox {
          display: inline-block;
          height: 14px;
          width: 14px;
          background: $primary-white;
          box-shadow: -2px 3px 3px #337AB721;
          border: 1px solid $primary-sky-blue;
          border-radius: 2px;

          &::after {
            content: "\f00d";
            font-family: "FontAwesome";
            font-size: $fs-8;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $primary-sky-blue;
            position: relative;
            top: -0px;
          }
        }

        .grid-row-checkbox {
          min-width: 28px;

          .MuiButtonBase-root {
            padding: 5px !important;

            svg {
              height: 18px;
              width: 18px;
            }
          }

          .MuiButtonBase-root:not(.Mui-checked, .Mui-disabled) {
            .MuiIconButton-label {
              position: relative;

              &::after {
                position: absolute;
                content: "";
                width: 13px;
                height: 13px;
                background: $primary-white;
                left: 3px;
                top: 3px;
                z-index: -1;
                border-radius: 2px;
              }
            }

            svg {
              height: 19px;
              width: 19px;
            }
          }
        }

        .grid-checkbox {
          position: relative;

          .last-updated-tooltip {
            min-height: 70px;
            min-width: 210px;
            background: $primary-white 0% 0% no-repeat padding-box;
            box-shadow: -7px 9px 21px #0000001F;
            border: 1px solid $secondary-blueish-grey;
            position: absolute;
            z-index: 999;
            top: 25px;
            left: 0px;
            padding: 10px 7px;
            row-gap: 6px;
            min-height: inherit;

            &::after {
              content: "";
              height: 8px;
              width: 8px;
              border-top: 1px solid $secondary-blueish-grey;
              position: absolute;
              background-color: white;
              top: -5px;
              transform: rotate(45deg);
              border-left: 1px solid $secondary-blueish-grey;
              left: 10px;
            }

            &>span {
              .last-updated-icons {
                svg {
                  width: 11px;
                  height: 11px;
                }
              }

              &:first-of-type {
                margin-bottom: 5px;
              }
            }
          }

          .MuiButtonBase-root {
            padding: 5px !important;

            svg {
              height: 18px;
              width: 18px;
            }
          }

          .MuiButtonBase-root:not(.Mui-checked, .Mui-disabled) {
            .MuiIconButton-label {
              position: relative;

              &::after {
                position: absolute;
                content: "";
                width: 13px;
                height: 13px;
                background: $primary-white;
                left: 3px;
                top: 3px;
                z-index: -1;
                border-radius: 2px;
              }
            }

            svg {
              height: 19px;
              width: 19px;
            }
          }

          .MuiCheckbox-root.Mui-disabled {
            input:disabled~span {
              border-radius: 2px;
            }
          }
        }

        .disabled-checkbox {
          display: inline-block;
          height: 13px;
          width: 13px;
          background-color: #DEDEDE;
          border-radius: 1px;
          border: 1.2px solid#d2d2d2;
          margin: 8px;
        }

        &:nth-last-child(-n+3) {
          .grid-checkbox .last-updated-tooltip {
            left: auto;
            right: -10px;

            &::after {
              right: 18px;
              left: auto;
            }
          }
        }

        &:nth-child(2) {
          .grid-checkbox .last-updated-tooltip {
            left: -222px;
            right: auto;
            padding: 10px 7px;
            min-height: auto;
            min-width: 270px;

            &::after {
              right: auto;
              left: 232px;
            }

            .gap-x-2 {
              column-gap: 0.3rem;
            }
          }

        }
      }

      &.hover-style {

        // box-shadow: -5px 6px 36px #0a3ca21d;
        td {
          &:first-child {
            &:first-child {
              background: transparent linear-gradient(24deg, $modal-footer-background 0%, $primary-white 100%) 0% 0% no-repeat padding-box;
              border-left: 1px solid $primary-blue-dark;
              color: $primary-blue-dark;
            }

          }

          &:last-child {
            border-right: 0.8999999881px solid $primary-blue-dark;
          }

          border-top: 1px solid $primary-blue-dark;
          border-bottom: 1px solid $primary-blue-dark;


        }
      }

      &:nth-last-child(-n+2) {

        .grid-checkbox .last-updated-tooltip {
          top: auto;
          left: -8px;
          bottom: 21px;
          padding: 10px 7px;
          row-gap: 6px;
          min-height: inherit;

          &::after {
            top: auto;
            bottom: -5px;
            border-left: 0;
            border-top: 0;
            border-bottom: solid 2px $secondary-blueish-grey;
            border-right: solid 2px $secondary-blueish-grey;
          }
        }

        td {
          .grid-checkbox .last-updated-tooltip {
            left: -1px;
          }

          &:nth-child(2) {
            .grid-checkbox .last-updated-tooltip {
              left: -220px;
            }
          }

          &:nth-last-child(-n+3) {
            .grid-checkbox .last-updated-tooltip {
              left: auto;
            }
          }

          &:nth-child(2) {
            .grid-checkbox .last-updated-tooltip {
              left: -222px;
              right: auto;
            }
          }
        }
      }

      &:only-child {
        td {
          .grid-checkbox .last-updated-tooltip {
            padding: 10px 7px;
            bottom: 19px;
            min-height: inherit;
            left: 0;
            row-gap: 6px;
          }

          &:nth-last-child(-n+3) {
            .grid-checkbox .last-updated-tooltip {
              left: auto;
              right: -10px;
            }
          }

          &:nth-child(2) .grid-checkbox .last-updated-tooltip {
            left: -220px;
            right: auto;
          }

          &:nth-child(2) {
            .grid-checkbox .last-updated-tooltip {
              .gap-x-2 {
                column-gap: 0.3rem;
              }
            }
          }
        }
      }

      &:nth-child(1) {
        td {
          &:nth-child(2) {
            .grid-checkbox .last-updated-tooltip {
              padding: 10px 7px;
              min-height: 50px;

              &::after {}
            }

          }
        }

      }
    }
  }

  .text-field {
    .MuiCheckbox-indeterminate {
      // opacity: 0;
      z-index: 9999;

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: "";
        display: inline-block;
        height: 14px;
        width: 14.1px;
        background: $primary-white;
        box-shadow: -2px 3px 3px #337AB721;
        border: 1px solid $primary-sky-blue;
        border-radius: 2px;
      }

      &::before {
        position: absolute;
        content: "";
        width: 6px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        border-top: 1.2px solid $primary-sky-blue;
        color: $primary-sky-blue;
      }
    }

    span {
      line-height: normal;
    }
  }

  tr {
    th {
      background: #f8f8f8;

      &:first-of-type {
        position: sticky;
        left: 0px;
        z-index: 998;
      }

      &:nth-child(2) {
        min-width: 120px;
        border-left: solid 1px transparent;
        // position: relative;
        z-index: 99;

        &.hover-style {
          border-left: 1px solid $primary-blue-dark;
        }
      }
    }

    td {
      background: $primary-white;

      &:first-of-type {
        position: sticky;
        left: 0px;
        z-index: 99;
      }

      &:nth-child(2) {
        min-width: 123px;
        border-left: solid 1px transparent;
        // position: relative;
        z-index: 99;

        &.hover-style {
          border-left: 1px solid $primary-blue-dark;
        }
      }
    }
  }

  span.hideTitle {
    opacity: 0;
  }
}

&.ck-table--mouse-over-filter:not(.ck-table-disable-filter) {
  .ck-table-header-sticky {
    position: sticky;
    top: 0;
    z-index: 2;

    tr {
      th:first-child {
        z-index: 999999;
      }
    }
  }

  thead {
    .table-header {
      th:not(.ck-no-sorting) {
        >.sorting-data {
          opacity: 1;
        }

        >.ck--filter-block {
          position: absolute;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
          top: 0;
          gap: 5px;
          opacity: 0;
          margin-right: 0;
          display: flex;

          span {
            position: relative;
            width: auto;
          }
        }

        &:hover {
          >.sorting-data {
            opacity: 0;
          }

          >.ck--filter-block {
            opacity: 1;
          }
        }
      }
    }
  }
}

&.ck-table--shadow-as-border {
  border: none;
}
}

&.cld-Table {
  table {
    thead {
      th {
        font-size: $fs-12 !important;
        font-weight: normal !important;
      }
    }

  }
}

.billdesk-disabled-icon {
  color: #8c92a2 !important;
  cursor: not-allowed !important;

  svg {
    pointer-events: none;
    fill: #8c92a2;

    path {
      stroke: #8c92a2;
    }
  }

  &.resend-verification-icon {
    button.outline-primary {
      border: 1px solid #898a90;
      background: #fff;
      color: #898a90;
      pointer-events: none;
    }
  }

  &.switch-icon {
    span.MuiSwitch-root {
      pointer-events: none;

      span.MuiSwitch-thumb {
        background: #8c92a2 !important;
      }

      span.MuiSwitch-track {
        background: #dfdfdf !important;
      }
    }
  }
}

.preview-icon {
  svg {
    path {
      stroke: $primary-blue-dark;
    }
  }
}
}

.multi-select-checkbox {
  .columnHoverDiv {
    position: absolute;
    width: calc(100% + 17px);
    left: 50%;
    top: 50%;
    height: calc(100% + 10px);
    transform: translate(-50%, -50%);
    background: $modal-footer-background ;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-info {
      position: static;

      svg {
        position: absolute;
        bottom: auto;
        left: auto;
        top: 7px;
        right: 7px;
        // font-size: 0.8rem;
        width: 11px;
        height: 12px;
      }
    }

    .edit-icon {
      margin-right: 0px !important;
      display: inline-block;
      width: 13px;
      height: 13px;
      margin-left: 9px;
      color: $primary-blue-dark;

      path {
        stroke: none;
      }

      &:hover {
        filter: drop-shadow(-1px 3px 2px rgb(10 61 163 / .4));
      }
    }

    .edit-icon~span {
      padding-left: 5px;
      font-size: $fs-11;
      font-weight: 500;
    }

    &.rowWise {
      width: calc(100% + 2px);
      height: calc(100% + 1px);
      justify-content: flex-start;
      padding-left: 5px;
      left: -1px;
      transform: translate(0, -50%);
      top: 52%;

      &.checkboxOnly {
        padding-left: 5px;
      }

      .icon-info svg {
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.checkboxOnly {
      background: $modal-footer-background;
    }
  }
}

th {
  .sorting-data {
    min-width: 23px;
    text-align: center;
    justify-content: center;
    position: relative;
    user-select: none
  }

  .show-sort-filter {
    visibility: hidden;

    [id^="filter_div"] {
      margin-left: 6.5px;
      margin-right: 0;

      &::after {
        content: "";
        position: absolute;
        left: -5px;
        color: $secondary-black;
        height: 100%;
        top: 0;
        // background: $table-heading-color;
        width: 0px;
        border-right: 1px solid $table-heading-color;
      }

      .MuiOutlinedInput-notchedOutline {
        border: none !important;
      }
    }

    &>svg {
      min-width: 7.4px;
      margin: 0 !important;
      height: 12px;
      width: 6px;
      margin-right: 4px !important;
      cursor: pointer;
    }
  }

  position: relative;

  .show-sort-filter {
    position: absolute;
    // z-index: 9999;

    left: 0;
    top: 0;
    background: #e1dede;
    width: 100%;
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    z-index: 9;

    &:empty {
      background: none;
      outline: none;
    }
  }

  &:hover {
    .show-sort-filter {
      visibility: visible;
    }
  }
}

[id^="menu-"] {
  &.MuiPopover-root {
    .MuiPaper-root.MuiPaper-rounded {
      box-shadow: 0px 9px 10px #00000015;
      border: 1px solid #a6bcc9;
      overflow-y: auto !important;
      overflow-x: hidden !important;

      &>ul {
        // max-height: 150px !important;
        padding-top: 0;
        // max-width: ;
      }

      #includeBox {
        position: static;
        max-width: 100px;
        margin-left: auto;
        margin-right: 10px;
        top: auto;
      }
    }
  }
}

[id^="menu-"]~[id^="menu-"] {
  &.MuiPopover-root {
    .MuiPaper-root.MuiPaper-rounded {
      box-shadow: 0px 9px 10px #00000015;
      border: 1px solid $secondary-blueish-grey;
    }
  }
}

.MuiPopover-paper {
  overflow-y: auto !important;
}

th:has(.active_filter) #header_name .sorting-data:after {
  background: #436ab2;
  border: 1px solid #0a3ca2;
  border-radius: 50%;
  content: "";
  height: 5px;
  margin-left: 3px;
  position: absolute;
  right: 0;
  top: 20%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 5px;
}

th:has(.active_filter) #header_name {
  color: $primary-blue-dark;
}

th:has(.active_filter) #header_name .sorting-data {
  display: inline-block !important;
}

table.table.table-grid-ui {
  .table-header th:not(:first-child) {

    span#header_name {
      display: inline-flex;
      height: 100%;
      width: 100%;
      align-items: center;
    }

    .sorting-data {
      display: inline-flex;
      height: 100%;
      width: 100%;
      align-items: center;
      position: static;
    }

    .sorting-data:has(.text-field) {
      padding: 0;
    }

    .text-field {
      width: 100%;
      justify-content: center;
      cursor: pointer;
      height: 100%;

      &>span {
        &:first-of-type {
          // padding: 0 10px;
        }
      }
    }

    // &.hover-style{
    //   padding: 0 !important;
    // }
  }
}

table.table.table-grid-ui .table-header th:first-of-type .sorting-data {
  justify-content: flex-start;
  width: auto;
}

.table-container.table-scrollbar {
  max-width: 100%;
  overflow-x: auto;
  outline: solid 1px $secondary-blueish-grey;
  border-radius: 4px;
  max-height: 70vh;
  overflow-y: auto;

  .table.table-grid-ui {
    border: 0;
  }
}

.table-container.no-data-table-container {
  width: 100%;

  table {
    width: 100%;
  }
}

.loadingAnimation {
  border-radius: 3px;
  margin: 3px 0;
  height: 38px;
  aspect-ratio: 2/1;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.animating {
  background: #eee;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.9), transparent);
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    -webkit-animation: loading 1.5s infinite;
    animation: loading 1.5s infinite;
  }
}

@keyframes loading {
  0% {
    transform: skewX(-10deg) translateX(-100%);
  }

  100% {
    transform: skewX(-10deg) translateX(200%);
  }
}

button#promote svg {
  margin-right: 0;
  margin-top: -1px;
}

.filterTable_tooltip {
  .infoTooltip {
    top: unset;

    svg {
      width: 10px;
      height: 10px;
      margin-left: 5px;
    }
  }
}

.input_icon {
  height: 15px !important;
}

.cppTextField {
  .Mui-disabled {
    border-radius: 0 !important;
  }

  .Mui-checked {
    path {
      fill: $primary-blue-dark;
    }
  }

  svg {
    height: 16px !important;
  }
}

.sub-legends-wrapper {
  position: absolute;
  width: 130%;
  top: 2px;
  left: 30%;
  z-index: 1;

  .sub-legends-content-wrapper {
    width: 100%;
    height: 20px;

    span {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      padding: 0 10px;
      background-color: #f8f8f8;
      color: $primary-sky-blue;
      font-style: italic;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 85%;
    }

    .horizontal-line {
      position: absolute;
      top: 5px;
      width: 100%;
      border-top: 1px solid $primary-sky-blue;
      border-left: 1px solid $primary-sky-blue;
      border-right: 1px solid $primary-sky-blue;
      height: 5px;
    }
  }
}

.table-wrapper-V2 {
  &.ck-table--shadow-as-border {
    .table-container {
      box-shadow: 0px 0px 0px 1px #E6E6E6;
      border-radius: 4px;
      width: fit-content;
      max-width: 100%;

      .Striped-Table {
        table {
          border: none;
        }
      }
    }
  }

  &.ck-table--disable-filter-sorting {
    .reset-icon {
      display: none !important;
    }
  }

  &.ck-filter-table-squeeze {
    .table-container {
      .table {
        .table-header th {
          min-width: auto;
        }
      }

      .Striped-Table {
        table {
          .table-body-row {
            max-height: 40px;
            height: auto;
          }
        }
      }
    }
  }

  .table-container {
    border: unset;
    margin-top: 0;
    padding: 12px;
    border-radius: 0px 4px 0px 4px;
    width: 100%;

    .Striped-Table {
      border: unset !important;

      table {
        .table-body-row {
          td {
            border: unset;
            border-right: 1px solid #fff !important;
            white-space: nowrap;
          }
        }

        .table-header th {
          min-width: 100px;
          text-align: left !important;
          border: unset;
          border-right: 1px solid #fff !important;
          font-weight: 500 !important;

          &:first-child,
          &:last-child {
            &::after {
              width: 0;
            }
          }
        }
      }
    }
  }

  .reset-icon {
    background: white;
    padding: 10px;
    border-radius: 4px 4px 0px 0px;
    flex-direction: row-reverse;
    column-gap: 16px;

    .reset-icon-btn {
      column-gap: 0.45rem;
    }

    &:empty {
      padding: 0px;
    }
  }

  .sorting-data {
    &+span {
      height: 12px;
      margin-bottom: 0 !important;
      gap: 0px;
      top: 9px;
      align-items: center;

      svg {
        width: 11px;
        height: 11px;
      }
    }
  }
}

.table-container .table {
  .table-header {
    th {
      padding: 0px 11px;
    }
  }

  .table-body-row {
    &.expanded {
      background: #C3D8FF;

      td {
        background: unset !important;
        border-bottom: 0px !important;
      }

      td.\!sticky {
        background: #C3D8FF !important;
      }
    }
  }
}

.inner-table {
  padding: 0px !important;
  border: 2px solid #C3D8FF !important;

  .table-container {
    display: table;
    border: none !important;
    border-radius: 0px !important;
    box-shadow: none;
  }
}

.table_configuration_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background-color: #ffffff;
  padding-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .column_selector_dropdown {
    .MuiSelect-select {
      margin-top: 1px;
    }
  }
}

.table-wrapper-V3 {

  //This is to override all the css border radius coming from various main.scss files and mui css overrides
  .table-container,
  .Striped-Table,
  .table,
  .table-header,
  thead,
  th,
  tbody,
  tr,
  td {
    border: none !important;
    border-radius: 0 !important;
  }

  .table-container {
    border: unset;
    margin-top: 0;
    width: 100%;
    border-radius: 4px !important;



    .striped-table {
      border-radius: 2px;

      .table {
        border: unset;
        width: 100%;
        border: 1px solid #E6E6E6 !important;
        border-radius: 4px !important;



        .table-header {
          background: #0A3CA2;

          th {
            background-color: #0A3CA2 !important;
            padding: 0 8px !important;
            font-size: 11px !important;
            font-weight: 500 !important;
            color: #fff !important;
            text-align: left;
            border-right: 1px solid #E6ECF1 !important;
            height: 28px;

            &:first-child {
              border-top-left-radius: 4px !important;
            }

            &:last-child {
              border-top-right-radius: 4px !important;
            }
          }
        }

        tbody {
          tr {
            height: 21px;
            font-size: 11px;
            font-weight: 500;
            color: #23324E;

            &:last-child {
              td {
                &:first-child {
                  border-bottom-left-radius: 4px !important;
                }

                &:last-child {
                  border-bottom-right-radius: 4px !important;
                }
              }
            }

            td {
              padding: 0 8px !important;
              border-right: 1px solid #E6ECF1 !important;
              color: #2B2B2B;

              &.tuner-odd-child {
                background-color: #fff !important;
              }

              &.tuner-even-child {
                background-color: #F8F7FC !important;
              }

              &.finding_reasons {
                padding: 4px 8px !important;
                padding-left: 14px !important;
              }

              &.remediate-cell {
                padding: 4px 8px !important;
                font-weight: 500;
                font-size: 11px;
                line-height: 14px;
              }

              p {
                color: #2B2B2B;
              }
            }

            &.disabled-row {
              td {
                color: #B6B6B6;

                .Mui-checked {
                  svg {
                    path {
                      fill: #B6B6B6;
                    }
                  }
                }

                p {
                  color: #B6B6B6 !important;
                }
              }
            }

          }

          //  tr:nth-child(even) {
          //    &:hover td , td{

          //        background: #F8F7FC !important;
          //    }
          //  }
          //  tr:nth-child(odd) {
          //    &:hover td , td{

          //        background: #fff !important;
          //    }
          //  }
        }
      }

    }
  }

  .reset-icon {
    background: white;
    padding: 10px;
    border-radius: 4px 4px 0px 0px;
    flex-direction: row-reverse;
    column-gap: 16px;
  }
}

.MuiPopover-paper {
  &:has(.table_header_tooltip) {
    &::before {
      top: 50% !important;
    }

    border-radius: 10px !important;
    margin-top: 8px !important;
  }
}

#table_reset_stylings {
  .show-more {
    background: #0A3CA2 0% 0% no-repeat padding-box !important;
    width: 28px !important;
    height: 18px !important;
    border-radius: 14px;
    font: normal normal bold 10px/13px IBM Plex Sans;
    color: #ffffff !important;
  }
}

.table-container {

  .lens-table,
  .lens-table.Striped-Table {
    border: 1px solid #E6ECF1 !important;
    border-radius: 5px !important;

    .sorting-data {
      &+span {
        top: 5px !important;
      }
    }

    table.table {
      * {
        font-family: Inter !important;
      }

      thead {
        tr.table-header {
          th {
            background-color: #0a3ca2 !important;
            color: white !important;
            padding: 2px 8px !important;
            height: 23px !important;
            border-bottom: none !important;

            span {
              font-weight: 600 !important;
              user-select: none;
              line-height: 13.31px !important;
            }

            span.cursor-pointer:has(svg.sorting_v2) {
              display: none;
            }
          }
        }
      }

      tbody {
        tr.table-body-row {

          td,
          td.lastLoginTime {
            border-right: 1px solid #E6ECF1 !important;
            border-bottom: 1px solid #E6ECF1 !important;
            padding: 0px 8px !important;
            color: #2B2B2B !important;
            line-height: 14px !important;
            height: 22px !important;
            vertical-align: middle !important;
            font-size: 11px !important;

            &:first-child {
              font-weight: 500 !important;
            }

            &:last-child {
              border-right: none !important;
            }
          }

          &:nth-child(even) {
            td {
              background-color: #F8F7FC !important;
            }
          }

          &:nth-child(odd) {
            td {
              background-color: #ffff !important;
            }
          }
        }
      }

    }
  }
}

.table-container .ck-table--background-on-hover tr {
  &:not(:has(.ck-filter-table-virtualization)):nth-child(odd) {
    &:hover {
      td {
        background-color: $global-background-blue !important;
      }
    }
  }

  &:not(:has(.ck-filter-table-virtualization)):nth-child(even) {
    &:hover {
      td {
        background-color: $global-background-blue !important;
      }
    }
  }
}