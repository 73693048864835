.auto-renew-tooltip {
  >div {
    svg {
      width: 12px;
      height: 11px;
    }
  }

  .tooltip-box {
    display: none;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    bottom: 24px;
    z-index: 999;
    box-shadow: -8px 12px 16px rgba(0, 0, 0, 0.15);
    border: 1px solid #DBDBDB;
    right: -8px;
    min-width: 200px;
    color: #000;

    &::before {
      background: #fff;
      border: 1px solid #e6e6e6;
      border-top: 0;
      border-left: 0;
      content: "";
      height: 10px;
      right: -6px;
      position: absolute;
      bottom: 35px;
      transform: rotate(-45deg);
      width: 10px;
    }

    .tooltip-heading {
      font-size: 12px !important;
      font-weight: 500 !important;
      color: #000000 !important;
      text-align: left;
      padding: 7px 10px;
      border-bottom: 1px solid #DDDFE8;
    }

    .tooltip-content {
      padding: 7px 10px;
      text-align: left;

      span {
        font-size: 11px;
        font-weight: 400;
        color: #151E33 !important;
        margin-bottom: 5px;
        display: inline-block;

        svg {
          width: 11px;
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
  }

  >div {
    display: inline-block;
    position: relative;
    padding: 5px;

    svg {
      color: #0A3CA2;
    }

    cursor: pointer;

    &:hover .tooltip-box {
      display: block;
    }
  }
}

.auto-renew-tooltip-wrapper {
  .show-more {
    background: #fff !important;
    color: #0A3CA2 !important;
    padding: 2px 8px !important;
    margin-left: 0px !important;
  }
}

#auto-renew-popover.bottom_pop {
  .MuiPaper-root.MuiPopover-paper {
    margin-top: -24px;

    &::before {
      bottom: -5px;
      top: unset;
      transform: rotate(-135deg);
    }
  }
}

#auto-renew-popover {
  .MuiPaper-root.MuiPopover-paper {
    overflow: initial !important;
    margin-top: 24px;
    //   min-height: 105px;
    box-shadow: -5px 13px 31px rgba(0, 0, 0, 0.3529411765);
    border: solid 1px #e6e6e6;

    &::before {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      border: solid 1px #e6e6e6;
      background: #fff;
      border-right: 0;
      border-bottom: 0;
      top: 26px;
      right: -4px;
      transform: rotate(135deg) !important;
    }
  }

  &.customer-list-tooltip {
    .MuiPaper-root.MuiPopover-paper {
      &::before {
        top: 10px;
      }
    }
  }
}

.flat-discount-description {
  width: 400px;
}

.generic_wrapper {
  .search-bar {
    right: 390px !important;
  }
}