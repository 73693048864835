@import "../../../../../styleguide/contant.scss";

.multiselect_component{
    .multi-select-wrapper{
        margin: 0 !important;
    }
    .modal-title-multiselect{
        font-size: $fs-15 !important;
        color: #000 !important;
        font-weight: 800;
        padding: $sub-heading-padding;
        border: 1px solid $secondary-blueish-grey;
        position: relative;
        svg{
            position: absolute;
            top: -30px;
            right: -30px;
            cursor: pointer;
        }
        span{
            color: $primary-blue-dark;
            &::before{
                content: " | ";
                color: #d9d9d9;
            }
        }
    }
    .search_wrapper{
        border-bottom: 1px solid $secondary-blueish-grey;
    }
    .action_buttons{
        border-top: 1px solid $secondary-blueish-grey;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .button{
            border-radius: 4px;
            &:last-of-type{
              &:hover{
                background: $primary-blue-dark;
              }
            }
            &:first-child{
                margin-right: 5px;
            }
        }
    }
    .options_wrapper_multiselect{
        display: flex;
        flex-direction: column;
        max-height: 50vh;
        overflow-x: auto;
        flex-wrap: wrap;
        align-content: baseline;
        padding: 9px 15px;
    }
    .wrapper_padding{
        padding: $sub-heading-padding;
    }
    .MuiFormControlLabel-root{
        margin-left: 0 !important;
    }
    .search-bar{
        width: 30%;
        margin: 0 !important;
    }
    .options_wrapper_multiselect{
        .MuiSvgIcon-root{
            width: 0.7em !important;
            height: 0.7em !important;
        }
        .MuiCheckbox-root{
            padding: 5px 0 !important;
        }
        .MuiFormControlLabel-label{
            font-size: $fs-12;
            display: flex;
            white-space: nowrap;
            margin-left: 4px;
            color : #525252;
        }
        .Mui-checked+.MuiFormControlLabel-label{
            color: $secondary-black;
            font-weight: 500;
        }
    }
    
}

.multiseelct_toggler_button{
    display: flex;
    align-items: center;
    svg{
        cursor: pointer;
        zoom: 1.3;
    }
    p{
        font-size: $fs-14;
        font-weight: 500;
        &.disabled {
            color: #8e8e8e;
            svg {
                path {
                    color:#8e8e8e;
                }
            }
        }
    }

}

#exclude-checkbox{
    svg{
        height: 0.7em;
        width: 0.7em;   
    }
    .Mui-checked+.MuiTypography-body1{
        color: $primary-blue-dark;
        font-weight: $text-weight;
    }
    .MuiTypography-body1{
        font-size: $fs-13 !important;
    }
}