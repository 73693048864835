@import "../../styleguide/contant.scss";


body {
  // font-family: "Oxygen", sans-serif;
  font-family: $font-family-roboto;
  background: $global-white;
  width: 100%;
  height: 100%;
  margin: 0;

}

* {
  font-family: $font-family-roboto;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Metropolis Semibold';
  src: url('../../../src/Assets/Fonts/Metropolis/fonts/Metropolis-SemiBold.woff2') format('woff2'),
       url('../../../src/Assets/Fonts/Metropolis/fonts/Metropolis-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis Bold';
  src: url('../../../src/Assets/Fonts/Metropolis/fonts/Metropolis-Bold.woff2') format('woff2'),
       url('../../../src/Assets/Fonts/Metropolis/fonts/Metropolis-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}


.App {
  text-align: center;
  min-height: 100vh;
  position: relative;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $secondary-blueish-grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #426699;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-grey-dark;
}


// Reset css
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// Headings style

.heading:not(.main-text-bold) {
  font-size: $fs-22;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.44px;
  text-align: left;
  color: $primary-grey-dark;
  font-weight: 900;
  margin-bottom: 5px;
  font-family: $font-family-roboto;
  margin-top: 10px;
  text-align: left;
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 0px 20px 10px;
}

.sub-heading {
  font-size: $fs-14;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: $primary-grey-dark;
  font-family: $font-family-roboto;
  line-height: 2.57;
}

.sub-heading-table {
  font-size: $fs-18;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: $primary-grey-dark;
  font-family: $font-family-roboto;
  margin-right: 15px;
}

.mt-30 {
  margin-top: 30px;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

// Table styles
table.table-wrapper {
  border-collapse: inherit;
  width: 100%;
  font-size: $fs-12;
  background-color: $global-white;
  border: 0;
  border-radius: 4px;

  tr {
    th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: center;
      font-size: $fs-11;
      color: $table-heading-color;
      // background-color: #323232;
      // color: white;
      // text-transform: capitalize;
      letter-spacing: -0.11px;
      vertical-align: middle;
      border-top: $border-light;
      border-bottom: $border-light;

      &:first-child {
        border-left: $border-light;
        border-radius: 4px 0 0 0;
      }

      &:last-child {
        border-right: $border-light;
        border-radius: 0 4px 0 0;
      }
    }
  }

  tr {
    td {
      border: $border-light;
      border-top: 0;
      border-right: 0;
      text-align: center;
      font-size: $fs-12;
      color: $secondary-black;

      &:last-child {
        // border-right:0 //$border-light;
      }

      &:first-child {
        border-right: 0 // $border-light;
      }
    }

    &:last-of-type {
      td {
        border-bottom: $border-light;

        &:last-child {
          border-radius: 0 0 4px 0;
        }

        &:first-child {
          border-radius: 0 0 0 4px;
        }
      }
    }
  }
}

// Buttons style
.btn,
button {
  // padding: 7px 20px;
  // border-radius: 4px;
  // box-shadow: -6px 5px 5px 0 rgba(10, 60, 162, 0.12);
  outline: none;
  // min-height: 32px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  background-color: $primary-blue-dark;
  color: $global-white;
  cursor: pointer;

  &.btn-filled-blue {
    border: solid 1px $primary-blue-dark;
    background-color: $primary-blue-dark;
    color: $primary-white;

    &[disabled] {
      background: $primary-grey-light;
      color: $muted-color;
    }

    &:hover {
      // box-shadow: -7px 6px 6px 0 rgba(10, 60, 162, 0.24);
    }
  }

  &.MuiButton-text {
    box-shadow: none;
  }

}

div#infinite_scroll {
  max-height: inherit;
}

.btn-outline {
  padding: 7px 20px;
  border-radius: 4px;
  outline: none;
  color: $primary-blue-dark;
  border: solid 1px $primary-blue-dark !important;
  background: $global-white;
  box-shadow: none;

  &[disabled] {
    color: $muted-color;
    border-color: $muted-color;
  }

  &:hover {
    background: $button-hover;
    // box-shadow: -3px 3px 4px 0 rgba(10, 60, 162, 0.1);
  }
}

// Green download button 
button.download-icon-btn {
  max-width: 85px;
  max-height: 35px;
  padding: 0 !important;
  border: 0.5px solid #529F87 !important;
  background-color: #64AC56 !important;
  width: 68px;
  height: 34px;
  display: inline-flex;
  align-items: center;
  transition: all .3s;
  padding: 0;
  min-width: inherit;
  box-shadow: none;
  color: $primary-white;
  border-radius: 4px;

  span {
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  span.xcelIcon {
    width: 60%;
  }

  span.xcelDownloadIc {
    width: 40%;
    &::before{
      content: "";
      border-left: 1px solid #40852a;
      height: 25px;
      position: absolute;
      left: 32px;
    }
  }
}



.body-text,
.body-text-m,
.body-text-s {
  font-size: $fs-13;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: left;
  color: $secondary-black;
}

.body-text-m {
  font-size: $fs-12;
  line-height: 1.5;
}

.body-text-s {
  font-size: $fs-10;
}

.bold {
  font-weight: bold;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

// tootltip design
.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    background: #343d57;
    margin-top: 8px !important;
    // left: -9px;
  }

  span.MuiTooltip-arrow {
    color: #343d57;
    // left: 7px !important;
  }
}
.MuiTooltip-popper[data-popper-placement*="bottom-end"]{
  right: -8px !important;
  span.MuiTooltip-arrow {
    color: #343d57;
    left: -7px !important;
  }
}



// Toast styles
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiAlert-root {
  border-radius: 4px !important;
  padding: 10px 20px;
  min-width: 300px;
  align-items: center;

  &.MuiAlert-filledSuccess {
    // box-shadow: -10px 8px 15px 0 rgba(50, 180, 55, 0.17);
    border: solid 1px $primary-green;
    background-color: #fcfffc;
    color: $primary-grey-light;

    .MuiAlert-icon {
      background: $primary-green;
      // box-shadow: -5px 4px 8px 0 rgba(50, 180, 55, 0.17);
    }
  }

  &.MuiAlert-filledError {
    // box-shadow: -10px 8px 15px 0 rgba(247, 114, 115, 0.17);
    border: solid 1px #f77273;
    background-color: #fffafa;
    color: $primary-grey-light;

    .MuiAlert-icon {
      background: #ff2024;
      // box-shadow: -5px 4px 8px 0 rgba(247, 114, 115, .17);
    }

    svg.MuiSvgIcon-root {
      color: $primary-white;
    }
  }

  .MuiAlert-icon {
    margin-right: 15px;
    font-size: $fs-25;
    width: 27px;
    height: 27px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    svg {
      color: $primary-white;
      display: block;
      width: 15px;
      height: 15px;
    }
  }

}

@media (prefers-color-scheme: light) {
  $primary-grey-dark: $dasboard-background;

}


// Dark theme css

// @media (prefers-color-scheme: dark) {


html[data-theme="dark"] {
  &>* {
    transition: all .2s ease;
  }

  $primary-grey-dark: $dasboard-background;

  body {
    background: $primary-grey-dark ;
  }

  .Header_wrapper {
    background: $primary-grey-dark ;
    color: $global-white;

    .header_right {
      border-bottom-color: $primary-grey-dark;
      color: $global-white;
    }
  }

  button#navToggle {
    background: $global-white;
    color: $primary-grey-dark;
  }

  .option_List,
  .admin-wrapper,
  body {
    background: $primary-grey-light;
  }

  .expandable_data_table_wrapper .table-heading {
    color: $global-white;
  }

  table tr:hover,
  table tr:nth-child(even) {
    background: $primary-grey-dark;
  }

  .expandable_data_table_wrapper table,
  table {
    background: $primary-grey-dark;

    th,
    td {
      border-color: $primary-grey-light  !important;
      color: $global-white  !important;
      background: transparent;

      .arrow-right svg {
        color: $global-white  !important;
      }

      a {
        color: $global-white;
      }
    }

    tfoot {
      background: $primary-grey-dark;
    }
  }

  .loader-wrapper {
    .loader {
      border-top-color: $accent-blue-light ;
    }
  }

  .sidenav---selected---1EK3y {
    background: $global-white;
  }
}

.scroll-height.colored_table_wrapper {
  height: 40vh;
}

.sorting-data {
  cursor: pointer;

  svg {
    margin-right: -2px;
  }
}

.disabled-tabs {
  pointer-events: none;
  cursor: unset;
}

.download-icon-btn.disabled-button {
  pointer-events: none;
  opacity: 0.8 !important;
}

.no_wrap {
  white-space: nowrap;
}

a {
  text-decoration: none !important;
  color: inherit;
}

.no_data_cell {
  &:first-child {
    border-left: solid 1px $secondary-blueish-grey !important;
  }

  border-left: none !important;
}

.MuiSwitch-root {
  .MuiSwitch-switchBase {
    color: #051e51 !important;
  }

  margin-left: 2px;
  margin-right: 2px;

  span.MuiSwitch-thumb {
    color: #051e51;
    background-image: linear-gradient(158deg, #1c50bc 14%, #051e51 85%);
  }

  span.MuiSwitch-track {
    background: #c3d6ea !important;
    opacity: 1 !important;
  }
}

.toggle_wrapper {
  margin: 0 10px;
}

.toggler_text {
  font-size: $fs-12;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #686868;
  font-family: $font-family-roboto;
  // line-height: 2.57;
  // margin-right: 6px;
  white-space: nowrap;

  &:last-child {
    // margin-left: 6px;
  }

  &.active {
    color: $primary-blue-dark;
  }
}

.toggler_text+button {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-25 {
  margin-top: 25px;
}

.table_Headings {
  font-size: $fs-16;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: $primary-grey-dark;
}

.main_table_heading {
  font-size: $fs-22;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  text-align: left;
  color: $primary-grey-dark;
  border-bottom: 2px solid $secondary-blueish-grey;
  padding-bottom: 8px;
  margin-bottom: 30px;
}

.main_secondary_header {
  font-size: $fs-20;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: $primary-grey-dark;
  padding-left: 10px;
  // border-left: 4px solid $primary-blue-dark;
  margin-bottom: 10px;
}

.expiring_soon {
  td {
    color: $accent-red !important;
    font-weight: 700;
    // background-color: #FFF8F8 !important;
  }
}

.expiring_delay {
  td {
    color: #FA9001 !important;
    // background-color: #FFF5E6 !important;
  }
}

#riExpiryTable {
  margin-top: 0;

  .expiring_soon {
    td {
      color: $accent-red !important;
      font-weight: 700;
      // background-color: #FFF8F8 !important;
    }
  }

  .expiring_delay {
    td {
      color: #FA9001 !important;
      // background-color: #FFF5E6 !important;
    }
  }
}

.reset_filter {
  margin-right: 10px !important;
  // border:  1px solid $primary-blue-dark !important;
  color: $primary-blue-dark !important;
  font-weight: normal !important;
  font-size: $fs-12 !important;
  height: 30px;
  min-height: inherit;
  box-shadow: none !important;
  padding: 3px 9px !important;
  border-radius: 4px !important;
}

.reset_filter+.buttons-group-tabs {
  padding-left: 10px;

  &::after {
    left: 0;
  }
}

.dateRange_pickerBtn {
  border-radius: 4px;
  border: 1px solid $secondary-grey;
  background-color: $primary-white;
  line-height: 1;
  cursor: pointer;
  // width: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: $fs-11;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: $secondary-black;
  height: 30px;
  padding: 0;
  outline: none;

  .dateText {
    padding: 0 10px;
  }

  label.addOn {
    width: 34px;
    height: 28px;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #f0f1f5;
    border-radius: 0px 3px 3px 0px;
    box-sizing: content-box;
    border-top: 1px solid $secondary-grey;
    border-bottom: 1px solid $secondary-grey;
  }
}

.filter-list {
  // border-radius: 4px;
  border: 1px solid $secondary-grey !important;
  min-width: 220px !important;
  padding: 10px !important;
  background-color: $primary-white;
  left: 4px !important;
  top: 24px !important;

  .filter-check-box.selected {
    background-color: #051e51 !important;
  }
}

th {
  &:last-child {
    .filter-list {
      left: -200px !important;
    }
  }

  &:nth-last-child(2) {
    .filter-list {
      left: -200px !important;
    }
  }
}

.filter-list-item:nth-child(odd) {
  background-color: $primary-white !important;
}

.filter-check-box.selected:after {
  content: '';
  position: absolute;
  width: 9px;
  height: 5px;
  box-sizing: border-box;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  top: 50%;
  margin-top: -4px;
  left: 2px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-tranfsorm: rotate(-45deg);
  transform: rotate(-45deg);
}

.sort-parent {
  position: absolute;
  background: #051e51 !important;
  z-index: 1;
  right: 11px !important;
  top: 12px !important;
  border: 1px solid #E1DDDD;
  text-align: center;
  padding: 7px 15px !important;
}

.search-parent {
  padding-right: 49px !important;
}

.table-filter-arrow {
  background-color: $primary-white !important;
}

.table-filter-arrow.dsc:after {
  border-bottom: 5px solid $primary-white !important;
}

.table-filter-arrow.asc:after {
  border-top: 5px solid $primary-white !important;
}

.rmsc {
  width: 250px;
  margin-left: 00px;
  height: 34px;
}

.table-filter-parent {
  margin-bottom: -3px !important;
  position: relative;
  margin-left: 10px;
  display: inline-block;
}

.no_data {
  border-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 200px;
  height: 100%;
  color: $primary-blue-dark;
  font-weight: 700;
}

.profitabilityTooltip {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  display: none;
  transition: all 0.3s ease;
  z-index: 999;
  background: $primary-white;
  // box-shadow: 5px 5px 10px 1px #00000014;
  box-shadow: 8px 15px 22px #00000034;
  border-radius: 8px;
  min-width: 500px;

  .colored_table.popupTable {
    overflow: auto;
    max-width: 850px;
  }

  outline: solid 1px $secondary-blueish-grey;

  .colored_table.popupTable table {
    tr {

      &>td,
      &>th {
        text-align: right !important;
        letter-spacing: -0.3px;
        padding: 8px 8px;
        position: relative;
        cursor: auto;

        &:first-of-type {
          text-align: left !important;
        }
      }
    }

    tfoot {
      tr {
        td {
          font-size: $fs-11;

          &:first-of-type {
            text-decoration: none;

            &:hover {
              text-decoration: none !important;
            }
          }
        }
      }
    }
  }

  &::before {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    border: solid 2px $secondary-blueish-grey;
    transform: rotate(45deg);
    left: -5px;
    background: $primary-white;
    border-radius: 1px;
    top: 50%;
    margin-top: -4px;
    border-right: 0;
    z-index: 9;
    border-top: 0;
  }

  .legends_with_tabs {
    padding: 10px 0;
    border: solid 1px $secondary-blueish-grey;
    border-radius: 4px 4px 0 0;
    border-bottom: 0;
    margin-bottom: -3px;

    .main_secondary_header {
      font-size: $fs-16;
      margin-bottom: 0;
    }

    .colorCodes_container {
      margin-left: auto;
    }
  }

  .colored_table {
    margin-top: 0;
  }

  div#infinite_scroll {
    max-height: 60vh;
    margin-top: 0;
  }
}

.revenue_table_wrapper:last-of-type {
  padding-bottom: 0;
}

.inventory_body .colored_table_wrapper table tr td:last-of-type {
  background: $primary-white;
  font-size: $fs-12;
  color: $secondary-black;
  font-weight: 400;
}

.inventory_body .colored_table_wrapper table tr th:last-of-type {
  font-size: $fs-11;
  color: $table-heading-color;
  letter-spacing: -.11px;
  font-weight: 400;
}

button.MuiButtonBase-root {
  white-space: nowrap;
  border-radius: 4px;
}


li.MuiButtonBase-root {
  white-space: nowrap;
  border-radius: 4px;
  display: flex;
}

.buttons-group-tabs .MuiButtonBase-root{
  border: 1px solid #D8D8D8 ;
  padding: 10px 15px;
}

#coverageTable {
  #filter_div .MuiFormControl-root {
    position: relative !important;
  }
}

.forNA {
  background-color: #f8f8f8 !important;
  color: #8B8B8B !important;
}


.dialog_row>.MuiPaper-root {
  display: flex;
  align-items: center;
  justify-content: center;

}

.dialog_row .MuiCardContent-root .planNotAvailable {
  // background-image: url(../src/Assets/images/bg-dotted.svg);
  background-position: center;
  width: 100%;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// .colored_table_wrapper.firstColFreeze{
//   overflow: auto;
//   max-width: calc(100% - 200px);
// }

.dates_nowrap {
  white-space: nowrap;
}

.revenue_table_wrapper table th {
  background-color: #f8f8f8 !important;
}

.disable_Click {
  pointer-events: none;
}

.colored_table_wrapper.clickableColumn table tbody.disable_Click tr td:first-child {
  color: $secondary-black !important;

  a {
    color: $secondary-black !important;
    font-weight: normal;
  }
}

.collapsibleTable_container.inventory_table {
  table {
    thead {
      th {
        color: $table-heading-color !important;
      }
    }
  }
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6 px solid transparent;
  border-left: 6 px solid green;
}

#realtive_table table thead>tr>th:first-of-type,
#infinite_scroll #hasSerialTable thead>tr>.serialNumber {
  position: relative !important;
}

.no_cursor {
  pointer-events: none !important;
  cursor: unset !important;
}

#red_data {
  color: $accent-red !important;
}

.carttable_style {

  thead th:nth-child(2),
  th:first-of-type {
    text-align: center !important;
  }

  tr td:nth-child(2) {
    text-align: center !important;
  }
}

.borderRight0 {
  border-right: 0px !important;
}



#resetUpdated {
  border: 0;
  text-transform: inherit;
  min-height: inherit;
  height: inherit;
  min-width: inherit;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  font-size: $fs-12;
  font-weight: 500;
  color: $primary-blue-dark;
  padding: 3px 9px;
  &.deviderLeft {
    // border-left: solid 1px $secondary-blueish-grey;
    border-radius: 0;
    padding-left: 0;
    position: relative;
    margin-left: 10px;

    &::before {
      position: absolute;
      content: '';
      width: 0;
      height: 27px;
      top: 50%;
      transform: translateY(-50%);
      border-right: solid 1px $secondary-blueish-grey;
      left: -10px;
      pointer-events: none;
    }
  }

  &:hover {
    background: none;
  }

  .MuiTouchRipple-root {
    display: none;
  }

  &.Mui-disabled {
    color: $table-heading-color !important;
  }

  svg {
    margin-right: 3px;
  }
}

.opacity-zero {
  opacity: 0 !important;
}


.pointerDisable {
  pointer-events: none !important;
}

th {
  &:has(.active_filter) {
    #header_name {
      .sorting-data {
        padding-right: 10px;
        position: relative;
        color: $primary-blue-dark;
        font-weight: 500;
        &:after {
          position: absolute;
          top: 20%;
          right: 0;
          transform: translateY(-50%);
          content: "";
          height: 5px;
          width: 5px;
          margin-left: 3px;
          border-radius: 50%;
          // margin-bottom: 15px;
          border: 1px solid $primary-blue-dark;
          background: #436AB2;
        }
      }

    }
  }
}

// .Toastify__toast-container--top-right {
//   top: 4em !important;
// }

[aria-disabled="true"] {
  cursor: not-allowed !important;
}

.Mui-disabled {
  cursor: not-allowed !important;
  color: $table-heading-color !important;
}

.MuiFormControl-root .MuiOutlinedInput-root .MuiSelect-select {
  display: flex !important;
  align-items: center !important;
  font-weight: 500;
  color: $secondary-black;
}

.doubleCalendar {
  .dateRange_pickerBtn {
    border: 0 !important;
  }

  .MuiPopover-paper {
    min-width: 0 !important;
    min-height: 0 !important;
  }

  .dateText.date_label {
    display: none;
  }

  .addOn.date_label {
    display: none !important;
  }
}

.dates_filter{
  .MuiPopover-paper{
    background-color: transparent;
    box-shadow: none;
    border: none;
    .dateRange_pickerBtn{
      border: 0;
    }
  }
}



body>iframe[style*="position: fixed"] {
  pointer-events: none;
}
td.colorRed{
  color: $accent-red !important;
} 
td.colorYellow{
  color: $accent-dark-orange !important;
}
.firstPart{
  display: block;
}

.validation_check{
  font-size: $fs-12;
  margin-top: 5px;
  font-weight: 400;
  line-height: 1.5;
  margin-right: 10px;
  color: $accent-red !important;
  display: flex;
  justify-content: flex-end;
}
.newBorderTable{
  table tbody{
    tr:nth-child(even){
      &>td{
        background: #f8f8f8 !important;
      &.hasTooltip{
        &:hover{
          background: #f0f5fa !important;
        }
      }
      }
    }
  }
}

.green {
  color: #5cb85c !important;
}
.red {
  color: $accent-red !important;
}
.amber{
  color: $accent-dark-orange !important;
}

.validation_check{
  font-size: $fs-12;
  margin-top: 5px;
  font-weight: 400;
  line-height: 1.5;
  margin-right: 10px;
  color: $accent-red !important;
  display: flex;
  justify-content: flex-end;
}

.card_heading{
  font-size: $fs-16;
  font-weight: 500;
  line-height: 2;
  margin-bottom: 5px;
}

.card_sub_heading{
  font-size: $fs-14;
  line-height: 1.2;
  margin-bottom: 20px !important;
  color: #727272;
}

.small_blue_text{
  font-size: $fs-14;
  line-height: 1.2;
  margin-bottom: 12px;
  color: #0037AF;
}

.sub_heading_black_text{
  font-size: $fs-22;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 55px;
  color: #1D2B38;
}

td{
  .MuiBox-root{
    max-width: fit-content;
    margin: 0 auto;
  }
}

.table_wrapper{
  background-color: $primary-white;
  // border: 1px solid $secondary-blueish-grey;
  // min-height: 30vh;
  // margin: 10px;
  border-radius: 4px;
}

.filter_table{
  overflow: auto;
}

.show-sort-filter {
  visibility: hidden;

  [id^="filter_div"] {
    margin-left: 6.5px;
    margin-right: 0;

    &::after {
      content: "";
      position: absolute;
      left: -5px;
      color: $secondary-black;
      height: 100%;
      top: 0;
      // background: $table-heading-color;
      width: 0px;
      border-right: 1px solid $table-heading-color;
    }
  }

  &>svg {
    min-width: 7.4px;
    margin: 0 !important;
    height: 10px;
    width: 6px;
    margin-right: 2px !important;
  }
}

th {
  position: relative;
.show-sort-filter {
  position: absolute;
  left: 0;
  top: 0;
  background: #e1dede;
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  z-index: 9;
  outline: solid 1px #e1dede;
}

&:first-of-type{
  .show-sort-filter{
    border-radius: 2px 0 0 0;
  }
}
&:last-of-type{
  .show-sort-filter{
    border-radius:  0 2px 0 0;
  }
}

&:has(.active_filter_multiselect){
  .show-sort-filter {
    visibility: visible;
  }
  .overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0); /* Adjust the transparency as needed */
    z-index: 9; /* Ensure the overlay appears on top */
  }
}

&:hover {
  .show-sort-filter {
    visibility: visible;
  }
}
}

.body{
  width: fit-content;
  padding: 10px;
}

.form_body{
  .MuiTextField-root{
    width: 40% !important;
    .MuiInputBase-root.MuiInput-root{
      .MuiInputBase-input{
        border: solid 1px $secondary-grey;
        background: $primary-white;
        min-height: 45px;
        border-radius: 4px;
        color: #7b7d86;
        font-family: Roboto,sans-serif;
        font-size: $fs-12;
        line-height: 1.5;
        margin-bottom: 6px;
        margin-top: 10px;
        outline: none;
        padding: 12.5px 0 12.5px 1rem;
        // width: 100%;
        width: 474px !important;
        margin-top: 5px;
        &::placeholder{
          color: #7b7d86;
          opacity: 1;
        }
        &:focus{
          border-color: $primary-blue-dark;
        }
      }
      &::before,&::after{
       opacity: 0;
      }
      &.Mui-error{
        .MuiInputBase-input{
          border-color: red;
        }        
      }
      margin-top: 0;
    }
    .MuiFormLabel-root{
      padding-left: 0;
      line-height: 1;
      .MuiFormLabel-asterisk{
        color: red !important;
      }
    }
    .MuiFormLabel-root.MuiFormLabel-filled + .MuiInputBase-root.MuiInput-root input{
      font-size: $fs-14;
      color: $secondary-black;
      font-weight: 500;
    }
  }
}

.loading {
  pointer-events: none;
}