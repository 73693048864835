@import "../../../../styleguide/contant.scss";

.MuiDialog-container {
  align-items: flex-start !important;

  .MuiPaper-root {
    top: 30px;

    .delete-dialog-title,
    .delete-dialog-body,
    .delete-dialog-footer {
      display: flex;
      justify-content: center;
    }

    .delete-dialog-title {
      padding: 24px 0 10px 0;
      font: normal normal bold #{$fs-23}/#{$fs-26} Roboto;
    }
    .delete-dialog-body {
      margin: 0 auto;
      width: 70%;

      p {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: black;
        font: normal normal normal #{$fs-14}/#{$fs-19} Roboto;
        text-align: center;
        margin-bottom: 15px;

        svg {
          margin-bottom: 15px;
        }
      }
    }

    .delete-dialog-footer {
      background: $modal-footer-background;
      border-radius: 0px 0px 4px 4px;
      padding: 15px 0;
      border-top: 1px solid #dedede;
    }
  }

  .MuiPaper-root[aria-labelledby="alert-delete-dialog"] {
    min-width: 289px;
  }
}
#delete_popup .delete-dialog-footer {
  justify-content: center;
  padding: 15px 0;
}