@import url('../../../../../styleguide/contant.scss');

.list_selector_Wrapper{
    border:  1px solid #e6e6e6;;
    border-radius: 4px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    
    // justify-content: space-around;
    background: #F7F7F8;
    // padding: 10px;
    .list_selector{
        background-color: #ffffff;
        border: 1px solid #E8E6E6;
        width: 27%;
        position: relative;
        margin: 10px 5px;
        &:first-child{
            margin-left: 10px;
        }
        &:last-child{
            width: calc(46% - 10px);
            margin-left: 5px;
            margin: 0;
            border-right: 0;
            border-top: 0;
            border-bottom: 0;
            height: 456px;
        }
        height: 436px;
        overflow: auto;
        .config_header{
            background: #F1F2F6; //to be changed
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            border-bottom: 1px solid #DDDFE8;
        }
                .individual_config {
                    margin-top: 6px;
                    border: 1px solid #F1F2F6;
                    border-radius: 4px;
        
                    .config_wrapper {
                        border: 1px solid#DDDFE8;
                        font-size: 10px;
                        padding: 2px 4px;
                        display: flex;
                        gap: 4px;
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        align-items: center;
                        p{
                            line-height: unset;
                        }
        
                        &:hover {
                            border: 1px solid#0A3CA2;
        
                            svg {
                                border: 1px solid #0A3CA2;
                                border-radius: 10px;
                                color: #FFFF;
                                background-color: #0A3CA2;
                            }
                        }
        
                        svg {
                            // margin-top: 1px;
                            font-size: 12px;
                            cursor: pointer;
                        }
                    }
        
                    .dimension-box {
                        background: #F1F2F6;
                    }
        
                    svg.deleteIcon{
                        color: #CBCBCB;
                        &:hover:hover {
                            color: #0A3CA2;
                            path {
                                fill: #0A3CA2;
                            }
                        }
                    }
                }
        .list_selector_heading{
            padding: 10px 12px;
            background: #EFF4FF;
            font-size: 12px;
            font-weight: 500;
            // color: $primary-blue-dark;
            color: #0A3CA2;
            position: sticky;
            top: 0;
            z-index: 1;
        }
        .search_template_list {
            &:nth-child(even) {
                background-color: #FAFAFA;
            }
        }
        .selected_length{
            background-color: #D8EAFF;
            border-radius: 3px;
            border: 1px solid #BDDAFB;
            width: fit-content;
            min-width: 16px;
            height: fit-content;
            min-height: 16px;
            text-align: center;
        }
        .MuiFormControlLabel-label{
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            margin-left: 5px;
            margin-top: 2px;
        }
        .MuiCheckbox-root{
            padding: 0;
            margin-left: 10px;
            svg{
                fill: #0A3CA2;
            }
        }
        ul{
            li{
                padding: 5px 10px;
                font-size: 12px;
                font-weight: 400;
            }
        }
        .not_data {
            height: 80%;
        }
    }
}

.erro_wrapper{
    margin: 4px;
    padding: 10px;
    .error_message{
        position: unset;
        text-align: right;
    }
}

.template_type.disabled{
    input{
        border: 1px solid #DDDFE8;
        background: #F1F2F6;
        font-weight: 400;
    }
}

.template_name{
    .MuiInputBase-root{
        margin-top: 0;
    }
    textarea{
        max-width: 100% !important;
    }
}

.form_component_title{
    font-size: 14px;
    font-weight: 700;
}

.listSelector_nodata{
    max-width: 171px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg{
        margin-bottom: 11px;
    }
    p{
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color: #898A90;
        text-align: center;
    }
}

.drop-down{
    background-color: unset;
    font-size: 10px;
    line-height: 11.72px;
    font-weight: 500;
    option{
        background-color: #f0f0f0;
        color: #333;
        padding: 5px;
        border: none;
    }
}

.Template-Summary{
    background: #F1F2F6 !important;
}

.vertical-line {
    width: 1px;
    height: 13px;
    background-color: #DDDFE8;
    margin: 3px 0px;
  }

  .bg-custom-gray {
    background-color: #F8F8F8;
  }

  .rounded-div {
    width: fit-content;
    min-width: 46px;
    height: 16px;
    background-color: #EDF5FF;
    border-radius: 2px;
    border: 1px solid #BDDAFB;
    margin-left: 4px;
    color: #337AB7;
    p {
        display: inline-flex;
        font-size: 9px;
        align-items: center;
        margin-left: inherit;
        margin-right: 4px;
    }
  }  

  .more-button {
    font-weight: 700;
    min-width: 67px;
    width: fit-content;
    height:22px;
    border: 0.7px solid #0A3CA2 !important;
    border-radius: 3px;
    background-color: #D8EAFF;
    color: #0A3CA2;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    p, span{
        margin: 0 3px;
        svg{
            margin-left: 2px;
        }
    }
  }

  .disable-hover {
    pointer-events: none;
  }

  .reset_config{
    p.disabled{
        color: #898A90;
    }
  }