@import "../../../styleguide/contant.scss";

.custom{
    background: $modal-footer-background 0% 0% no-repeat padding-box;
    border: 1px solid $primary-sky-blue;
    border-radius: 4px;
    opacity: 1;
    padding: 4px;
    color: $primary-sky-blue;
}
.default{
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border: 1px solid #DEDEDE;
    border-radius: 4px;
    opacity: 1;
    padding: 4px;
    color: $disable-text-color;
}