.budget_alert_wrapper {
  .edit_td {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      cursor: pointer;
    }
  }

  .sction_container {
    display: inline-flex;
    align-items: center;

    svg {
      margin: 0;
    }
  }

  .group {
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    border-radius: 60px;
    padding: 4px 6px !important;
    margin: 0;
    color: #0a3ca2 !important;
    box-shadow: -1px 5px 6px 0 rgb(178 214 244 / 34%);
    border: solid 1px #e0f1ff;
    background-color: rgba(224, 241, 255, 0.53);
    margin-right: 4px;
  }

  .table_wrapper {
    width: max-content;
    min-width: 50%;

    table {
      tr {
        td {
          padding: 7px 15px;
        }
      }
    }


  }
}


.budget_alert_wrapper .table_wrapper table tbody tr td {
  min-width: 160px;
  padding: 6px 15px;
}

.budget_alert_wrapper .table_wrapper table thead th {
  padding: 0px 13px;
  height: 35px;
}

.breached {
  color: red !important;
  padding-bottom: 4px;
  border-bottom: 2px solid red;
}

.normalText {
  text-transform: initial;
}

#DataTransferTop10ResourcesLast30Days {
  .dataTransferResourceTable {
    color: #0A3CA2 !important;
    font-weight: 700 !important;

  }
}

.cudos-data-transfer {
  .table_wrapper {
    table {
      thead {
        height: 44px !important;
      }

      tr {
        td {
          padding: 7px 15px;
        }
      }
    }
  }

  .sub-legend-header {
    position: relative;
    min-width: 85px;

    .header-name {
      position: absolute;
      bottom: 4px;
      left: 50%;
      transform: translateX(-50%);
    }

    .sub-legends-wrapper {
      position: absolute;
      width: 130%;
      top: 2px;
      left: 30%;
      z-index: 1;

      .sub-legends-content-wrapper {
        width: 100%;
        height: 20px;

        span {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;
          padding: 0 10px;
          background-color: #f8f8f8;
          color: #337ab7;
          font-style: italic;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 85%;
        }

        .horizontal-line {
          position: absolute;
          top: 5px;
          width: 100%;
          border-top: 1px solid #337ab7;
          border-left: 1px solid #337ab7;
          border-right: 1px solid #337ab7;
          height: 5px;
        }
      }
    }
  }
}

.header-name-last-sticky {
  display: flex;
  justify-content: end !important;
  width: 75px;
}

.header-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-td-align {
  text-align: right !important;
}

.left-td-align {
  text-align: left !important;
}

.single-striped-table {
  border: none !important;
  border-radius: 3px !important;

  table {
    th {
      &:last-child {
        background-color: #0A3CA2 !important;
        color: white !important;
      }
    }

    th,
    td {
      &:last-child {
        position: sticky;
        top: 0;
        right: -1px;
        z-index: 1;
        width: 75px;
        text-align: right !important;

        &:after {
          position: absolute;
          content: '';
          width: 1px !important;
          height: 100%;
          top: 0;
          left: 0px !important;
          background-color: #fff;
        }
      }
    }
  }
}

.table_wrapper {
  border-radius: 4px;
  &.lens-plus-customer {
    table:first-child {
      border-collapse: separate;
      &>thead {
        &>tr {
          &>th {
            background-color: #0A3CA2 !important;
            padding: 4px 8px !important;
            color: white !important;
            height: 24px !important;
            border-right: 1px solid white !important;
              border-left: 0px solid white !important;
            
              &:first-child::after {
                  right: -1px;
                }
            span {
              font-weight: 600;
              line-height: 13px;
              margin: 0px !important;
                display: block !important;
            }

            &:last-child {
              
              &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                box-shadow: -4px 0px 4px 0px rgba(12, 71, 126, 0.3490196078);
                width: 1px;
              }
            }

            div {
              div {
                display: none;
              }
            }
          }
        }
      }

      &>tbody {
        tr {
          td,
            td:first-child {
            border-right: 1px solid #e6ecf1 !important;
            border-bottom: 1px solid #e6ecf1 !important;
            border-top: 0px solid #e6ecf1 !important;
            padding: 4px 8px !important;
            font-weight: 500 !important;
            line-height: 14px !important;

          }
          

          td:last-child {
            font-weight: 600 !important;
            color: #151E33 !important;
            border-left: 1px solid #e6ecf1 !important;



            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              box-shadow: -2px 0px 4px 0px rgba(12, 71, 126, 0.3490196078);
              border-left: 1px solid #e6ecf1 !important;
              width: 1px;
            }



          }

          &:nth-child(odd) {
            td {

              background-color: #FFFF !important
            }
          }

          &:nth-child(even) {
            td {
              background-color: #F8F7FC !important;
            }
          }
        }
      }

      &>tfoot {
        tr {
          td {
            border-bottom-width: 0px;
            background-color: #E4F4FB !important;
            color: #2B2B2B !important;
            font-size: 11px !important;
            font-weight: 600 !important;
            padding: 4px 8px !important;
            line-height: 14px !important;
            height: 24px !important;
            border-left: 0px !important;

            &:first-child {
              padding: 4px 10px !important;
              &::before {
                  background-color: #e6ecf1;
                  height: 1px;
                }
            }

            &:last-child {
              border-left: 1px solid #e6ecf1 !important;
              &::before {
                  background-color: #e6ecf1;
                  height: 1px;
                }
              &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                box-shadow: -2px 0px 4px 0px rgba(12, 71, 126, 0.3490196078);
                border-left: 1px solid #e6ecf1 !important;
                width: 1px;
              }
            }
          }
        }
      }
    }

  }
}