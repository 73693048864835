.dialog-component-wrapper {
    .dialog-header {
        // border-bottom: 1px solid $secondary-blueish-grey;
        min-height: 40px;
    }

    .dialog_modal_info_box {
        svg {
            margin-left: 0 !important;
        }

        .message_style {
            font-size: 11px;
        }
    }
}