@import "../../../../../../../styleguide/contant.scss";

.s3-cost-breakup-wrapper {
  .s3-cost-no-data-wrapper {
    min-height: 220px;
    position: relative;
    width: 100%;
    overflow: hidden;

    .no_data {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }


  }

  .table_wrapper {
    &.table-width {
      width: 100%;
      min-width: max-content;

      &.lens_table {
        overflow: unset;
      }
    }

    table {

      th:first-child,
      td:first-child {
        position: sticky;
        left: 0;
        z-index: 4;
      }

      th {
        line-height: 13px;
        font-weight: 600 !important;
      }

      tr:hover {
        background: $cudos-hover-blue !important;

        td:first-child {
          background: $cudos-hover-blue !important;
        }
      }

      tr:nth-child(even) td:first-child {
        background: $cudos-table-background;
      }

      tr:nth-child(odd) td:first-child {
        background: $primary-white;
      }

      tfoot td {
        line-height: 13px;

        &:first-child {
          border-right: 1px solid $cudos-border-blue !important;
        }
      }

      tbody td {
        line-height: 14px;
      }

      tr td:last-child {
        font-weight: 600;
        color: $primary-grey-dark !important;
      }

      tfoot td:last-child {
        position: sticky;
        right: 0;
      }
    }
  }
}