.ck-cudos-months-cards {
  background: white;
  border-radius: 5px;
  position: relative;
  &.monthly-cost-cards {
    background-color: #d9efdd;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    * {
      color: #2b2b2b;
    }
    h4 {
      font-weight: 600;
      font-size: 13px;
      margin-bottom: 3px;
      margin-top: 0px;
      line-height: 15px;
    }
    p {
      font-size: 11px;
      font-weight: 400;
      line-height: 12px;
    }
    .amount {
      margin-top: 5px;
      font-size: 17px;
      font-weight: 600;
      line-height: 21px;
    }
  }
  p {
    margin-bottom: 0px;
  }
}
