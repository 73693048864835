.MuiStack-root:has(.MonthCalendar){
    p.MuiTypography-root.MuiTypography-body2 {
        font-size: 11px;
        margin-bottom: 2px;
    }
    .MonthCalendar{
        margin-top: 0;
        .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-adornedEnd{
            fieldset.MuiOutlinedInput-notchedOutline {
                border: unset !important;
                top: 0;
            }
        }
        &> div.MuiInputBase-root {
            display: flex !important;
            padding: 11px;
        }
        input{
            padding: 0 !important;
            border-radius: unset;
            &::placeholder{
                font-size: 12px !important;
                color: #898A90;
            }
        } 
    }
    .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-adornedEnd{
        .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
            background: unset;
            height: auto;
            button{
                margin: 0;
            }
        }
        .MuiInputAdornment-root.MuiInputAdornment-positionEnd button svg {
            border: unset;
            margin: 0;
            border-radius: unset;
        }
    } 
}

.monthCalender-calender{
    .MuiPaper-root {
        min-width: 246px;
        box-shadow: -8px 12px 16px 0px #00000026;
        border: 1px solid #DDDFE8;
        border-radius: 4px;
    }
    .MuiPickersLayout-contentWrapper{
        .MuiDateCalendar-root{
            max-height: 152px !important;
            width: 246px;
        }
    }
    .MuiPickersCalendarHeader-root{
        padding: 8px 13px;
        margin: 0;
        background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #F0F0F0 0%, #FFFFFF 100%);
        border-bottom: 1px solid #E6E6E6;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .MuiPickersCalendarHeader-label{
            font-size: 12px;
        }
    }
    .MuiMonthCalendar-root{
        width: 246px;
    }
    .MuiPickersMonth-root,.MuiPickersYear-root{
        button{
            color: #000;
            font-size: 11px;
            height: 24px;
            margin: 2px 0;
            &.Mui-selected{
                background-color: #F3F3F3;
                font-weight: 500;
                padding: 5px 19px;
            }
        }
    }
    .MuiYearCalendar-root{
        width: unset !important;
    }
    // &:has(.MuiYearCalendar-root){
    //    .MuiPickersCalendarHeader-root{
    //         margin-top: 14px;
    //    }
    // }
}

.mandatory-field{
    .MuiStack-root{
        p::after{
            content: "*";
            color: red;
            margin-left: 3px;
        }
    }
}

.reset.disabled{
    svg{
        path{
            fill: #898a90;
        }
    }
}
