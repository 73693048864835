@import "../src/styleguide/contant.scss";
@import "../src/Constants/contant.scss";


* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

::-webkit-scrollbar-thumb {
  background: $primary-blue-dark !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 10px !important;
}

.toast {
  top: 4rem !important;
}

.icon-info {
  position: relative;

  svg {
    position: absolute;
    bottom: 0;
    left: -3px;

    &.small {
      font-size: $fs-10;
    }

    &.big {
      font-size: $fs-14;
      bottom: -6px;
    }
  }
}

.btn-filled {
  border-radius: 4px;
  box-shadow: -9px 7px 9px 0 rgba(10, 60, 162, 0.2);
  background-color: $primary-blue-dark !important;
  padding: 8px 12px;
  border: 1px solid $primary-blue-dark;
  font-size: $fs-12;
  color: $global-white;
  white-space: nowrap;
  cursor: pointer;
}

.btn-outline {
  background-color: white;
  border: 1px solid $primary-blue-dark;
  color: $primary-blue-dark;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: $fs-12;
  cursor: pointer;
  box-shadow: -9px 7px 9px 0 rgba(10, 60, 162, 0.2);
}

.MuiButton-root {
  font-size: 0.8em !important;
  text-transform: capitalize !important;
  box-shadow: -3px 3px 4px 0 rgba(10, 60, 162, 0.1) !important;
  white-space: nowrap !important;
}

.MuiButton-contained {
  background-color: $primary-blue-dark !important;
}

.MuiButton-outlined {
  border: 1px solid $primary-blue-dark !important;
  color: $primary-blue-dark !important;
  box-shadow: inherit !important;
  height: 34px;
  padding: 6px 12px;
  box-shadow: none !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: $primary-blue-dark !important;
}

.MuiFormHelperText-root {
  font-size: $fs-10 !important;
  margin-top: unset;
}

.MuiCircularProgress-root {
  width: 20px !important;
  height: 20px !important;
  color: $accent-red !important;
  margin-right: 10px;
}

.MuiBox-root {
  margin: 10px 0;

  .error_message {
    margin-top: 0;
  }
}

.MuiIconButton-colorSecondary {
  &:hover {
    background: none !important;
  }
}

.error_message {
  position: absolute;
  width: 100%;
  margin: auto;
  margin-top: -6px;
  color: red !important;
  text-align: left;
  font-size: $fs-10 !important;
}

.disable {
  cursor: not-allowed;
  pointer-events: none;
}

.display_flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.display_flex_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.widget-basic {
  background-color: white;
  box-shadow: -7px 14px 38px #343a4015;
  border: 1px solid $secondary-blueish-grey;
  border-radius: 4px;
}

.mandatory {
  &::after {
    content: "*";
    color: red;
    margin-left: 3px;
  }
}

.col-md-6,
.col-md-3,
.col-md-4,
.col-md-12,
.col-md-2 {
  padding-left: unset;
  padding-right: unset;
}

.seperator {
  height: 18px;
  border-left: 1px solid #BFD2E7;
  margin: 0 10px;
}

.Toastify__toast--success {
  background-color: #d5ffe6 !important;
  color: black !important;
  border: 1px solid #24A24933;
  border-left: 5px solid #24A148;
  font: normal normal 600 #{$fs-14}/#{$fs-18} IBM Plex Sans !important;

  .Toastify__toast-icon {
    svg {
      path {
        fill: #24A148;
      }
    }
  }
}

.Toastify__toast--error {
  background-color: #fadfdf !important;
  color: black !important;
  border: 1px solid #facbcb;
  border-left: 5px solid #e74a3b;
  font: normal normal 600 #{$fs-14}/#{$fs-18} IBM Plex Sans !important;

  .Toastify__toast-icon {
    svg {
      path {
        fill: #e74a3b;
      }
    }
  }
}

// removes default background color when autofilling inputs
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-tooltipPlacementTop {
  margin-bottom: 7px !important;
  padding: 4px 6px;
  font-size: $fs-11;
  line-height: 1.4;
  background: #525252 !important;

  .MuiTooltip-arrow {
    &::before {
      background: #525252 !important;
    }
  }
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  border-radius: 4px !important;
  line-height: 1;
  background: #525252 !important;
  font-size: $fs-10;
  padding-left: 6px !important;
  padding-right: 6px;

  .MuiTooltip-arrow {
    &::before {
      background: #525252;
    }
  }
}

.login_form .flex.createForm {
  min-height: inherit;
}

.delete-dialog-body {
  .MuiDialogContentText-root {
    font-size: $fs-14 !important;
    color: $secondary-black !important;
  }

}

.MuiDialogActions-root.delete-dialog-footer {
  button {
    min-width: 64px;
    text-align: center;
    justify-content: center;
  }
}

.MuiTooltip-popper[data-popper-placement*="bottom-start"] .MuiTooltip-tooltip {
  margin-top: 7px !important;
  margin-left: -7px;
  padding-top: 4px;
  font-size: $fs-10;
  padding-bottom: 5px;
  background: #525252 !important;

  span.MuiTooltip-arrow {
    left: 3px !important;

    &::before {
      background: #525252 !important;
    }
  }

  &:has(.custom_table_tooltip) {
    background-color: $primary-white !important;
  }
}

.MuiTooltip-popper[data-popper-placement*="top-start"] .MuiTooltip-tooltip {
  margin-bottom: 7px !important;
  background: #525252 !important;
  margin-left: -7px;
  font-size: $fs-10;

  span.MuiTooltip-arrow {
    left: 3px !important;

    &::before {
      background: #525252 !important;
    }
  }
}

.MuiTooltip-popper[x-placement="top"] .MuiTooltip-tooltip {
  margin-bottom: 7px !important;
  padding: 4px 6px;
  font-size: $fs-10;
  line-height: 1;
  background: #525252 !important;

  .MuiTooltip-arrow {
    &::before {
      background: #525252 !important;
    }
  }
}

.customer-linked-acc-wrapper {
  padding-top: 5px;
}

.settingsBtn.button {
  font-size: $fs-13;
  padding: 5px 10px !important;
  justify-content: center;
  align-items: center;

  &:hover {
    color: $primary-white !important;
  }
}

.settingsBtn svg {
  width: 19px;
  height: 15px;
}

.table-container .table .table-body-row td:first-of-type {
  min-width: 84px;
}

.login_form .input-field-container {
  input {
    margin-bottom: 10px;
  }

  .error_message {
    margin-top: -10px;
  }
}

.smtp-password {
  padding-right: 40px !important;
}

#tabs-cont {
  .react-tabs__tab--selected::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 5px;
    background: $primary-white;
    bottom: -3px;
    left: -0.2px;
    z-index: 2;
  }
}

#tabs-cont ul a:only-child {
  .react-tabs__tab:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background: $primary-white;
    bottom: -1px;
    left: -0.2px;
    z-index: 2;
  }
}

.MuiSelect-select {
  width: initial !important;
}

#go-to-top {
  border: solid 1px $primary-blue-dark !important;
  background-color: $primary-blue-dark !important;
  color: $primary-white !important;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-90deg) scale(1.1, 1.2);
    font-size: $fs-25;

    path {
      stroke: $primary-blue-dark;
    }
  }
}

.back-to-top {
  position: fixed;
  z-index: 999;
  bottom: 80px;
  right: 60px;
  font-size: 100px;
  color: white;
  cursor: pointer;
  border: none;
  outline: none;
  width: 50px;
  height: 50px;
  transition: 1s;
  border-radius: 100%;
  opacity: .8;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: all 1s;

  &.show {
    visibility: visible;
    opacity: 0.8;
    transition: all 0.3s;
  }
}



.invoiceGeneration {
  .dropdown-wrapper:first-child {
    p.text-field.x-small.primary {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &>.\!mb-8 {
    margin-bottom: 20px !important;
    padding-left: 0;
  }
}

.d-flex {
  display: flex;
}

.tableBodyIcons {
  .MuiSwitch-switchBase.Mui-disabled {
    .MuiSwitch-thumb {
      background-color: #626262 !important;
    }
  }

  .MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track {
    background-color: $muted-color !important;
  }
}

.Mui-disabled {
  pointer-events: none !important;
}

#outline-disabled-btn {
  border: 1px solid #9AA6B6 !important;
  color: #9AA6B6 !important;
  background-color: #fff !important;
  pointer-events: none !important;
}

.switch-icon:has(.Mui-disabled) {
  cursor: not-allowed !important;
}

.excel-loading {
  position: relative;

  .excel-loading-gif {
    position: absolute;
    top: 7px;
    right: 10px;
  }
}

.dBlock {
  display: block;
}

.downloadxBtn-loading {
  border: 0 !important;
  background-color: transparent !important;
}

.mr_5 {
  margin-right: 5px;
}

.public_module {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.grecaptcha-badge {
  z-index: 9;
  bottom: 50px !important;
}

.divider_left {
  border-left: solid 1px $secondary-blueish-grey;
}

p.font-semibold {
  margin-bottom: 0;
}

.row:before {
  display: none;
  content: none;
}

table {
  td {
    text-align: center;
  }
}

.portal-root {
  &:has(.active_filter_multiselect) {
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
      /* Adjust the transparency as needed */
      z-index: 9;
      /* Ensure the overlay appears on top */
    }
  }
}

.unset_width {
  width: unset !important;
}

.margin-loader {
  margin-left: 500px;
}

.template_type.disabled {
  .dropdown {
    background-color: #F1F2F6;
    border-radius: 4px;

    img {
      display: none;
    }
  }
}

.fit-content {
  width: fit-content;
}

.summary_active,
.template_name_V2 {
  .list-popup-wrapper {
    display: flex;
    justify-content: center;

    .Active_Chip_Edp {
      min-width: 35px;
      padding: 4px 3px;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #337AB7;
      border-radius: 2px;
      border: 1px solid #779FF0;
      background: #E8F1FF;
      margin-left: 5px;
      font-family: Roboto;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.1px;
      text-transform: capitalize;
    }
  }
}

.discount_name_V2 {
  &>div {
    &.list-popup-wrapper {
      &.auto-renew-tooltip-wrapper {
        justify-content: flex-start !important;
      }
    }
  }
}

.MuiPaper-root {
  margin-top: 0 !important;

  &:has(.Edp_Tooltip_Style_V2) {
    min-height: 10px !important;

    &::before {
      top: 16px !important;
    }
  }

  &:has(.Edp_TemplateTooltip_V2) {
    min-height: unset !important;
  }
}

.mr-custom {
  margin-right: calc(100% - 750px);
}

#cld_datepicker {
  .calender {
    .error_message {
      color: red;
    }
  }
}

// azure onboarding toast

.toast-V2 {
  .Toastify__toast {
    border-radius: 12px 0 0 12px;
  }

  .Toastify__close-button--light {
    color: #979FA9 !important;
    opacity: 1;

    &:hover {
      color: #979FA9 !important;
    }
  }

  .Toastify__close-button {
    position: absolute;
    top: 22px;
    right: 18px;
  }

  .Toastify__toast-body>div:last-child {
    color: #23324E;
    margin-right: 17px;
  }

  .Toastify__toast--success {
    background-color: #F6FFF9 !important;
    border-left: unset;
    border: 1px solid #008C51;
    border-right: 4px solid #008C51;

    .Toastify__toast-icon {
      svg {
        path {
          fill: #008C51;
        }
      }
    }
  }

  .Toastify__toast--error {
    background-color: #FFF5F3 !important;
    border-left: unset;
    border: 1px solid #E02B46;
    border-right: 4px solid #E02B46;

    .Toastify__toast-icon {
      svg {
        path {
          fill: #E02B46;
        }
      }
    }
  }
}

.Active_Chip {
  min-width: 35px;
  padding: 4px 3px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #337AB7;
  border-radius: 2px;
  border: 1px solid #779FF0;
  background: #E8F1FF;
  margin-left: 5px;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.1px;
  text-transform: capitalize;
}

//Billing console
.DB_table_wrapper_V2 {
  width: 100%;
  padding: 10px 0px 0;
  box-sizing: border-box;
  padding: 9px;

  .colorCodes_container {
    margin-right: unset;
    justify-content: space-between;
    align-items: center;

    .colorCodes {
      align-items: center;

      h4 {
        color: $secondary-black;
        opacity: 60%;
        font-size: 13px;
        margin: 0;
        margin-left: 6px;
      }

      .circle {
        width: 8px;
        height: 8px;
        margin-bottom: 2px;
        border-radius: 50%;
      }
    }
  }

  .loader_border {
    border-top: 1px solid $border-sky-blue;
  }
}

.cld-Table {
  .disabled-icon {
    fill: #898A90 !important;
  }
}

.read_only {
  border: none !important;
  padding: 0;
  border: none !important;
  padding: 0 !important;
  height: 20px !important;
}

.disabled {
  span.Mui-disabled {
    svg {
      background: #CBCBCB;
      fill: #CBCBCB;
      border: 1px solid #898A90;
      border-radius: 2px;
      height: 14px !important;
      width: 14px !important;
      margin-left: 3px;
    }
  }

  span.Mui-disabled.Mui-checked,
  {
  svg {
    color: #898A90 !important;
    fill: #898A90;
    background: unset;
    border-radius: 2px;
    border: unset;
    height: 14px !important;
    width: 14px !important;
    border: 1px solid #898A90;
    position: relative;

    path {
      transform: scale(1.3);
      position: absolute;
      top: 10px;
      left: 10px;
      transform: scale(1.5) translate(-4px, -4px);
    }
  }
}
}

.discountTypeChip {
  padding: 3px 3px;
  background: #e8f1ff;
  color: #337ab7;
  margin: 5px;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.1px;
  border: 1px solid #779ff0 !important;
}

.Mui-disabled.MuiButton-root {
  background-color: #898a90 !important;
  color: #ffffff !important;
  svg.btn_icon_style.download_excel_v2{
    fill: #ffffff;
  }
}

.toggleDisable {
  .MuiSwitch-thumb {
    background: #6E7383 !important;
  }

  .MuiSwitch-root span.MuiSwitch-track {
    background: #E8E7E7 !important;
  }
}

.table_checkbox {
  padding: 0 !important;
}

.select-all-checkbox {
  max-width: fit-content;
}

.charges_and_refund {
  .search-bar {
    right: 380px !important;
  }
}

.AdditionalDiscount {
  .search-bar {
    right: 365px !important;
  }
}

.ck-read-only-bread-crums {
  .crumb-wrapper {
    a {
      pointer-events: none;
      color: #898A90 !important;
      font-weight: 500;
    }

    &:first-child {
      a {
        pointer-events: unset !important;
        cursor: pointer;
      }
    }
  }
}

:root {
  --font-size-11: 11px;
  --padding-pill: 4px 8px;
  --ck-pill-green-bg: #F0F8F0;
  --ck-pill-green-text-color: #20845A;
  --border-radius-pill: 40px;
  --pill-border-color: #ECECEC;
  --pill-bg: #F7F7F7;
  --pill-lh: 8px;
  --pill-border-size: 1px;
  --pill-text-color: #2B2B2B;
}

.ck-pill {
  display: inline-block;
  font-size: var(--font-size-11);
  padding: var(--padding-pill);
  line-height: var(--pill-lh);
  border-radius: var(--border-radius-pill);
  box-shadow:inset 0px 0px 0px var(--pill-border-size)  var(--pill-border-color);
  background: var(--pill-bg);
  color: var(--pill-text-color);
  font-weight: 500;
  &-wrapper {
    display: flex;
    gap: 4px;
  }
  &-green {
    --pill-bg: var(--ck-pill-green-bg);
    --pill-border-color: #96CF96;
  }
}