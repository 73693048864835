@import "../../../styleguide/contant.scss";

.reset-password-wrapper {
    height: 400px;
    width: 30%;
    margin: auto 0;
    background-color: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px;
}

.text-secondary {
    margin: 10px 0 20px 0;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: $disable-text-color;
    font-size: 12px;
}

.return-signin {
    text-align: center;
    font-size: $fs-12;
    margin: 14px 0 8px 0;
    .text-secondary{
        margin: 0;
    }
}

.login_form{
    &.email-sent{
    align-items: center !important;
    justify-content: center;
    padding: 40px 28px 24px 28px !important;
    .forgot-return-btn{
        width: 100%;
        color: white;
        margin-bottom: 0px !important;
        height: 60px;
        &:hover{
            background: transparent linear-gradient(90deg, $primary-blue-dark 0%, #0A3DA2 45%, #0A50A2 100%) 0% 0% no-repeat padding-box;
            box-shadow: -6px 13px 15px #0A3CA24A;


        }
    }
    .email-sent-info{
        &.one{
            font: normal normal bold #{$fs-24}/#{$fs-32} Roboto;
            letter-spacing: -0.24px;
            color: $secondary-black;
            margin: 24px 0 4px 0;
        }
        &.two{
            font: normal normal normal #{$fs-12}/#{$fs-19} Roboto;
            letter-spacing: -0.14px;
            color: $disable-text-color;
            margin-bottom: 24px;
        }
    }
}
}

.forgotPassword{
    .login-form-wrapper {
        .login_form {
            h1 {
                margin: 0;
                margin-top: 10px;
            }
            .input-field-container{
                .input-label{
                    margin-bottom: 9px;
                }
            }
        }
    }
}