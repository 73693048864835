.pagination-table-wrapper {
  margin-top: 20px;
  position: relative;
  background-color: #fff;
  button {
    color: #333;
    background-color: #fff;
    border: 0.7px solid #f1f1f1;
    font-size: 9px;
    &.Mui-selected {
      border: none;
      color: #0a3ca2;
      background: #e7eefd !important;
    }
    &.MuiPaginationItem-firstLast {
    }
    svg {
      width: 12px;
      height: 12px;
    }
  }
  .header {
    width: 100%;
    margin-bottom: 14px;
    h3 {
      font-size: 14px;
      font-weight: 500;
      color: #000;
      &:after {
        content: "\f105";
        font-family: "FontAwesome";
        color: #000;
        margin: 0 7px;
        font-size: 14px;
        line-height: 1;
        display: inline-block;
        transform: rotate(90deg);
      }
    }
    p {
      margin-top: 6px;
      color: rgba(0, 0, 0, 0.45);
      font-weight: 400;
      font-size: 11px;
    }
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    border: none;
    &.expanded {
      h3 {
        &:after {
          transform: rotate(270deg);
        }
      }
    }
  }
  .search-and-pagination-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;
    .search-wrapper {
      .search-bar {
        padding: 9px 7px 8px 35px;
        font-size: 10px;
        width: 450px;
        position: relative;
        border: 1px solid #eaeaea;
        color: #000;
        svg {
          position: absolute;
          left: 12px;
        }
      }
    }
    .pagination {
      margin: 0;
    }
  }
  table {
    border: 1px solid #e7ecf5;
    thead {
      border-bottom: 1px solid #fff;
      th {
        background: #eff4ff !important;
        color: #0a3ca2;
        height: 28px;
        &.checkbox-header {
          width: 26px;
        }
      }
    }
    .paginated-headers {
      align-items: center;
      position: relative;
      min-width: 80px;
      span {
        width: 18px;
        display: inline-block;
        position: absolute;
        right: 0;
        svg {
          display: inline-block;
          font-size: 13px;
          width: 13px;
          height: 13px;
          cursor: pointer;
        }
      }
    }
    th,
    td {
      text-align: left;
      min-width: fit-content;
      border-right: 1px solid #fff !important;
      padding: 5px;
      font-size: 11px;
      input {
        cursor: pointer;
        width: auto;
      }
      &:last-child {
        border: none !important;
      }
    }
    td {
      height: 24px;
      font-weight: 400;
    }
    tr {
      background-color: #fff;
      &:nth-child(odd) {
        background-color: #f7f7f7;
      }
    }
  }
  .no-data {
    text-align: center;
    padding: 40px 0;
  }
  .footer-table {
    margin-top: 20px;
    .preview-btn {
      color: #fff;
      padding: 10px 14px;
      font-size: 12px;
      line-height: 14px;
      box-shadow: none;
    }
  }

  .show-selectAll-options{
    position: relative;
    width: 36px !important;
    span.MuiCheckbox-root{
      padding: 2px 10px;
      &.MuiCheckbox-indeterminate,&.Mui-checked{
        color: #0075ff;
      }
    }
    label.MuiFormControlLabel-root {
      svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium{
        width: 17px;
        height: 17px;
      }
    }
    .selectAll-dropdown-icon{
      position: absolute;
      top: 9px;
      left: 25px;
      cursor: pointer;
      svg{
        width: 15px;
        height: 15px;
      }
    }
    .option_container{
      position: absolute;
      left: 25px;
      top: 25px;
      z-index: 9;
      min-width: 100px;
      border-radius: 5px;
      background: #ffffff;
      padding: 8px 0;
      box-shadow: 0px 2px 6.3px 0px #5a627280;      
      .option {
        color: #000;
        text-transform: none;
        &:hover {
          background-color: #e6ecf1;
          color: #0a3ca2;
          border-radius: 3px;
        }
      }       
    }
    &.disabled-checkbox{
      cursor: not-allowed;
      label.MuiFormControlLabel-root,.selectAll-dropdown-icon{
        pointer-events: none;
      }
    }
  }

}
