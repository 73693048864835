@import "../../Constants/constant.scss"; 
.infobox_style {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 5px;
    background-color: #D8EAFF;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    gap: 5px;
    .info_style {
        .infoIcon_style {
           height: 12px;
           width: 12px;
           margin-left: 10px;
           color: $secondary-black;
        }
    }
    .message_style {
        color: $secondary-black;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }
}