.Edp_Tooltip_Style_V2 {
  > div {
    svg {
      width: 12px;
      height: 11px;
    }
  }

  max-height: 400px;
  max-width: 300px;
  overflow-y: auto;
  border-radius: 4px;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  padding: 12px 14px;
  min-height: fit-content;
  min-width: fit-content;
}

.Edp_TemplateTooltip_V2 {
  border-radius: 4px;
  overflow-y: auto;
  max-height: 100px;
  max-width: 400px;
  color: #292929;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  padding: 12px 14px;
  min-height: fit-content;
  min-width: fit-content;
}

.Edp_Tooltip_term_level_summary_V2 {
  border-radius: 4px;
  // max-height: 400px;
  // min-height: fit-content;
  min-width: fit-content;
  padding: 9.864px;
  display: flex;
  justify-content: center;
  align-items: center;

  .TermLevelSummary_tooltip_style {
    border: 1px solid #dddfe8;
    border-radius: 4px;
    background: #fff;

    .TermLevelSummary_tooltip_tableData {
      border-radius: 3px 3px 0px 0px;
      background: #f9f9f9;
      border-bottom: 1px solid #dddfe8;
      color: #495057;
      text-align: center;
      font-family: Roboto;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 12px !important;

      &.term_level_Header {
        min-width: 140px;
        padding: 8px 12px !important;
      }
    }

    .TermLevelSummary_tooltip_tableRow {
      border-bottom: 1px solid #dddfe8;
      text-align: center;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 12px;
      background-color: #fff !important;

      &.activeRow {
        background-color: #eff4ff !important;
      }

      &.nullified-row {
        background-color: #ececec !important;
      }

      &.term_level-row {
        padding: 8px 12px !important;
      }
    }

    .MuiLinearProgress-root {
      height: 8px;
    }
  }
}

#auto-renew-popover {
  .MuiPaper-root.MuiPopover-paper {
    &:has(.Edp_Tooltip_term_level_summary_V2) {
      &::before {
        top: 50%;
      }
    }

    &:has(.credits-template-name-tooltip) {
      margin-top: 0;

      &::before {
        top: 16px;
      }
    }
    &:has(.tuner_tooltip) {
      &::before {
        width: 8px;
        height: 8px;
        border: 0;
        background: #fff;
        top: 90%;
        right: 48%;
        transform: rotate(45deg) !important;
        z-index: 2;
        transform: translate(50%, 50%);
      }
    }
    &:has(.tuner_extension_tooltip) {
      &::before {
        top: -7%;
        right: 50%;
      }
    }
    &:has(.tuner_tooltip_account_type) {
      &::before {
        right: 49%;
        top: 91%;
      }
    }
  }
}

.show-more-copy {
  cursor: pointer;
}

#ck-bottom-tooltip {
  .text_accounts {
    background-color: #ffffff;
  }

  .MuiPaper-root.MuiPopover-paper {
    overflow: initial !important;
    margin-top: 4px !important;
    box-shadow: -5px 13px 31px rgba(0, 0, 0, 0.3529411765);
    border: 1px solid #d6d6d6;

    &::before {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      border: 1px solid #d6d6d6;
      background: #fff;
      border-right: 0;
      border-bottom: 0;
      top: -4px;
      right: 50%;
      transform: rotate(45deg) !important;
      z-index: 2;
    }
  }
}

.tuner_tooltip {
  max-height: 400px;
  max-width: 300px;
  overflow-y: auto;
  border-radius: 8px;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  padding: 10px 16px;
  min-height: fit-content;
  min-width: fit-content;
  &.tuner_extension_tooltip {
    max-width: initial;
  }
  &.tuner_tooltip_account_type {
    max-width: initial;
    font-family: Inter !important;
  }
}
