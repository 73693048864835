@import "../../../../../styleguide/contant.scss";

.rangeDateModule,.singleDateModule{
  .daterangepicker {
  box-shadow: 0px 2px 10px rgb(0 0 0 / 16%);
  margin-top: unset !important;

  &.show-ranges{
    padding-top: 55px;
   
  }

  &.single {
    min-width: 308px !important;
    .drp-calendar{
      margin-bottom: unset !important;
      .calendar-table{
        padding:0 !important;margin: 0;
        th.month {
          font-weight: 500 !important;
        }
      }
    }
    .drp-buttons{
      border-top: unset !important;
      padding: 00px !important;
    }
  }

  th.month {
    select.monthselect,
    select.yearselect {
      padding: 0 !important;
      border: 0;
      width: auto;
      margin: 0 5px;
      background: transparent;
      outline: none;
    }
  }

  .drp-calendar.left {
    margin-right: 5px !important;
    border-left: 0 !important;
  }

  .drp-calendar.right {
    margin-left: 5px;
  }

  .drp-buttons {
    .btn.applyBtn {
      color: rgb(255, 255, 255);
      background: $primary-blue-dark;
      border-radius: 4px;
      border: 1px solid $primary-blue-dark;
      padding: 4px 16px;
    }
    .btn.cancelBtn {
      color: $primary-blue-dark;
      border-color: $primary-blue-dark;
      background: rgb(255, 255, 255);
      border-radius: 4px;
      padding: 4px 16px;
      border: 1px solid $primary-blue-dark;
    }
  }

  td.active:not(.off.ends),
  td.active:not(.off.ends):hover {
  }
  td.in-range {
    color: rgb(0, 0, 0);
    box-shadow: inherit;
    background: rgb(204, 227, 246);
  }
  td.in-range:hover {
    color: rgb(0, 0, 0);
    background: rgb(204, 227, 246);
  }
  td.start-date:not(.off.ends),
  td.end-date:not(.off.ends) {
    box-shadow: rgba(51, 122, 183, 0.32) -2px 3px 6px 0px !important;
    color: rgb(255, 255, 255) !important;
    background: #338ddb !important;
  }
  td.off,
  td.off.start-date,
  td.off.end-date {
    color: $primary-white;
  }
}
.daterangepicker.show-calendar {
  .drp-calendar {
    display: block;
    width: 50% !important;
    padding: 0px !important;
    max-width: 100%;
    border: 0;
    border-radius: 4px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 40px;
    min-width: 294px;
  }
  .calendar-table {
    table {
      border-spacing: 0;
      border-collapse: separate;
      border: solid 1px $secondary-blueish-grey;
      border-radius: 4px;
      // max-width: 293px;
      margin-bottom: 0;
      td:last-child,
      th:last-child {
        font-size: $fs-11;
        color: currentColor !important;
      }
      td {
        font-size: $fs-10;
        border-radius: 50%;
        font-weight: 500 !important;
        color: $secondary-black !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      td.active:not(.off.ends) {
        color: $primary-white !important;
      }
      td.disabled {
        color: #b9becc !important;
        font-weight: normal !important;
      }
      tbody {
        tr {
          padding: 2px 10px;
          &:first-of-type {
            padding-top: 10px;
          }
          &:last-of-type {
            padding-bottom: 10px;
          }
        }
      }
      tr {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%;
      }
      th,
      td {
        height: 23px;
        max-width: 23px;
        max-height: 23px;
        width: 23px;
        padding: 0;
        min-width: inherit;
        color: $primary-grey-light;
      }
      thead {
        tr {
          th.next,
          th.prev {
            padding: 0;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            color: #338ddb;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            font-size: $fs-12 !important;
            background: rgb(224, 241, 255);
            border-width: 1px;
            border-style: solid;
            border-color: #338ddb;
            -o-border-image: initial;
            border-image: initial;
            border-radius: 50%;
            span {
              padding: 2px;
              border-color: #338ddb;
              margin-top: 1px;
            }
          }
          th.month {
            height: 23px;
            text-transform: uppercase;
            color: rgb(0, 0, 0);
            font-weight: 400;
            font-size: $fs-12 !important;
            -webkit-box-flex: 1;
            -ms-flex: 1 1 0%;
            flex: 1 1 0%;
            max-width: inherit;
            background: transparent;
            margin-top: -2px;
          }
        }
        tr:first-of-type {
          // background-image: -webkit-gradient(
          //   linear,
          //   left bottom,
          //   left top,
          //   from(rgb(244, 244, 244)),
          //   to(rgb(255, 255, 255))
          // );
          // background-image: -o-linear-gradient(bottom, rgb(244, 244, 244), rgb(255, 255, 255));
          // background-image: linear-gradient(to top, rgb(244, 244, 244), rgb(255, 255, 255));
          padding: 6px 10px;
          border-radius: 3px;
          align-items: center;
          th {
            background: transparent !important;
          }
          th.prev {
            padding-left: 3px !important;
            background: $primary-white !important;
          }
          th.next {
            padding-right: 3px !important;
            background: $primary-white !important;
          }
          th.next.available,
          th.prev.available {
            background: #eef6fe !important;
            border-color: rgb(53, 93, 177);
            color: rgb(53, 93, 177);
          }
          th:first-of-type,
          th:last-of-type {
            height: 16px;
            max-width: 16px !important;
            width: 16px !important;
          }
        }
        tr:last-of-type {
          padding: 2px 10px;
          border-bottom: 1px solid rgb(230, 230, 230);
          border-top: 1px solid rgb(230, 230, 230);
          th {
            text-transform: uppercase;
            margin-bottom: 0;
            color: $primary-grey-light !important;
            background: transparent !important;
            font-size: $fs-10 !important;
            font-weight: 400 !important;
            &:hover {
              color: $secondary-black !important;
            }
          }
        }
      }
    }
  }
}
.off.ends.available {
  color: black !important;
}

td.off {
  visibility: hidden !important;
}
td.off.ends.off.disabled {
  visibility: hidden !important;
}

td.off.disabled {
  visibility: unset !important;
}

.daterangepicker.show-calendar {
  .ranges {
    margin-top: 0;
    width: 100%;
    position: absolute;
    bottom: 65px;
    left: 0;
    ul {
      width: 100%;
      display: flex;
      padding: 10px 20px;
      padding-left: 93px;
      position: relative;
      column-gap: 6px;
      &::before {
        position: absolute;
        content: "Auto-Select:";
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: $primary-grey-light;
        font-size: $fs-12;
      }
      li {
        cursor: pointer;
        width: 45px;
        height: 20px;
        border-radius: 6px;
        border: solid 1px $secondary-blueish-grey;
        background-color: #f5f5f5;
        font-size: $fs-11;
        text-transform: uppercase;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $primary-grey-light;
        &:hover {
          background-color: #eee;
        }
        &:last-of-type, &:first-of-type {
          display: none;
        }
      }
      li.active {
        border-radius: 6px;
        box-shadow: -4px 7px 17px 0 rgb(10 60 162 / 25%);
        border: solid 1px $primary-blue-dark;
        background-color: #eef6fe;
        color: $primary-blue-dark;
        font-weight: bold;
        padding: 0;
      }
    }
  }
}

.clear-selection-wrapper{
  display: none;

  &.show{
    position: absolute;
    top: 57px;
    right: 25px;
    z-index: 9999;
    display: flex !important ;
    justify-content: space-between;
    align-items: center;
  }

  .clear-selection{
    cursor: pointer;
    color: $primary-blue-dark;
    font-size: $fs-11;
    p{
      display: inline;
    }
    svg{
      font-size: $fs-14;
    }
  }
  .date-text{
    font: normal normal normal #{$fs-12}/#{$fs-18} Roboto;
    color: $secondary-black;
    cursor: default;
    span{
      border: 1px solid $secondary-grey;
      border-radius: 4px;
      font: normal normal normal #{$fs-11}/#{$fs-30} Roboto;
      padding: 5px;
      margin-left: 9px;
      &:first-child{
        margin-right: 9px ;
      }
    }
   
  }
}
}
.rangeDateModule{
  .daterangepicker{
    min-width: 625px;
    padding: 20px;
    border-radius: 4px;
    .drp-calendar{
      min-width: 290px !important;
      .calendar-table{
        padding: 0 !important;
      }
      
    }
    .drp-buttons .drp-selected{
      top: 22px;
    }
  .drp-buttons .btn{
    min-height: 28px;
  }
  }
  &.calender-wrapper{
    .calender .header.dropdown{
      border-bottom: solid 1px $secondary-blueish-grey;
      border-radius: 4px ;
    }
  }
}
.singleDateModule{
  .daterangepicker{
    padding: 10px !important;
    border-radius: 4px;
    .drp-calendar{
      min-width: 100% !important;
      .calendar-table{
        padding: 0 !important;
        margin: 0;
        table{
          max-width: 100%;
        }
      }
      
    }
    .drp-buttons {
      padding: 0 !important;
      .drp-selected{
        top: 22px;
        display: none;
      }
    }
  .drp-buttons .btn{
    min-height: 28px;
  }
  }
  .daterangepicker.show-calendar .drp-calendar.left{
    padding-right: 0 !important;
    margin: 0;
  }
  .daterangepicker.show-calendar .calendar-table table{
    margin-bottom: 10px;
  } 
  &.calender-wrapper{
    .calender.disabled {
      pointer-events: none;
      .header {
        background-color: #F1F2F6;
        span {
          color: #898A90;
        }
      }
    }
    .calender .header.dropdown{
      border-bottom: solid 1px $secondary-blueish-grey;
      border-radius: 4px ;
    }
  }
}
.daterangepicker.show-calendar.auto-apply {
  padding: 2px !important;
  border-radius: 4px;

  .calendar-table table {
    border: none;

    thead {

      tr:first-of-type {
        // background-color: white !important;
        background-image: linear-gradient(to top, rgb(255, 255, 255), rgb(255, 255, 255));
        position: relative;

        &::after {
          content: "";
          position: absolute;
          width: 95%;
          border-top: 1px solid #C5CBD8;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        .month {
          .monthselect {
            text-align-last: right;
            option {
              direction: ltr;
            }
          }
        }

        th.next,
        th.prev {
          &.available {
            background: #b1bccb !important;
            border-color: #b1bccb;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          span {
            border-color: #000000 !important;
          }

          &:hover {
            &.available {
              background: #003da1 !important;
              border-color: #003da1;
            }

            span {
              border-color: #ffffff !important;
            }
          }
        }

        th.prev {
          &.available {
            padding-left: 1px !important;
            padding-bottom: 1px !important;
          }
        }

        th.next {
          &.available {
            padding-right: 2px !important;
            padding-bottom: 1px !important;
          }
        }


      }

      tr:last-of-type {
        border-bottom: none;

        th {
          color: #1F1F1F !important;
          font-weight: 600 !important;
          font-size: 11px !important;
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 11px !important;
          font-weight: 400 !important;

          &.available {
            background-color: #f8f9fc;

            &.active {
              box-shadow: none !important;
              font-weight: 400 !important;
            }
          }
        }
      }
    }
  }

  .drp-calendar {
    margin-bottom: 0 !important;

    &.left {
      padding-right: unset !important;
    }

    .calendar-table table {
      margin-bottom: 0 !important;
    }
  }
}

.daterangepicker.minute-allowed.single:has(.hourselect) {
  .calendar-time {
    .minuteselect {
      pointer-events: unset;
      color: black;
    }
  }
}