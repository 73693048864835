.text_clipper {
    p {
        color: #000 !important;
        font-size: 14px!important;
        font-weight: 400;
    }

    button {
        border: none !important;
        height: unset;
        min-width: unset !important;
        font-size: 13px !important;
        margin-left: 2px;

        .MuiButton-startIcon {
            margin-right: 4px !important;
        }

        &:hover{
            background-color: unset;
        }
    }
}