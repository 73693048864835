// @import "../../Constants/constant.scss";

.clone_modal_wrapper {
  position: absolute;
  top: calc(100% - 14px);
  left: calc(100% - 18px);
  border: 1px solid #e6e6e6;
  z-index: 11;
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: -3px 5px 27px 0px #00000038;
  .clone_modal_block {
    .clone_modal_header {
      background-color: #f0f6ff;
      padding: 12px;
      border-bottom: 1px solid #e8e6e6;
      position: sticky;
      width: 100%;
      top: 0;
      z-index: 1;
      h4 {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: #000000;
      }
    }
    .clone_modal_body {
      padding: 8px 13.5px;
      overflow: auto;
      max-height: 200px;
      .MuiFormGroup-root {
        .MuiFormControlLabel-root {
          padding: 8px 26px 8px 0px;
          margin: 0;
          .MuiButtonBase-root {
            padding: 0;
            padding-right: 9px;
          }
          .MuiTypography-root {
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0px;
            text-align: left;
          }
        }
      }
    }
    .clone_modal_footer {
      position: sticky;
      bottom: 0;
      .cancel{
        padding: 7px 18px;
        border-radius: 4px;
        line-height: 14px;
      }
      .apply-rate{
        padding: 7px 15px;
        border-radius: 4px;
        line-height: 14px;
        margin-left: 0px;
      }
    }
  }
}
