.custom-dropdown {
    position: relative;
  }
  
  .selected-option {  
    cursor: pointer;
  }
  
  .options {
    
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    position: absolute;
    top: 100%;
    left: -24px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #DDDFE8;
    width: 121px;
    max-height: 510px;
    box-shadow: 0 4px 5px rgba(56, 56, 56, 0.11);
    overflow-y: auto;
  }
  
  .options li {
    padding: 5px;
    cursor: pointer;
  }
  
  .options li:hover {
    background-color: #F1F6FF;
  }

  .selected-li{
    background-color: #F1F6FF;
  }

  .expend{
      transform: rotate(90deg);
      transition: all 0.2s ease;
  }

  .expend-close {
    transform: rotate(270deg);
    transition: all 0.2s ease;
  }