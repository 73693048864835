.data-transfer-summary-cards {
  flex-grow: 1;
  background: white;
  border-radius: 5px;
  position: relative;

  .summary-cards-heading {
    background: #4698d3;
    border-radius: 2px 10px 10px 0px;
    width: fit-content;
    padding: 2px 7px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
  }
  .summary-cards-item {
    background: #e6f5ff;
    color: #0c477e;
    line-height: 12px;
    font-size: 12px;
    font-weight: 400;
    padding: 2px 5px;
    border-radius: 2px;
    margin-bottom: 8px;
    width: fit-content;
    max-width:100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .summary-cards-amount {
    line-height: 14px;
    font-size: 14px;
    color: #0c477e;
    font-weight: 700;
  }
  .divider {
    border-right: 1px solid #e6ecf1;
    padding-right: 12px;
  }
}

.rds,
.elasticache,
.s3
 {
  .summary-cards-item-container {
    width: calc(100% / 2);
    box-sizing: content-box;
  }
  .summary-cards-content {
    display: flex;
    padding: 15px 10px 20px;
    gap: 8px;
  }
}

.data-transfer {
  .summary-cards-content {
    display: flex;
    padding: 15px 10px 20px;
    gap: 8px;
  }
}

.rds {
  .summary-cards-content {
    padding: 8px 12px 7px;
  }

  &.fargate{
    .summary-cards-content {
      padding: 15px 12px 14px;
    }
  }
}
