.billing_console_header.disabled {
    label {
        color: #6a6a6a;
        font-family: Roboto;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    select {
        color: #6a6a6a;
    }
}

.lens-filter-style {
    &.month-dropdownCont {
        label {
            display: none;

        }

        &::after {
            right: -9px !important;
        }

        select {
            padding: 8px 8px 8px 12px !important;
            // min-width: unset !important;
            min-height: 33px !important;
            color: #2B2B2B !important;
            border-radius: 5px !important;
            outline: 1px solid #E6ECF1 !important;
            line-height: 14.52px !important;
            font-size: 12px !important;
        }
    }
}