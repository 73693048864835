.marketPlace {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border: 1px solid #e1eaef;
    background-color: #fff;
    box-shadow: -4px 2px 12px 0px #00000012;
    .header-logo {
      img {
        width: 172px;
      }
    }
    .close_page_button {
      button {
        color: #5a96d2 !important;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
      }
      svg {
        margin-right: 10px;
      }
    }
  }
  .section {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 30px 0;
    text-align: center;
    height: calc(100vh - 277px);
    .thankyou-section {
      position: relative;
      width: 60%;
      .first-msg {
        font-size: 13px;
        line-height: 13px;
        font-family: Metropolis !important;
        color: #253e66;
        margin-bottom: 11px;
        margin-top: 20px;
        font-weight: 400;
      }
      .marketplace-heading {
        margin-bottom: 30px;
        font-family: Bitter !important;
        p {
          color: #253e66;
          font-size: 26px;
          font-weight: bold;
          margin-bottom: 10px;
          line-height: 1.2;
          font-family: Bitter !important;
          span {
            font-family: Bitter !important;
          }
          .pink-text {
            color: #d93081;
            font-family: Bitter !important;
          }
        }
      }
      .icon-div-section {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 55%;
        flex-direction: column;
        .please-complete-text {
          svg {
            display: inline-block;
            margin: 0 5px;
          }
        }
        .icon-containing-box {
          display: flex;
          .icon {
            width: 25%;
          }
          .content {
            width: 75%;
          }
        }
      }
      .contact-section {
        font-size: 14px;
        color: #333333;
        line-height: 17px;
        font-family: Metropolis !important;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
        a {
          color: #4398d7;
          display: inline-block;
          font-family: Metropolis !important;
        }
      }
    }
  }
  .cloud-bg {
    background: url("../../Assets/images/cloud-bg.png") no-repeat center center;
    background-color: #fff;
    position: relative;
    height: 190px;
    background-size: cover;
    background-position: top;
    .cloud-section {
      display: flex;
      justify-content: space-between;
      width: 40%;
      margin: 0 auto;
      height: 190px;
      align-items: flex-end;
      padding-bottom: 20px;
      .info-content {
        h3 {
          font-size: 15px;
          color: #253e66;
          line-height: 12px;
          font-family: Metropolis Bold !important;
          margin-bottom: 6px;
        }
        p {
          font-size: 11px;
          line-height: 16px;
          font-weight: 400;
          color: #333333;
          font-family: Metropolis !important;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .section {
      .thankyou-section {
        width: 90%;
        position: relative;
        .icon-div-section {
          width: 100%;
          .icon-containing-box {
            flex-direction: column;
            .icon {
              width: 100%;
              margin-bottom: 10px;
            }
            .content {
              width: 100%;
            }
          }
        }
      }
    }
    .cloud-section {
      width: 90% !important;
      .info-content {
        width: 20%;
      }
    }
  }
}
.bold-text {
  font-weight: 600 !important;
}

.marketPlace_button {
  min-height: 74px;
  color: #263e66;
  font-weight: normal;
  font-size: 18px;
  white-space: nowrap;
  border: 2px solid #e2f2f0;
  border-radius: 6px;
  padding: 23px 26px;
  box-shadow: -4px 9px 16px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 17.5px;
  line-height: 21.6px;
  p {
      span {
        font-family: Bitter !important;
    }
  }
}

.metropolis_family {
  font-family: Metropolis !important;
}