@import "../../../styleguide/contant.scss";

.list-popup-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    .show-more {
        background: $primary-blue-dark 0% 0% no-repeat padding-box;
        width: 28px;
        height: 18px;
        border-radius: 14px;
        font: normal normal bold #{$fs-10}/#{$fs-13} IBM Plex Sans;
        color: $primary-white;
        padding: 5px 10px;
        margin-left: 10px;
        cursor: pointer;
    }

    .pop-up {
        display: inline-block;
    }


    .account-list {
        li {
            font: normal normal medium #{$fs-12}/89px Roboto;
            color: $secondary-black;
            margin: 5px 0;
        }
    }
}


.mav-show-more {
    width: 20px;
    height: 20px;
    background: $primary-blue-dark 0% 0% no-repeat padding-box;
    border-radius: 10px;
    display: inline-block;
    color: $primary-white;
    text-align: center;
    font: normal normal normal #{$fs-12}/#{$fs-21} Roboto;
    font-weight: 500;
    margin-left: 5px;
}

.credits-list-popup-tooltip {
    .MuiPaper-root.MuiPopover-paper {
        background: #FFF;
        border-radius: 4px;
        border: 1px solid #DDDFE8 !important;
        box-shadow: none !important;
        margin-top: -17px !important;
        margin-left: 9px;

        &::before {
            display: none;
        }
    }

    &.modal-version {
        .MuiPaper-root.MuiPopover-paper {
            margin-top: 19px !important;
        }
    }
}

.list-popup-wrapper {
    column-gap: 4px;

    .tags {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        white-space: nowrap;
        vertical-align: middle;
        box-sizing: border-box;
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 500;
        margin-left: 1px;
        margin-right: 1px;
        max-width: 100px;
        padding: 4px 6px !important;
        text-align: center;
        background-color: #eff4ff;
        border: 1px solid #86aedd;
        border-radius: 2px !important;
        color: rgba(0, 0, 0, 0.85);
        height: 16px;

        span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 1.2;
            font-size: 11px;
            font-weight: 400;
        }
    }

    .show-more {
        &.show-more-revamp {
            white-space: nowrap;
            border-radius: 2px !important;
            line-height: 1.2 !important;
            font-size: 11px !important;
            font-weight: 400 !important;
            margin-left: 0 !important;
            padding: 2px 8px !important;
        }
    }
}

.lensVersion.list-popup-wrapper {
    .tags {
        padding: 2px 4px !important;
        border: none;
        border-radius: 4px !important;
        background: #E4EFFF;


        span {
            color: #060D1B;
            font-size: 10px !important;
            font-weight: 400 !important;
        }
    }

    .show-more {
        padding: 0px !important;
        background: none;
        color: #0A3CA2;
        font-weight: 400 !important;
        line-height: 14px !important;
    }
}