@import "../../../../styleguide/contant.scss";
@import "../../../../styleguide/mixin.scss";

.create-customer-wrapper {
  .configure-customer {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: -12px;
      width: 1px;
      height: 100%;
      background: #dddfe8;
    }
  }

  .create-customer-form {
    .MuiTypography-root {
      margin-top: 0 !important;
    }

    #columnSelector-parent .MuiSelect-select {
      // padding: 5px 13px !important;
    }

    .MuiFormControl-root .MuiOutlinedInput-input.MuiInputBase-input {
      margin-top: -2px !important;
    }

    .custom-margin {
      &:last-child {
        .MuiPaper-root {
          margin-bottom: 0px !important;
          padding-bottom: 1px !important;
        }

        border-bottom: 1px solid #dedede !important;
        border-radius: 2px;
      }

      table {

        th,
        td {
          text-align: left !important;
        }

        td.text-center {
          svg {
            margin: 2px !important;
          }
        }

        .list-popup-wrapper {
          justify-content: flex-start !important;
        }
      }
    }

    .MuiAccordion-root {
      box-shadow: none;
      background: #ffffff 0% 0% no-repeat padding-box;
      border-top: 1px solid #dedede;
      border-right: 1px solid #dedede;
      border-radius: 2px;
      border-left: 1px solid #dedede;
      overflow: hidden !important;
      margin-bottom: 0 !important;

      &:hover {
        background-color: #f7faff;
        border-left: 1px solid #bfd3f2;

        .MuiAccordionSummary-root {
          &::before {
            border-left: 1px solid #bfd3f2 !important;
          }
        }
      }

      &.active {
        // border: 1px solid #0a3ca2;
      }

      .MuiCheckbox-root {
        padding: 0px 8px 0px 15px;
      }

      .MuiAccordionSummary-root {
        background: #ffffff 0% 0% no-repeat padding-box;
        height: 46px;

        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        background-color: transparent;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: middle;
        -moz-appearance: none;
        -webkit-appearance: none;
        -webkit-text-decoration: none;
        text-decoration: none;
        color: inherit;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        min-height: 46px;
        padding: 0px 16px;
        -webkit-transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-left: 1px solid white;
        }

        .MuiAccordionSummary-expandIconWrapper {
          color: #0a3ca2;

          svg {
            font-size: 1.8rem;
          }
        }
      }

      &.Mui-expanded {
        border: 1px solid #bfd3f2;
        border-radius: 0px;

        &:hover {
          background-color: #ffffff;
          border-left: 1px solid #bfd3f2;
        }

        .MuiAccordionSummary-root {
          background: #f7faff 0% 0% no-repeat padding-box;
          border-bottom: 1px solid #bfd3f2;

          p {
            color: #0a3ca2 !important;
          }
        }

        .MuiAccordionSummary-content {
          p {
            color: white;
          }
        }
      }

      .MuiAccordionSummary-content {
        align-items: center;

        p {
          font: bold 13px/21px Roboto;
          font-weight: 500;
          letter-spacing: 0px;
          color: $secondary-black !important;
        }

        .tag-wrapper {
          position: relative;
          display: flex;
          align-items: center;
          gap: 4px;
          margin-right: 8.5px;
          margin-left: auto;

          p {
            font-weight: 400;
            border-radius: 4px;
            font-size: 10px;
            line-height: 14px;
            padding: 5.5px 7px !important;

            &.active-tag {
              background: #E6ECF1;
              color: #23324E !important;
            }

            &.not_linked-tag {
              background: #FCF2E6;
              color: #C97A20 !important;
            }
          }
        }


      }

      .MuiAccordionDetails-root {
        padding: 0;
      }

      .add-user-wrapper {
        margin: 0;
        box-shadow: none;
        border: none;
      }
    }

    .associated-accounts-wrapper {
      .switch-icon {
        color: #c3d6ea;
        cursor: pointer;

        .css-julti5-MuiSwitch-root {
          top: 0;
        }

        .MuiSwitch-thumb {
          background: linear-gradient(158deg, #1c50bc 0%, #051e51 100%) !important;
          box-shadow: 0px 3px 6px #0000002c !important;
        }

        .MuiSwitch-track {
          background-color: #c3d6ea !important;
          opacity: 1;
        }

        .MuiButtonBase-root {
          display: -webkit-inline-box;
          display: -webkit-inline-flex;
          display: -ms-inline-flexbox;
          display: inline-flex;
          -webkit-align-items: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          position: relative;
          box-sizing: border-box;
          -webkit-tap-highlight-color: transparent;
          background-color: transparent;
          outline: 0;
          border: 0;
          margin: 0;
          border-radius: 0;
          padding: 0;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          vertical-align: middle;
          -moz-appearance: none;
          -webkit-appearance: none;
          -webkit-text-decoration: none;
          text-decoration: none;
          color: inherit;
          padding: 9px;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          color: #fff;
          -webkit-transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
      }

      .table-container {
        padding: 0;
        width: 100%;
        margin-top: 4px;

        .table {
          width: 100%;
          border: none;

          .table-body-row.disabled-row td {
            color: #8c92a2 !important;
            background-color: #fbfbfb;
          }

          .table-body-row.grey-out-row td {
            color: #8c92a2 !important;
            background-color: #fbfbfb;
          }

          .table-body-row.disabled-row-v2 td {
            color: #898a90 !important;
            background-color: #ececec;
          }

          .support-empty-checkbox {
            height: 18px;
            width: 26px;
            display: inline-block;
          }

          .MuiCheckbox-root {
            padding-left: 0;

            &:hover {
              background: none;
            }

            &.Mui-checked svg,
            &.MuiCheckbox-indeterminate svg {
              color: #0a3ca2;
            }

            svg {
              height: 18px !important;
              width: 18px;
              color: #898a90;
              margin: -2px !important;
            }

            &.Mui-disabled {
              svg {
                fill: #cbcbcb !important;
                background: #cbcbcb !important;
                border: 1px solid #898a90;
                border-radius: 2px;
                height: 13px !important;
                margin-left: 0 !important;
                width: 13px !important;
              }
            }
          }

          thead {
            tr th {
              border-bottom: 1px solid #e6e6e6;

              &.supportConfigAccount {
                text-transform: none;
              }

              &.z_max {
                z-index: 999 !important;
              }
            }
          }

          tbody {
            td {
              border: none;

              font: normal normal normal 13px/11px Roboto;
              font-weight: 500;
              text-align: left;

              input {
                padding: 4px 15px;
                // width: 205px;
              }

              .dropdown-wrapper {
                width: 205px;

                .header {
                  height: auto;
                  padding: 5px 15px;

                  &.filled {
                    span {
                      font-weight: bold;
                      color: black;
                    }
                  }

                  span {
                    font: normal normal normal 11px Roboto;
                    color: #898a90;
                  }
                }
              }

              .invoice-customer-wrapper {
                margin: 0;
                padding: 0 !important;
                width: 205px;

                .invoice-customer {
                  &>div {
                    height: auto;
                    padding: 7.5px 15px;
                    margin-top: 0;
                  }

                  .multi-select-wrapper {
                    width: 205px;
                    min-width: 205px;
                    padding: 10px 0 0 0;

                    .multi-select-dropdown {
                      flex-direction: column !important;
                      overflow: hidden;
                    }

                    .flex.justify-end {
                      padding: 10px;
                    }

                    .search-wrapper {
                      padding: 0 10px;

                      .search-bar {
                        width: 100%;
                        min-width: 100%;
                      }
                    }
                  }
                }
              }

              .calender-wrapper {
                .header {
                  height: auto;
                  padding: 5px 15px;
                  width: 205px;

                  &.filled {
                    span {
                      font-weight: bold;
                      color: black;
                    }
                  }

                  span {
                    font: normal normal normal 11px Roboto;
                    color: #898a90;
                  }
                }
              }
            }
          }
        }

        // .table-body-row:first-child,
        //  .table-body-row:nth-child(2)
        // {
        //   .specific-table-item-tooltip {
        //     .tooltip-box {
        //       top: 110%;
        //       bottom: unset;

        //       &::before {
        //         top: -5px;
        //         border-bottom: 0;
        //         border-right: 0;
        //         border-top: 1px solid #e6e6e6;
        //         border-left: 1px solid #e6e6e6;
        //         bottom: unset;
        //       }
        //     }
        //   }
        // }
      }

      .bottom {
        button[disabled] {
          background-color: #fff !important;
          color: #8c92a2 !important;
          border: 0.7px solid;
          border-color: #8c92a2;

          &:hover {
            box-shadow: none;
          }
        }

        button {
          &.primary {
            color: #0a3ca2;
            background-color: #fff;
            font-size: 11px !important;
            border-radius: 4px;
            font-weight: 500 !important;
            padding: 7px 10px;
          }
        }
      }

      .middle.table-scrollbar .table-container {
        max-height: 460px;
        overflow: auto;
        max-width: 100%;
        border-radius: 4px 4px 0px 0px !important;
        border-left: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;
        border-top: 1px solid #e6e6e6;
        border-bottom: 0px !important;

        table {
          thead {
            tr {
              th {
                position: sticky;
                top: 0;
                z-index: 9;
                text-align: left !important;
              }
            }
          }
        }
      }
    }

    p.breadcrumb-wrapper.mb-1:empty {
      display: none;
    }

    .VirtualReservations {
      &.supportConfigV2 {
        .associated-accounts-wrapper {
          .middle.table-scrollbar {
            .reset-icon {
              right: 7px;
              left: 182px !important;
            }
          }

          .auto-renew-tooltip-wrapper {
            .pop-up {
              margin-bottom: 1px;
            }

            .show-more {
              padding: 0 !important;
              margin-bottom: 0;
              background-color: transparent !important;
              width: 11px !important;
              height: 11px !important;
            }
          }
        }

        .addtional-content {
          .button.disabled {
            color: #cbcbcb !important;
            border-color: #898a90;
          }
        }

        .table-container {
          .table {
            tbody {
              tr {
                td:nth-child(2) {
                  padding-left: 11px !important;
                }
              }
            }
          }
        }
      }

      &.edp-discount-program {
        .associated-accounts-wrapper {
          .middle.table-scrollbar {
            .reset-icon {
              right: 148px;
              left: 182px;

              &::before {
                top: 5px;
              }
            }
          }

          padding-bottom: 5px;

          table {

            th,
            td {
              &:not(:first-child) {
                padding: 0 7px;
              }
            }
          }
        }

        .listing_component_download {
          left: calc(100% - 374px);
          margin-right: 0;
          margin-top: 1px;
        }

        #columnSelector-parent {
          width: 162px;
          left: 0;

          &>div {
            width: 162px;
          }

          .MuiSelect-select {
            &::after {
              margin-bottom: -1px;
            }
          }
        }

        .new_table_header {
          margin: 0;

          .tabV2_wrapper .tab-btn {
            width: 83px;
            display: flex;
            justify-content: center;
          }
        }

        .auto-renew-tooltip-wrapper {
          .show-more {
            padding: 2px 0 !important;
            width: 11px !important;
            height: 15px !important;
            margin-left: 5px !important;
          }
        }

        .table-container {
          .table {
            .table-header {
              th {
                padding: 0 7px;
              }
            }
          }
        }

        .button.primary.medium {
          &>img[alt="add icon"] {
            width: 17px;
          }
        }

        p.text-base.\!text-black.font-medium.current-date-text.flat_discount {
          padding-top: 4px;
        }
      }

      &.credits-listing {
        #columnSelector-parent {
          left: 43px;
        }

        .configure_discount_btn {
          min-width: 124px;
        }
      }

      &.AdditionalDiscount {
        .associated-accounts-wrapper {
          .middle.table-scrollbar {
            .reset-icon {
              left: 182px;
            }
          }
        }
      }

      .associated-accounts-wrapper {
        position: relative;

        .middle.table-scrollbar {
          .reset-icon {
            position: absolute;
            top: 22px;
            left: 224px;
            right: 175px;
            width: -moz-available;
            /* WebKit-based browsers will ignore this. */
            width: -webkit-fill-available;
            /* Mozilla-based browsers will ignore this. */
            width: fill-available;
            justify-content: space-between;
            align-items: center;

            .multipleSelectCheckmarks {
              width: 126px !important;
              height: 32px !important;

              .columnManager {
                display: flex;
                width: 126px !important;
                height: 32px !important;

                &:hover {
                  box-shadow: -2px 4px 8px rgba(10, 60, 162, 0.2431372549);
                }

                &::after {
                  width: 150px !important;
                  height: 32px !important;
                  font-size: 11px;
                  padding-left: 9px;
                  top: -4px;
                  right: 70px;
                }

                .MuiSelect-select {
                  padding: 0 !important;
                  width: 126px !important;
                  margin: 0 !important;
                }

                .MuiInputBase-root {
                  input {
                    width: 126px !important;
                  }
                }

                &::before {
                  left: -3px;
                  top: 5px !important;
                }
              }
            }

            &::before {
              content: "";
              position: absolute;
              left: -9px;
              width: 1px;
              height: 18px;
              background-color: #e6e6e6;
              top: 5px;
            }

            span {
              padding-bottom: 3px;
            }
          }

          .virtual_reservation_instance {
            margin-top: 0;
          }
        }

        .table-container {
          .table {
            tbody {
              td {
                border-bottom: 1px solid rgb(221 223 232);
                color: #000000;
                font-weight: 400;
                font-size: 12px;
                text-align: left !important;

                .text-\[\#525252\] {
                  color: #000000;
                }

                &.supportConfigV2 {
                  .list-popup-wrapper {
                    justify-content: unset !important;

                    .show-more {
                      border: 1px solid #0a3ca2;
                      color: #0a3ca2;
                      font-size: 10px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: normal;
                      letter-spacing: 0.5px;
                      border-radius: 4px;
                      background-color: #d8eaff;
                    }
                  }
                }
              }

              tr {
                td {
                  &:first-child {
                    .MuiCheckbox-root {
                      padding-right: 0;
                    }
                  }

                  &:nth-child(2) {
                    padding-left: 8px;
                    z-index: 4;
                  }
                }
              }
            }
          }
        }
      }

      .MuiTooltip-arrow {
        &::before {
          left: 0;
        }
      }

      .MuiAccordion-root {
        overflow: unset !important;
      }

      button {
        &.download-icon-btn {
          width: 57px;
          height: 28px;
          border: 0.7px solid #40852a !important;
          top: 0.5px;

          .xcelIcon {
            svg {
              width: 15px;
              height: 15px;
            }
          }
        }
      }

      &.credits-listing {
        .associated-accounts-wrapper {
          padding-bottom: 20px;

          .table-container .table tbody td {
            padding: 0 7px;

            span.show-more {
              border: 1px solid #0a3ca2;
              color: #0a3ca2;
              font-size: 10px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              letter-spacing: 0.5px;
              border-radius: 4px;
              background-color: #d8eaff;
              padding: 4px 10px;
            }

            .list-popup-wrapper {
              justify-content: flex-start !important;

              .pop-up {
                &:has(span.show-more) {
                  margin-left: -4px !important;
                  margin-bottom: 0 !important;
                }
              }
            }
          }

          .middle.table-scrollbar .reset-icon {
            right: 154px;
          }

          .listing_component_download {
            left: calc(100% - 338px);
          }
        }

        .new_table_header {
          .configure_discount_btn {
            width: auto;
            padding: 9px 15px;
          }
        }
      }

      &.customer_management_edit_wrapper {
        .columnManager {
          left: 0;
        }

        .listing_component_download {
          left: calc(100% - 374px);
        }
      }
    }

    .reservation_discount-wrapper {
      #columnSelector-parent {
        .MuiSelect-select {
          padding: 5px 13px 5px 10px !important;
        }
      }

      table {
        td {
          .list-popup-wrapper {
            &.auto-renew-tooltip-wrapper {
              justify-content: flex-start !important;
              padding: 0 4px;
            }
          }
        }
      }
    }

    .tabV2_wrapper .tab-btn {
      height: 32px;
    }
  }

  .flat_discount_accordian_wrapper {
    .associated-accounts-wrapper {
      .accounts_data_wrapper {
        margin-bottom: unset;

        .table-container {
          margin-top: 0;
        }
      }
    }

    &.supportConfigV2 {
      .associated-accounts-wrapper {
        .accounts_data_wrapper {
          .listing_component_download {
            left: unset;
            right: 184px;
            margin-right: 11px;
          }

          .search-bar {
            position: absolute;
            right: 220px;
            z-index: 2;
            min-height: 32px;
            width: 288px;
          }
        }
      }
    }

    .columnManager {
      left: -8px;
    }

    .listing_component_download {
      left: calc(100% - 382px);
    }

    &.v2 {
      .columnManager {
        left: -8px !important;
      }

      .listing_component_download {
        left: calc(100% - 382px) !important;
      }
    }
  }

  .reservation_discount-wrapper {
    .associated-accounts-wrapper {
      .accounts_data_wrapper {
        margin-bottom: unset;

        .table-container {
          margin-top: 0;
        }
      }
    }
  }

  .support-configuration-wrapper {
    .associated-accounts-wrapper {
      .accounts_data_wrapper {
        margin-bottom: unset;

        .table-container {
          margin-top: 0;
        }
      }
    }
  }

  .ppa-revamp-wrapper {
    .search-bar {
      padding: 8px;
    }

    .table-container {
      box-shadow: none;

      .table {
        tbody {
          tr:last-of-type {
            td {
              border-bottom: 0px !important;
            }
          }
        }
      }
    }
  }

  .multi-select-wrapper {
    .search-bar {
      input {
        font-size: 9.5px;
        margin-bottom: 0;
        border: none;
        width: calc(100% - 24px);
        text-overflow: ellipsis;

        &::placeholder {
          line-height: normal;
          font-size: 9.5px;
          color: #898a90;
        }
      }
    }
  }

  .hoverDiv {
    display: none;
  }

  .config-discount-img {
    width: 25px;
  }

  .listing_component_download,.download_Wrapper{
    button.download-icon-btn{
      &.disabled-button{
        border: 1px solid #898a90 !important;
        .xcelIcon{
          path:last-child{
            fill: #898a90;
          }      
        } 
        .xcelDownloadIc{
          &::before{
            border-left: 1px solid #ffffff;
          }
        }
      }
    }
  }
}

.data_not_found {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px 4px 4px 4px;
  box-shadow: 0 7px 7px 0 rgb(0 0 0 / 6%);
  padding: 50px;
  position: relative;
  text-align: center;
  margin-top: 10px;
}

.support-dialog .MuiPaper-root.MuiDialog-paper {
  width: 388px;
  min-width: 388px;
}

.virtual_dialog {
  &.reservation_dialog {
    .MuiDialogContent-root.MuiDialogContent-dividers {
      .read-only-view {
        &+div {
          margin-bottom: 8px;
        }
      }
    }
  }

  .dialog-header {
    margin-top: 16px;

    // margin-bottom: 5px;
    &:has(.end-reservation-header) {
      margin-top: 8px;
    }
  }

  .MuiDialogContent-root.MuiDialogContent-dividers {
    border-top: none;
    border-bottom: none !important;
  }

  .support-body {
    .form-footer {
      padding: 15px !important;
      margin-top: 5px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      .items-center {
        .button.disabled {
          background-color: #898a90;
          color: #fff;
          border: 1px solid #898a90;

          &:hover {
            box-shadow: none;
          }
        }

        .outline-primary-nohover {
          &:hover {
            box-shadow: -7px 6px 9px rgba(10, 60, 162, 0.1490196078);
          }
        }
      }
    }

    .add-user-wrapper.widget-basic .MuiDialogContent-root.MuiDialogContent-dividers {
      border-top: none;
    }
  }

  .radio-button-group {
    margin-bottom: 25px;
  }

  form div div {
    margin-top: 0 !important;

    .read-only-item {
      .items-center {
        p {
          font-size: 9px !important;
        }
      }
    }
  }

  .read-only-item {
    padding: 6px 10px;

    &>p {
      font-weight: 500;
    }

    .items-center {
      p {
        font-size: 10px !important;
      }
    }
  }

  .expiring-in-days {
    p {
      display: inline-block;
    }

    span {
      display: inline-block;
      font-size: 10px;
      background-color: #e3f3ff;
      padding: 2px 4px 1px;
      line-height: 14px;
      margin-left: 5px;
    }
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded p:not(.ri_tenure) {
    margin-top: 0px;
  }

  .VRManageRenewalRadio .option-wrapper .option label {
    padding: 12px 40px !important;
  }

  label.MuiFormControlLabel-root span.MuiRadio-root.MuiRadio-colorPrimary.Mui-checked {
    color: #0a3ca2;
  }

  .MuiTypography-root {
    font-weight: 400;
    font-size: 14px;
  }

  .MuiRadio-root.Mui-checked+.MuiTypography-root {
    font-weight: 500 !important;
    color: #0a3ca2 !important;
  }

  // .support-header p{
  //   margin-bottom: 5px;
  // }
  .calender p,
  .current-date-label {
    font-size: 10px !important;
  }

  .current-date-text {
    font-size: 14px !important;
    color: #898a90 !important;

    &.flat_discount {
      color: $secondary-black !important;
    }
  }

  .calender {
    .header {
      max-height: 28px;
      padding: 21px 11px !important;
      max-width: 100% !important;
      font-size: 12px !important;
      box-shadow: none !important;
    }
  }

  .text-area-wrapper {
    label {
      p {
        font-size: 11px !important;
        color: $secondary-black !important;

        span {
          font-size: 10px !important;
          color: #898a90 !important;
        }
      }
    }

    .text-area.resize-none {
      max-height: 86px;
    }

    .counter span {
      font-weight: 500;
      font-size: 9px;
    }
  }

  .pb-25 {
    padding-bottom: 18px !important;
  }

  .end-reservation-buttons {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    button {
      font-weight: 500 !important;
      padding: 7px 12px;
    }

    .cancel {
      color: #0a3ca2;
      border-color: #0a3ca2;

      &:hover {
        box-shadow: -7px 6px 9px rgba(10, 60, 162, 0.1490196078);
      }
    }
  }

  .acknowledge-consent {
    .MuiTypography-root {
      font-size: 12px !important;
      font-weight: 400 !important;
    }

    .MuiSvgIcon-root {
      width: 15px!important;
      height: 15px!important;
    }
  }

  .end-private-pricing-buttons {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    button {
      font-weight: 500 !important;
      padding: 7px 15px;
      border-radius: 4px;
    }

    .cancel {
      color: #0a3ca2;
      border-color: #0a3ca2;

      &:hover {
        box-shadow: -7px 6px 9px rgba(10, 60, 162, 0.1490196078);
      }
    }
  }

  .list-popup-wrapper {
    font-weight: 500;
    font-size: 12px;

    span.show-more {
      border: 1px solid #0a3ca2;
      color: #0a3ca2;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.1;
      display: inline-block;
      letter-spacing: 0.5px;
      border-radius: 4px;
      background-color: #d8eaff;
      padding: 4px 10px;
      height: 20px;
      min-width: 32px;
      margin-left: 6px;
    }
  }

  .MuiDialog-container .MuiPaper-root {
    border-radius: 6px;
  }

  .read-only-view {
    border-bottom: 0.8px solid #e0f1ff;

    .list-popup-wrapper .pop-up {
      margin-top: -2px;
    }

    .read-only-item {
      padding: 10px;

      .items-center p {
        margin-bottom: 4px;
      }
    }
  }

  .text-area-wrapper {
    .counter {
      margin-top: 2px !important;
    }
  }
}

.vri_datepicker {
  &.flat_discount {
    .calender-wrapper {
      margin: 0 !important;

      .calender {
        // max-width: 150px;
        margin-left: 4px;
      }
    }
  }

  .calender-wrapper {
    margin: 0 !important;

    .calender {
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

.legal-entity-creation-form {
  &.creation-form {
    .dialog-header {
      padding-top: 12px !important;
      padding-bottom: 12px !important;
    }

    .createForm {
      max-height: calc(100vh - 200px);
      overflow: auto;
    }

    .form-footer {
      margin-top: 4px;
      border-radius: 0 0 4px 4px;
    }
  }

  .MuiPaper-root.MuiDialog-paper {
    width: 500px;
    min-width: 500px;
    top: 79px;
    margin-top: 0;

    .support-body {
      .dashboard_wrapper {
        p.font-semibold {
          padding-top: 12px;
          display: none;
        }

        .text-area-wrapper {
          .error_message {
            margin-top: -4px !important;
          }
        }
      }
    }

    // .error_message {
    //   margin-top: -5px !important;
    // }

    .country-list {
      .dropdown {
        z-index: 9999;
      }
    }

    .search-bar {
      input {
        border: none;
        margin-bottom: 0px;
      }
    }
  }
}

.create-customer-table {
  .table-container {
    padding: 0;
    border: 0;

    &.table-scrollbar {
      max-height: 205px;
    }
  }
}

.create-customer-checkbox {
  .MuiTypography-root.MuiFormControlLabel-label {
    font: normal normal normal 12px/22px Roboto;
    font-weight: 500;
    letter-spacing: 0.13px;
    color: #0a3ca2;
  }
}

.cbu-creation-form {
  .MuiPaper-root.MuiDialog-paper {
    width: 420px;
    min-width: 420px;
    top: 70px;
  }

  &.creation-form {
    .dialog-header {
      padding-top: 12px !important;
      padding-bottom: 12px !important;
    }
  }
}

.creation-form.support-dialog {
  .country-list {
    .dropdown {
      .body {
        .option-wrapper {
          .option {
            padding-bottom: 0;
            padding-top: 0;
          }
        }
      }
    }
  }
}

.dialog-component-wrapper {
  .dialog-header {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .support-body {
    .add-user-wrapper.widget-basic {
      border: none;
      box-shadow: none;
      margin: 0;

      form {
        div {
          div {
            margin-top: 5px;

            p.Label {
              margin-top: 15px;
            }
          }
        }
      }

      .dropdown-wrapper,
      .calender-wrapper {
        .text-field.small {
          font: normal normal normal 11px Roboto;
          color: $primary-grey-dark;
          letter-spacing: 0px;
        }

        .dropdown,
        .calender {
          .header {
            font: normal normal normal 14px Roboto;
          }

          .header.filled {
            font-weight: 500;
          }

          .body {
            margin-top: 0;

            .option-wrapper {
              margin: 0;
              max-height: 205px;
              overflow-y: auto;
            }

            .option {
              font: normal normal normal 14px Roboto;
              font-weight: 500;
              margin-bottom: 0;
              margin-top: 0;
            }
          }

          .error_message {
            bottom: -20px;
          }
        }
      }
    }

    .form-footer {
      padding: 4px 15px 9px 15px;

      button {
        min-width: 67px;
        text-align: center;
        justify-content: center;
      }
    }
  }
}

.createInvoice.add-user-wrapper {
  .text-area-wrapper {
    textarea {
      margin-top: 0;

      &[name="summary"] {
        min-height: 110px;
      }
    }

    &.invoicesummary {
      margin-bottom: 55px;
    }
  }

  .input-field-container {
    p {
      &.read-only {
        color: #000;
        font-weight: 500;
      }
    }
  }
}

.addUserLeftBtns {
  button.button {
    width: 100px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    transition: all 0.2s ease;
    padding: 0;

    svg {
      height: 15px;
      width: 17px;
    }
  }
}

.cloudfrontpricing-body-tables {
  display: flex;
}

.page_container {
  .input_box {
    background-color: none !important;
  }

  .input_box:focus::placeholder {
    color: transparent;
  }

  .input_label:focus::placeholder {
    color: transparent;
  }

  .table_header {
    color: $secondary-black;
  }

  .yearly-commitment-container {
    background: #f9f9f9;
    border: 0.7px solid #dddfe8;
    // margin: 100px;
    padding: 20px 20px;
    margin: 20px 20px 0 20px;
    min-height: 340px;
    border-radius: 4px;

    .commitment-header {
      padding-bottom: 20px;
      color: $secondary-black;
    }

    #yearyly_wise_contianer {
      border: none;
      padding-top: 0 !important;
      white-space: nowrap;

      table {
        background-color: #f8f8f8;

        thead {
          tr {
            font-size: 16px !important;
            // font-weight: normal  !important;
            font-weight: 100;

            th {
              padding: 10px 10px;
              background-color: none !important;
              font-weight: 400 !important;
              font-size: 11px;
              line-height: 13px;
              color: $primary-grey-dark;

              &:first-child {
                text-align: left;
                padding-left: 0 !important;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              // min-width: 200px;
              text-align: center;
              background-color: #f8f8f8;
              font-weight: 700;
              font-size: 14px !important;
              color: $secondary-black;
              max-height: 35px;

              .break_space {
                white-space: break-spaces;
              }

              div {
                .errorClass {
                  height: 8px;
                }

                &:has(.errorClass) {
                  margin-top: 8px;
                }
              }

              &:first-child {
                text-align: left;
                position: relative;
                min-width: 100px;
              }

              input {
                padding: 7px;
                text-align: center;
                margin: auto;
                font-weight: 700;
                font-size: 12px;
              }

              .dropdown-wrapper {
                margin: auto;
                position: relative;

                .dropdown {
                  min-width: 100px !important;
                  height: 33px;
                  margin: auto;
                  font-weight: 100;
                  z-index: unset;
                  background-color: #fff;

                  .header {
                    min-width: 100px !important;
                    height: 33px;
                    margin-top: 0;
                    font-weight: 400;
                    font-size: 12px;
                  }

                  .option-wrapper {
                    padding: unset;
                  }

                  .option {
                    font-size: 12px;
                    font-weight: 400;
                    text-align: left;
                    padding: 5px 5px;
                  }
                }

                &::before {
                  content: "";
                  border-left: 1px solid #e6e6e6;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  right: -1px;
                }
              }

              &:last-child {
                div {
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
  }
}

.add_icon {
  svg {
    &:hover {
      path {
        fill: #0a3ca2;
        stroke: #ffffff;
      }
    }

    path {
      stroke: #0a3ca2;
      fill: #ffffff;
      cursor: pointer;
    }
  }
}

.add_icon.grey_icon {
  &:hover {
    path {
      stroke: #ffffff;
      fill: #555b6c;
    }
  }

  path {
    stroke: #ffffff;
    fill: #555b6c;
    pointer-events: none;
  }
}

.cloudfrontpricing-wrapper {
  line-height: 1;
  padding: 20px;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e8e6e6;
  box-shadow: 0 5 17 0 #000000;
  margin: 20px 20px 0 20px;
  background-color: #ffffff;

  input:focus::placeholder {
    color: transparent;
  }

  textarea:focus::placeholder {
    color: transparent;
  }

  input:focus::placeholder {
    color: transparent;
  }

  textarea:focus::placeholder {
    color: transparent;
  }

  .btn_group_container {
    padding: 15px 20px;

    p {
      font-size: 12px;
      font-weight: 500;
    }
  }

  .cloudfrontpricing_name,
  .cloudfrontpricing_description {
    font-size: 0.9rem !important;
    color: $secondary-black !important;
    font-weight: 500 !important;

    &::placeholder {
      font-size: 0.8rem !important;
    }
  }

  .text-area-wrapper {
    .error_message {
      margin-top: -24px !important;
    }
  }

  .counter {
    span {
      font-size: 10px !important;
      line-height: 14px;
      border-radius: 4px;
      padding: 4px 8px;
      font-weight: 400;
    }
  }

  .cloudfrontpricing_description.text-area-wrapper {
    margin-bottom: 20px;

    .text-area.filled {
      font-weight: 400 !important;
      color: $secondary-black !important;
    }
  }
}

.cloudfrontpricing-body-tables {
  display: flex;
  gap: 10px;
}

.cloudfrontpricing-body-left {
  width: 40%;

  .multipleSelectCheckmarks {
    .mandatory {
      padding: 6px !important;
    }
  }
}

.cloudfrontpricing-body-right {
  width: 60%;
}

.yearly-commitment-container {
  margin: 15px 20px;
}

.cppinfoTooltip {
  position: relative;
  display: inline-block;
  left: 5px;
  color: #0a3ca2;

  &:hover {
    .hoverDiv {
      display: block !important;
    }
  }

  svg {
    width: 12px;
    height: 12px;
  }

  .cloudfrontpricing-body-left,
  .cloudfrontpricing-body-right {
    width: 50%;
  }

  .yearly-commitment-container {
    margin: 15px 20px;
  }

  .cloudfrontpricing-body-left,
  .cloudfrontpricing-body-right {
    width: 50%;
  }

  &:hover {
    .icon-container {
      svg {
        #userCircle {
          fill: #0a3ca2;
          stroke: #0a3ca2;

          g {
            fill: #0a3ca2;
          }
        }

        g {
          path {
            stroke: #fff;
          }
        }
      }
    }

    .hoverDiv {
      flex-direction: column;
      opacity: 1;
      word-break: break-all;
      max-width: 400px;
      visibility: visible;
      transition: all 0.3s ease;
      top: calc(100% + 10px) !important;
      right: unset !important;
      left: -38px !important;
      font-size: 13px !important;
      min-width: 270px !important;
      line-height: 15px;
      border-radius: 12px !important;
    }

    .hoverDiv:before {
      right: unset !important;
      left: 40px !important;
    }
  }
}

.Cpp_footer {
  line-height: 1;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  margin: 0 20px 20px;
  border: 1px solid #e8e6e6;
  border-top: none;
  background-color: #f0f6ff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  .disabled {
    background-color: #555b6c !important;
    color: #9aa6b6 !important;
    border: 1px solid #626262 !important;
  }
}

.shortfallCharge {
  padding: 6px 20px !important;
}

// Modal styling

.cloudfront-dialog .MuiPaper-root.MuiDialog-paper {
  width: 100% !important;
  height: 100% !important;
  min-width: 80vw !important;
  min-height: 80vh !important;

  .Cpp_footer {
    display: none;
  }

  .cloudfrontpricing-wrapper {
    margin: 0;
  }
}

.cloudfront-dialog .MuiPaper-root .MuiDialogContent-root {
  overflow-y: auto !important;
}

.cloudfront-dialog .MuiPaper-root.MuiDialog-paper {
  width: 475px !important;
  height: 389px !important;
  // top: calc(50% - 50vh);
  box-shadow: -9px 10px 37px rgba(0, 0, 0, 0.07);

  #ic-cancel {
    border-radius: 6px;
    top: -13px;
    right: -13px;
  }

  h6 {
    font-size: 24px;
  }
}

.cloudfrontpricing_non_editable {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  .label_input_container {
    .left_container {
      .heading {
        color: #000000 !important;
      }
    }
  }

  .radio-button-group {
    p {
      color: #000000 !important;
    }
  }

  // background-color: blue;
  .btn_group_container {
    .option-wrapper {
      .buttonStyle {
        background-color: #f9f9f9 !important;
        border: 1px solid #dddfe8 !important;
        color: #525252 !important;
      }

      .buttonStyle.selected {
        background-color: #747474 !important;
        color: #cbcbcb !important;
        box-shadow: unset !important;
      }
    }
  }
}

.td_with_circle {
  // position: relative;

  &::after {
    content: "";
    width: 8px;
    height: 8px;
    // background-color: #ffffff;
    border: 2px solid #dedede;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -11px;
    border-radius: 50%;
  }
}

.cloudfront_private_accordian {
  .table-container {
    min-height: 200px !important;
  }

  .table:has(.no_data_found) {
    min-height: 200px !important;
  }

  .show-more {
    border-radius: 15px !important;
  }
}

.read_only_view_tags {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 20px;

  .date_wrapper {
    display: flex;
    gap: 10px;

    .commitmentStartDate {
      font-weight: 500;
      font-size: 14px;
      color: #000000 !important;
    }
  }

  .text_small {
    font-weight: 400;
    font-size: 12px;
    color: $primary-grey-dark !important;
  }
}

.tags_list {
  .list-popup-wrapper {
    display: flex;
    gap: 5px;

    .tags {
      background-color: #7c7c7c;
      color: #ffffff !important;
      border-radius: 15px;
      margin: 0 !important;
      padding: 3px 10px;
    }

    .show-more {
      margin-left: 0 !important;
      background-color: #7c7c7c;
    }
  }

  margin: 0 20px;
}

.icon_btn_revert {
  &:disabled {
    color: #a9acb5 !important;
    background-color: #ffffff;
  }

  padding: 8px;
}

.cppTextField {
  .disabledCheckBox {
    position: relative;

    &:after {
      position: absolute;
      content: "";
      // background: #CBCBCB;
      height: 10px;
      width: 10px;
      left: 4px;
      top: 4px;
    }
  }

  &.shopping-cart .MuiCheckbox-indeterminate {
    svg {
      color: #0a3ca2;
    }
  }
}

.cpp_assosciated {
  #cppDisableEndDiscountButton {
    background-color: #fff !important;
    color: #898a90 !important;
  }

  #cppEndDiscountButton {
    background-color: #fff !important;
    color: #0a3ca2 !important;
  }

  .text-center {
    .list-popup-wrapper {
      justify-content: left;
    }
  }

  .tab-btn {
    width: 83px;
  }
}

.listing-loader {
  min-height: 150px;
  position: relative;
}

.configureDiscounts-dialog .MuiPaper-root.MuiDialog-paper {
  height: max-content !important;
  width: 90% !important;
  min-width: 80vw !important;
  max-height: 80vh !important;
  padding-bottom: 20px !important;

  .MuiDialogContent-root {
    overflow: auto !important;
  }

  .configureDiscount-DialogBody {
    // height: 100%;
    // padding: 0 20px;
    // min-height: 80vh !important;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .heading {
        font-size: 20px;
        margin-top: 20px;
        font-weight: 500;
        color: $secondary-black;
        border: 0 !important;
        padding-left: 0 !important;
        margin-top: 0 !important;
        padding-bottom: 5px;
      }

      .content {
        margin-bottom: 10px;
        min-height: 12px;
      }
    }

    >.header {
      position: sticky;
      left: 0;
      top: 0;
      background-color: #fff;
      z-index: 999;
      padding: 0 20px;
    }

    .drag-container {
      box-shadow: -7px 14px 38px rgb(52 58 64 / 8%);
      border-radius: 4px;
      border: 1px solid #e6e6e6;
      color: $secondary-black;
      margin: 0 20px;

      .info {
        background: #f8f8f8 0% 0% no-repeat padding-box;
        border-bottom: 1px solid #e6e6e6;
        border-radius: 4px 4px 0px 0px;
        padding: 10px;
        display: flex;
        justify-content: end;
        align-items: center;
        font-size: 11px;
        font-weight: normal;
        color: #525252;

        .dragIcon {
          cursor: move;
          border: 1px solid grey;
          width: 15px;
          height: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          margin: 0 5px;

          svg {
            height: 7px;

            path {
              fill: grey;
            }
          }
        }
      }
    }

    .save {
      float: right;
      color: #fff;
      margin: 20px 0;
    }
  }
}

.configureDiscountTable {
  background-color: rgb(255 255 255);
  width: 95%;
  margin: 5% auto;

  #multpledrag_container {
    .dragCardHTML {
      p {
        &:first-child {
          text-align: center !important;
        }
      }
    }
  }

  .header {
    p {
      text-transform: capitalize;

      &:first-child {
        text-align: left;
      }
    }
  }

  .drag_drop_container {
    border-radius: 4px;
    margin-bottom: 20px;

    .dragCardHTML {
      width: 100%;
      display: flex;
      align-items: center;

      .card {
        gap: 10px !important;
      }

      .newDragCard {
        position: relative;

        &::before {
          content: "NEW";
          color: #fff;
          border-radius: 4px 1px 4px 1px;
          position: absolute;
          background-color: #0a3ca2;
          font-size: 8px;
          padding: 3px 6px;
          top: 0;
          left: 0;
        }

        .card {
          align-items: center;
        }
      }

      .dragCard {
        padding: 0.5rem 1rem;
        width: 95%;
        border: 1px solid #e6e6e6;
        border-radius: 4px;

        p {
          text-transform: capitalize;

          &:first-child {
            text-align: left !important;
          }
        }
      }

      .dragIcon {
        cursor: move;
        border: 1px solid grey;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        svg {
          path {
            fill: grey;
          }
        }

        &:hover {
          background-color: #0a3ca2;

          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }
}

.configDiscount_Container {
  .table-container {
    overflow: unset !important;

    tr {

      // th,
      td {
        // width: 14% !important;
        padding: 5px 8px !important;
        line-height: 15px !important;
      }
    }
  }

  table {
    .relative.h-48.w-20 {
      height: 40px;
    }

    tbody {
      tr.table-body-row {
        td.text-center {
          &:last-child {
            .specific-table-item-tooltip {
              .tooltip-box {
                left: unset;
                right: -10px;

                &::before {
                  left: unset;
                  right: 13px;
                }
              }
            }
          }
        }
      }
    }

    td.text-center {
      .list-popup-wrapper {
        .tags {
          color: $secondary-black !important;
        }
      }

      font-weight: 400 !important;
    }

    .table-header {
      th:last-child {
        .open_filters {
          right: 70px !important;
        }
      }
    }
  }

  .blue-info_icon {
    svg {
      color: #0a3ca2;
      height: 11px;
      width: 11px;
    }

    .tooltip-box {
      left: -20px !important;
      bottom: 24px !important;
    }
  }
}

.specific-table-item-tooltip {
  >div {
    position: relative;
    display: inline-block;
    padding: 5px;

    &:hover .tooltip-box {
      display: block;
    }

    svg {
      display: inline-block;
    }

    .tooltip-box {
      background-color: #fff;
      border-radius: 4px;
      position: absolute;
      bottom: 22px;
      padding: 15px;
      z-index: 999;
      box-shadow: -8px 12px 16px rgba(0, 0, 0, 0.15);
      border: 1px solid #dbdbdb;
      left: -22px;
      display: none;
      min-width: 200px;

      &::before {
        background: #fff;
        border: 1px solid #e6e6e6;
        border-top: 0;
        border-left: 0;
        content: "";
        height: 10px;
        left: 25px;
        position: absolute;
        bottom: -5px;
        transform: rotate(45deg);
        width: 10px;
      }

      table {
        tr {
          border-bottom: 1px dashed #dbdbdb;

          td:first-of-type {
            min-width: unset !important;
          }

          &:last-child {
            border-bottom: unset;
          }

          th {
            padding: 10px 4px;
            border: none;
            background-color: #ffffff !important;
            position: unset !important;
          }

          td {
            padding: 8px 4px !important;
            text-align: center;

            &:first-child {
              text-align: left;
            }
          }
        }
      }

      .tooltip-heading {
        font-weight: 500;
        font-size: 14px !important;
        text-align: left;
        color: $secondary-black !important;
        margin-bottom: 10px;
      }

      .tooltip-content {
        width: 240px;
        line-height: 14px;
        text-align: left;
        font-weight: 400;
      }

      .tooltip-heading {
        font-weight: 500;
        font-size: 14px !important;
        text-align: left;
        color: $secondary-black !important;
        margin-bottom: 10px;
      }

      .tooltip-content {
        width: 240px;
        line-height: 14px;
        text-align: left;
        font-weight: 400;
      }
    }
  }
}

.VRManageRenewalRadio {
  .option-wrapper {
    .selected {
      border: 1px solid $primary-blue-dark !important;
      background: #f3f8ff !important;
      box-shadow: 0px 9px 11px 0px rgba(10, 60, 162, 0.12);
      color: $primary-blue-dark !important;
    }

    .option {
      label {
        gap: 10px;
        border: 1px solid #dddfe8;
        background: #fff;
        border-radius: 4px;
        padding: 8px 25px;
        color: #8c92a2;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.vr-tabs {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;

  .tab_button {
    border: 1px solid #dddfe8;
    color: #000000;
    padding: 8px 25px;
    background: transparent;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 400;

    &:hover {
      color: #0a3ca2;
      background: #f7faff;
    }
  }

  .tab_button.selected {
    border: 1.2px solid #0a3ca2;
    color: #0a3ca2;
    background: #f7faff;
    pointer-events: none;
    font-weight: 700;
  }
}

#customerVRI {
  .VRItabs {
    padding: unset !important;
    margin: 20px !important;
    margin-bottom: 0 !important;
    border-bottom: 0;

    .vr-tabs {
      .tab_button {
        font-size: 12px;
        border-radius: 4px 4px 0 0 !important;
        color: #495057;
        border: 1px solid #e6e6e6;
        border-bottom: 0;
        background-color: #fff;
        padding: 10px 32px !important;
      }

      .selected {
        box-shadow: 0px -1px 7px 0px rgba(0, 0, 0, 0.09);
        border: 1px solid #e6e6e6;
        border-bottom: 0;
        background-color: #fff;
        color: $secondary-black;
        font-weight: 700;
        position: relative;

        &::before {
          width: 100%;
          height: 2px;
          background-color: #ffffff;
          content: "";
          position: absolute;
          left: 0;
          z-index: 9;
          bottom: -1px;
        }
      }
    }
  }

  .associated-accounts-wrapper {
    border: 1px solid #e6e6e6 !important;
    background-color: #fff !important;
    margin: 0 20px;

    .table-container {
      border: unset !important;
      box-shadow: unset !important;
      padding: 0 !important;
    }
  }
}

.virtual_reservation_instance {
  table {

    th,
    td {
      &:first-child {
        width: 30px;
        min-width: 30px !important;
        padding-right: 0 !important;
      }
    }
  }

  &.vri-no-data {
    table thead tr .left-\[30px\] {
      left: 15px;
    }
  }
}

.VRItabs {
  .multiSelectDownloadSheet {
    .download_Wrapper {
      button.MuiButtonBase-root {
        &:hover {
          box-shadow: -4px 8px 9px 0px #64ac5640 !important;
        }
      }

      .options_list {
        max-height: 254px;

        span {
          padding: 6px 0 !important;

          &.MuiFormControlLabel-label {
            font-size: 12px;
            color: #000000;
          }
        }

        p {
          margin-top: 0 !important;
          padding: 0 18px !important;
        }

        .w-100 {
          font-size: 12px !important;
          font-weight: 500 !important;
        }
      }
    }
  }
}

.MuiTooltip-popper .MuiTooltip-tooltip:has(> .auto-renewal-tooltip) {
  background: #525252 !important;
  margin-top: 8px !important;
  padding: 5px 7.5px !important;
  max-width: 233px;
  line-height: 13px !important;
  font-size: 10px !important;
  font-weight: 500 !important;
}

.MuiTooltip-popper .MuiTooltip-tooltip:has(> .end-reservation-tooltip) {
  background: #525252 !important;
  margin-top: 8px !important;
  padding: 5px 7.5px !important;
  max-width: 225px;
  line-height: 11px !important;
  font-size: 10px !important;
  font-weight: 500 !important;
}

.EDP-modal-wrapper {
  .MuiDialog-container .MuiDialog-paper {
    &>h2 {
      display: none;
    }
  }

  .styleSelectedConfig {
    .selectedConfigValues {
      overflow: initial !important;
      padding: 8px !important;
      max-height: none !important;

      .individual_config {
        &:first-child {
          margin-top: 0 !important;
        }
      }
    }
  }

  .button.medium {
    padding: 7px 18px;
  }
}

.edpDiscountsModalWrapper {
  .MuiDialog-container .MuiDialog-paper {
    &>h2 {
      display: none;
    }
  }

  .button.medium {
    padding: 7px 18px;
  }
}

.modalWrapperFlatDiscount_dialog {
  .MuiDialog-container {
    .MuiDialog-paper {
      min-width: min-content;
      border-radius: 6px;
    }
  }

  .dialog-header {
    min-height: unset;
    height: unset;
    padding: unset;
  }

  .modalWrapperFlatDiscount {
    padding: 20.5px;

    .templateSub,
    .styleDescription {
      font-size: 12px;
      font-weight: 400;
      line-height: 14.06px;
      font-style: normal;
      color: #495057;
      // color: red;
    }

    .templateValue,
    .descriptionValue {
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 17.4px;
      color: $secondary-black !important;
      margin-top: 8px !important;
    }

    .templateInfo {
      display: flex;
      align-items: flex-start;
      // justify-content: center;

      .styleTemplateInfo {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-right: 87px;
      }
    }

    .configFlatDiscountDescription {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-top: 19px;
    }

    .styleSelectedConfig {
      .selectedConfigHeader {
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17.4px;
        color: $secondary-black;
        margin-bottom: 4.5px;
      }

      .selectedConfigValues {
        margin-bottom: 8px;
        padding: 0 8px 8px 8px;
        min-width: 1084px;
        border-radius: 4px;
        border: 0.7px solid #dddfe8;
        overflow: auto;
        max-height: 200px;

        .individual_config {
          background-color: #f1f2f6;
          border-radius: 4px;
          border: 0.7px solid #dddfe8;
          margin-top: 8px;

          .config_header {
            h4 {
              font-weight: 500;
              font-size: 12px;
              line-height: 14.06px;
              color: $primary-grey-dark;

              .rounded-div {
                p {
                  font-family: Roboto;
                  font-size: 9px;
                  font-weight: 600;
                  line-height: 11px;
                  letter-spacing: 0.02em;
                  text-align: left;
                  color: #337ab7;
                  margin: 0;
                  padding: 2px 2px 3px 4px;
                }
              }
            }
          }

          .dimension-box {
            gap: 6px;
            max-height: 200px;
            overflow-y: auto;

            .config_wrapper {
              margin: 0;
              border-radius: 3px;
              border: 1px solid #dddfe8;
              padding: 7px 4px 7px 6px;
              background-color: #ffffff;

              p,
              span {
                margin-top: 0;
                font-size: 10px;
                font-weight: 400;
                line-height: 11.72px;
                color: $primary-grey-dark;
              }
            }

            .more-button {
              margin: 0 !important;
              width: unset;
              height: unset;
              padding: 6.8px 4.3px;
              cursor: pointer;
              display: flex;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;

              p,
              span {
                margin: 0 2px;
                color: #0a3ca2;
                font-weight: 700;
                font-size: 10px;
                line-height: 11.72px;

                svg {
                  margin-left: 6px;
                  width: 8px;
                  height: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.ConfigureFlatDiscount {
  line-height: 1;

  .list-wrapper {
    height: auto;

    .createInvoice {
      .module_dropdown {
        width: 30% !important;
        padding-right: 0 !important;
        margin-right: 20px;

        &.select_template.v2 {
          .search-bar {
            padding: 9px 15px;
            font-size: 12px;
            font-weight: 400;
            margin: unset !important;
            border: none;
            border-bottom: 1px solid #dddfe8;
            border-radius: unset;

            input {
              border: none;
              margin: unset;
            }
          }
        }

        &.dropdown-wrapper {
          width: 380px;

          .body {
            &.invisible {
              display: none;
            }

            &.visible {
              display: block;
            }
          }
        }

        p {
          margin-top: 0;
          font-size: 11px;
          line-height: 14px;
        }

        .invoice-customer {
          .chip {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 7px 6px;
            height: 22px;
            font-size: 10px;
            border: 1px solid #dddfe8;
            border-radius: 3px;
            margin-right: 6px;
            color: $primary-grey-dark;
            max-width: 120px;

            .chip-text {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .chip-icon {
              display: inline-block;
              margin-left: 6px;
              padding: 3px;

              svg {
                height: 8px;
                width: 8px;

                path {
                  fill: $primary-grey-dark;
                }
              }
            }

            &:hover {
              border: 1px solid $primary-blue-dark;

              .chip-icon {
                background-color: $primary-blue-dark;
                border-radius: 50%;

                svg {
                  path {
                    fill: $global-white;
                  }
                }
              }
            }
          }

          .chip-counter {
            border: 1px solid $primary-blue-dark;
            border-radius: 4px;
            padding: 4px 12px;
            height: 22px;
            font-size: 10px;
            font-weight: 700;
            color: $primary-blue-dark;
            background-color: #d8eaff;
            line-height: 11px;
            position: relative;

            .popupList {
              display: none;
              padding: 13px 15px;
              background-color: $global-white;
              border: 1px solid #dddfe8;
              position: absolute;
              top: 22px;
              left: 0;
              z-index: 10000;
              width: 187px;
              border-radius: 4px;
              max-height: 250px;
              overflow-y: auto;

              .popupListItem {
                font-size: 12px;
                font-weight: 400;
                color: $secondary-black;
                margin-bottom: 10px;

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }

            &:hover {
              .popupList {
                display: block;
              }
            }
          }

          .invoice-customer-footer {
            border-top: 1px solid #ebebeb;
          }
        }
      }

      .input-field-container {
        width: 14%;
        padding-right: 0 !important;
        margin-right: 20px;

        span {
          font-size: 11px !important;
        }
      }

      .calender-wrapper.singleDateModule {
        width: 19%;
        padding-right: 0 !important;
      }

      .search-wrapper {
        padding: 0;

        .search-bar {
          margin-top: 0;
          padding: 9px 15px;
          font-size: 12px;
          font-weight: 400;
          border-left: 0;
          border-right: 0;
          border-radius: 0;
        }
      }

      .multi-select-wrapper {
        width: 30%;
        min-width: 30%;

        button {
          font-size: 12px;
          font-weight: 500;
          padding: 7px 18px;
        }
      }

      .multi-select-dropdown {
        min-height: auto;
        max-height: inherit;
        flex-direction: column !important;

        .MuiFormControlLabel-label {
          color: $secondary-black;
        }
      }

      .form-footer {
        button {
          padding: 7px 18px;
        }
      }
    }

    &.widget-basic {
      margin-bottom: 20px;
    }
  }

  .invoice-customer-wrapper {
    .multi-select-wrapper {
      .multi-select-dropdown {
        flex-direction: column !important;

        .option {
          margin: 6px 0;
          padding-left: 14px;

          &>.MuiFormControlLabel-root {
            margin-left: 0;

            .MuiCheckbox-root {
              padding: 0 5px 0 0;
            }
          }

          &:not(:first-child) {
            margin-top: 0 !important;
          }
        }
      }
    }
  }
}

.listing_component_download {
  left: calc(100% - 374px);
  z-index: 9;

  button.download-icon-btn {
    height: 32px !important;
  }
}

.virtual_reservation_instance {
  table {
    thead {
      tr {
        th {
          text-transform: none;
        }
      }
    }
  }
}

.new_table_header,
.edp-discout-template {
  padding: 20px 0;

  .configure_discount_btn {
    position: absolute;
    right: 0;
    margin-right: 0;
    min-width: 160px;
    margin-right: 0;
    margin-bottom: 14px;
  }

  .tabV2_wrapper .tab-btn {
    border: 1px solid #d5ddec;
    padding: 9px 25px;
    color: #898a90;
    line-height: 1;
    border-right: none;
    border-left: none;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      border-left: 1px solid #d5ddec;
      // &.active {
      //   border: 1px solid #779FF0 !important;
      //   color: #0a3ca2;
      // }
    }

    &:last-child {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      border-right: 1px solid #d5ddec;

      // &.active {
      //   border: 1px solid #779FF0 !important;
      //   color: #0a3ca2;
      // }
    }
  }
}

.edp-discout-template {
  .configure_discount_btn {
    margin-right: 0;
  }
}

.custom-margin {
  margin-bottom: -0.5rem;
}

.LegalEntity-mandatory {
  .createForm {
    .input-field-container input {
      margin-bottom: 2px;
    }

    .error_message {
      margin-top: unset !important;
    }

    .text-area-wrapper {
      .error_message {
        margin-top: unset !important;
      }
    }

    .text-area {
      margin-bottom: 2px;
    }

    .dropdown {
      .Mui-error {
        bottom: unset !important;
      }
    }
  }
}

.wrapper-support-configuration {
  .widget-basic {
    margin: 0;
  }

  hr {
    border: none;
  }

  .support-header {
    text-align: left;

    p {
      justify-content: flex-start;
    }
  }

  .MuiPaper-root {
    min-width: 1104px;
  }

  .dialog-header {
    padding: 13px 20px;

    .support-header {
      p {
        padding-bottom: 0;
      }
    }
  }

  .createForm>div {
    margin: 15px 20px !important;

    .showSelectedIcon {
      font-weight: 700 !important;
    }
  }

  &.moveAccount {
    .MuiPaper-root {
      min-width: 420px;
      max-width: 420px;
    }

    .dropdown-wrapper.v2 {
      .dropdown.dropdownOpen {
        .body.visible {
          position: relative;
        }
      }
    }
  }

  .dialog-body {
    border-top: none;
  }

  .widget-basic {
    border: none;
  }
}

.supportType {
  width: 348px !important;
}

.wrapper-support-configuration {
  .createForm>div {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    //why this was added?
    margin: 15px 15px 15px 15px;

    .module_dropdown,
    .input-field-container {
      min-width: unset;
    }

    .input-field-container {
      width: 168px;
    }

    .module_dropdown.disabled .header.filled {
      background: #f1f2f6;
      color: $secondary-black;

      img {
        display: none !important;
      }
    }

    .input-field-container {
      span {
        font-size: 11px !important;
        color: $primary-grey-dark !important;
        margin-top: 1px !important;
      }
    }

    .supportConfig_comment,
    .singleDateModule {

      label,
      p {
        font-size: 11px !important;
        color: $primary-grey-dark !important;
        margin-top: 5px !important;

        &.error_message {
          color: red !important;
          font-size: 10px !important;
        }
      }
    }

    .multi-select-wrapper {
      width: 348px;

      .search-wrapper {
        padding: 0;

        .search-bar {
          margin: 0;
          border-radius: 0;
          border-left: none;
          border-right: none;
          padding: 8px 16px;
          font-size: 12px;
        }
      }

      .multi-select-dropdown {
        flex-direction: column !important;
        padding: 0 15px;
      }
    }
  }

  &.dialog-component-wrapper {
    .dialog-header {
      padding-bottom: 0;
    }
  }

  .billerAccountId {
    .search-bar {
      margin: 0 !important;
      border-left: unset;
      border-radius: unset !important;
      border-right: unset;
      border-top: unset;
      flex-direction: row-reverse;

      input {
        padding: 3px 10px;
      }
    }
  }
}

// .support-configuration-wrapper{
//   .columnManager{
//     margin-right: -4px;
//   }
// }

.create-customer-wrapper {

  .dropdown-wrapper,
  .input-field-container {
    margin-top: 0;
    margin-bottom: 20px;

    .text-field {
      margin-top: 0;
    }
  }

  .supportConfigV2 {
    .listing_component_download {
      right: 139px !important;
    }

    .new_table_header {
      margin: 0;

      .tabV2_wrapper .tab-btn {
        width: 83px;
        display: flex;
        justify-content: center;
      }
    }

    .reset-icon {
      left: 204px !important;
    }

    #columnSelector-parent {
      width: 141px;
      left: 15px;

      &>div {
        width: 141px;
      }

      .MuiSelect-select {
        padding: 5px 13px 5px 10px !important;

        &::after {
          margin-bottom: -1px;
          padding-left: 20px;
          font-size: 11px;
          height: 14px !important;
        }
      }
    }
  }

  .customer_accounts_wrapper {
    #columnSelector-parent {
      left: 33px !important;
    }

    .configure_discount_btn {
      min-width: auto !important;
    }

    .download-wrapper {
      left: calc(100% - 342px) !important;
    }
  }
}

.blue_text {
  path {
    fill: $primary-blue-dark;
  }

  color: $primary-blue-dark;
}

.clear-selection {
  &:has(.blue_text) {
    pointer-events: unset;
  }
}

.legal_entity_table_wrapper {
  table {
    td {
      color: #000000 !important;
      font-weight: 400 !important;
      font-size: 12px !important;

      &:first-of-type {
        min-width: 0px !important;
      }

      .list-popup-wrapper {
        .show-more {
          @include showMore;
        }
      }
    }
  }
}

.billing_alias_input_wrapper {
  .error_message {
    bottom: -6px;
  }
}

.cloudfront_form_fields {
  display: inline-block;
  margin-right: 0.333%;
  width: 33% !important;

  .multi-select-wrapper {
    width: 31%;
  }
}

.cld_cloudfront_commitments {
  min-height: 458px;
  border: 1px solid #dddfe8;
  border-radius: 4px;

  .listSelector_nodata {
    max-width: max-content;
  }
}

.cld_cloudfront_config_headers {
  .cld_text_bold {
    color: $primary-grey-dark;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }
}

.cld_cloudfront_radio_group {
  span.MuiRadio-root.MuiRadio-colorPrimary.Mui-checked {
    color: $primary-blue-dark !important;
  }

  .MuiRadio-root.Mui-checked+.MuiTypography-root {
    color: $secondary-black !important;
  }

  .text-field {
    font-size: 14px !important;
  }

  .MuiTypography-root {
    font-size: 12px;
    font-weight: 400;
    color: $secondary-black;
  }
}

.refresh_template {
  cursor: pointer;
  position: absolute;
  right: 11px;
  top: -4px;

  svg {
    display: inline-block;
    margin-right: 5px;

    path {
      fill: #0a3ca2;
    }
  }
}

.listing_accordian_footer {
  border: 1px solid #dddfe8;
  // margin: 0 1rem 1rem 1rem;
  border-radius: 0px 0px 4px 4px;
  border-left: 1px solid #dddfe8;
  border-right: 1px solid #dddfe8;
  border-bottom: 1px solid #dddfe8;
  background: #f7faff;
}

.customer-status-dropdown {
  .dropdown .body .option-wrapper .option {
    display: flex;
    justify-content: space-between;
  }
}

.modalWrapper-customerAaccount {
  &.orderingTemplate {
    .MuiPaper-root {
      max-width: 930px;

      .table-wrapper-V2 {
        .reset-icon {
          padding: 10px 20px;
        }

        .table-container {
          padding: 0px 20px 22px;

          .table {
            width: 100%;
            overflow: hidden;

            .table-header {
              th {
                &:last-child {
                  width: 0px;
                  min-width: 0px;
                }
              }
            }

            .table-body-row {
              td {
                &:last-child {
                  text-align: -webkit-center;
                }
              }
            }
          }
        }
      }
    }
  }

  &.customerType {
    .MuiPaper-root {
      max-width: 908px;

      .table-wrapper-V2 {
        .reset-icon {
          padding: 10px 20px;
        }

        .table-container {
          padding: 0px 20px 22px;

          .table {
            width: 100%;
            overflow: hidden;
          }
        }
      }
    }
  }
}

.ordring-details {
  background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(247, 250, 255, 1) 100%);
  background: -webkit-linear-gradient(180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(247, 250, 255, 1) 100%);
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(247, 250, 255, 1) 100%);
  position: relative;

  &::after {
    content: "";
    position: absolute;
    right: 13px;
    bottom: 16px;
    width: 53px;
    height: 46px;
    background: url("../../../../Assets/icons/background-icon.svg");
  }

  >div:not(:last-child) {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 0.1px;
      height: 100%;
      background-color: #dddfe8;
      right: -30px;
      top: 0;
    }
  }

  h3 {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    text-align: left;
    color: $primary-grey-dark;
    position: relative;
    padding-left: 7px;
    margin-bottom: 14px;

    &::before {
      content: "";
      background: #41b8ff;
      width: 0.6%;
      height: 100%;
      position: absolute;
      left: 0;
    }
  }

  .disabled {
    path {
      fill: $primary-grey-light;
    }

    &.history {
      path {
        stroke: #555b6c;
        stroke-width: 0.4;
      }
    }
  }
}

.create-customer-wrapper {
  .reservation_discount-wrapper {
    .billdesk_RO {
      table {
        tr {

          td,
          th {
            &:first-child {
              left: 0;
              padding-left: 8px;
            }
          }

          th {
            &:first-child {
              padding-right: 6px !important;
            }
          }

          td,
          th {
            &:nth-child(2) {
              left: 72px;
            }
          }

          td {
            &:nth-child(2) {
              padding-left: 2px !important;
            }
          }
        }
      }

      .middle.table-scrollbar {
        .reset-icon {
          left: 215px !important;
          right: 10px !important;
        }
      }
    }
  }

  .edp-discount-program,
  .supportConfigV2 {
    .billdesk_RO {

      td,
      th {
        &:first-child {
          left: 0;
          z-index: 1;
        }
      }
    }
  }

  .edp-discount-program {
    .billdesk_RO {
      .listing_component_download {
        left: calc(100% - 207px) !important;
      }

      .associated-accounts-wrapper {
        .middle.table-scrollbar {
          .reset-icon {
            right: -4px !important;
          }
        }
      }
    }
  }

  .legal_name {
    .table-container {
      .table {
        tbody {
          tr {
            td {
              &:first-child {
                .MuiCheckbox-root {
                  padding-right: 8px !important;
                }
              }
            }
          }
        }
      }
    }

    .billdesk_RO {
      td {
        &:first-child {
          padding-left: 8px;
        }
      }
    }
  }

  .flat_discount_accordian_wrapper {
    .billdesk_RO {
      .columnManager {
        left: 16px !important;
      }

      th,
      td {
        &:first-child {
          padding-right: 6px !important;
        }
      }
    }
  }

  .credits-listing {
    .billdesk_RO {
      .associated-accounts-wrapper {
        .middle.table-scrollbar {
          .reset-icon {
            right: 22px !important;
          }
        }
      }

      th,
      td {
        &:first-child {
          padding-right: 6px !important;
          position: sticky;
        }
      }

      #columnSelector-parent.columnManager {
        left: 42px !important;
      }
    }
  }

  .associated-accounts-wrapper {
    .billdesk_RO {
      .text-center {
        svg {
          margin: unset;
        }

        .text-field {
          span.support-empty-checkbox {
            width: auto !important;
          }
        }
      }
    }
  }

  .customer_accounts_wrapper {
    .billdesk_RO {
      .form-footer {
        height: 63px;
      }
    }
  }

  .reservation_discount-wrapper {
    .billdesk_RO {
      .columnManager {
        .MuiFormControl-root {
          right: -18px;
        }
      }
    }
  }

  .ppa-revamp-wrapper {
    .billdesk_RO {
      .columnManager {
        .MuiFormControl-root {
          right: -167px;
        }
      }
    }
  }

  .VirtualReservations.customer_management_edit_wrapper {
    .billdesk_RO {
      .columnManager {
        left: 16px !important;
      }
    }
  }

  .supportConfigV2 {
    .billdesk_RO {
      .columnManager {
        .MuiFormControl-root {
          right: 1px;
        }
      }

      .supportConfigAccount {
        padding: 0 6px 0 8px;
      }
    }
  }

  .customer_account_wrapper {
    .billdesk_RO {
      #columnSelector-parent {
        left: 0px !important;
      }

      .columnManager {
        .MuiFormControl-root {
          right: -16px;
        }
      }

      .listing_component_download {
        left: calc(100% - 207px) !important;
      }
    }
  }

  .billdesk_RO {
    .support-empty-checkbox {
      width: unset !important;
    }
  }
}

.create-customer-wrapper.billdesk_RO {
  .reservation_discount-wrapper {
    table {
      tr {

        td,
        th {
          &:first-child {
            left: 0;
            padding-left: 8px;
          }
        }

        th {
          &:first-child {
            padding-right: 6px !important;
          }

          &:nth-child(2) {
            left: 72px !important;
          }
        }

        td,
        th {
          &:nth-child(2) {
            left: 91px;
          }
        }

        td {
          &:nth-child(2) {
            padding-left: 2px !important;
            left: 72px !important;
          }
        }
      }
    }

    .middle.table-scrollbar {
      .reset-icon {
        left: 215px !important;
        right: 10px !important;
      }
    }
  }

  .edp-discount-program,
  .supportConfigV2 {

    td,
    th {
      &:first-child {
        left: 0;
        z-index: 1;
      }
    }

    #columnSelector-parent {
      left: 15px !important;
    }

    .associated-accounts-wrapper {
      .search-bar {
        right: 225px !important;
      }
    }
  }

  .vri-table-list {
    .associated-accounts-wrapper {
      .search-bar {
        right: 155px !important;
      }
    }
  }

  .AdditionalDiscount,
  .credits-listing {
    .associated-accounts-wrapper {
      .search-bar {
        right: 225px !important;
      }
    }
  }

  .edp-discount-program {
    .listing_component_download {
      left: calc(100% - 212px) !important;
    }

    .associated-accounts-wrapper {
      .middle.table-scrollbar {
        .reset-icon {
          right: -4px !important;
        }
      }
    }
  }

  .legal_name {
    td {
      &:first-child {
        padding-left: 8px;
      }
    }
  }

  .flat_discount_accordian_wrapper {
    &.v2 {
      .columnManager {
        left: 16px !important;
      }
    }

    th,
    td {
      &:first-child {
        padding-right: 6px !important;
      }
    }
  }

  .credits-listing {
    .associated-accounts-wrapper {
      .middle.table-scrollbar {
        .reset-icon {
          right: 22px !important;
        }
      }
    }

    th,
    td {
      &:first-child {
        padding-right: 6px !important;
        position: sticky;
      }
    }
  }

  .associated-accounts-wrapper {
    .text-center {
      .text-field {
        span.support-empty-checkbox {
          width: auto !important;
        }
      }
    }
  }

  .customer_accounts_wrapper {
    .form-footer {
      height: 63px;
    }
  }

  .reservation_discount-wrapper {
    .columnManager {
      .MuiFormControl-root {
        right: -16px;
      }
    }
  }

  .ppa-revamp-wrapper {

    td,
    th {
      &:first-child {
        left: 0;
        z-index: 1;
      }
    }

    .search-bar {
      right: 155px;
    }

    .columnManager {
      .MuiFormControl-root {
        right: -166px;
      }
    }
  }

  .customer_accounts_wrapper {
    #columnSelector-parent {
      left: 0px !important;
    }
  }

  .supportConfigV2 {
    .inner-table {
      table {
        tbody {
          tr {
            td {
              &:first-child {
                padding: 0px 6px 0px 11px !important;
              }
            }
          }
        }
      }
    }

    .associated-accounts-wrapper {
      .search-bar {
        right: 215px !important;
      }
    }
  }
}

.create-customer-wrapper {
  .create-customer-form {
    .supportConfigV2 {
      .associated-accounts-wrapper {
        .table-container {

          .table .cppTextField,
          .select-all-checkbox {
            .Mui-disabled svg {
              fill: #cbcbcb;
              background: #cbcbcb;
              border: 1px solid #898a90;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
}

// this to be a mixin
.MuiTooltip-popper {
  .MuiTooltip-tooltip:has(.discount-representation-tooltip) {
    background-color: #fff !important;
    box-shadow: -5px 12px 30px 0px rgba(52, 58, 64, 0.2509803922);
    border: 1px solid #e6e6e6;
    border-radius: 4px !important;
    padding: 0 !important;
  }
}

.ordering-wrapper {
  .dialog-header {
    background: #f0f6ff;
    border-radius: 4px 4px 0 0;
  }

  .draggable {
    overflow-x: auto;
    margin: 15px;
    padding-bottom: 20px;

    >div {
      column-gap: 10px;
      max-height: 450px;
      flex-wrap: wrap;
      row-gap: 3px;
    }
  }
}

.create-customer-wrapper {
  .create-customer-form {

    .credits-listing,
    .VirtualReservations {
      .associated-accounts-wrapper {
        .table-container {
          .table .cppTextField {
            .Mui-disabled svg {
              fill: #cbcbcb;
              background: #cbcbcb;
              border: 1px solid #898a90;
              border-radius: 2px;
              height: 13px !important;
              margin-left: 0 !important;
              width: 13px !important;
            }
          }
        }
      }
    }
  }
}

.renewal-modal {
  .dialog-header {
    padding-bottom: 0px;
  }

  .support-body {
    .add-user-wrapper.widget-basic {
      .createForm {
        >div {
          padding-top: 0px !important;

          .read-only-view {
            margin-top: 22px;

            .read-only-item {
              >p {
                color: #151e33;
                font-weight: 500;
                font-size: 12px;
              }

              &.expiring-in-days>p {
                display: inline-block;
                margin-right: 9px;
                margin-top: 2px;
              }
            }

            div {
              margin-top: 0px !important;
            }
          }
        }
      }

      .form-footer {
        border-radius: 0 0 6px 6px;
        padding: 12px 15px 14px 15px;

        .acknowledge_wrapper {
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

//Configure commitments read only styling
.read_only_commitment {
  .commitment-section .dropdownContatiner {
    align-items: center;
  }

  .cld_pp_header,
  .region-row p,
  .private_pricing_td p {
    color: #151e33 !important;
    font-weight: 400 !important;
  }

  .aos-section p {
    color: #151e33 !important;
    font-weight: 500 !important;
  }

  .counter_container {
    position: relative;

    input {
      padding: 0 !important;
    }

    &::after {
      content: "Year(s)";
      position: absolute;
      display: block;
      z-index: 99999;
      top: 26px;
      right: 56px;
      font-size: 14px;
    }
  }

  .reset_btn {
    display: none !important;
  }

  .counter-btn {
    display: none !important;
  }

  .counter-wrapper {
    border: none !important;
    padding: 0;
    height: unset !important;
    min-height: unset !important;

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded p:not(.ri_tenure) {
      margin-top: 0 !important;
    }

    input {
      text-align: left;
      font-weight: 700;
    }
  }

  .label_font_size,
  // .text-field
    {
    color: #000 !important;
    font-weight: 400;
    margin-top: 0 !important;
    pointer-events: none;
  }

  .calender {
    pointer-events: none;

    .header {
      border: unset !important;
      margin-top: 0 !important;
      padding: 0 !important;
      height: unset !important;
    }

    img {
      display: none;
    }
  }

  .input_box {
    background-color: #f1f2f6;
    pointer-events: none;
    font-size: 13px !important;
  }

  .dropdown {
    pointer-events: none;

    .header {
      // padding: 0 !important;
      // border: none !important;
      background-color: #f1f2f6 !important;

      .truncate {
        font-size: 13px !important;
        line-height: 14px !important;
      }

      svg {
        display: none;
      }
    }
  }

  .option-wrapper {
    .option {
      display: none !important;
    }

    &:has(.selected-group) {
      .selected-group {
        display: block !important;
      }
    }

    svg {
      height: 20px;
      width: 20px;
      fill: #999ca6;
    }
  }

  table {
    th {
      text-wrap: nowrap;
    }
  }

  .cld_cloudfront_radio_group,
  .text-field {
    &>p {
      color: #000 !important;
      font-size: 13px !important;
    }
  }

  .table_container {
    // max-height: 300px; / TO Do
    overflow: auto;

    .table-radio-input {
      &:disabled {
        background-color: #f1f2f6 !important;
      }
    }
  }

  .aos-input {

    input,
    span {
      pointer-events: none;
      background-color: #f1f2f6;
    }
  }

  .label_input_container {
    .right_container {
      .input_container {
        .input_label {
          padding-right: 5px;
          text-align: center;
          background-color: #f1f2f6;
          pointer-events: none;
          font-size: 13px !important;
        }
      }
    }
  }

  .cld_pp_secondary_header {
    margin-top: 0px !important;
  }
}

.bg-white {
  &:has(.customer_table) {
    padding: 0 !important;
    border-radius: 4px;

    .table-wrapper-V2 {
      max-width: max-content;
    }
  }
}

.table-wrapper-V2 {
  &:has(.customer_table) {
    padding: 12px;
  }

  &:has(.ri-ops-table) {
    padding-top: 20px;
  }
}

.customer_table_wrapper,
.customer_table {
  table {
    th {
      padding-right: 33px !important;

      &.action-header {
        min-width: unset !important;
        padding-right: 0px !important;
      }

      .sorting-data {
        text-wrap: nowrap;
      }
    }
  }
}

.billdesktable {
  th {
    &:first-child {
      padding-right: unset !important;
    }
  }

  .list-popup-wrapper {
    .display-text {
      min-width: 69px;
    }
  }
}

.customer_table {
  th {
    &:last-child {
      padding-right: unset !important;
    }
  }
}

.table-wrapper-V2 {
  &:has(.customer_table) {
    .reset-icon.item-center {
      flex-direction: row;
      padding-left: 0;
      background-color: unset;
      position: relative;
      max-width: fit-content;
      z-index: 99;
    }

    button {
      margin-right: 0;
    }
  }
}

.preference-section {
  background: #f9f9f9 !important;
  border: 1px solid #DDDFE8;
  border-radius: 4px;
  margin-top: 10px;
  padding: 0px 11px;

  .radio-button-group {

    .text-field {
      &.mandatory::after {
        margin-left: -12px;
      }

      .icon-info {
        margin-left: 16px;
        top: 3px;
      }
    }


    .option-wrapper {
      margin-left: 10px;

      .option {
        label {
          display: flex;
          gap: 9px;

          .MuiRadio-root {
            padding: 0px;
          }

          .MuiFormControlLabel-label {
            font-size: 12px;
          }

          .MuiRadio-root.Mui-checked {
            +.MuiTypography-root {
              color: #000000 !important;
            }
          }

          span.MuiRadio-root.MuiRadio-colorPrimary.Mui-checked {
            color: #0A3CA2 !important;
          }
        }
      }
    }
  }

  .error {
    font-size: 12px;
    color: red;
    margin-top: 6px;
  }
}

.legal-change-config-modal .MuiPaper-root.MuiDialog-paper {
  width: 420px;
  min-width: 420px;

  div {
    margin-top: 0px !important;
  }

  .dialog-header {
    padding: 13px 15px;
  }

  .createForm {
    >div {
      padding: 15px 15px !important;
    }

    .dropdown-wrapper {
      .body {
        padding: 0px;
      }

      .search-bar {
        margin: 0px !important;
        border-radius: 0px !important;
        border: 0px;
        border-bottom: 1px solid $secondary-grey;
      }

      .option {
        &.showSelectedIcon {
          &::after {
            right: 10px !important;
          }
        }
      }
    }

    .selected-options-readonly {
      margin-top: 0px !important;
    }

    .list-popup-wrapper {
      .show-more {
        @include showMore;
        padding: 3px 10px 3px 10px;
        width: auto;
        height: auto;
      }
    }

    .calender-wrapper {
      margin-bottom: 0px !important;
    }
  }

  .form-footer {
    padding: 9px 15px !important;

    .acknowledge_wrapper {
      margin-left: 0px;
    }
  }
}

.generic-discount {
  .form-footer{
    padding: 15px 20px;
  }
}
