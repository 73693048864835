@import "../../../styleguide/contant.scss";

//input
.input-field-container {
  position: relative;
  width: 347px;

  &>span.text-black.block {
    line-height: 1;
  }

  .icon {
    position: absolute;
    top: 23px;
    left: 15px;
  }

  .icon.icon-password {
    top: 23px;
  }

  .icon-container {
    top: 16px;
    position: absolute;
    padding: 10px 0 0 10px;
    transition: 0.3s;
    color: #373737;
    width: 23px;
    height: 23px;
    left: 50px;
  }

  input {
    width: 100%;
    height: 45px;
    padding: 12.5px 0 12.5px 45px;
    border-radius: 4px;
    border: solid 1px #dddfe8;
    background-color: #ffffff;
    outline: none;
    color: #7b7d86;
    // transition: 0.3s;
    margin-bottom: 6px;
    margin-top: 10px;
    font-size: 12px;
    line-height: 1.5;
    font-family: "Roboto", sans-serif;

    &:focus {
      border: 1px solid #0a3ca2;
    }

    &.filled {
      color: black;
      font-weight: 500;
    }

    &.no-border {
      width: 298px;
      font-weight: unset;

      &:focus,
      &:active {
        border: 1px solid transparent;
        box-shadow: 0 0 8px #b4b3ce;
      }
    }

    &[disabled] {
      background-color: #ebeef7;
      cursor: not-allowed;
    }
  }
}

.createForm {
  // min-height: 345px;
}

.MuiFormControl-root {
  // width: 36%;
  display: block !important;
  margin-bottom: 0 !important;

  .MuiFormLabel-root {
    font-size: 12px;
    padding: 0 3px;

    // &::after {
    //   content: "*";
    //   color: red;
    //   margin-left: 3px;
    // }

    &.MuiInputLabel-shrink {
      top: 3px;
      color: #0a3ca2;

      // &::after {
      //   content: "*";
      //   color: red;
      //   margin-left: 3px;
      // }
    }
  }

  .MuiFormHelperText-root {
    font-size: 11px;
    margin: unset;
  }

  .MuiInputBase-root {
    display: block !important;

    input {
      // height: 45px;
      box-sizing: border-box;
    }

    .MuiSelect-select {
      padding: 10px 15px;
    }
  }

  &.select-role {
    width: 20%;
  }
}

.MuiInputAdornment-root {
  p {
    font-size: 11px !important;
    color: #337ab7;
  }
}

.MuiOutlinedInput-input {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.customername {
  .MuiInputLabel-root {
    top: -1px !important;

    &.MuiInputLabel-shrink {
      top: 0 !important;
    }
  }

  fieldset.MuiOutlinedInput-notchedOutline {
    top: 0;
  }

  .MuiFormHelperText-root.Mui-error {
    color: red !important;
    margin-top: 0 !important;
  }
}

//button
.button {
  cursor: pointer;
  font-weight: 400;
  align-items: center;
  display: flex;
  height: 32px;
  transition: all 0.2s ease;

  &.small {
    padding: 6px 14px;
    height: 28px;
  }

  &.medium {
    font-size: 12px !important;
    padding: 3px 10px;
    font-weight: 500;
  }

  &.large {
    font-size: 14px !important;
    padding: 5px 12px;
  }

  &.outline-primary {
    color: #0a3ca2;
    border: 1px solid #0a3ca2;
    background: #fff;

    &:hover {
      background-color: #f2f9ff;
      box-shadow: 0px 5px 5px #0a3ca21a;
      border: 1px solid #0a3ca2;
    }
  }

  &.outline-primary-nohover {
    color: #0a3ca2;
    border: 1px solid #0a3ca2;
  }

  &.hover-solid {
    color: #0a3ca2;
    border: 1px solid #0a3ca2;
    background-color: white;

    &:hover {
      color: rgb(224, 200, 200);
      background-color: #0a3ca2 !important;
      box-shadow: -9px 7px 9px #0a3ca234;
    }
  }

  &.primary {
    color: white;
    background-color: #0a3ca2;
    border: 1px solid #0a3ca2;

    &>img[alt="add icon"] {
      width: 10px;
      display: inline-block;
      margin-top: -1px;
    }

    &:hover {
      box-shadow: -7px 6px 9px #0a3ca226;
    }

    &.medium {
      &>img[alt="add icon"] {
        width: 14px;
      }
    }
  }

  &.primary-hover {
    &:hover {
      box-shadow: -10px 13px 17px #0a3ca234;
    }
  }

  &.disabled {
    background-color: #898a90;
    color: #fff;
    cursor: not-allowed !important;
    pointer-events: unset;
    font-weight: 500;
    border: 1px solid #898a90;

    svg {
      cursor: not-allowed !important;
    }
  }

  &.dividerRight {
    position: relative;
    margin-right: 10px;

    &::after {
      position: absolute;
      content: "";
      width: 0;
      height: 27px;
      top: 50%;
      transform: translateY(-50%);
      border-right: solid 1px #e6e6e6;
      right: -12px;
      pointer-events: none;
    }
  }
}

//Text
.text-field {
  &.large {
    font-weight: bolder;
    font-size: 22px;
    line-height: 36px;
    font: normal normal 900 22px/36px Roboto, sans-serif;
    padding: 5px 20px;
    padding-top: unset;
  }

  &.base {
    font: normal normal normal 16px/14px Roboto !important;
    padding: 10px 20px 15px 20px !important;
  }

  &.x-small {
    font-size: 11px;
  }

  &.small {
    font-size: 12px;
  }

  &.medium {
    font-size: 14px;
  }

  &.bold {
    font: normal normal bold 14px/22px Roboto;
    letter-spacing: 0.15px;
    color: #000000;
  }

  &.primary {
    color: #151e33;
  }

  &.large.primary {
    line-height: 1;
    padding-bottom: 10px;
  }
}

.text-field,
.label {
  &.mandatory {
    &::after {
      content: "*";
      color: red;
      margin-left: 3px;
      font-size: 11px;
    }
  }
}

//multi-select
.multi-select-wrapper {
  width: 36%;
  border: 1px solid #ebebeb;
  margin: 5px 0;
  align-items: center;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;

  .no-item-selected-list {
    .MuiSvgIcon-root {
      color: #0a3ca2 !important;
      height: 24px !important;
      width: 24px !important;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .search-wrapper {
    background: transparent linear-gradient(0deg, #f0f6ff 0%, #e5eefa 100%) 0% 0% no-repeat padding-box;
    border-bottom: 1px solid #ebebeb;
    padding: 10px 13px;

    .search-label {
      font: normal normal normal 13px/18px Roboto;
      letter-spacing: 0.15px;
    }

    .search-bar {
      margin: unset;
      margin-top: 6px;

      input {
        width: 100%;
      }
    }
  }

  .multi-select-dropdown {
    min-height: 223px;
    overflow-y: auto;

    .Mui-checked {
      svg {
        color: #0a3ca2;
      }

      &+.MuiFormControlLabel-label {
        font-weight: 500;
        color: #000000;
      }
    }

    .MuiCheckbox-root {
      padding: 5px 8px 5px 15px;

      &:hover {
        background-color: transparent;
      }
    }

    .MuiSvgIcon-root {
      color: #999ca6;
      height: 20px;
      width: 20px;
    }

    .MuiFormControlLabel-label {
      font: normal normal normal 12px Roboto;
      color: #7c7c7c;
    }
  }
}

.customername {
  .error_message {
    margin-top: -5px !important;
  }
}

.customer-linked-acc-wrapper {
  .search-wrapper {
    background: unset;
    padding: 10px 0;

    .search-label {
      font-size: 11px;
      margin: 0 15px;
    }

    .search-bar {
      margin: 8px 15px 0 15px;
      color: #000000;

      &:has(input:focus) {
        border: solid 1px #0a3ca2;
      }
    }
  }

  .flow-icon-wrapper {
    .icon {
      width: 50px;
      border: 1px solid #525252;
      border-radius: 13px;
      background-color: #6a6a6a;
      color: white;
      position: relative;
      height: 24px;

      img {
        position: absolute;
      }

      &.active {
        background: transparent linear-gradient(180deg, #0a3ca2 0%, #002777 100%) 0% 0% no-repeat padding-box;
        border: 1px solid #0a3ca2;
        cursor: pointer;
      }

      &:first-child {
        position: relative;
        margin-left: 10px;
      }
    }
  }

  .multi-select-wrapper {
    width: 410px;
    height: 283px;
    border-radius: 4px;

    .multi-select-dropdown {
      min-height: 201px;
      max-height: 201px;
      padding-top: 0;
    }

    &.selected {
      border: 1px solid #0a3ca2;
      box-shadow: -12px 10px 21px #0a3ca229;
    }
  }

  .error_message {
    bottom: -12px;
  }
}

//upload-input
.upload-input-wrapper {
  .error_message {
    margin-top: 5px;
  }

  .button.hover-solid:hover {
    color: #fff;
  }
}

.mui-checkbox {
  .MuiSvgIcon-root {
    color: #999ca6;
    height: 20px;
    width: 20px;
  }

  .MuiCheckbox-root {
    padding: 5px 7px 5px 9px;
  }

  .Mui-checked {
    svg {
      color: #0a3ca2;
    }

    &+.MuiFormControlLabel-label {
      font-weight: 500;
      color: #000000;
    }
  }

  .MuiFormControlLabel-label {
    font: normal normal normal 11px Roboto;
    color: #7c7c7c;
  }
  &.upgrade_flow_checkbox {
    .MuiFormControlLabel-label {
      font-size: 16px !important;
      color: black !important;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

// text-area
.text-area-wrapper {
  position: relative;

  &.active {
    .text-area-label {
      font-weight: bold;
    }

    .counter {
      span {
        background-color: #0a3ca2;
      }
    }
  }

  .text-area-label {
    font: normal normal normal 12px/14px Roboto;
  }

  .text-area {
    width: 100%;
    border: 1px solid #dddfe8;
    border-radius: 4px;
    padding: 10px 14px;
    font: normal normal normal 13px/22px Roboto;

    &.filled {
      font-size: 14px;
      font-weight: 500;
    }

    &:focus {
      border: 1px solid #0a3ca2;
    }

    &.fixed-height {
      height: 45px;
    }

    &.resize-none {
      resize: none;
      overflow: auto;
    }
  }

  .counter {
    span {
      background-color: #525252;
      color: white;
      font: normal normal bold 8px/11px Roboto !important;
      padding: 2px 5px;
      border-radius: 5px;
    }
  }
}

//dropdown
.dropdown-wrapper,
.calender-wrapper,
.invoice-customer-wrapper {
  margin-bottom: 15px;

  &.v3 {
    .dropdown,
      .header {
        height: 32px !important;
        margin-top: 0 !important;

        svg path{
          fill: #4698D3;
        }

        .truncate {
          font-size: 12px;
          font-weight: 500;
          color: #4698D3;
        }

        &.filled {
          border: 1px solid #4698D3 !important;
        }
      }
    .enable-scroll {
      height: 360px;
      overflow: scroll;
    }

}
.enable-elipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

  .dropdown,
  .calender,
  .invoice-customer {
    .header {
      border-radius: 4px;
      border: 1px solid #dddfe8;
      padding: 0 13px;
      cursor: pointer;
      margin-top: 5px;
      color: #898a90;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 46px;

      &.filled {
        font: normal normal normal 14px/30px Roboto;
        letter-spacing: 0px;
        color: #000000;
        box-shadow: unset;
        border: 1px solid #dddfe8;
        border-radius: 4px;
        font-weight: 500;
      }

      &.dropdown {
        box-shadow: 0px -1px 10px #00000029;
        border-radius: 4px 4px 0 0;
        border-bottom: unset;
      }
    }

    .body {
      border: 1px solid #dddfe8;
      box-shadow: 0px 2px 10px #00000029;
      border-radius: 0px 0px 3px 3px;
      position: absolute;
      background-color: white;
      width: 100%;
      z-index: 999;

      .option-wrapper {
        padding: 8px 0;

        .option {
          padding: 5px 15px;
          font-size: 14px;
          color: black;
          cursor: pointer;

          &:hover {
            background-color: #f7f7f7;
          }

          &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }

          &.showSelectedIcon {
            display: flex;

            &::after {
              color: white;
              background-color: #41aa41;
              content: "\f00c";
              font-family: "FontAwesome";
              font-size: 8px;
              height: 15px;
              width: 15px;
              padding: 3px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              margin-left: 10px;
              position: absolute;
              right: 40px;
            }
          }
        }

        .no_data_message {
          min-height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #0A3CA2;
          font-weight: 500;
        }
      }
    }

    .chip {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px 6px;
      height: 22px;
      font-size: 10px;
      border: 1px solid #DDDFE8;
      border-radius: 3px;
      margin-right: 6px;
      color: #151E33;
      max-width: 120px;

      .chip-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .chip-icon {
        display: inline-block;
        margin-left: 6px;
        padding: 3px;

        svg {
          height: 8px;
          width: 8px;

          path {
            fill: #151E33;
          }
        }
      }

      &:hover {
        border: 1px solid $primary-blue-dark;

        .chip-icon {
          background-color: $primary-blue-dark;
          border-radius: 50%;

          svg {
            path {
              fill: $global-white;
            }
          }
        }
      }
    }

    .chip-counter {
      border: 1px solid $primary-blue-dark;
      border-radius: 4px;
      padding: 4px 12px;
      height: 22px;
      font-size: 10px;
      font-weight: 700;
      color: $primary-blue-dark;
      background-color: #D8EAFF;
      line-height: 11px;
      position: relative;

      .popupList {
        display: none;
        padding: 13px 15px;
        background-color: $global-white;
        border: 1px solid #DDDFE8;
        position: absolute;
        top: 22px;
        left: 0;
        z-index: 10000;
        width: 187px;
        border-radius: 4px;
        max-height: 250px;
        overflow-y: auto;

        .popupListItem {
          font-size: 12px;
          font-weight: 400;
          color: $secondary-black;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &:hover {
        .popupList {
          display: block;
        }
      }
    }
  }

  .Mui-error {
    margin-top: 0;
  }
}

//  radio-buttons
.radio-button-group {
  margin-bottom: 12px;

  &.discountRepresentation {
    .text-field {
      font-size: 14px !important;
      font-weight: 500;
      margin-bottom: 5px;
    }
  }

  .chargeMinType.disabled {
    background-color: #F1F2F6 !important;
    border: 1px solid #DDDFE8 !important;
    border-radius: 4px !important;
    color: #151E33 !important;
  }

  .option-wrapper {
    display: flex;
    align-items: center;
    column-gap: 10px;

    .buttonStyle {
      color: #525252;
      font-size: 12px;
      border: 1px solid #dddfe8;
      border-radius: 4px;
      padding: 13px 25px;
      cursor: pointer;

      &.selected {
        border: 1px solid #779FF0;
        color: #0A3CA2;
        font-weight: 500;
        background-color: #E8F1FF;
      }

      &.withTooltip {
        padding: 10px 9px;
        margin-right: 4px;

        svg.info-icon {
          color: #0A3CA2;
        }

        &.disabled {
          pointer-events: none;
          color: #CBCBCB;

          svg {
            color: #CBCBCB;
          }
        }
      }

      &.invoiceformat {
        &.selected {
          box-shadow: -3px 2px 12px #0a3ca234;
          background-color: #0a3ca2;
          color: white;
          position: relative;

          img {
            position: absolute;
            top: -2px;
            right: -2px;
            height: 15px;
            width: 15px;
            background-color: white;
            border-radius: 50%;
          }
        }
      }
    }

    .option {
      &:first-child {

        // background-color: purple;
        .buttonStyle.tabsStyle {
          border-radius: 4px 0 0 4px;
        }
      }

      &:last-child {
        .buttonStyle.tabsStyle {
          border-radius: 0 4px 4px 0;
        }
      }

      // }
      .buttonStyle.tabsStyle {
        color: #525252;
        font-size: 12px;
        border: 1px solid #dddfe8;
        // border-radius: 4px;
        padding: 9px 23px;
        cursor: pointer;

        // &.invoiceformat {
        &.selected {
          // box-shadow: -3px 2px 12px #0a3ca234;
          // background-color: #0a3ca2;
          background: #e8f1ff;
          color: #0a3ca2;
          border-color: #779ff0;
          position: relative;

          img {
            position: absolute;
            top: -2px;
            right: -2px;
            height: 15px;
            width: 15px;
            background-color: white;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

//invoice-delivery-type
.invoice-delivery-wrapper {
  .invoice-delivery {
    .option {

      .Download,
      .Email {
        color: #525252;
        font-size: 12px;
        border: 1px solid #dddfe8;
        border-radius: 4px;
        padding: 0 15px;
        cursor: pointer;
        height: 45px;
        width: 100%;
        font: normal normal normal 14px/34px Roboto !important;

        &.selected {
          background-color: #f0f6ff;
          box-shadow: -4px 8px 8px #0a3ca21a;
          border: 1px solid #0a3ca2;
          color: #0a3ca2;
        }

        label {
          font-weight: unset;
          cursor: pointer;
        }
      }

      .Email {
        &.filled {
          background-color: unset;
          color: black;
          border-radius: 4px !important;
          font: normal normal normal 14px/30px Roboto !important;
        }

        &.selected {
          border: 1px solid #dddfe8;
          border-radius: 4px 4px 0 0;
          box-shadow: unset;
        }
      }

      &:first-child {
        margin-right: 10px;
      }

      .email-dropdown-wrapper {
        box-shadow: -7px 5px 19px #00000029;
        border: 1px solid #dddfe8;
        border-radius: 0px 0px 3px 3px;
        height: 60px;
        border-top: unset;
        position: absolute;
        background-color: white;
        width: 100%;
        z-index: 999;
      }
    }
  }
}

//calender
.calender-wrapper {
  .calender {
    .header {
      padding: 13px;

      img {
        height: 16px;
        width: 16px;
      }
    }
  }
}

//upload-modal

.MuiPaper-root[aria-labelledby="alert-upload-dialog"] {
  max-width: 500px;
}

.MuiPaper-root[aria-labelledby="alert-upload-dialog-preview"] {
  max-width: fit-content;
}

.upload-dialog-body {
  position: relative;
  min-height: 200px;
  max-height: 200px;
  width: 90%;
  margin: 10px auto;
}

.upload-dialog-title {
  border: 1px solid #e6e6e6;
}

//partner-settings

.upload-label {
  width: 100%;
  font: normal normal bold 14px/36px Roboto;
  color: #151e33;
}

.orSeperator {
  position: relative;
  color: #898a90;
  font-size: 12px !important;
  letter-spacing: 0.14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 50px;

  &::after,
  &::before {
    content: "|";
    position: absolute;
  }

  &::after {
    bottom: -2px;
    left: 5px;
  }

  &::before {
    top: -2px;
    right: 5px;
  }
}

.use-existing-checkbox {
  .MuiFormControlLabel-label {
    font: normal normal bold 14px/36px Roboto;
    color: #525252;
    font-weight: 500;
  }

  position: relative;
  bottom: 10px;
}

.partner-settings-form {
  .Mui-disabled {
    color: #c3c3c3 !important;
  }

  .upload-input-wrapper.disabled {
    opacity: 0.7;
  }
}

.partner-email-settings-wrapper {
  .partner-email-settings {
    column-gap: 20px;

    .option-label {
      color: #898a90;
      font-size: 14px;
      margin-left: 5px;
      font-weight: unset;
      cursor: pointer;

      &.selected {
        color: #0a3ca2;
      }

      &.disable {
        color: #898a90;
      }
    }

    .option-input {
      background: #f8f9f9 0% 0% no-repeat padding-box;
      border: 1px solid #dedede;
      border-radius: 6px;
      color: #525252;
      margin-top: 8px;
      padding: 20px;
      min-width: 243px;

      .key {
        display: block;
        padding: 4px 7px 4px 7px;
        font-size: 12px;
        width: fit-content;
        position: relative;
        padding-left: 0;
        letter-spacing: -0.24px;

        &.default {
          padding-left: 7px;
          font-size: 10px;
          color: white;
          letter-spacing: -0.2px;
          background-color: #898a90;
          font-weight: 500;
          padding: 10px 7px 7px 7px;
          padding-right: 15px;
          line-height: 1;
          margin-bottom: 5px;

          &::before,
          &::after {
            position: absolute;
            content: "";
            width: 10px;
            height: 20px;
            background-color: #f8f9f9;
          }

          &::before {
            right: -3px;
            top: 12px;
            transform: rotate(40deg);
          }

          &::after {
            right: -3px;
            top: -6px;
            transform: rotate(-40deg);
          }
        }
      }

      .value {
        font: normal normal bold 15px/25px Roboto;
        letter-spacing: 0.17px;
        color: #525252;

        &.selected {
          color: #000000;

          .error_message {
            max-width: 300px;
          }
        }

        input {
          border: 1px solid #dedede;
          border-radius: 3px;
          font-size: 15px;
          padding: 0 10px;
          width: 100%;
          height: 40px;
          font-weight: 500;

          &:focus {
            border: 1px solid black;
          }

          &:disabled {
            background-color: #f8f9f9;
            cursor: not-allowed;
            color: #898a90;
          }

          &::-webkit-input-placeholder {
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 0.13px;
          }

          &:-ms-input-placeholder {
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 0.13px;
          }

          &::placeholder {
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 0.13px;
          }
        }
      }

      &.selected {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: -4px 10px 28px #0a3ca229;
        border: 1px solid #0a3ca2;

        .default {
          color: white;
          background-color: #0a3ca2;

          &::before,
          &::after {
            background-color: white;
          }
        }
      }

      &.default {
        &.selected {
          color: #0a3ca2;
        }

        &.disable {
          .key {
            background-color: #898a90;
          }

          .value {
            color: #525252;
          }
        }
      }

      &.disable {
        border: 1px solid #dedede;
      }
    }

    .orSeperator {
      margin: 0 10px;
      font-weight: 500;
      letter-spacing: 0.14px;

      &::before {
        bottom: -15px;
      }
    }

    .orSeperator:last-child {
      display: none;
    }
  }
}

.smtp-settings-wrapper {
  input {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.17px;
    color: #000000;

    &:disabled {
      background-color: #f8f9f9;
      color: #898a90;
    }

    &::-webkit-input-placeholder {
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0.13px;
    }

    &:-ms-input-placeholder {
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0.13px;
    }

    &::placeholder {
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0.13px;
    }
  }

  .smtp-username {
    svg {
      color: #898a90;
    }
  }

  .option-wrapper {
    .MuiRadio-root.Mui-checked {
      color: #0a3ca2 !important;

      &.Mui-disabled {
        color: #525252 !important;
      }
    }

    .MuiFormControlLabel-label {
      color: #525252 !important;
    }

    .MuiRadio-root.Mui-checked+.MuiTypography-root {
      // color: black !important;
    }
  }

  .smtp-settings-encryption {
    .MuiRadio-root {
      color: #999ca6;
      padding-right: 0 !important;

      svg {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }

      &.Mui-checked {
        color: #0a3ca2;
      }
    }

    .MuiRadio-root.Mui-checked+.MuiTypography-root {
      color: black !important;

      &.Mui-disabled {
        color: #525252 !important;
      }
    }

    .MuiFormControlLabel-label {
      font-size: 14px;
    }
  }

  .smtp-password-wrapper {
    .showHide_password {
      svg {
        color: #0a3ca2;
      }

      &.disabled {
        svg {
          color: #898a90;
        }
      }
    }
  }
}

.cloudonomic-domain,
.custom-domain {
  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-adornedEnd .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
    background: unset !important;
    border: unset !important;
  }
}

.partner-email-settings {
  .partner-settings-email {
    margin-top: 0;
  }
}

.form-footer {
  border-top: 1px solid #e6e6e6;

  .reset-button.disabled {
    cursor: not-allowed;
    // opacity: 0.5;
    color: #7c7c7c;
  }
}

.dropdown-wrapper.v2 {
  .dropdown.dropdownOpen {
    .body.visible {
      padding: 0;

      .option-wrapper {
        max-height: 200px;
        overflow: auto;
        padding: unset;

        .option {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.12px;
          padding: 6px 16px;

          // &:nth-child(even) {
          //   background-color: #F9F9F9 !important;
          // }
        }
      }
    }
  }
}

.ChipSelectorHeader {
  border-bottom: 1px solid #efefef;
  padding: 6px 5px 6px 20px;
}

.chip-selector-selector-all {
  font-size: 9px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 22px;
  margin: 0px 4px;
  color: rgba(0, 0, 0, 0.85);
}

.optionHoverCss {
  cursor: pointer;
  width: 100% !important;
  padding: 4.4px 12px;

  &:hover {
    background-color: #f5f5f5;
  }
}

.chipOptionCheckbox {
  padding: 0px !important;

  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }

  .PrivateSwitchBase-root {
    padding: 0px;
  }
}

.multiSelectLabel {
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  font-weight: 400;
}

.multiSelectCheck {
  width: 0px !important;
}

.select_box_container {
  display: flex;
  justify-content: end;
  margin: 0px !important;
  padding: 0px !important;

  select {
    background-color: #ffffff;
    border: 0px !important;
    color: #333333;
    font-size: 9px;
  }
}

.auto-resize-wrapper {
  min-height: 40px;
  height: max-content;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 12px 12px;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
}

.auto-resize-wrapper-error {
  border: 1px solid red !important;
}

[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block;
  /* For Firefox */
  color: #00000040;
  font-size: 12px;
  margin-top: 1px;
}

.autoresize_error_message {
  margin-top: 2px !important;
}

.horizontalCheckGroup {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
}

.verticalCheckGroup {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
}

.checkboxClass {
  cursor: pointer;
  margin: -3px 0 0 0px !important;
  height: 16px;
  width: 16px;
}

.descFont {
  color: #000000;
}

.headingFont {
  color: #000000d9;
}

#myCheckbox:checked {
  /* Change the background color when the checkbox is checked */
  accent-color: #0a3ca2 !important;
  /* Replace with your desired color code */
}

.autoResMsgInfo {
  .infobox_style .info_style .infoIcon_style {
    margin-left: 0px;
  }

  .infobox_style {
    padding: 2px 12px;
  }
}

.no-data-label {
  color: black !important;
  font-size: 14px !important;
  font-family: Roboto;
  font-weight: 400 !important;
  line-height: 20px !important;
  word-wrap: break-word;
}

.noDataWrapper {
  position: absolute;
  top: 20%;
}

.noDataIcon {
  width: 78px;
  height: 90px;
}

.mav-dropdown-wrapper {
  .dropdown {
    .header.filled {
      height: 32px;
      font-size: 13px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  .dropdown.dropdownOpen {
    .body.visible {
      padding: 0;

      .option-wrapper {
        max-height: 200px;
        overflow: auto;

        .option {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.12px;
          padding: 10px 15px;
        }
      }
    }
  }
}

.mav-search-box {
  .icon {
    top: 15px !important;
    left: 10px !important;
  }

  input {
    height: 30px;
    padding: 12.5px 0 12.5px 28px;
  }

  input::placeholder {
    color: rgba(0, 0, 0, 0.25);
    font-size: 11px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.form-wrapper-v2 {
  .input-field-container input {
    height: unset;
  }

  .button {
    &.primary {
      padding: 10px 20px;
    }

    &.medium {
      padding: 8px 14px;
    }

    &.small {
      padding: 10px 14px;
    }
  }

  .dropdown-wrapper {
    .dropdown {
      .header {
        height: 32px;
      }

      .body {
        .option-wrapper {
          padding: 10px 0;
        }
      }
    }
  }
}

.customer-type {
  .counter {
    margin-top: -1px !important;
  }
}

.add-customer {
  .dropdown-wrapper.v2.select-template {
    .refresh_template {
      label {
        color: #151e33;
        cursor: pointer;
      }
    }

    .dropdown.dropdownOpen {
      .body.visible {
        bottom: 100%;

        .option {
          display: flex;
          gap: 9px;

          .default {
            font-size: 10px;
            font-weight: 500;
            color: #337ab7;
            padding: 2px 5px;
            background-color: #edf5ff;
            border: 0.7px solid #bddafb;
            border-radius: 2px;
            margin-left: auto;
          }
        }
      }
    }
  }

  .infobox_style {
    padding: 0px;
    background: unset;

    .info_style {
      .infoIcon_style {
        margin-left: 0px;

        path {
          fill: #0a3ca2;
        }

        .message_style {
          font-size: 11px;
        }
      }
    }
  }
}

//read-only-form
.read-only-form {
  .upload-input-wrapper {
    div {
      >label {
        background-color: #898a90 !important;
        border-color: #898a90 !important;
        color: #ffffff !important;
        pointer-events: none !important;
      }
    }
  }

  .invoice-customer-wrapper {
    pointer-events: none;

    .invoice-customer {
      opacity: 0.7 !important;

      .header {
        background: #f6f6f6 !important;
      }
    }
  }

  .option-wrapper {
    .option {
      .MuiRadio-root {
        display: none;
      }
    }
  }

  .shortfall-chargeback {
    .option-wrapper {
      margin-left: 11px !important;
    }
  }

  .exclusion-preference {
    .text-field {
      font-weight: 500;
    }

    .icon-info svg {
      width: 13px;
      height: 13px;
      left: -10px;
      top: -9px;
    }
  }
}
.ck-search-cross {
  position: absolute;
  right: 8px;
  height: 10px !important;
  width: 10px !important;
  cursor: pointer;

  path {
    fill: #B0B0B0
  }
}