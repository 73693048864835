//primary Colors code : 
$primary-blue-dark: #0A3CA2;
$primary-blue: #337AB7;
$primary-green: #32B437;
$primary-grey-dark: #151E33;
$primary-grey-light: #555B6C;

//secondary color code
$secondary-black: #000000;
$secondary-blueish-grey: #E6E6E6;
$secondary-grey-dark: #555B6C;
$secondary-grey-light: #E6E6E6;
$secondary-blus  :#E0F1FF;

//Accent colors
$accent-red : #D9534F;
$accent-green-dark : #49AF49;
$accent-dark-orange : #FA9000;
$accent-purple : #8558FF; 
$accent-blue-light : #48C0FF;
$accent-light-orange : #D9534F;
$accent-green-light : #AFD94F;
$accent-maroom : #CE1E76;

$global-white : #ffffff;    

$header-shadow : 0 4px 6px 0 rgba(52, 58, 64, 0.04);
$global-shadow: -7px 14px 38px 0 rgba(52, 58, 64, 0.08);

//Variables for sidebar
$dasboard-background : #151e33;
$submenu-bg-color: #23324e;
$dashboard-font-color : #8b99bb;
$dropdown-hover-color: #F5F5F5;

//dashboard variables
$option-list-background : #fafafa;


//button shadows
$filled-btn-shadow : -7px 6px 6px 0 rgba(10, 60, 162, 0.24);
$outline-btn-shadow : -3px 3px 4px 0 rgba(10, 60, 162, 0.1);

$font-family-roboto:"Roboto", sans-serif;

//Striped table
$header-background-blue : #EFF4FF;
$striped-background-grey : #F7F7F7;
$footer-background-blue : #EAF1FF;
$striped-border-color:#D5DDEC; 
$global-background-blue: #EFF4FF;


//Revamo theme : 
$background-label : #d8eaff;