.download_v2 {
    line-height: 1 !important;

    .download_excel_v2 {
        .path1 {
            stroke: #64AC56;
        }

        .path2,
        .path3 {
            fill: #64AC56;
        }

        .path4 {
            fill: #FFFFFF;
        }
    }
}

.download_v2:hover {
    .download_excel_v2 {
        .path1 {
            stroke: #FFFFFF;
        }

        .path2,
        .path3 {
            fill: #FFFFFF;
        }

        .path4 {
            fill: #64AC56;
        }
    }
}

.lens-button-style {
    &>button.MuiButtonBase-root {
        margin-left: 8px !important;
        border: 1px solid #E6ECF1 !important;
        color: #2B2B2B !important;
        padding: 8px 8px 8px 12px !important;

        &:hover {
            background-color: white !important;
            color: #4698D3 !important;
            border: 1px solid #4698D3 !important;

            &::before {
                background: url('../../../../../Assets/icons/dropdownDownloadActive.svg') no-repeat center;

            }
        }

        &::before {
            content: "";
            background: url('../../../../../Assets/icons/dropdownDownload.svg') no-repeat center;
            background-size: contain;
            width: 10px;
            height: 13px;
            display: inline-block;
            margin-right: 7px;
            /* Space between text and image */
            vertical-align: middle;
        }

        svg {
            display: none;
        }
    }

    &>a>button.MuiButton-root {
        margin-left: 8px !important;
        padding: 8px 8px 8px 12px !important;
        line-height: 14.52px;
        border: 1px solid #E6ECF1 !important;
        color: #2B2B2B !important;

        &:hover {
            background-color: white !important;
            color: #4698D3 !important;
            border: 1px solid #4698D3 !important;

        }

    }
}