.tabV2_wrapper {
  display: flex;
  // padding: 20px;
  align-items: center;

  &.v3 {
    a:last-child>div.tab-btn {
      border-right: 1px solid #d5ddec;
      border-top-right-radius: 2px !important;
      border-bottom-right-radius: 2px !important;
    }

    a:first-child>div.tab-btn {
      border-top-left-radius: 2px !important;
      border-bottom-left-radius: 2px !important;
    }

    .tab-btn {
      font-size: 12px;
      font-weight: 400;
      color: #23324e;
      height: 32px;
      padding: 7px 25px;
      border: 0;
      border-top: 1px solid #d5ddec;
      border-bottom: 1px solid #d5ddec;
      border-radius: unset !important;

      &.active {
        background-color: #ecf5fb;
        color: #4698d3;
      }
    }
  }

  .tab-btn {
    &:first-child {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      border-left: 1px solid #d5ddec;

      &.active {
        border-left: 1px solid #779ff0;
      }
    }
  }

  .tab-btn {
    &:last-child {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }

  // .tabV2_wrapper_link {
  .tab-btn {
    font-size: 12px;
    white-space: nowrap;
    font-weight: 400;
    padding: 9px 25px;
    background-color: #fff;
    border-top: 1px solid #d5ddec;
    border-bottom: 1px solid #d5ddec;
    border-right: 1px solid #d5ddec;
    color: #1c1e23;
    vertical-align: text-bottom;
    height: 36px;
    cursor: pointer;

    &.active {
      border: 1px solid #779ff0 !important;
      background-color: #e8f1ff;
      color: #0a3ca2;
      font-weight: 500;

      svg {
        path {
          fill: #0a3c87;
        }
      }
    }

    // }
  }

  &.lens-tab-style {
    a:first-child {
      .tab-btn {
        border-radius: 5px 0px 0px 5px !important;
      }
    }

    a:last-child {
      .tab-btn {
        border-radius: 0px 5px 5px 0px !important;
        border-right-width: 1px !important;
      }
    }

    a:only-child {
      .tab-btn {
        border-radius: 5px !important;
      }
    }

    .tab-btn {
      padding: 9px 20px !important;
      border-radius: 0px !important;
      border-right-width: 0px !important;
      line-height: 14.06px !important;
      font-weight: 400 !important;
      color: black;
      height :33px !important;
      *{
        font-family: Inter !important;
      }

      &.active {
        border: 1px solid #D5DDEC !important;
        color: #253E66 !important;
        font-weight: 600 !important;

      }
    }
  }
}

.roundedWrapper {
  .tab-btn {
    border-radius: 0px !important;
    border-right-width: 0px !important;
  }

  a {
    &:first-child {
      .tab-btn {
        border-top-left-radius: 2px !important;
        border-bottom-left-radius: 2px !important;
      }
    }

    &:last-child {
      .tab-btn {
        border-top-right-radius: 2px !important;
        border-bottom-right-radius: 2px !important;
        border-right-width: 1px !important;
      }
    }
  }
}

.recommendation_tabs {
  margin: 0 !important;
  padding: 8px !important;
  display: flex;
  align-items: center;
  height: 44px !important;
  min-width: 110px;

  .savings_amount {
    color: #7d7d7d;
  }

  &.active {
    .savings_amount {
      color: #2b2b2b;
    }
  }
}

@media screen and (max-width: 1024px) {
  .tab-btn {
    padding: 9px 18px !important;
  }
}