@import "../../../../../styleguide/contant.scss";

input {
    // padding: 16px 15px 14px !important;
    width: 100%;
    border-radius: 4px;
    border: solid 1px $secondary-grey;
    margin-bottom: 20px;
  
    &::placeholder {
      line-height: 2.5;
      font-size: $fs-12;
      color: $disable-text-color;
    }
    &:focus {
      outline: none;
    }
  }

  
  input[name="I_AM_Role"] {
    width: 100%;
  }

  .errorClass{
    position: unset;
    margin-top: 2px;
    text-align: center;
  }
  