.ppa-revamp-wrapper{
    .reservation_dialog{
        .current-date-text{
            font-size: 14px !important;
              color: #000 !important;
          }
    }
    .multiSelectDownloadSheet {
        .download_Wrapper {
            &>button {
                width: 58px;
                height: 28px;
                .xcelIcon{
                    svg{
                        width: 15.502px;
                        height: 14.945px;
                    }
                }
            }
        }
    }
    .columnManager{
        width: 141px !important;
        .MuiFormControl-root{
            right: 23px;
            width: 141px;
            right: 0;
            .resetEnabled .MuiSelect-select::before{
                    right: 16px !important;
                    margin: -10px;
                
            }
        }
    }
    .new_table_header .tabV2_wrapper .tab-btn{
        width: 90px;
        padding: 9px 15px;
    }
    .associated-accounts-wrapper .middle.table-scrollbar .reset-icon{
        left: 196px !important;
        right: 159px !important;
        padding-right: 0 !important;
        &::before{
            top: 6px !important;
            height: 17px !important;
        }
    }
    .associated-accounts-wrapper {
        .middle.table-scrollbar {
            .table-container {
                table thead {
                    tr th{
                        &:nth-child(2){
                            padding-left: 6px !important;
                            padding-right: 18px !important;
                        }
                    }
                }
                .table{
                    tbody{
                        td{
                            z-index: 1 !important;
                        }
                    }
                }
            }
        }
        .bottom {
            button{
                &.primary{
                    &>img{
                        width: 17px;
                        height: 16px;
                    }
                }
            }
        }
    }
    .discount_name_V2{
        &>div{
          &.list-popup-wrapper {
            &.auto-renew-tooltip-wrapper{
              justify-content: center !important;
            }
          } 
        }
    }
    .vr-tabs{
        .tab_button{
            display: flex;
            min-width: 70px;
            height: 28px;
            padding: 7px 14px;
            justify-content: center;
            align-items: center;
        }
    }
    #columnSelector-parent .MuiSelect-select{
        padding: 5px 13px 5px 10px !important;
        &::after{
            font-size: 11px !important;
            height: 14px !important;
            padding-left: 20px;
        }
        &:before{
            right: 22px !important;
        }
    }
    .list-popup-wrapper {
        .show-more{
            padding: 0 !important;
            font-size: 11px;
            width: 11px;
            height: 11px;
        }
        .pop-up{
            margin-left: 3px;
            margin-bottom: 3px;
        }
    }
    &.ppa-revamp-wrapper{
        .list-popup-wrapper{
            justify-content: flex-start !important;
            .pop-up{
                margin-bottom: 0px;
            }
            span.show-more{
                border: 1px solid #0A3CA2;
                color: #0A3CA2;
                font-size: 10px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 0.5px;
                border-radius: 4px;
                background-color: #D8EAFF;
                padding: 5px 10px !important;
            }
            svg.show-more{
                margin-bottom: 1px;
            }
        }
    }
}

.virtual_dialog.ppa_dialog{
    button.primary.disabled{
        border-color: #898a90;
    }
}

.preview-modal {
    .MuiPaper-root {
        max-width: 1120px;
        border-radius: 11px;
    }
}
// .reservation_tooltip{
//     max-height: 200px;
// }

// .serviceType{
//     &>p {
//         color: #000000 !important;
//         font: normal normal normal 11px/14px Roboto !important;
//     }
// }

// .template_name{
//     // margin-top: 12px;
//     label, span{
//         font: normal normal normal 11px/14px Roboto !important;
//     }
// }
