$defaultColor: #151e33;
$height: 36px;
$br : 20px;
$cs : 30px;

.Quote_generator_wrapper{
    .pdf_container{
        height: calc(100vh - 350px);;
        overflow-y: auto;
        margin-top: 18px;
    } .right_container{
        width: 40%;
    }
    .custom-file-upload-wrapper{
        width: 60%;
         .input_box.customer_name{
            margin-bottom: 0;
            padding: 14px !important;
            font-size: 12px;
        }
    }
    .fille_upload_footer{
        margin-top: 20px;
        text-align: right;
        display: flex;
        gap: 12px;
        justify-content: flex-end;
        button[disabled]{
            border: 1px solid #999999;
            background-color: #cccccc;
            color: #666666;
        }
    }
    .input_file_container{
        display: flex;
        gap: 18px;
    }
    .body_container{
        height: calc(100vh - 350px);
        overflow-y: auto;
        padding: 10px 12px;
        position: relative;
        li{
            margin-top: 8px;
        }
        a{
            text-decoration: none;
            color: $defaultColor;
            font-size: 13px;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .right_container{
        box-shadow: -7px 14px 38px rgb(52 58 64 / 8%);
        border: 1px solid #e6e6e6;
        border-radius: 4px 4px 4px 4px;
        margin-top: 10px;
        .container_header{
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            h4{
                font-size: 14px;
                color: $defaultColor;
                font-weight: 700;
            }
            padding: 10px 12px;
            border-bottom: 1px solid #e6e6e6;
        }
    }

    .custom-file-upload {
        margin-top: 12px;
        svg{
            // margin-bottom: 18px;
        }
        .upload_quote_wrapper{
            padding: 12px;
            // border: 2px dashed #84A5F0;
            border-radius: 5px;
            margin: 0 0 22px 0;
        }
        .max_file_allowed{
            font-size: 14px;
            color: #a1a1a1;
            margin-bottom: 8px;
        }
        label {
            // width: 100%;
            // border: 1px solid #151e33;
            text-align: left;
            // position: relative;
            background-color: transparent;
            color: #151e33;
            padding: 11px;
            display: flex;
            flex-direction: column-reverse;
            font-weight: 500;
            font-size: 12px;
            // margin-top: 50px;
            &:before {
                    content: 'Upload File';
                    // position: absolute;
                    top: -1px;
                    right: -1px;
                    display: inline-block;
                    height: $height;
                    background: #0a3ca2;
                    color: #fff;
                    z-index: 25;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: $height;
                    padding: 0 15px;
                    text-transform: uppercase;
                    pointer-events: none;
                    border-radius: 4px;
                }
            }
        }

        input[type="file"] {
            display: none;
        }

        .fileUpload input.upload {
            display: inline-block;
        }  
        .customer_name{
            margin-top: 10px;
            padding: 14px !important;
            &::placeholder{
                font-size: 14px;
            }
        } 
}

.external_referer{
    color: #0a3ca2 !important;
    display: flex;
    align-items: center;
    font-size: 14px !important;
    svg{
        fill: #0a3ca2;
        font-size: 14px;
        margin-left: 12px;
    }
}

.customer_name_accordion{
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #151e33 !important;
}

.custom_wrapper{
    border: 1px solid #0a3ca2;
    border-radius: 4px;
    margin-top: 4px;
    margin-top: 12px;
    padding: 12px;
}

.action_icons{
    cursor: pointer;
    path{
        fill: #ffffff;

    }
}

.page_box_content{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    flex-wrap: wrap;
    .page_box{
        position: relative;
        border-radius: $br;
        background-color: #ffffff;
        color: #363c5f;
        padding: 40px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        clip-path: polygon(0 0, calc(100% - $cs) 0, 100% $cs, 100% 100%, 0 100%);
        word-break: break-all;
        width: 15%;
        font-size: 12px;
        &:after{
            content: '';
            position: absolute;
            display: block;
            width: $cs;
            height: $cs;
            background-color: #e1e1f2;
            top: 0;
            right: 0;
            border-bottom-left-radius: $br;
        }
    }    
}







