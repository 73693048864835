.ck-cudos-summary-cards-wrapper {
  border-radius: 5px;
  justify-content: space-between;
  display: flex;
  .ck-cudos-summary-card {
    flex-grow: 1;
    background: #fff;
    border-right: 1px solid #e3eaef;

    &:last-child {
      border-right: none;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  & > .card-used-unused-view + .ck-cudos-summary-card {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .card-used-unused-view {
    flex-grow: 1;
    background: #fff;
    margin-right: 5px;
    border-radius: 5px;
    .card-label {
      display: inline-block;
      padding: 5px 12px;
      background: #4698d3;
      color: #ffffff;
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      border-radius: 2px 12px 12px 0px;
      line-height: 14px;
    }
    .used {
      border-right: 1px solid #e3eaef;
      padding-left: 8px;
      &:first-child {
        padding-left: 0;
        padding-right: 5px;
      }
      &:last-child {
        border-right: unset;
      }
    }
  }

  &.fargate-cpu-memeory-card{
    .card-content{
      margin: 8px !important;
      .card-heading{
        margin-top: 5px;
        font-size: 11px !important;
      }
      .cost{
        margin-top: 4px;
        font-size: 15px;
        font-weight: 500;
        line-height: 15px;
      }
    }
  }
}
