@import "../contant.scss";

h3.section-heading {
    font-size: $fs-16;
    margin: 0;
    padding-bottom: 10px;
    font-weight: bold;
    color: $secondary-black;
    list-style: 1;
  }

.get_accountdata_wrapper{
    display: flex;
    .s3GraphCont{
        width: 50%;
        &:first-child{
            margin-right: 20px;
        }
    }
}

.s3-dashboard-container{
    width: 100% !important;
    table{
        width: 100% !important;
    }
}


.s3-dashboard-container {
  position: relative;
  min-height: 60vh;
  box-shadow: 0 7px 7px 0 rgb(0 0 0 / 6%);
  background-color: $primary-white;
  border-radius: 0 4px 4px 4px;
  border: 1px solid $secondary-blueish-grey;
  margin-bottom: 20px;
  padding: 18px !important;
  width: 100%;
  .table_container-div{
    min-height: 180px;
  }

  width: 100%;
  .s3GraphCont,
  .tabPanel {
    .data_renderer {
      min-height: unset;
      .table_container_wrapper {
        padding-top: 0;
        table {
          thead {
            th {
              font-size: $fs-11 !important;
              font-weight: 500 !important;
              color: $heading-txt-color;
              line-height: 1;
              white-space: nowrap;
              height: 28px;
              padding: 0px 5px !important;
              &:last-child{
                color: #fff !important;
              }
              span {
                display: inline-block;
                margin-bottom: 4px;
                svg {
                  transform: scale(0.9) translateY(1px);
                }
              }
            }
          }
          tbody {
            tr {
              td {
                border-right: 1px solid #fff;
                padding: 8px 14px;
                font-size: $fs-11;
                color: $heading-txt-color;                ;
                font-weight: normal;
                &:first-child {
                  font-weight: 500;
                  font-size: $fs-12;
                  &:after {
                    background-color: #fff;
                    content: "";
                    height: 100%;
                    position: absolute;
                    top: 0;
                    width: 1px;
                  }
                }
                &:last-child {
                  font-weight: 500;
                  color: $primary-blue-dark;
                }
              }
            }
          }
          tfoot {
            td {
              padding: 3.5px 4px!important;
              line-height: 1;
            }
          }
        }
      }
    }
    g[class*="dataset-axis-name"] text {
      font-size: $fs-10 !important;
      font-weight: 500 !important;
      fill: $secondary-black;
    }
    g[class*="-dataset-axis"] text {
      font-size: $fs-10 !important;
      font-weight: 500 !important;
      // fill: $secondary-black;
    }
    g.raphael-group-UjxezPnO,
    g.raphael-group-DInkRdQq {
      display: none;
    }

    // css to remove fusion chart logos if one want

    // .fusion_chart_wrapper {
    //   svg > g[class^="raphael-group-"] {
    //     display: none;
    //   }
    //   svg > g[class$="-parentgroup"] {
    //     display: block;
    //   }
    //   svg > g[class$="-legend"] {
    //     background: red;
    //   }
    // }
  }

  .TabSelector_wrapper {
    height: 25px;
    & > div {
      min-width: 33px;
    }
    .selected {
      color: $primary-blue-dark;
      svg > path {
        stroke: none !important;
        fill: currentColor;
      }
      svg {
        .cls-lineraph {
          stroke: none !important;
        }
      }
    }
  }
  //   .fusion_chart_wrapper {
  //     .fc__tooltip.fusioncharts-div > div > div:first-of-type {
  //       display: none !important;
  //   }
  //   .fc__tooltip.fusioncharts-div > div > div:last-of-type {
  //     width: 100%;
  // }
  //   }
  .fusion_chart_wrapper {
    margin-top: 1px;
    .fc__tooltip.fusioncharts-div {
      max-height: 300px;
      overflow-y: auto !important;
      z-index: 99;
      & > .tooltip_wrapper {
        flex-direction: column;
        .headingCont {
          position: static;
        }
        & > span {
          display: flex;
          justify-content: space-between;
          padding: 6px 14px;
        }
      }

      & > div {
        & > div {
          .tooltip_wrapper {
            .headingCont {
            }
          }
        }
        display: flex;
        position: relative;
        & > div:nth-child(1) {
          position: absolute;
          bottom: 8.5px;
          left: 14px;
          height: 8px;
          width: 8px;
          line-height: 8px;
          text-indent: -999px;
          background: currentColor;
        }
        & > div:nth-child(2) {
          flex: 1;
          .tooltip_wrapper {
            position: relative;
            & > span {
              display: flex;
              justify-content: space-between;
              padding: 6px 14px;
              border-bottom: solid 1px $secondary-blueish-grey;
              padding-left: 31px;
              font-size: $fs-10;
              color: $primary-grey-light;
              font-weight: 500;
              label {
                display: block;
                max-width: calc(100% - 50px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              b {
                font-weight: bold;
              }
            }
          }
        }
        &:last-of-type > div {
          .tooltip_wrapper {
            span {
              border-bottom: 0;
            }
          }
        }
      }
      & > div:not(:first-of-type) {
        & > div {
          .tooltip_wrapper {
            .headingCont {
              display: none;
            }
          }
        }
        & > div:nth-child(2) {
        }
      }
    }

    & > .fc__tooltip.fusioncharts-div {
      & > div {
        background: $secondary-black;
        .tooltip_wrapper {
          .headingCont {
            display: none;
          }
        }
        &:first-of-type {
          .tooltip_wrapper {
            .headingCont {
              display: block;
            }
          }
        }
      }
    }
  }
  &.s3-resources {
    .grand_total_column { 
      min-width: fit-content;
      .card_table_child{
        padding: 7px 14px;
    }
      max-height: 53px;
      padding: 0 10px;
      label{
        color: $heading-txt-color;
        font-size: $fs-10;
      }
      p {
        font-weight: 700;
      }
    &:last-child {
      label {
          text-align: center;
      }
  }
    }
    .alert_wrapper{
      .savings_wrapper {
          padding: 10px 0;
          overflow: hidden;
          overflow-x: auto;
      }
    }
  }
}
.tooltip_wrapper {
  & > span {
    font-size: $fs-10;
    color: $primary-grey-light;
    font-weight: 500;
    b {
      font-weight: bold;
    }

    label {
      display: block;
      max-width: calc(100% - 50px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.billing_summary_wrapper.s3GraphCont {
  margin: 20px 0;
}
.Graph_table_wrapper.box-styling {
  box-shadow: none;
  padding: 0;
}

.highchart.s3_highlights_container {
  background: #F6FAFF;
  padding: 20px 0;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    background: #F6FAFF;
    left: -19px;
    top: 0;
    width: calc(100% + 38px);
    height: 100%;
    z-index: 0;
    border: solid 1px #e2eaef;
  }
  & > * {
    position: relative;
    z-index: 1;
  }
  .header {
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    .TabSelector_wrapper {
      // height: 30px;
      div {
        background: $primary-white;
        color: $primary-grey-light;
        height: 30px;
        border-color: #e2edf7;
        &:first-of-type {
          border-right: solid 1px #e2edf7;
        }
        &:first-of-type.selected {
          // border-right: 0;
        }
        &:last-of-type.selected {
          border-left: 0;
        }
      }
      .selected {
        background: $primary-white;
        color: #1c50bc;
      }
      .selected svg .cls-pie {
        stroke: #1c50bc !important;
      }
    }
    h3 {
      font-size: $fs-16;
      margin: 0;
      padding-bottom: 00px;
      font-weight: bold;
      color: $secondary-black;
    }
  }
}

.s3GrandTotal_container {
  position: relative;
  .react-tabs {
    padding: 0 20px;
  }

  .table_header_download {
    margin: 20px 0 !important;
    margin-bottom: 10px !important;
    p{
      font-weight: bold !important;
      color: $secondary-black;
    }
  }
  .section-heading {
    padding-bottom: 0 !important;
  }
  .table_container_wrapper {
    padding-top: 0;
    overflow-x: auto;
    border-radius: 4px;
    table {
      color: $secondary-black;
      thead {
        th {
          font-size: $fs-12 !important;
          font-weight: 500 !important;
          color: $heading-txt-color;
          padding:10px 14px !important;
          line-height: 1;
          white-space: nowrap;
          border: solid 1px $primary-white;
          border-right: 0;
          border-left: 0;
          &:first-of-type {
            border-left: solid 1px $secondary-blueish-grey;
          }
          &:last-of-type {
            border-right: solid 1px $secondary-blueish-grey;
          }
          span {
            svg {
              transform: scale(0.9) translateY(1px);
              display: inline-block;
              margin-bottom: 4px;
            }
          }
        }
      }
      tbody {
        border-radius: 2px !important;
        tr {
          border: solid 1px $secondary-blueish-grey;

          td {
            border-right: 0;
            padding: 8px 14px !important;
            font-size: $fs-11;
            white-space: nowrap;
            // border: solid 1px $secondary-blueish-grey;
            color: #21262b;
            &:first-child {
              font-weight: 500 !important;
              font-size: $fs-12;
            }
            &:last-child {
              font-weight: normal !important;
              color: #21262b;
            }
          }
        }
      }
      tfoot {
        td {
          padding: 11px 14px !important;
          line-height: 1;
        }
      }
    }
  }
}
.section-heading {
  font-size: $fs-16;
  margin: 0;
  font-weight: bold;
  color: $secondary-black;
}

.highlight_card {
  width: 25%;
  // height: 282px;
  border-radius: 4px;
  box-shadow: -7px 14px 38px 0 rgb(52 58 64 / 8%);
  border: solid 1px $secondary-blueish-grey;
  background-color: $primary-white;
  margin: 0 5px 0px;
  .fusion_chart_wrapper {
    overflow: hidden;
    height: 100%;
  }
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
  .heading {
    display: flex;
    align-items: center;
    // box-shadow: 0 3px 6px 0 rgb(139 153 187 / 12%);
    border-bottom: solid 0.5px $secondary-blueish-grey;
    height: 30px;
    h5 {
      margin: 0;
      // padding-left: 14px;
      color: $secondary-black;
      letter-spacing: -0.24px;
      font-size: $fs-12;
      font-weight: 600;
    }
  }
  .table_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0px !important;
    .table_body {
      flex: 1;
      padding: 5px 15px;
      max-height: 260px;
      overflow-y: auto;
      min-height: 260px;
      position: relative;
      .table_row {
        display: flex;
        justify-content: space-between;
        padding: 7px 0;
        border-bottom: dashed 1px #cfdde5;
        line-height: 1;
        position: relative;
        &::before {
          position: absolute;
          content: "";
          width: 4px;
          height: 4px;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          box-shadow: 1px 1px 2px 0 rgb(51 122 183 / 16%);
          background-color: $primary-sky-blue;
        }
        &:last-child {
          border: none;
        }
        .label {
          flex: 1;
          position: relative;
          font-size: $fs-10;
          line-height: 1;
          font-weight: 500;
          // padding-left: 11px;
          max-width: 220px;
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $common-grey;
          padding-left: 1.3em;
          padding-bottom: 0px;
          text-align: left;
        }

        .value {
          font-size: $fs-10;
          line-height: 1;
          color: $primary-blue-dark;
          font-weight: bold;
        }
      }
    }
    .no_data_highlight{
      vertical-align: middle;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 700;
      font-size: $fs-12;
      color: $primary-blue-dark;
    }
    .table_footer {
      border-top: solid 1px $secondary-blueish-grey;
      background-color: $primary-blue-dark;
      display: flex;
      justify-content: space-between;
      padding: 0px 15px;
      line-height: 29px;

      border-radius: 0 0 4px 4px;
      .footer_label {
        color: $primary-white;
        font-size: $fs-11;
        font-weight: normal;
        margin-right: auto;
      }
      .total_value {
        color: $primary-white;
        font-size: $fs-11;
        font-weight: bold;
      }
    }
  }

  .highlight_chart {
    position: relative;
    width: 357px;
    height: 230px;
    text-align: left;
  }
}
.table_header_container {
  width: 100%;
  .table_subheading {
    display: flex;
    align-items: center;
    padding: 10px 0;
    padding-bottom: 19px;
    h3 {
      font-size: $fs-16;
      margin: 0;
      padding-bottom: 10px;
      font-weight: bold;
      color: $secondary-black;
    }
  }
  // .alert_wrapper {
  //   position: relative;
  //   height: 74px;
  //   margin-bottom: 15px;
  //   border-radius: 4px;
  //   border: solid 1px #cfdde5;
  //   background-color: #f6faff;
  //   .savings_wrapper {
  //     margin-left: 140px;
  //     display: flex;
  //     height: 100%;
  //     align-items: center;
  //     position: relative;
  //     padding-left: 0;
  //     overflow: auto;
  //   }
  // }
  // .alert_wrapper::before {
  //   content: url("../../../../Assets/images/grand_total.svg");
  //   position: absolute;
  //   left: -1px;
  //   top: -1px;
  // }
}

// .grandtotal_last:last-child {
//   display: none !important;
// }
.grand_total_column {
  min-width: 130px;
  height: 54px;
  margin: 0 10px 0 0;
  border-radius: 6px;
  border: solid 1px #d5e4ed;
  background-color: $primary-white;
  padding: 0 5px;
  text-overflow: ellipsis;
  // overflow: hidden;
  position: relative;
  div {
    padding: 0 10px;
    width: 50%;
  }
  // &:nth-last-child(0) {
  //   display: none !important;
  // }
  & > div {
    width: 50%;

    &:nth-child(2) {
      border-right: 1px solid #d5e4ed !important;
    }
  }
  label {
    margin-top: 9px !important;
    font-weight: 400;
    font-size: $fs-9;
    color: $heading-txt-color;
    padding: 0;
    margin: 0;
    display: block;
    white-space: nowrap;
    font-weight: normal;
    text-align: center;

    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    text-align: center;
    font-size: $fs-16;
    font-weight: 900;
    color: $secondary-black;
    margin: 0 !important;
  }
  .grand_total_heading {
    position: absolute;
    color: white;
    font-size: $fs-9;
    background-color: $primary-sky-blue;
    width: 50% !important;
    border-radius: 3px;
    top: -6px;
    left: 50%;
    padding: 1px 0;
    transform: translateX(-50%);
    align-items: center;
    text-align: center;
  }
}
.dollarIcon {
  font-weight: normal;
}
.Graph_table_wrapper {
  .Graph_table_heading {
    padding: 10px;
    // padding-left: 15px;
    &.withTabs {
      padding: 14px;
      font-size: $fs-15;
      font-weight: 700;
    }
    .dataTransferHeading{
      font-size: $fs-16;
      line-height: 36px;
    }
  }
  &.box-styling{
    tfoot{
      td{
        border-top: solid 1px #fff !important;
        border-right: solid 1px #fff !important;
        background: #F9FBFC;
        color: $primary-blue-dark;
      }
    }
  }
}

button.downloadBtn.disable {
  pointer-events: none;
  opacity: 0.7;
}

button.downloadBtn {
  border: solid 0.01px #138813;
  background-color: $accent-green-dark;
  width: 44.8px;
  height: 23px;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s;
  padding: 0 !important;
  margin-left: auto;
  font-size: $fs-12;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    box-shadow: -6px 6px 7px 0 rgb(73 175 73 / 22%);
  }
  span {
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  span.xcelIcon {
    width: 60%;
  }
  span.xcelDownloadIc {
    width: 40%;
    border-left: solid 1px #138813;
  }
}

button.download_as_pdf {
  position: fixed;
  right: 60px;
  bottom: 160px;
  z-index: 9;
  color: $primary-white;
  background: $primary-blue-dark !important;
}
span#chartobject-214 {
  padding-right: 10px !important;
}


@media screen and (max-width: 1367px) {
  .ckAdwisor-widget-container {
    .widgetInner {
      p {
        padding: 0 10px;
      }
    }
    
    // .advisorBtnCont{
    //   margin-top: 0;
    //   padding-bottom: 4px;
    // }
  }
  .s3-dashboard-container .s3GraphCont g[class*=-dataset-axis] text{
    font-size: $fs-9 !important;
  }

}

// .alert_wrapper::before {
//  content: url("../../../../../Assets/images/grand_total.png");
//   position: absolute;
//   left: -1px;
//   top: -1px;
// }

.alert_wrapper::after {
  content: "";
   position: absolute;
   left: 10px;
   top: 10px;
   height: 50px;
   width: 80px;
   background-color: #46b346;
   z-index: 9;
 }

 .graphHeaderRadioChildClass{
  .MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 12px;
    color: #737373;
    font-weight: 400;
  }

  label.MuiFormControlLabel-root {
    margin-right: 8px !important;
  }

  label.MuiFormControlLabel-root span.MuiRadio-root.MuiRadio-colorPrimary.Mui-checked{
    color: #0A3CA2 !important;
  }

  label.MuiFormControlLabel-root span.MuiRadio-root.MuiRadio-colorPrimary{
    padding: 0 4px !important;
  }

  label.MuiFormControlLabel-root span.MuiRadio-root{
    padding: 0px 6px !important;
  }
}

.graphHeaderRadio{
  margin-bottom: 0px !important;
  .option-wrapper{
    column-gap: 6px !important;
  }
}

.graphHeaderRadioParent{
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-right: 16px;
  border-right: 1px solid #D9D9D9;
  padding-right: 8px;
}


@media screen and (min-width: 1600px) {
  .download_card .month_wrapper .cardDate span{
    font-size: $fs-12;
  }
  .download_card .download_card_footer > div{
    font-size: $fs-14;
  }
  .s3-dashboard-container .s3GraphCont g[class*="-dataset-axis"] text, 
  .s3-dashboard-container .tabPanel g[class*="-dataset-axis"] text{
    font-size: $fs-12 !important;
  }
  .highlight_card .table_container .table_body {
    max-height: 280px;
    min-height: 280px;
    .table_row {
      .label,.value{
        font-size: $fs-12;
      }
    }
    .table_footer{
      .footer_label,.total_value{
        font-size: $fs-13;

      }
    }
  }
  .highlight_card .heading {
    height: 35px;
    h5{font-size: $fs-13;}
  }
  .grand_total_column{
    height: 60px;
  }
  .s3GrandTotal_container .table_container_wrapper table tbody tr td,
  .s3GrandTotal_container .table_container_wrapper table thead tr th{
    font-size: $fs-13 !important;
  }

}

// specific to Cudos Data transfer : 

.table-container{
    .react-tabs{
           padding: 0px;
           margin-top: 13px;
       }
   }
   
   .dataTransferheading{
       font-size: $fs-16;
       font-weight: 700;
       line-height: 18.75px;
   }
   
   .dataTransferIcon{
       position: relative;
       bottom: 73px !important;
   }
   
   .dataTransfer.noTableData{
       min-height: 60vh;
   }
   
   .dataTransfer{
       padding-top: 0px;
       min-height: 100px;
       box-shadow: none;
       margin-bottom:6px;
       .grand_total_column {
           min-width: unset;
           .card_table_child{
               padding: 7px 14px;
               min-width: fit-content;
           }
           label{
               margin-top: 0 !important;
               color: $heading-txt-color;
               text-align: left;
               font-size: $fs-9;
           }
           > div:nth-child(2){
               border-right: 0 !important;
           }
           &:last-child {
               label {
                   text-align: center;
               }
           }
       }
       .grand_total_column {
           max-height: 52px;
           padding: 0;
       }
   
       .grand_total_column div {
           padding: 0;
           width: 100%;
                   }
       .cardlabel, .cardlabelRegion{
           position: absolute;
           font-size: $fs-9 !important;
           border-radius: 2px;
           background-color: $primary-sky-blue;
           top: -9px;
           color: white;
           padding: 0px 2px;
       }
   
       .cardsTable{
           display: flex;
      
        .cardsTable1{
       border-right: 1px solid #D5E4ED;
       position: relative;
        }  
      
       }
   
       .savings_wrapper{
           .grand_total_column {
               &:last-child{
                   min-width: 172px;
                   min-height: 52px;
                   display: flex;
                   flex-direction: column;
                   justify-content: center;
                   }
           }
          
       }
   
       .savings_wrapper{
           margin-left: 152px;
           padding: 12px 0 9px;
           overflow: hidden;
           overflow-x: auto;
       }
       .alert_wrapper {
           max-height: 74px;
       }
   }

   .alert_wrapper::before {
    content: url("../../Assets/images/grand_total.png");
     position: absolute;
     left: -1px;
     top: -1px;
   }

   .renderToggleClass{
    margin-right: 8px;
   }
   