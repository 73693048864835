.ck-cudos-wrapper {
  * {
    font-family: Inter !important;
  }
  .Component_header_wrapper {
    border-bottom: none;
    padding: 16px 12px 8px;
    .billing_console_header {
      padding: 0 0 8px;
    }
  }
  .cards-graph-table {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin: 2px 12px;
    .billing_summary_wrapper.s3GraphCont {
      margin: 0 !important;
      .Graph_table_wrapper {
        border: none;
      }
      .Graph_table_wrapper .data_renderer {
        height: auto !important;
        min-height: 350px !important;
      }
      .Graph_table_heading {
        padding: 15px;
        padding-right: 18px;
        h3 {
          margin: 0px;
        }
        .showMoreIcon {
          position: relative;
          .more_container {
            position: absolute;
            right: 0px;
            top: 30px;
            z-index: 9;
            min-width: 152px;
            border-radius: 5px;
            background: #fff;
            padding: 8px 6px;
            box-shadow: 0px 2px 6.3px 0px #5a627280;
            .option {
              font-size: 12px;
              padding: 6px 10px;
              font-weight: 500;
              color: #2b2b2b;
              line-height: 1;
              &:hover {
                background-color: #e6ecf1;
                border-radius: 3px;
              }
            }
            .option[disabled] {
              pointer-events: none;
              opacity: 0.7;
            }
          }
          .more-icon {
            width: 4px !important;
            height: 24px !important;
            display: inline-block;
            color: #b9b9b9;
            &.active,
            &:hover {
              color: #4698d3;
            }
          }
          .expand-icon {
            width: 16px !important;
            height: 16px !important;
          }
        }
      }
      .more-icon {
        height: 22px !important;
        color: #737373;
      }
      .no_data {
        min-height: 346px !important;
      }
    }
    .table-wrapper {
      width: 100%;
      margin-top: 0 !important;
      box-shadow: 0px 2px 5px 0px #26334d08;
      border-radius: 5px;
      border: none;
    }
    &.elasticache {
    }
  }
  .tabV2_wrapper {
    margin: 0px 0 9px 12px;
    overflow-x: auto;
  }
  .doughnut-chart-wrapper {
    .ri-coverage-donut-chart-wrapper {
      justify-content: space-between;
    }
    .ri-coverage-description {
      width: 70%;
      margin: 0;
      & > .flex {
        justify-content: space-around;
      }
    }
    .bottom-container {
      .overflow-auto {
        height: 160px !important;
      }
    }
  }
  .table-wrapper {
    tr {
      &:hover {
        background-color: #e4f4fb !important;
      }
    }
    .table-header-top {
      margin: 0;
      padding: 12px !important;
      border-bottom: 1px solid #E6ECF1;
    }
    .amazon_relation_table_wrapper {
      margin-top: 6px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      .table_wrapper.cost_explorer {
        thead{
          th{
            &:last-child{
              position: sticky;
              top: 0;
              right: -0.5px;
              background-color: inherit;
              box-shadow: -4px 0px 4px 0px #0C477E2B;
              &:after{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 1px;
                background-color: #ffffff;
              }
            }
            &:nth-last-child(2) {
              border-right-width: 0;
            }
          }
        }
        tbody {
          tr {
             background-color: #ffffff;
            &:nth-child(even) {
              background-color: #f8f7fc;
              &:hover {
                background-color: #e4f4fb;
              }
            }
            td {
              border-bottom: 1px solid #e6ecf1;
              border-right: 1px solid #e6ecf1;
              color: #2b2b2b !important;
              &:last-child {
                border-right: 1px solid #e6ecf1;
                position: sticky;
                top: 0;
                right: -0.5px;
                background-color: inherit;
                color: #151E33 !important;
                  font-weight: 600 !important;
                &:after{
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 100%;
                  box-shadow: -4px 0px 4px 0px #0c477e59;
                  width: 1px;
                }
              }
              &:first-child {
                border-left: 1px solid #e6ecf1;
                font-weight: 500 !important;
              }
            }
          }
        }
        tfoot {
          td {
            background-color: #ecf5fb;
            padding: 6px;
            color: #2b2b2b;
            font-weight: 600;
            font-size: 11px;
            &::after {
              content: none;
            }
            &:last-child {
              text-align: right;
              padding-right: 4px;
              border-bottom-right-radius: 5px;
              border-left: 1px solid #e6ecf1 !important;
            }
            &:first-child {
              border-bottom-left-radius: 5px;
            }
          }
        }
      }
    }
  }
  .ck-cudos-no-data {
    width: calc(100% - 24px);
    margin: 12px;
    background: #ffffff;
    border-radius: 4px;
    height: calc(100vh - 220px);
  }

  button.downloadxBtn{
    &.download-disabled-icon{
      border-color: #898a90 !important;
      .xcelIcon{
        svg{
          g{
            path:last-child{
              fill: #898a90;
            }
          }
        }
      }
      .xcelDownloadIc{
        border-color: #e1e1e1;
      }
    }
  }

  .infobox_style{
    &.cudos-info-box{
      .info_style{
        svg{
          margin-left: 0;
        }
      }
      .message_style{
        color: #2B2B2B;
        font-size: 11px;
        font-weight: 500;
      }
    }
  }
}
.cudos-region-select-wrapper {
  margin-bottom: 0;
  .header {
    height: 22px !important;
    margin-top: 0 !important;
    padding: 0 5px !important;
  }

  .option {
    font-size: 10px !important;
    font-weight: 400;
    padding: 2px 10px !important;
    line-height: 1.4;
  }
  .truncate{
    font-size: 10px;
  }
}
.iconSize {
  height: 12px;
  width: 12px;
}

.delete-dialog-body {
  #alert-dialog-description {
    overflow: auto;
  }
  .expanded-graph {
    min-height: 380px;
    .no_data {
      min-height: 346px;
    }
  }
  .table-wrapper {
    border: 1px solid #e6ecf1;
    border-radius: 5px;
    margin-top: 0px !important;
    overflow: auto;
    .no_data {
      font-size: 12px !important;
      font-weight: 500 !important;
    }
    thead {
      th:last-child {
        border-top-right-radius: 5px !important;
        right: -1px;
        position: sticky;
        top: 0;
        z-index: 99;
      }
      th:first-child {
        position: sticky;
        left: 0;
        top: 0;
        z-index: 99;
      }
    }

    tbody {
      tr {
        td {
          white-space: nowrap;
          z-index: 2;
          // position: sticky;
          &:last-child {
            border-right: none;
            right: -1px;
            position: sticky;
            top: 0;
            z-index: 99 !important;
          }
          &:first-child {
            border-left: none;
            position: sticky;
            left: 0;
            top: 0;
            z-index: 99 !important;
          }
        }
      }
    }

    .explorer_footer {
      td {
        background-color: #ecf5fb !important;
        padding: 6px;
        color: #2b2b2b;
        font-weight: 600;
        font-size: 11px;
        &:last-child {
          border-bottom-right-radius: 4px !important;
          position: sticky;
          right: -1px;
          bottom: 0;
          z-index: 99;
        }
        &:last-child::after {
          border: none;
        }
        &:first-child {
          border-bottom-left-radius: 4px !important;
        }
      }
      td::after {
        border-top: none;
      }
    }
    tbody {
      td {
        border-bottom: 1px solid #e6ecf1;
        border-right: 1px solid #e6ecf1;
        color: #2b2b2b !important;
        &:first-child {
          border-left: 1px solid #e6ecf1;
        }
      }
    }
  }
}

.fusion_chart_wrapper {
  .fc__tooltip.fusioncharts-div {
    .cudos_tooltip {
      .row {
        width: 100%;
        border-bottom: solid 1px #E6E6E6 !important;
        margin-left: 0;
        b{
          margin-left: auto;
          white-space: nowrap;
        }
      }
  
      .totalStyle {
        width: 100%;
        display: none;
        justify-content: space-between;
        height: 20px;
        padding: 0px 14px;
        border-radius: 4px 4px 0 0;
        align-items: center;
        letter-spacing: -0.26px;
        text-align: left;
        color: #000;
        font-size: 10px !important;
        font-weight: 500;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        border-bottom: none;
        margin: 0 !important;
      }
  
    }
  
    &>div:last-of-type {
      &>div{
        top: 8.5px;
        bottom: 0;
      }
      .totalStyle {
        display: flex !important;
      }
    }
    &>div:only-child {
      &>div{
        top: 38px;
        bottom: 0;
      }
    }
    & > div {
      & > div:nth-child(2) {
        .tooltip_wrapper {
          span {
            label {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.ck-cudos-wrapper {
  .fusion_chart_wrapper {
    .fc__tooltip.fusioncharts-div {
      min-width: 240px;
    }
  }
}

