.selection-table-wrapper {
    .selection-table {
        border: 1px solid #dddfe8;
        border-radius: 3px;

        table {
            th {
                padding: 7.5px 10px;
                background-color: #ffff !important;
                border-bottom: 1px solid #DDDFE8;

                &.column-head {
                    background-color: #E5F5FF !important;
                }
            }

            td {
                padding: 5.5px 10px;
            }

            tr {
                &:nth-child(odd) {
                    background-color: #F9F9F9;
                }
            }
        }

        .select-button {
            font-size: 11px !important;
            width: 48px;
            justify-content: left;

            &::before {
                content: "";
                width: 1px;
                height: 100%;
                background: #DDDFE8;
                position: absolute;
                left: -8px;
            }
        }
    }

    .MuiIconButton-label {
        input[type=checkbox] {
          display: none;
      
          &::before {
            content: none;
          }
        }
      }
      
      input[type="checkbox"] {
        appearance: none;
        width: 14px;
        height: 14px;
        background-color: #fff;
        border: 1px solid #999CA6;
        cursor: pointer;
        position: relative;
        display: inline-block;
        border-radius: 2px;
      
        &:checked {
          background-color: #0A3CA2;
          border-color: #0A3CA2;
      
          &::before {
            opacity: 1;
          }
        }
      
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: translate(-50%, -60%) rotate(45deg);
          opacity: 0;
        }
      
        &:disabled {
          background-color: #CBCBCB;
          cursor: not-allowed;
      
          &::before {
            border-color: #ccc;
          }
        }
      
        &:focus {
          outline: none;
        }
      }
}
