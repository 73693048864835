//primary Colors code : 
$primary-blue-dark: #0A3CA2;
$primary-blue: #0F62FE;
$primary-green: #32B437;
$primary-grey-dark: #151E33;
$primary-grey-light: #555B6C;
$primary-sky-blue: #337ab7;
$primary-white: #ffffff;
$primary-greyish-white: #E9E9E9;

//secondary color code
$secondary-black: #000000;
$secondary-blueish-grey: #E6E6E6;
$secondary-blus: #E0F1FF;
$secondary-grey: #DDDFE8;
$secondary-grey-dark: #555B6C;
$secondary-grey-light: #E6E6E6;
$secondary-blus  :#E0F1FF;
$secondary-whitish-grey : #D9D9D9;

//Accent colors
$accent-red : #D9534F;
$accent-green-dark : #49AF49;
$accent-dark-orange : #FA9000;
$accent-purple : #8558FF; 
$accent-blue-light : #48C0FF;
$accent-green-light : #AFD94F;
$accent-maroom : #CE1E76;
$accent-yellow-light: #FBE5A3;
$accent-yellow-lighter: #FFF4D6;

//New Accent Colors -- 
$accent-new-blue: #2498FE;
$accent-new-light-blue: #61DBFD;
$accent-new-light-green: #A3DC28;
$accent-new-orange: #FFA213;
$accent-new-greenish-blue: #7AF0CA;
$accent-new-yellow: #F2CB00;
$accent-new-light-red: #FF8080;
$accent-new-purple: #BB4DFF;
$accent-new-pink: #F457E8;
$accent-new-green: #1BCA23;
$accent-new-off-blue: #0A878F;
$accent-new-neon: #0AAF83;
$striped-border-color:#D5DDEC;

//Striped table
$header-background-blue : #EFF4FF;
$striped-background-grey : #F7F7F7;
$footer-background-blue : #EAF1FF;
$striped-border-color:#D5DDEC;

//Variables for sidebar
$dasboard-background : #151e33;
$submenu-bg-color: #23324e;
$dashboard-font-color : #8b99bb;
$dropdown-hover-color: #F5F5F5;

//variables for cudos
$cudos-hover-blue: #e4f4fb;
$cudos-table-background: #f8f7fc;
$cudos-border-blue :#e6ecf1;

//---------------- Miscellaneous Colors --------------------- //

$border-blueish-color:#d5e0e9; 
$muted-color:#9aa6b6;
$button-hover:#edf4fd;
$grey-color: #A5A5A5;
$label-grey-color: #525252;
$disable-grey-color: #8B8B8B;



$global-white : #FAFAFA;
$table-heading-color:#7c7d7e;
$menu-text-color:#8c92a2;

$header-shadow : 0 4px 6px 0 rgba(52, 58, 64, 0.04);
$global-shadow: -7px 14px 38px 0 rgba(52, 58, 64, 0.08);
$global-background-blue: #EFF4FF;

//Variables for sidebar
$dasboard-background : #151e33;
$submenu-bg-color: #23324e;
$dashboard-font-color : #8b99bb;

//dashboard variables
$option-list-background : #fafafa;
$modal-footer-background: #F0F6FF;

//button shadows
$filled-btn-shadow : -7px 6px 6px 0 rgba(10, 60, 162, 0.24);
$outline-btn-shadow : -3px 3px 4px 0 rgba(10, 60, 162, 0.1);
$font-family-roboto:"Roboto", sans-serif;

//border Colors 
$primary-border-color : #e6e6e6;
$grey-border: rgba(164, 177, 222, 0.30);


//texts color 
$heading-txt-color : #495057;
$heading-bg-color : #f7f7f7;
$disable-text-color : #898A90;

// *--------------------------------------- Margin and Padding constants --------------------------------------------* //

$heading-padding : 9px 28px;
$sub-heading-padding : 9px 15px;
$heading-font-size : 0.81em;
$heading-letter-spacing : -0.13px;

$heading-alignment : "left";


$text-weight : 500;
$border-radius : 4px;

//Weights  
$normal : 400;
$bold : 700;
$heavy : 900;

//Font-sizes : 
$heading : 16px;
$sub-heading : 14px;

//button shadows
$filled-btn-shadow : -7px 6px 6px 0 rgba(10, 60, 162, 0.24);
$outline-btn-shadow : -3px 3px 4px 0 rgba(10, 60, 162, 0.1);
$border-light:solid 1px #e6e6e6;
$font-family-roboto:"Roboto", sans-serif;

$common-grey: #333333;
$primary-blue-box-shadow: #0a3ca234;

// Font Sizes :-
$fs-3: 3px;
$fs-6: 6px;
$fs-8: 8px;
$fs-9: 9px;
$fs-10: 10px;
$fs-11: 11px;
$fs-12: 12px;
$fs-13: 13px;
$fs-14: 14px;
$fs-15: 15px;
$fs-16: 16px;
$fs-17: 17px;
$fs-18: 18px;
$fs-19: 19px;
$fs-20: 20px;
$fs-21: 21px;
$fs-22: 22px;
$fs-23: 23px;
$fs-24: 24px;
$fs-25: 25px;
$fs-26: 26px;
$fs-30: 30px;
$fs-32: 32px;
$fs-34: 34px;
$fs-36: 36px;
$fs-37: 37px;
$fs-38: 38px;
$fs-39: 39px;


@mixin inter-font {
    font-family: "Inter", sans-serif !important;
  }