.ck-cudos-month-cards-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  .ck-cudos-months-cards {
    flex-grow: 1;
    background: white;
    border-radius: 5px;
    position: relative;
    .trend-card {
    }
    &.monthly-cost-cards {
      background-color: #d9efdd;
      padding: 12px;
      * {
        color: #2b2b2b;
      }
      h4 {
        font-weight: 600;
        font-size: 12px;
        margin-bottom: 3px;
      }
      p {
        font-size: 10px;
        font-weight: 400;
      }
      .amount {
        margin-top: 5px;
        font-size: 17px;
        font-weight: 700;
      }
    }
    &.forcasted {
      .trend-card {
      }
    }
    .forecast-container {
      border-radius: 5px;
      margin-top: -6px;
      width: 55%;
    }

    &.month-card-container {
      background-size: cover;
      background-position: center;
    }
  }
}

.rds {
  .month-card-container {
    background-position: 50% 30% !important;
  }
  .forecast-container {
    width: 65% !important;
    .amount-container {
      gap: 10px;
    }
  }
  .amount-container {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .amount-container > div {
    margin: 0px;
  }

  &.fargate{
    .forecast-container{
      &> div{
        padding: 9px 12px;
      }
    }
  }
}
