@import "../../../styleguide/contant.scss";

.Register-mainContainer {
  background-color: #f2f2f2 !important;
  min-height: 100vh;

  .login-btn {
    margin-top: 24px !important;
  }

  .logo {
    margin: auto;
    max-width: 280px;
    min-height: 50px;
    -webkit-transform: inherit;
    transform: inherit;
    width: 100%;
  }
  .input-field-container{
    margin-top: 16px;
    &:first-child{
      margin-top: 8px;
    }
  }
}

.ck_auto_module{
  position: relative;
  .auto-module-footer{
    position: absolute !important;
    background-color: $primary-white !important;
  }
}


.Register-mainContainer.leftHTML {
  // display: flex;

  .login_wrapper {
    width: 50%;
   
  }
}

.Register-mainContainer .login_form {
  margin: auto !important;
  margin-top: 34px !important;
}

.Register-mainContainer .footer-text {
  margin-top: 25px;
}

.Register-mainContainer h1 {
  margin-bottom: 18px;
}

.accountRegister {
  color: #888a93;
  margin-top: 15px;
  text-align: center;
  font-size: 0.9em;
  width: 100%;

  .Link {
    color: $primary-blue-dark;
    font-size: 1em;
    cursor: pointer;
    font-weight: 500;
  }
}

.Register-mainContainer .formErrors {
  margin-bottom: 0px;
}

.Register-mainContainer .login_wrapper {
  padding-top: 30px !important;
}

.Register-mainContainer .input-field-container>span.text-black.block {
  font: normal normal normal #{$fs-12}/#{$fs-16} Roboto;
  letter-spacing: 0.13px;
  color: $disable-text-color !important;
}

.input-field-container.Registerpasword {
  margin-bottom: 15px;

  p.error_message {
    line-height: 1.2;
  }
}

.whiteLabeledProduct {
  position: relative;

  >* {
    font-family: 'metropolis' !important;
  }

  .login-form-wrapper {
    // width: 60% !important;

    form .remember-me-container .MuiFormControlLabel-root svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
      fill: #4398D7;
    }

  }

  .w48 {
    width: 48% !important;
  }

  .ml1 {
    margin-left: 2%;
  }

  .mr1 {
    margin-right: 2%;
  }

  .logo-container {
    padding: 0 20px !important;

    .logo {
      margin: unset !important;
      max-width: 160px !important;

      img {
        margin: unset !important;
      }
    }
  }

  .login_wrapper {
    font-family: 'metropolis' !important;
    background-color: $primary-white !important;

    h1 {
      font: normal normal bold #{$fs-21}/#{$fs-21} metropolis !important;
      color: #263E66;
      font-size: $fs-20 !important;
      font-family: 'Metropolis Semibold' !important;
    }

    .login-form-wrapper {
      width: 68%;
      .login_form .input-field-container {
        font-family: 'metropolis' !important;
  
        input {
          height: 43px !important;
          border: 1px solid #DBDBDB;
          margin-top: 14px !important;
  
          &::placeholder {
            font: normal normal #{$fs-14}/#{$fs-30} metropolis;
          }
  
          &.filled {
            font: normal normal bold #{$fs-14}/#{$fs-30} metropolis;
          }
        }
  
        span.text-black.block {
          color: $common-grey !important;
          font: normal normal normal 14px 'metropolis' !important;
        }
      }
    }

    .input-field-container.cleaBoth.pt-6 {
      padding-top: 36px;
    }

    .input-field-container.mt-6 {
      margin-top: 36px;
    }

    :first-child {
      margin-top: unset !important;
    }

    .cleaBoth {
      clear: both
    }




    .login-btn {
      font-family: 'metropolis' !important;
      font: normal normal medium #{$fs-16}/#{$fs-19} "metropolis" !important;
      background: #4398D7 !important;
      margin-top: 36px !important;
      height: 46px !important;
      margin-bottom: 16px !important;

      &:disabled {
        background: #4398D7 !important;
        color: $primary-white;
      }
    }



    .login_form {
      box-shadow: unset !important;
      font-family: 'metropolis' !important;
      width: 100% ;
      margin-top: 15px !important;
      padding: 20px;

      .subHeading {
        color: #414141;
        font-size: $fs-15;
        font-family: "metropolis" !important;
        font-weight: 200;
        margin-bottom: 47px;
      }
     .formErrors {
      margin: 0 0 6px 0 !important;
      margin-bottom: 15px !important;
     }

     .captcha-container{
      .captcha-label{
        color: $common-grey !important;
        font: normal normal normal #{$fs-14}/#{$fs-30} "metropolis" !important;
      }
      input#user_captcha_input {
        margin-top: 0;
      }
      .w48{
        width: 45% !important;
      }
      #canv,.input{
        max-width:100% ;
        height: 46px;
        border: 1px solid #DBDBDB;
      }
     }
    }

  }

  .accountRegister {
    font-family: 'metropolis' !important;
    font-size: $fs-13 !important;
    margin-top: unset !important;
    span{
      font-family: Metropolis !important;
      font-weight: 400;
      font-size: $fs-14;
      color: #707070;
    }

    .Link {
      color: #4398D7 !important;
      font-weight: 500;
    }
  }

  .login-footer {
    position: unset;
    background-color: unset;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 52px;
    padding: 18px 35px;
    z-index: 9;

    .login-left {
      font-size: $fs-14;
      color: $common-grey;
      line-height: 17px;
      font-family: metropolis !important;
      font-weight: 400;

      a {
        font-size: $fs-14;
        color: #4398d7;
        display: inline-block;
        font-family: metropolis !important;
        font-weight: 400;
      }
    }

    .login-right {
      background-color: unset;
      font-size: $fs-12;
      color: #707070;
      font-family: metropolis !important;
    }

    &.newUI-footer-bg{
      background-color: #F5F5F5 !important;
    }
  }

  @media only screen and (max-width: 1400px) {

    .login_wrapper {
      .login-btn {
        margin-top: 36px !important;
      }

      .login-form-wrapper .login_form {
        .my-5 {
          margin-top: .5rem;
          margin-bottom: .5rem;
        }

        .mt-5 {
          margin-top: .50rem !important;
        }

        .pt-5 {
          padding-top: .50rem;
        }

        .input-field-container {
          span.text-black.block {
            // height: 35px !important;
          }
        }
      }
    }
  }
  // .login_wrapper{
  //   padding: unset !important;
  // }
}

.whiteLabeledProduct>* {
  font-family: 'metropolis' !important;
}

@media only screen and (max-width: 1200px) {
  .whiteLabeledProduct {
    .login-form-wrapper {
      width: 90% !important;
    }

    .w48 {
      width: 100% !important;
    }

    .ml1 {
      margin-top: 1.5rem;
      margin-left: unset;
    }

    .mr1 {
      margin-right: unset;
    }
  }
}

.withoutHTMLContent{
  .login_wrapper {
    .logo-container{
      .logo{
        margin: auto !important ;
      }
    }
    .login_form{
      width: 471px !important;
      box-shadow: 0 2px 36px 0 rgba(160, 158, 158, 0.34) !important;
      margin: 30px auto !important;
    }
    &.assessment{
      .logo-container {
        margin-bottom: 0;
      }
      
      .login_form{
        box-shadow: none !important;
        padding: 0;
      }
      .formErrors{
        margin-bottom: 28px !important;
      }
    }
  }
}


.login_wrapper_Without_bg_without_boxshadow{
  &.withoutHTMLContent{
    .login_wrapper{
      width: 100% !important;
      .login-form-wrapper{
        width: 100% !important;
        .login_form{
          margin-bottom: 52px !important;
        }
      }
    } 
  }
}

@media only screen and (max-width: 600px) {
  .whiteLabeledProduct {
    .login-form-wrapper {
      width: 80% !important;
    }

    .Register-mainContainer .login_wrapper .login-form-wrapper .login-btn {
      margin-top: 40px;
    }
  }
  .ck_auto_module{
    position: unset;
  }
  .withoutHTMLContent{
    .login_wrapper {
      .login_form{
        width: 80% !important;
      }
    }
  }
  .signup_wrapper{
    display: flex;
    flex-direction: column-reverse;
  }
  .Register-mainContainer.leftHTML .login_wrapper{
    position: unset;
  }
  .login_wrapper:has(.auto){
    position: relative;
  }
  .login-footer.auto{
    background-color: $primary-white;
    position: absolute;
  }
}