.infinte-scroll {
   
    overflow: hidden;
    overflow-y: auto;

    &.small {
        height: 150px;
    }

    &.medium {
        max-height: 300px;
    }

    &.large {
        height: 500px;
    }
    &.full {
        height: calc(100vh - 108px);
    }
}