@import "../../../../../../../../src/styleguide/contant.scss";

.ck-cudos-highlight-cards-wrapper {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 5px;

  .ck-cudos-highlight-heading-part {
    border-bottom: 1px solid #e6ecf1;
    .ck-cudos-highlight-info-icon {
      cursor: pointer;
      svg {
        height: 13px;
        width: 13px;
      }
    }
  }

  .ck-cudos-highlight-card {
    display: flex;
    background: $primary-white;
    padding: 6px 8px;
    column-gap: 15px;

    .card-wrapper {
      background-size: cover;
      background-position: center;
      &:last-child {
        border-right: none;
      }
    }

    .no-link {
      border-right: 1px solid #e0e0e0;
      background-color: inherit;
      border-radius: none;
      flex: 1;
      padding: 4px;
    }

    .has-link {
      background-color: #e6f5ff;
      border-right: none;
      border-radius: 5px;
      padding: 4px 10px 7px;
    }

    .ck-cudos-highlight-data-link {
      display: flex;
      align-items: center;
      font-weight: 500;
      color: #4698d3;
      background-color: $primary-white;
      border-radius: $border-radius;
      width: fit-content;
      padding-right: 5px;

      .ck-cudos-highlight-label {
        background-color: $primary-white;
      }
    }

    .ck-cudos-highlight-label {
      font-size: $fs-12;
      font-weight: 400;
      color: #0c477e;
      line-height: 12px;
      background-color: #e6f5ff;
      padding: 2px 5px;
      border-radius: 2px;
      width: fit-content;
    }
  }
}
