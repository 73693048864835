@import "../../../styleguide/contant.scss";

.internalError-texts {
  width: 364px;
  height: 43px;
  margin: 10px 0 0;
  font-family: Roboto;
  font-size: $fs-16;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $primary-grey-light;
}

.internalError-button {
    outline: none;
    width: auto;
    height: 51px;
    border-radius: 6px;
    box-shadow: -6px 8px 10px 0 rgb(10 60 162 / 9%);
    border: solid 0.5px;
    background-color: #0a4ca2;
    margin: 20px 10px 0 10px;
    font-size : 14px;
    display: inline-flex;
    align-items: center;
    line-height: 1;
    padding: 0 18px;
    font-weight: normal;
    color: $primary-white;
    transition: all 0.3s ease;
    &:hover {
      text-decoration: none;
      background-color: $primary-blue-dark;
      color: $primary-white;
      box-shadow: -10px 13px 17px 0 rgba(10, 60, 162, 0.2);
    }
}

.internalError-heading {
    h3 {
      font-family: Roboto;
      font-size: $fs-20;
      font-weight: bold;
      text-align: center;
      margin: 0;
      margin-bottom: 30px;
      color: $secondary-black;
      z-index: 1;
    position: relative;
    }
  }
  .errorPageNotFound {
    min-height: 600px;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding-top: 50px;
    bottom: 0;
    background-size: cover;
    .not-foundIcon {
      margin-bottom: 30px;
      z-index: 1;
      position: relative;
  }
}
.internalError-texts {
  width: 364px;
  height: 43px;
  margin: 10px 0 0;
  font-family: Roboto;

  p{
    font-size: $fs-16;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $primary-grey-light;
  }
}
.error-button-wrapper {
  width: 100%;
  text-align: center;
  padding-bottom: 40px;
}