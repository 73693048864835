.text-field-autocomplete{
    .MuiOutlinedInput-root .MuiAutocomplete-input{
        width: 100% !important;
        margin-top: -5px !important;
        border-radius: 4px;
        border: solid 1px #dddfe8;
        &:focus {
            border-color: #0A3CA2;
            outline: none;
        }
        padding: 21.5px 15px !important
    }
    .MuiOutlinedInput-root{
        padding: 0px !important;
    }
    & .MuiInputBase-input::placeholder {
        font-weight: 400 !important;
        font-size: 12px !important;
        color: #999 !important;
    }
}

.error-border{
    .MuiOutlinedInput-root .MuiAutocomplete-input{
        border: 1px solid red !important;
    }
}

.error_message{
    width: 100%;
    margin: auto;
    margin-top: 0px;
    color: red !important;
    text-align: left;
    font-size: 10px !important;
    position: unset !important;
}

.MuiPaper-root.MuiAutocomplete-paper {
    min-width: unset !important;
    .MuiAutocomplete-option{
        font-size: 12px !important;
        font-weight: 400 !important;
    }
}