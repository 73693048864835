.draggable {
  &:not(.readOnly){
    margin-top: 15px;
    padding: 8px;
    border: 2px dashed #dddfe8;
    border-radius: 4px;
    margin-top: 6px;
    background-color: #ffffff;
    .drag_card {
      border: 1px solid #dddfe8;
      background-color: #f6f7f9;
      padding: 6px 10px;
      font-size: 12px;
      border-radius: 2px;
      &:not(.readOnly):hover {
        border: 1px solid #0a3ca2;
        background-color: #e0f1ff;
        transition: background 0.5s ease-in-out;
        cursor: move;
        .card-element {
          background: #0a3ca2;
          color: #fff;
          transition: background 0.5s ease-in-out;
          .card-button {
            color: white;
            background-color: #0a3ca2;
            border: 1px solid #0a3ca2;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
