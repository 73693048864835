.tuner-event-history-wrapper {
  .tuner-content-wrapper {
    background-color: #fff;
    margin: 0 12px;
    padding: 12px;
    .columnManager .MuiFormControl-root {
      max-width: 150px;
    }
    .table-container {
      max-height: calc(100vh - 335px);
      padding: 0;
      box-shadow: none;
      .striped-table {
        box-shadow: -7px 14px 38px rgba(52, 58, 64, 0.0823529412);
        .table {
          .table-header {
            th {
              height: 24px;
              min-width: 115px;
              position: sticky;
              top: 0;
              z-index: 2;
              &:first-child {
                left: auto;
              }
              .sorting-data {
                display: inline-block;
                height: 24px;
                line-height: 24px;
                & + span {
                  top: 6px;
                }
              }
            }
          }
          tr {
            td {
              p {
                margin: 0;
              }
              &:first-child {
                position: static;
              }
            }
          }
        }
      }
    }
    .table_configuration_wrapper {
      padding: 0;
      margin: 24px 0;
      .reset_btn_table {
        margin-top: 10px;
        margin-left: 18px;
        position: relative;
        &::before {
          position: absolute;
          left: -8px;
          content: "";
          background-color: #b6b6b6;
          width: 1px;
          height: 16px;
        }
      }
    }
    .event_history_search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .search-bar {
        position: relative;
        padding-left: 28px;
        top: 62px;
        width: 240px;

        svg {
          position: absolute;
          left: 8px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
