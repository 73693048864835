@import "../../../../styleguide/contant.scss";

//component header css

.Component_header_wrapper {
  padding: 10px 20px;
  border-bottom: 1px solid $secondary-blueish-grey;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .downArrow {
    width: 25px;
    border-left: 1px solid rgb(10 60 162 / 20%);
    padding-left: 5px;
  }

  .header_text {
    font-size: $fs-26;
    font-weight: 700;
    margin: 0 !important;
  }

  .breadcrums_wrapper {

    // padding: 8px 0;
    // padding-bottom: 5px;
    .Mui-disabled {
      background-color: #898a90 !important;
      color: #fff !important;
      font-weight: 500;
    }

    ol {
      li.MuiBreadcrumbs-separator {
        margin: 0;

        svg {
          width: 17px;
          margin: 0 5px;

          path {
            stroke: white;
            stroke-width: 0.8px;
          }
        }
      }

      li {
        color: $primary-grey-light;
        font-size: $fs-10;
        font-weight: normal;
        text-transform: unset !important;

        a.MuiTypography-root {
          color: $primary-grey-light;
          font-size: $fs-10;
          font-weight: normal;
          text-decoration: none;
          cursor: pointer;

          p {
            color: $primary-grey-light;
            font-size: $fs-10;
            font-weight: normal;
            margin: 0;

            &:hover {
              color: $primary-blue-dark;
              text-decoration: underline;
            }
          }
        }

        &:last-of-type {
          pointer-events: none;
        }
      }
    }
  }
}

//dateRangickerCSS
.daterangepicker {
  -webkit-box-shadow: -13px 11px 17px 0 rgb(15 15 15 / 11%);
  box-shadow: -13px 11px 17px 0 rgb(15 15 15 / 11%);
  border: solid 1.2px #dbdbdb;
  border-radius: 15px;
  padding: 15px;
  cursor: default;

  th.month {
    text-transform: none !important;

    select.monthselect,
    select.yearselect {
      padding: 0 !important;
      border: 0;
      width: auto;
      margin: 0 5px;
      background: transparent;
      outline: none;
    }
  }

  .drp-buttons {
    padding: 0;
    padding-top: 15px;

    .btn {
      width: auto;
      padding: 9px 15px 9px;
      line-height: 1;
      outline: none;
      cursor: pointer;
    }

    button[disabled] {
      opacity: 0.65;
      cursor: not-allowed;
    }

    .btn.applyBtn {
      color: rgb(255, 255, 255);
      background: rgb(10, 60, 162);
      border-radius: 5px;
      border: 1px solid rgb(10, 60, 162);
    }

    .btn.cancelBtn {
      color: rgb(10, 60, 162);
      border-color: rgb(10, 60, 162);
      background: rgb(255, 255, 255);
      border-radius: 5px;
      border: 1px solid rgb(10, 60, 162);
    }
  }

  td.active:not(.off.ends),
  td.active:not(.off.ends):hover {
    color: $primary-white;
    -webkit-box-shadow: -5px 6px 9px 0 rgba(51, 122, 183, 0.32);
    box-shadow: -5px 6px 9px 0 rgba(51, 122, 183, 0.32);
    background-image: -o-linear-gradient(134deg, $primary-sky-blue 85%, #338ddb 39%);
    background-image: linear-gradient(316deg, $primary-sky-blue 85%, #338ddb 39%);
  }

  td.in-range {
    color: rgb(0, 0, 0);
    box-shadow: inherit;
    background: rgb(204, 227, 246);
  }

  td.in-range:hover {
    color: rgb(0, 0, 0);
    background: rgb(204, 227, 246);
  }

  td.start-date:not(.off.ends),
  td.end-date:not(.off.ends) {
    box-shadow: rgba(51, 122, 183, 0.32) -2px 3px 6px 0px !important;
    color: rgb(255, 255, 255) !important;
    background: rgb(51, 141, 219) !important;
  }

  td.off,
  td.off.start-date,
  td.off.end-date {
    color: $primary-white;
  }
}

.daterangepicker.show-calendar .calendar-table table {
  margin-bottom: 25px;
}

.daterangepicker.show-calendar {
  .ranges {
    margin-top: 0;
    width: 100%;
    position: absolute;
    bottom: 64px;
    left: 0;

    ul {
      display: block !important;
      width: 100%;
      padding: 10px 20px;
      padding-left: 93px;
      position: relative;

      &::before {
        position: absolute;
        content: "Auto-Select:";
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: $primary-grey-light;
        font-size: $fs-12;
        font-weight: 700;
      }

      li {
        margin-right: 10px;
        cursor: pointer;
        width: 45px;
        height: 20px;
        border-radius: 6px;
        border: solid 1px $secondary-blueish-grey;
        background-color: #f5f5f5;
        font-size: $fs-11;
        text-transform: uppercase;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $primary-grey-light;
        font-weight: 700;

        &:hover {
          background-color: #eee;
        }

        &:last-of-type {
          display: none;
        }
      }

      li#customActive {
        border-radius: 6px;
        box-shadow: -4px 7px 17px 0 rgb(10 60 162 / 25%);
        border: solid 1px $primary-blue-dark;
        background-color: #eef6fe;
        color: $primary-blue-dark;
        font-weight: bold;
        padding: 0;
      }
    }
  }

  .drp-calendar {
    display: block;
    padding: 0px !important;
    width: 50% !important;
    max-width: 100%;
    border: 0;
    border-radius: 4px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 15px;
    min-width: 294px;
  }

  .drp-calendar.left {
    padding-right: 5px !important;
    border-left: 0 !important;
  }

  .drp-calendar.right {
    padding-left: 5px !important;
  }

  .drp-selected {
    display: inline-block;
    font-size: $fs-12;
    padding-right: 8px;
    font-weight: 700;
  }

  .calendar-table {
    padding: 0 !important;
    font-family: "Roboto", sans-serif;

    table {
      border-spacing: 0;
      border-collapse: separate;
      border: solid 1px $secondary-blueish-grey;
      border-radius: 4px;
      max-width: 293px;

      td:last-child,
      th:last-child {
        font-size: $fs-11;
        color: currentColor !important;
      }

      td {
        font-size: $fs-10;
        border-radius: 50%;
        font-weight: 500 !important;
        color: $secondary-black !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      td.active:not(.off.ends) {
        color: $primary-white !important;
      }

      td.disabled {
        color: #b9becc !important;
        font-weight: normal !important;
      }

      tbody {
        tr {
          padding: 2px 10px;

          &:first-of-type {
            padding-top: 10px;
          }

          &:last-of-type {
            padding-bottom: 10px;
          }
        }
      }

      tr {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%;
      }

      th,
      td {
        height: 23px;
        max-width: 23px;
        max-height: 23px;
        width: 23px;
        padding: 0;
        min-width: inherit;
        color: $primary-grey-light;
      }

      thead {
        tr {

          th.next,
          th.prev {
            padding: 0;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            color: rgb(53, 93, 177);
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            font-size: $fs-12 !important;
            background: rgb(224, 241, 255);
            border-width: 1px;
            border-style: solid;
            border-color: rgb(53, 93, 177);
            -o-border-image: initial;
            border-image: initial;
            border-radius: 50%;

            span {
              padding: 2px;
              border-color: #355db1;
              margin-top: 1px;
            }
          }

          th {
            background: transparent !important;
          }

          th.month {
            height: 23px;
            text-transform: uppercase;
            color: rgb(0, 0, 0);
            font-weight: 500;
            font-size: $fs-12 !important;
            -webkit-box-flex: 1;
            -ms-flex: 1 1 0%;
            flex: 1 1 0%;
            max-width: inherit;
            margin-top: -2px;
          }
        }

        tr:first-of-type {
          background-image: -webkit-gradient(linear,
              left bottom,
              left top,
              from(rgb(244, 244, 244)),
              to(rgb(255, 255, 255)));
          background-image: -o-linear-gradient(bottom, rgb(244, 244, 244), rgb(255, 255, 255));
          background-image: linear-gradient(to top, rgb(244, 244, 244), rgb(255, 255, 255));
          padding: 10px;
          border-radius: 3px;

          th.prev {
            padding-left: 3px !important;
            background: $primary-white !important;
          }

          th.next {
            padding-right: 3px !important;
            background: $primary-white !important;
          }

          th.next.available,
          th.prev.available {
            background: #eef6fe !important;
            border-color: rgb(53, 93, 177);
            color: rgb(53, 93, 177);
          }

          th:first-of-type,
          th:last-of-type {
            height: 20px;
            max-width: 20px !important;
            width: 20px !important;
          }
        }

        tr:last-of-type {
          padding: 4px 10px;
          border-bottom: 1px solid rgb(230, 230, 230);

          th {
            text-transform: uppercase;
            margin-bottom: 0;
            color: $primary-grey-light !important;
            background: transparent !important;
            font-size: $fs-10 !important;
            font-weight: 400 !important;

            &:hover {
              color: $secondary-black !important;
            }
          }
        }
      }
    }
  }
}

button.dateRange_pickerBtn {
  border-radius: 4px;
  border: solid 1px $secondary-grey;
  background-color: $primary-white;
  line-height: 1;
  cursor: pointer;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  font-size: $fs-11;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: $secondary-black;
  height: 30px;
  padding: 0;
  outline: none;

  label.addOn {
    background: #f0f1f5;
    width: 34px;
    height: 28px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
  }

  .dateText {
    padding: 0 10px;
    font-size: $fs-11;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
  }
}

.off.ends.available {
  color: black !important;
}

td.off {
  visibility: hidden !important;
}

td.off.ends.off.disabled {
  visibility: hidden !important;
}

td.off.disabled {
  visibility: unset !important;
}

.costExplorer_main {
  .Graph_table_wrapper {
    position: relative;
    min-height: 350px;
    padding: 0;

    .data_renderer {
      padding-top: 24px;
      padding-right: 9px;

      .switch_toggler {
        top: 7px;
      }
    }
  }

  .tag_internal_filter {
    margin-top: 15px;
  }

  #back-button {
    background: $primary-blue-dark;
    border: none;
    color: $primary-white;
    border-radius: 2px;
    margin: 8px 4px;
    padding: 2px;
    padding-left: 8px;
    cursor: pointer;
    margin-right: 8px;
  }

  .backwordArrow {
    font-size: $fs-11;
  }

  .tagList-Child {
    font-size: $fs-12;
    font-weight: 500;
  }

  .internal_tag_check_Box_wrapper {
    border-bottom: 1px solid $secondary-blueish-grey;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;

    .MuiTypography-root {
      padding: 3px;
    }

    &::after {
      width: 12px;
      height: 7px;
      background-image: url("../../../../Assets/icons/up-arrow-blue.svg");
      content: "";
      position: absolute;
      background-repeat: no-repeat;
      background-size: 80%;
      transform: rotate(90deg);
      right: 2%;
      cursor: pointer;
      bottom: 35%;
    }
  }

  .tagList {
    max-height: 250px;
    overflow-y: auto;

    & .tags:last-child .internal_tag_check_Box_wrapper {
      border-bottom: 0;
      padding-bottom: 2px;
    }
  }

  .MuiSwitch-root .MuiSwitch-switchBase {
    padding: 4px !important;

    span.MuiSwitch-thumb {
      background: #6e7383 !important;
    }
  }

  .show-filter {
    .no_data {
      min-height: 120px;
    }
  }

  .cost-quantity-dropdown {
    margin-left: 10px;
    margin-bottom: unset;

    .dropdown {
      .header.filled {
        width: 85px;
        font-size: 11px;
        padding: 5px 10px;
        height: 32px;
        margin-top: unset;
      }

      .body {
        .option {
          font-size: 11px;
          font-weight: 400;
          padding: 5px 10px;
        }
      }
    }
  }

  .fusion_chart_wrapper {
    .fc__tooltip.fusioncharts-div {
      min-width: 255px;
    }
  }
}

.costExplorer_header {

  // padding: 20px;
  // padding-top: 27px;
  .header_text {
    font-size: $fs-18;
    padding-top: 5px;
    color: $secondary-black;
  }

  .subheader_text {
    font-size: $fs-12;
    color: $primary-grey-light;
    letter-spacing: -0.24px;
    padding: 3px 0;
    padding-bottom: 0;
    font-weight: normal;
    margin: 0;
  }

  .header_buttons {
    display: flex;

    .list {
      top: calc(100% + 5px);
    }

    button {
      margin-left: 10px;
      background-color: $primary-white;
      font-size: $fs-13;
      transition: all 0.2s ease;
    }

    button.MuiButton-contained {
      box-shadow: -6px 5px 5px 0 rgb(10 60 162 / 12%);

      &:hover {
        box-shadow: -7px 6px 6px 0 rgb(10 60 162 / 24%) !important;
        font-weight: 500 !important;
      }
    }
  }

  .downArrow {
    border-left: none;
    transition: all 0.3s ease;
  }

  .rotateArrow {
    transform: rotateX(180deg);
  }

  .dropdown_container {
    position: relative;
  }

  .dropdown-wrapper {
    margin-bottom: unset;
    position: relative;

    .dropdown {
      .header {
        border: 1px solid #0a3ca2;
        height: auto;
        padding: 5px 15px;
        font-weight: 500;
        line-height: 1.75;
        font-size: 0.8em;
        margin-top: unset;

        .truncate {
          color: #0a3ca2;
        }

        svg {
          path {
            fill: #0a3ca2;
          }
        }
      }

      .header.dropdown {
        box-shadow: none;
      }

      .option-wrapper {
        position: absolute;
        width: 275px;
        right: 0;
        background: #fff;
        box-shadow: 0px 6px 16px 0px #00000014;
        padding: unset;
        border-radius: 0 0 4px 4px;

        .option {
          padding: 5px 10px;
          font-size: 12px;
          font-weight: 400;
          color: #000;
          line-height: 22px;
        }
      }
    }
  }
}

.list {
  position: absolute;
  margin: 0;
  z-index: 999;
  background: white;
  padding: 20px 0;
  min-width: 140px;
  width: calc(100% - 10px);
  box-shadow: -21px 11px 24px 0 rgb(15 15 15 / 11%);
  background-color: $primary-white;
  border-radius: 10px;
  top: 100%;
  left: 0;

  &::before {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    border: solid 1px #dbdbdb;
    top: -5px;
    left: 20px;
    border-radius: 1px;
    background: $primary-white;
    border-bottom: 0;
    border-right: 0;
  }

  p {
    margin-bottom: 10px;
    text-transform: capitalize;
    color: $primary-grey-light;
    font-size: $fs-12;
    text-align: left;
    padding: 7px 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.main_body {
  border: 1px solid $secondary-blueish-grey;
  margin: 20px;
  border-radius: 4px;
  overflow: hidden;

  .individual_items_outer_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .toogler {
      cursor: pointer;

      svg {
        display: block;
      }
    }

    .toogler.active {
      .cls-filter1 {
        fill: $primary-blue-dark;
      }

      .cls-filter2 {
        fill: $primary-white;
      }
    }
  }

  .secondary_header_wrapper {
    background-color: #f8f8f8;
    padding: 13px 18.5px;
    border-bottom: 1px solid $secondary-blueish-grey;

    .individual_items {
      margin: 0;
    }

    .more_wrapper,
    .tags_wrapper {
      position: relative;
      margin-left: 5px;

      .more {
        display: flex;
        border: none !important;
        background-color: inherit !important;
        align-items: center;

        .downArrow {
          width: 20px;
          height: 20px;
          margin-left: 3px;
        }

        .rotateArrow {
          transform: rotateX(180deg);
        }
      }

      .list {
        width: 150px !important;
        left: 1px !important;
        left: auto !important;
        border: solid 1px #dbdbdb;
        border-radius: 4px;
        // margin-top: 5px;
        z-index: 1000;
      }

      .tagDropDownList {
        width: 225px !important;
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 1000;
        fieldset {
          top: -8px;
        }
      }

      .tagDropDownList p {
        text-transform: none;
      }
    }

    .tags_wrapper {
      border-radius: 4px;
      border: solid 0.7px #cfdde5;
      background-color: $primary-white;
      margin-left: 0;

      input {
        padding: 5px 12px !important;
        font-size: $fs-12;
      }

      .MuiFormControl-root {
        padding: 0 18px;
      }

      .results_showing {
        font-size: $fs-11;
        font-weight: 500;
        color: $secondary-black;
        padding: 20px;
        padding-bottom: 8px;
        padding-top: 2px;
        margin-top: 8px;
      }

      .list {
        padding: 20px 0;

        p {
          margin-bottom: 0;
        }
      }

      .pointer {
        padding: 7px 20px;
      }

      .pointer:hover {
        background-color: #f2f2f2;
        font-weight: 500;
      }
    }

    .individual_items_wrapper {
      display: flex;
      align-items: center;

      p {
        margin: 0;
      }

      &>p {
        text-transform: capitalize;
      }

      .selected_group_by {
        padding: 5px 12px 5px 13px;
        border-radius: 4px;
        border: solid 0.5px $primary-blue-dark;
        background-color: $primary-blue-dark;
        color: $primary-white;
        margin: 0 5px 0 5px;
        font-size: $fs-11;
        font-weight: bold;
        text-align: center;
        position: relative;
        margin-right: 20px;
        display: flex;
        align-items: center;

        span.cross_icon {
          display: inline-flex;
          margin-left: 10px;
        }
      }

      .selected_group_by:after {
        right: -11px;
        top: 0;
        width: 1px;
        height: 100%;
        background: #dbdbdb;
        position: absolute;
        content: "";
      }

      .individual_items {
        padding: 5px 12px 5px 13px;
        border-radius: 4px;
        border: solid 0.7px #cfdde5;
        background-color: $primary-white;
        font-size: $fs-11;
        display: flex;
        align-items: center;
        font-weight: bold;
        text-align: center;
        color: $primary-blue-dark;
        margin-right: 5px;
        height: 25px;
        line-height: 12px;
        cursor: pointer;
        text-transform: capitalize;
      }

      .pointer {
        cursor: pointer;
      }

      .group_by {
        font-size: $fs-12;
        font-weight: bold;
        text-align: left;
        color: $secondary-black;
        margin-right: 5px;
      }
    }

    #removeCap {
      text-transform: none;
    }

    .tags_wrapper .individual_items {
      margin-right: 0;
    }
  }

  .main_internal_wrapper {
    padding: 0px 18.5px;
    background-color: $primary-white;
    padding-right: 0;
  }
}

.main_internal_wrapper {
  display: flex;

  .main_internal_left {
    min-width: 70%;
    flex: 1;
    padding-bottom: 15px;
    padding-right: 18px;
    transition: all 0.2s linear;
    overflow: hidden;

    .Graph_table_wrapper {
      &>.data_renderer {
        border: solid 1px $secondary-blueish-grey;
        border-radius: 4px;
        margin: 15px 0;
        min-height: 400px;
      }
    }
  }

  .main_internal_left.collapsed {
    min-width: 100%;
  }

  .main_internal_right {
    width: 262px;
    border-left: solid 1px $secondary-blueish-grey;
    // background-color: red;
    box-shadow: -7px 14px 38px 0 rgb(52 58 64 / 8%);
    transition: all 0.2s linear;
    transform: translateX(0px);
    overflow-y: auto;
    overflow-x: hidden;

    .filter_loader_wrapper {
      height: 500px;
      position: relative;
    }

    .filter_heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      padding-bottom: 10px;

      h5 {
        font-size: $fs-14;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: $secondary-black;
        margin: 0;
      }

      .resetButton {
        display: flex;
        align-items: center;
        box-shadow: none;
        font-size: $fs-11;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: right;
        color: $primary-blue-dark;
        margin: 0;
        margin-right: 0;
        cursor: pointer;

        span {
          margin-right: 10px;
        }

        svg {
          width: 12px;
          display: block;
          height: 12px;
        }
      }
    }
  }

  .main_internal_right.collapsed {
    opacity: 0;
    transition: all 0.2s linear;
    transform: translateX(320px);
  }

  .table_renderer {
    position: relative;
    border-radius: 4px;
    border: solid 1px $secondary-blueish-grey;
    background-color: $primary-white;
    // overflow: auto;
    min-height: 200px;

    .table_wrapper {
      overflow-x: auto;
      max-height: 500px;

      .explorer_footer {
        td {
          // background-image: linear-gradient(
          //       to bottom,
          //       $secondary-blus,
          //       #f6fbff 50%,
          //       $primary-white
          //     );
          color: $primary-blue-dark;
          font-size: $fs-12;
          background: #eef6fe;
          font-weight: 500 !important;
          padding: 7px 10px;
          white-space: nowrap;

          &:first-of-type {
            text-align: left;
            // font-size: $fs-13;
            // color: $heading-txt-color;
            // padding: 9px 8px;
          }
        }
      }
    }

    table {
      thead {

        // position: sticky;
        // left: 0;
        // background: $primary-white;
        // top: -0.5px;
        // z-index: 99;
        // &::after {
        //   position: absolute;
        //   content: "";
        //   width: 100%;
        //   height: 1px;
        //   bottom: 0.5px;
        //   left: 0;
        //   z-index: 99;
        //   background: $secondary-blueish-grey;
        // }
        th {
          font-size: $fs-12 !important;
          font-weight: 500;
          color: $heading-txt-color;
          white-space: nowrap !important;
          min-width: 90px;
          padding: 8px 10px;
          position: sticky;
          top: 0;
          border: 0 !important;
          background-color: #f8f8f8;
          text-align: center;

          span svg {
            display: unset;
            vertical-align: unset;
            height: 8px;
          }

          &:first-of-type {
            min-width: 271px;
            position: sticky;
            left: 0;
            top: 0;
            z-index: 99;
            text-align: left;

            // &::after{
            //   position: absolute;
            //   content: '';
            //   width: 1px;
            //   height: 100%;
            //   top: 0;
            //   right: -0.7px;
            //   z-index: 9;
            //   background: $secondary-blueish-grey;
            // }
          }

          &:last-of-type {
            font-size: $fs-13;
            color: $primary-blue-dark;
          }
        }
      }

      tbody {
        tr {
          td {
            font-size: $fs-11;
            color: $secondary-black;
            font-weight: 400;
            padding: 7px 10px;
            white-space: nowrap;
            text-align: center;

            &:first-of-type {
              position: sticky;
              left: 0;
              background: $primary-white;
              font-weight: 500;
              text-align: left;
              text-overflow: ellipsis;
              max-width: 270px;
              overflow: hidden;

              &::after {
                position: absolute;
                content: "";
                width: 1px;
                height: 105%;
                top: 0;
                right: -1px;
                z-index: 9;
                background: $secondary-blueish-grey;
              }

              &::before {
                position: absolute;
                content: "";
                width: 100%;
                height: 1px;
                bottom: -1px;
                left: 0;
                z-index: 9;
                background: $secondary-blueish-grey;
              }
            }

            &:last-of-type {
              font-size: $fs-12;
              font-weight: 500;
              color: $primary-blue-dark;
            }
          }
        }
      }
    }

    &.costExplorerTable {
      .table_wrapper.cost_explorer {
        table {
          thead th {
            &:after {
              position: absolute;
              content: "";
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              border: solid 1px $secondary-blueish-grey;
              border-left: 0;
              z-index: -1;
            }

            span svg {
              display: inline-block;
              height: 10px;
            }
          }

          tbody {
            tr {
              &:first-of-type {
                td {
                  border-top: 0;
                }
              }
            }
          }
        }

      }
    }
  }

  .table_header {
    display: flex;
    justify-content: flex-end;
    padding: 10px 15px;

    .Mui-disabled.downloadxBtn {
      opacity: 0.7;
    }

    .downloadxBtn {
      border: solid 0.01px #138813;
      background-color: $accent-green-dark;
      width: 44.8px;
      height: 23px;
      display: inline-flex;
      align-items: center;
      transition: all 0.3s;
      padding: 0;
      min-width: inherit;

      &:hover {
        box-shadow: -6px 6px 7px 0 rgb(73 175 73 / 22%) !important;
      }

      span {
        height: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      span.xcelIcon {
        width: 60%;
      }

      span.xcelDownloadIc {
        width: 40%;
        border-left: solid 1px #138813;
      }

      &.download-disabled-icon {
        .xcelDownloadIc {
          border-color: #e1e1e1;
        }
      }
    }
  }
}

.table_renderer {

  td,
  th {
    border: 1px solid $secondary-blueish-grey;
  }

  td:first-child,
  th:first-child {
    border-left: none;
  }

  td:last-child,
  th:last-child {
    // border-left: 2px dashed $secondary-blueish-grey;
  }
}

.minValue {
  justify-content: flex-end;
  display: flex;
  margin-right: 10px;
  padding-right: 10px;
  border-right: solid 1px #dbdbdb;
  min-width: 240px;

  label {
    font-size: $fs-11;
    align-items: center;
    display: flex;

    color: $heading-txt-color;

    svg {
      font-size: 1.1rem;
      margin-left: 3px;
      color: $primary-blue-dark;
    }
  }

  input {
    padding: 0;
    padding-left: 5px;
    width: 20%;
    text-align: center;
    margin: 0 10px;
    min-width: 50px;
    height: 25px;
    line-height: 1;
    font-size: $fs-12;
  }

  button#download_now {
    padding: 5px 9px;
    font-size: $fs-10 !important;
    height: 25px;
    min-width: initial;
  }
}

.hide-filter {
  display: none;
}

.graph_heading_options {
  display: flex !important;
  justify-content: space-between;
  margin-left: auto;

  .daterangepicker .drp-calendar.right {
    display: block !important;
  }

  &>.TabSelector_wrapper {
    min-width: 60px;
  }
}

//filterLiast Styling
.check_Box_wrapper_external.active {
  box-shadow: 0px 8px 18px -2px rgb(52 58 64 / 19%);
  border: solid 1px $secondary-blueish-grey;
  // margin: 5px;
  margin-top: -1px;
  border-radius: 4px;

  .infoIcon {
    margin-left: 7px !important;
  }

  .main_check_Box_wrapper {
    // margin-left: 9px;
    // margin-right: 9px;
    border-bottom: 0;

    .MuiListItemText-root {
      position: relative;
      width: 45%;
      word-break: break-all;
      padding-right: 10px;

      span.MuiTypography-root:after {
        width: 12px;
        height: 7px;
        background-image: url("../../../../Assets/icons/up-arrow-blue.svg");
        content: "";
        position: absolute;
        bottom: 9px;
        margin-left: 5px;
        background-repeat: no-repeat;
        background-size: 80%;
      }
    }
  }

  .show-filter {
    margin: 15px;
    margin-top: 0;
    margin-bottom: 10px;

    .MuiFormControl-root.MuiTextField-root {
      margin: 10px 0 !important;
    }

    .no_filter_added {
      font-size: $fs-10;
      font-weight: 500;
      color: $primary-blue-dark;
      margin-bottom: 10px;
      margin-top: 5px;
      padding-left: 2px;
    }

    .filterList_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .selectAll_Check {
      padding-left: 2px;

      span {
        font-weight: 500 !important;
        padding: 2px 10px;
        padding-right: 0px;

        &:last-child {
          padding-left: 8px;
        }
      }
    }

    .results_showing {
      font-size: $fs-11;
      font-weight: 500;
      color: $secondary-black;
      padding-bottom: 8px;
      padding-top: 2px;
    }
  }
}

.show-filter {
  .overflow_container {
    width: 100%;
    min-height: auto;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 8px;

    .List {
      min-height: auto;
      max-height: 250px;
      height: auto !important;

      &>div {
        &>div {
          height: auto !important;
        }
      }
    }

    &.not-resource {
      overflow-y: hidden;
    }
  }
}

.inner_checkbox_list {
  padding: 0 10px;
  padding-left: 12px;

  &:last-child {
    border-bottom: 0;
  }

  label {
    span.MuiTypography-root {
      line-height: 1.4;
      word-break: break-word;
    }

    span.MuiCheckbox-root {
      padding: 0;
      margin-right: 8px;
    }
  }
}

.filterButtons {
  width: 100%;
  margin-top: 5px;
  position: relative;
  display: flex;
  justify-content: flex-end;

  button {
    height: 24px;
    margin-left: 10px;
    font-weight: 500;
    font-size: 10px !important;
  }

  &::before {
    position: absolute;
    content: "";
    width: calc(100% + 30px);
    height: 1px;
    background: $secondary-blueish-grey;
    top: -12px;
    left: -15px;
  }
}

.check_Box_wrapper_external {
  span.MuiTypography-root {
    font-size: $fs-12;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    // line-height: 2.83;
    padding: 4px 0;
    letter-spacing: normal;
    text-align: left;
    color: #444444;
    text-transform: capitalize;
  }

  .show-filter {
    span.MuiTypography-root {
      text-transform: unset;
      font-size: 11px;
      font-weight: 400;
    }
  }

  .main_check_Box_wrapper {
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    border-bottom: solid 1px $secondary-blueish-grey;
    justify-content: space-between;

    .MuiFormControl-root {
      .MuiOutlinedInput-root {
        svg {
          width: 18px;
          height: 18px;
          margin-top: 3px;
          right: 0;
        }
      }

      div#demo-simple-select.MuiSelect-select {
        padding: 6px;
        padding-left: 0;
        padding-right: 20px;
        border: 0;
        font-size: $fs-10 !important;
        color: #939393;
        line-height: 14px;
        font-weight: normal !important;
        text-transform: capitalize;
        margin-top: 0;
      }

      fieldset {
        border: 0 !important;
      }
    }

    .MuiListItemText-root {
      cursor: pointer;
      margin: 0;
    }

    span.MuiCheckbox-root.MuiCheckbox-colorPrimary {
      transform: scale(0.9);
      padding: 0;
      margin-right: 8px;

      svg {
        path {
          // fill: #999ca6;
        }
      }
    }
  }

  .check_Box_inner_wrapper {
    display: flex;
    align-items: center;

    .iconSize {
      font-size: $fs-14;
      margin: 1px 2px 0px 4px;
    }
  }

  input {
    padding: 5px 12px !important;
    font-size: $fs-12;
  }

  &:last-of-type {
    .main_check_Box_wrapper {
      border-bottom: 0;
    }
  }
}

.tags span.MuiTypography-root {
  font-weight: 500;
}

.main_check_Box_wrapper span.MuiCheckbox-root.Mui-checked+.MuiListItemText-root span.MuiTypography-root,
.inner_checkbox_list span.MuiCheckbox-root.Mui-checked+span.MuiTypography-root {
  color: $secondary-black;
}

.MuiCheckbox-colorPrimary.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

.main_internal_left {
  .Graph_table_wrapper .Graph_table_heading.costExplorer_tabelHeader {
    border: 0;
    padding: 0;
    padding-top: 15px;

    .graph_heading_options {
      flex: 1;
      justify-content: flex-end;
      align-items: center;

      .TabSelector_wrapper {
        margin-left: 10px;
        // height: 25px;

        &>div {
          padding: 7px;
        }
      }
    }

    h3.heading.main-text-bold {
      font-size: $fs-11;
      color: $primary-grey-light;
      font-weight: 400;
    }

    .datePkr_textTabCont {
      display: flex;
      align-items: center;
      position: relative;
      border-right: solid 1px #dbdbdb;
      padding-right: 10px;

      button.dateRange_pickerBtn .dateText {
        white-space: nowrap;
      }

      .daterangepicker.show-calendar .calendar-table table {
        margin-bottom: unset;
      }

      .daterangepicker.show-calendar .ranges ul {
        display: block !important;

        li {
          &:last-child {
            display: none !important;
          }
        }
      }

      .drp-calendar.left {
        padding-right: 5px !important;
      }

      .drp-calendar.right {
        padding-left: 5px !important;
      }

      button.dateRange_pickerBtn {
        label.addOn {
          border-radius: 0 3px 3px 0;
          margin-right: -0.1px;
        }
      }

      button.dateRange_pickerBtn {
        // height: 25px;

        label.addOn {
          height: 100%;
        }
      }
    }

    .TabSelector_wrapper .selected svg path.cls-lineChart {
      stroke: none !important;
    }
  }
}

.datePkr_textTabCont {
  .daterangepicker.show-calendar {
    .ranges {
      margin-top: 0;
      width: 100%;
      position: absolute;
      bottom: 64px;
      left: 0;

      ul {
        width: 100%;
        display: flex;
        padding: 10px 20px;
        padding-left: 93px;
        position: relative;

        &::before {
          position: absolute;
          content: "Auto-Select:";
          left: 20px;
          top: 50%;
          transform: translateY(-50%);
          color: $primary-grey-light;
          font-size: $fs-12;
        }

        li {
          cursor: pointer;
          width: 45px;
          height: 20px;
          border-radius: 6px;
          border: solid 1px $secondary-blueish-grey;
          background-color: #f5f5f5;
          font-size: $fs-11;
          text-transform: uppercase;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: $primary-grey-light;

          &:hover {
            background-color: #eee;
          }

          &:last-of-type {
            display: none;
          }
        }

        li#customActive {
          border-radius: 6px;
          box-shadow: -4px 7px 17px 0 rgb(10 60 162 / 25%);
          border: solid 1px $primary-blue-dark;
          background-color: #eef6fe;
          color: $primary-blue-dark;
          font-weight: bold;
          padding: 0;
        }
      }
    }
  }

  .drp-calendar {
    width: 50% !important;
    margin-bottom: 41px !important;
  }

  .daterangepicker {
    min-width: 625px;
    z-index: 10;
  }
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiMenu-paper .MuiList-root.MuiList-padding.MuiMenu-list {
  border-radius: 4px !important;
}

// Tooltip css

.fusion_chart_wrapper {
  // margin-top: 1px;
  padding: 10px;

  .fc__tooltip.fusioncharts-div {
    max-height: 300px;
    overflow-y: auto !important;
    z-index: 99;

    &:has(.hourly-dashboard-tooltip-wrapper) {
      max-width: 400px !important;

      &>div {
        &>div {
          &:first-child {
            display: none !important;
          }
        }
      }
    }

    &>.tooltip_wrapper {
      flex-direction: column;

      .headingCont {
        position: static;
      }

      &>span {
        display: flex;
        justify-content: space-between;
        padding: 6px 14px;
      }
    }

    &>div {
      &>div {
        .tooltip_wrapper {
          .headingCont {}
        }
      }

      display: flex;
      position: relative;

      &>div:nth-child(1) {
        position: absolute;
        bottom: 8.5px;
        left: 14px;
        height: 8px;
        width: 8px;
        line-height: 8px;
        text-indent: -999px;
        background: currentColor;
      }

      &>div:nth-child(2) {
        flex: 1;

        .tooltip_wrapper {
          position: relative;

          &>span {
            display: flex;
            justify-content: space-between;
            padding: 6px 14px;
            border-bottom: solid 1px $secondary-blueish-grey;
            padding-left: 31px;
            font-size: $fs-10;
            color: $primary-grey-light;
            font-weight: 500;

            label {
              display: block;
              max-width: calc(100% - 50px);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            b {
              font-weight: bold;
            }
          }
        }
      }

      &:last-of-type>div {
        .tooltip_wrapper {
          span {
            border-bottom: 0;
          }
        }
      }
    }

    &>div:not(:first-of-type) {
      &>div {
        .tooltip_wrapper {
          .headingCont {
            display: none;
          }
        }
      }

      &>div:nth-child(2) {}
    }
  }

  &>.fc__tooltip.fusioncharts-div {
    &>div {
      background: $secondary-black;

      .tooltip_wrapper {
        .headingCont {
          display: none;
        }
      }

      &:first-of-type {
        .tooltip_wrapper {
          .headingCont {
            display: block;
          }
        }
      }
    }
  }

  .legend-container {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    position: relative;
    bottom: 25px;

    .legend {
      display: flex;
      column-gap: 7px;
      align-items: center;

      .color {
        height: 7px;
        width: 7px;
      }

      .key {
        font-size: $fs-9;
      }
    }
  }
}

.tooltip_wrapper {
  &>span {
    font-size: $fs-10;
    color: $primary-grey-light;
    font-weight: 500;

    b {
      font-weight: bold;
    }

    label {
      display: block;
      max-width: calc(100% - 50px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.graph_renderer {
  position: relative;

  .data_renderer {
    padding: 20px;
  }
}

.info_bar_container {
  position: relative;
}

.info_bar_wrapper {
  border: 1px solid #869bc3;
  background-color: #dbe6f8;
  display: flex;
  justify-content: center;
  padding: 15px 0;
  margin: 10px 0 15px;
  border-radius: 3px;

  p {
    color: #1945b7;
    font-size: $fs-14;
  }
}

ul.MuiList-root[aria-labelledby="demo-simple-select-label"] {
  padding: 10px 0;

  li {
    padding: 3px 25px;
    color: $primary-grey-light !important;
    font-size: $fs-12 !important;
    font-weight: normal !important;
  }
}

@media screen and (min-width: 1368px) {
  .main_internal_wrapper .main_internal_right {
    width: 25%;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1367px) {
  .main_internal_left.filterOpened {
    .datePkr_textTabCont {
      .daterangepicker.opensleft {
        right: 0 !important;

        &::after {
          right: 154px;
        }

        &::before {
          right: 153px;
        }
      }
    }

    button.dateRange_pickerBtn {
      .clear_selection {
        right: -125px;
      }
    }
  }

  .check_Box_wrapper_external.active .main_check_Box_wrapper .MuiListItemText-root span.MuiTypography-root {
    text-overflow: ellipsis;
  }
}

.daterangepicker {
  padding-top: 48px !important;
}

.clear_selection {
  // opacity: 0;
  display: none;
  // display: flex;
  align-items: center;
  position: absolute;
  top: 47px;
  right: 35px;
  z-index: 9999;

  p {
    color: $primary-blue-dark;
    font-size: $fs-12;
  }

  svg {
    fill: $primary-blue-dark;
    font-size: $fs-18;
    margin-left: 3px;
  }
}

.clear_selection.show {
  opacity: 1;
  display: flex !important;
  z-index: 11;
}

.no_filter_added.hidden {
  visibility: hidden;
  font-size: 4px !important;
}

.usageFooter {
  td {

    // font-weight: 500 !important;
    // font-size: $fs-12 !important;
    // color: $common-grey !important;
    // padding: 10px !important;
    &:first-child {
      // font-weight: 500 !important;
    }

    &:last-child {
      color: $primary-blue-dark !important;
    }
  }
}

.explorer_footer {
  td {
    position: sticky;
    bottom: -1px;
    background: $primary-white;
    z-index: 2;
    border: 0 !important;

    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border: solid 1px $secondary-blueish-grey;
      border-left: 0;
      border-bottom: 0;
    }

    &::before {
      // position: absolute;
      // content: "";
      // width: 100%;
      // height: 2px;
      // left: 0;
      // bottom: 0;
      // background: $primary-white;
    }

    &:first-child {
      position: sticky;
      left: 0;
      background: $primary-white;
      z-index: 99;
      // &:after {
      //   position: absolute;
      //   content: "";
      //   width: 1px;
      //   height: 105%;
      //   top: 0;
      //   right: 0;
      //   z-index: 9;
      //   background: $secondary-blueish-grey;
      // }
    }
  }
}

.usageHeading {
  font-size: $fs-13;
  font-weight: bold;
  color: $secondary-black;
  padding: 5px 0;
}

.flexHeading {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}

span.MuiSwitch-root .Mui-checked span.MuiSwitch-thumb {
  background: #144188 !important;
}

.clear_selection {
  top: 54px !important;
  right: 20px !important;
}

.table_wrapper.cost_explorer {
  border-radius: 5px !important;

  &.lens_table {
    position: relative;
    overflow: auto;

    &>table {
      position: relative;

      &>thead {
        &>th {
          padding: 2px 8px !important;
          height: 24px;
          border-right: none !important;
          border-left: 1px solid white;
          background-color: inherit;


          &:nth-child(2) {
            border-left: none !important;

          }

          &:first-child {
            position: sticky;
            left: 0px;
            z-index: 1;
            top: 0px;

            &::after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              height: 100%;
              width: 1px;
              background-color: white;
            }
          }

          &>div {
            line-height: 13.31px !important;
            font-size: 11px !important;
            font-weight: 600 !important;
            white-space: nowrap;
          }
        }

        th:last-child {
          border-left: none !important;

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 1px;
            background-color: #ffffff;
          }

        }
      }

      &>tbody {
        &>tr {
          &>td {
            padding: 4px 8px !important;
            line-height: 14px !important;
            border-right: none !important;
            border-left: 1px solid #E6ECF1 !important;
            background-color: inherit;
            white-space: nowrap;



            &:nth-child(2) {
              border-left: none !important;

            }

            &:first-child {
              position: sticky;
              left: 0px;
              z-index: 1;

              &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 1px;
                background-color: #E6ECF1;
              }
            }

            &:last-child {
              font-weight: 600 !important;
              color: #151E33 !important;
              line-height: 13.31px !important;
              border-left: none !important;


              &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                box-shadow: -3px 0px 4px 0px rgba(12, 71, 126, 0.3490196078) !important;
                width: 1px;
                background-color: #E6ECF1;
              }
            }
          }
        }
      }

      &>tfoot {
        position: sticky;
        bottom: 0px;

        td {
          padding: 2px 8px !important;
          line-height: 13.31px !important;
          height: 24px !important;
          background-color: #E4F4FB !important;

          &::after {
            border-right: 1px solid #E6ECF1 !important;
          }
        }

        td:first-child {
          padding: 2px 10px !important;
        }

        td:not(:first-child):not(:last-child) {
          z-index: 1;
          position: static;
        }

        td:last-child {
          right: 0px;
          z-index: 2;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            box-shadow: -3px 0px 4px 0px rgba(12, 71, 126, 0.3490196078) !important;
            width: 1px;
            background-color: #E6ECF1;
          }
        }
      }
    }
  }
}