@import "../../../styleguide/contant.scss";

.button.goToLogin{
  padding: 7px 42px;
  height: 35px;
  font-weight: 500;
}
.verification_expired {
  width: 550px;
  background: $primary-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 50px;
  border-radius: 4px;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  h3 {
    font-size: $fs-24;
    font-weight: bold;
    margin-bottom: 10px;
    color: $secondary-black;
    letter-spacing: 0.26px;
}
p{
  font-size: $fs-16;
  color: #525252;
  margin-bottom: 30px;
}
.goToLoginBtn{

}
}
.expired_icons {
  margin-bottom: 30px;
}
.footer.azure_activation_footer {
  background: #F7F7F7 !important;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  p,
  a {
    font-family: "Inter", "sans-serif" !important;
    color: #888888 !important;
  }
}