.optional-input-field {
    .radio-button-wrapper {
        background: #F5F6F8;

        label {
            cursor: pointer;

            &:first-child {
                border-radius: 3px 0px 0px 3px;
            }

            &:nth-child(2) {
                border-radius: 0px 3px 3px 0px;
            }
        }

        .active {
            background: #E8F1FF;
            border: 1px solid #779FF0;
            cursor: default;
        }


    }
}