.tabV2_wrapper {
    display: flex;
    // padding: 20px;
    align-items: center;

    & > :first-child {
        .tab-btn {
            border-left: 1px solid #D5DDEC;

            &.active {
                border-left: 1px solid #779FF0;

            }
        }
    }


    // .tabV2_wrapper_link {
    .tab-btn {
        font-size: 12px;
        white-space: nowrap;
        font-weight: 400;
        padding: 9px 25px;
        background-color: #fff;
        border-top: 1px solid #D5DDEC;
        border-bottom: 1px solid #D5DDEC;
        border-right: 1px solid #D5DDEC;
        color: #1C1E23;
        vertical-align: text-bottom;

        &.active {
            border: 1px solid #779FF0;
            background-color: #E8F1FF;
            color: #0A3CA2;
            font-weight: 500;
        }

        // }
    }

}

@media screen and (max-width: 1024px) {
    .tab-btn {
        padding: 9px 18px !important;
    }
  }