@import "../../styleguide/contant.scss";
@import "./Common/Table/multiLevelTable/style.scss";
@import "./Common/secondaryHeader/SwitchTab/style.scss";

.App {
  position: relative;
  min-height: 100vh;
  background-color: #363740;
  font-family: "Roboto", sans-serif;
  overflow: auto;

  * {
    font-family: "Roboto", sans-serif !important;
    outline: none;
  }

  button {
    outline: none !important;
  }

  .testing {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $accent-red;

    h3 {
      font-size: 80px;
    }

    p {
      font-size: $fs-22;
    }
  }
}

.d_flex {
  display: flex !important;
}

.p-20 {
  padding: 20px !important;
}

.p-14 {
  padding: 14px !important;
}

.mb_10 {
  margin-bottom: 10px !important;
}

.small_card_heading {
  font-size: 0.8em !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: $primary-grey-light;
  margin-bottom: 8px;
}

.small_card_heading_description {
  font-size: 1.6em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.48px;
  text-align: left;
  color: $secondary-black;
  margin: 0;
}

.dollar_cost {
  font-weight: normal;
  margin-right: 5px;
}

.btn-filled {
  // width: 100%;
  border-radius: 4px;
  box-shadow: -9px 7px 9px 0 rgba(10, 60, 162, 0.2);
  background-color: $primary-blue-dark;
  padding: 8px 12px;
  border: 1px solid $primary-blue-dark;
  font-size: $fs-12;
  color: $global-white;
  white-space: nowrap;
  cursor: pointer;
}

.btn-outline {
  background-color: white;
  border: 1px solid $primary-blue-dark;
  color: $primary-blue-dark;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: $fs-12;
  cursor: pointer;
  box-shadow: -9px 7px 9px 0 rgba(10, 60, 162, 0.2);
}

.small-text {
  font-size: 0.8em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $secondary-blus;
}

.small-text-breadcrum {
  font-size: $fs-10;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #7a7a92;
}

.main-text-bold {
  font-size: 1em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: left;
  color: $secondary-black;
}

.sub-heading-text {
  font-size: 0.8em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: $secondary-black;
}

.box-styling {
  border-radius: 4px;
  box-shadow: -7px 14px 38px 0 rgba(52, 58, 64, 0.08);
  border: solid 1px $secondary-blueish-grey;
  background-color: $primary-white;
}

.border-bottom-grey {
  border-bottom: solid 1px $secondary-blueish-grey;
}

.header_text {
  font-size: 1.5em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  text-align: left;
  color: $secondary-black;
  margin: 0;
}

.wrapper {
  padding: 25px 20px;
}

.header_wrapper .MuiButton-root {
  font-weight: 400;
  font-size: $fs-12 !important;
  height: 30px;
  box-shadow: none !important;
  padding: 3px 9px;
  border-radius: 4px;

  svg {
    margin-right: 10px;
  }
}

.MuiButton-root {
  font-size: 0.8em !important;
  text-transform: capitalize !important;
  box-shadow: $outline-btn-shadow !important;
  white-space: nowrap !important;
}

.MuiButton-contained {
  background-color: $primary-blue-dark !important;
}

.MuiButton-outlined {
  border: 1px solid $primary-blue-dark !important;
  color: $primary-blue-dark !important;
  box-shadow: inherit !important;
  height: 34px;
  padding: 6px 12px;
  box-shadow: none !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.btn_icon_style {
  margin-right: 5px !important;
  font-size: $fs-18 !important;
}

//accordion styling
.accordion_wrapper_outer .MuiAccordionDetails-root,
.accordion_wrapper_outer .MuiButtonBase-root {
  background-color: $primary-white !important;
}

.MuiPaper-root {
  margin-bottom: 8px !important;
  border-radius: 4px;
}

.Mui_accordion_wrapper .MuiButtonBase-root {
  background-color: $primary-white !important;
}

.Mui_accordion_wrapper .MuiPaper-root {
  border: none;
  margin-bottom: 10px !important;

  &:last-of-type {
    margin-bottom: 0 !important;
  }
}

.flex_center {
  align-items: center;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

::-webkit-scrollbar-thumb {
  background: #757A83 !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 10px !important;
}

#display_none {
  display: none;
}

.relative {
  position: relative;

  &.dialog-component-wrapper {
    position: fixed;
  }
}

.min_250 {
  min-height: 150px;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused {
  .MuiOutlinedInput-notchedOutline {
    border-color: $primary-blue-dark !important;
  }
}

.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: $primary-blue-dark !important;
}

.MuiButtonBase-root a,
.MuiSelect-select a {
  color: $secondary-black !important;
}

@media screen and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .pro-sidebar+.option_render {
    width: 100% !important;
  }
}

.MuiSwitch-root {
  .MuiSwitch-switchBase {
    color: #051e51 !important;
    padding: 9px;
  }

  margin-left: 2px;
  margin-right: 2px;

  span.MuiSwitch-thumb {
    color: #051e51;
    background-image: linear-gradient(158deg, #1c50bc 14%, #051e51 85%);
  }

  span.MuiSwitch-track {
    background: #c3d6ea !important;
    opacity: 1 !important;
  }
}

.MuiOutlinedInput-root {
  fieldset {
    border-color: $secondary-blueish-grey !important;
  }

  &:hover {
    fieldset {
      border-color: $secondary-blueish-grey !important;
      border-width: 1px !important;
    }
  }
}

.MuiFormControl-root {
  position: relative;

  .MuiInputLabel-formControl.Mui-error {
    color: red !important;
  }

  .MuiInputLabel-formControl.Mui-error+.MuiOutlinedInput-root {
    fieldset {
      border-color: red !important;
    }
  }

  .MuiOutlinedInput-root.Mui-error {
    fieldset {
      border-color: red !important;
    }
  }

  &>.MuiInputLabel-root {
    color: $disable-text-color;
    padding: 0 !important;
    font-size: $fs-12;
    top: -5px;
  }

  &>.MuiInputLabel-root.MuiFormLabel-filled,
  &>.MuiInputLabel-root.Mui-focused {
    font-size: inherit;
    padding: 0 5px !important;
    font-weight: 300;
    text-align: left;
    color: $primary-blue-dark;
    font-size: $fs-13;
    background: $primary-white;
    line-height: 1;
    top: 0;
    left: -3px;
  }

  &>.MuiInputLabel-root.Mui-focused {
    color: $primary-blue-dark;
  }

  fieldset.MuiOutlinedInput-notchedOutline {
    border: solid 1px $secondary-blueish-grey !important;
    border-radius: 4px !important;
    border-color: $secondary-blueish-grey !important;

    legend {
      display: none;
    }
  }

  .Mui-focused {
    fieldset.MuiOutlinedInput-notchedOutline {
      border: solid 1px $secondary-blueish-grey !important;
      border-radius: 4px !important;
      border-color: $secondary-blueish-grey !important;
    }
  }

  .MuiOutlinedInput-input.MuiInputBase-input {
    padding: 14px 15px !important;
    opacity: 1;
    margin-top: 3px;
  }

  .MuiOutlinedInput-input.MuiInputBase-input::-webkit-input-placeholder {
    color: $secondary-black !important;
    font-size: $fs-12;
  }

  .MuiOutlinedInput-input.MuiInputBase-input::-moz-placeholder {
    color: $secondary-black !important;
    font-size: $fs-12;
  }

  .MuiOutlinedInput-input.MuiInputBase-input:-ms-input-placeholder {
    color: $secondary-black !important;
    font-size: $fs-12;
  }

  .MuiOutlinedInput-input.MuiInputBase-input:-moz-placeholder {
    color: $secondary-black !important;
    font-size: $fs-12;
  }
}

.MuiFormControl-root.MuiTextField-root {
  textarea {
    border: 0 !important;
    color: $disable-text-color;
    font-size: $fs-12;
  }
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper {
  box-shadow: inherit !important;
  border-radius: 4px !important;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%) !important;
  overflow: hidden;

  ul.MuiList-root {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

label.MuiFormControlLabel-root {
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    width: 15px;
    height: 15px;
  }

  span.MuiRadio-root.MuiRadio-colorPrimary.Mui-checked {
    color: #0075ff;
  }
}

.css-1arijsr-MuiSnackbar-root {
  top: 76px !important;
  right: 12px !important;
}

.MuiSnackbar-root.MuiSnackbar-anchorOriginTopRight {
  top: 76px !important;
  right: 12px !important;
}

// .show{
//   display: block;
// }

// .hide{
//   display: none;
// }

.option_wrapper .back-to-top {
  visibility: hidden;
  opacity: 0;
  transition: all 1s;
}

.option_wrapper.show .back-to-top {
  visibility: visible;
  opacity: 0.8;
  transition: all 0.3s;
}

span.MuiInputLabel-asterisk.MuiFormLabel-asterisk {
  color: #d8197f;
}

.MuiRadio-root.Mui-checked+.MuiTypography-root {
  color: $primary-sky-blue !important;
  font-weight: 500;
}

.MuiButtonGroup-root.MuiButtonGroup-outlined {
  button {
    &:nth-child(2) {
      margin-left: -1px !important;
      margin-right: -1px !important;
    }
  }

  button.selected {
    border-color: #d0d9f0 !important;
    position: relative;
    z-index: 1;
  }
}

.asterisk {
  color: #d8197f;
}

.emailTableChips.MuiBox-root {
  margin: 0;
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-adornedEnd {
  fieldset.MuiOutlinedInput-notchedOutline {
    border-color: $primary-blue-dark !important;
  }

  .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
    height: 30px;
    background: $primary-blue-dark;
    color: $primary-white;
    // border-top: solid 2px $primary-blue-dark;
    // border-bottom: solid 1px $primary-blue-dark;
    border-radius: 0 4px 4px 0;
    padding-left: 5px;

    button {
      padding: 0px !important;

      svg {
        color: $primary-white;
        padding: 3px;
        width: 22px !important;
        height: 22px !important;
        border-radius: 50%;
        border: solid 1px;
        margin: 2px;
      }
    }

    button.saveIconBtn {
      svg {
        background: $primary-white;
        color: $primary-blue-dark;
      }
    }
  }
}

.month-dropdownCont {
  position: relative;

  &>label {
    font-weight: 300;
    z-index: 9;
    left: 10px;
    padding: 2px 5px;
    top: -7px;
    line-height: 1;
    text-align: left;
    color: $primary-blue-dark;
    position: absolute;
    font-size: $fs-11;
    background: $primary-white;
  }

  .custom_selectbox {
    padding: 8px 8px 6px;
    font-size: 12px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
    background: $primary-white;
    min-width: 158px;
    padding-right: 5px;
    border-right: 10px solid transparent;
    outline: solid 1px #dddfe7;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;

    option {
      // font-size: $fs-14;
      background: $primary-white;
      padding: 10px 0;
      display: block;
    }
  }

  &:after {
    content: "";
    background: url(../../Assets/icons/dropdown_arrow.svg) no-repeat;
    background-size: auto;
    position: absolute;
    right: -4px;
    width: 20px;
    height: 10px;
    top: 18px;
    pointer-events: none;
    transform: translate(-50%, -50%);
  }
}

// .Mui-disabled {
//   color: $primary-white !important;
//   opacity: 0.7 !important;
// }

.error_box {
  border: 1px solid red !important;
}

.disable {
  pointer-events: none;
}



button.downloadxBtn {
  border: solid 0.01px #138813 !important;
  background-color: $accent-green-dark !important;
  width: 44.8px;
  height: 23px;
  min-width: inherit;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s;
  padding: 0;
  min-width: inherit;

  &:hover {
    box-shadow: -6px 6px 7px 0 rgb(73 175 73 / 22%) !important;
  }

  span {
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  span.xcelIcon {
    width: 60%;
  }

  span.xcelDownloadIc {
    width: 40%;
    border-left: solid 1px #138813;
  }
  &.ck-excel-download--large {
    width: 58.8px;
    height: 35px;
  }

  &.download-disabled-icon{
    border-color: #898a90 !important;
    .xcelIcon{
      svg{
        g{
          path:last-child{
            fill: #898a90;
          }
        }
      }
    }
    .xcelDownloadIc{
      border-color: #e1e1e1;
    }
  }
}

// Toasts styles

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiAlert-root {
  border-radius: 4px !important;
  padding: 10px 20px;
  min-width: 300px;
  align-items: center;

  .MuiAlert-icon {
    margin-right: 15px;
    font-size: $fs-25;
    width: 27px;
    height: 27px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    svg {
      color: $primary-white;
      display: block;
      width: 15px;
      height: 15px;
    }
  }

  &.MuiAlert-filledSuccess {
    box-shadow: -10px 8px 15px 0 rgba(50, 180, 55, 0.17);
    border: solid 1px $primary-green;
    background-color: #fcfffc;
    color: $primary-grey-light;

    .MuiAlert-icon {
      background: $primary-green;
      box-shadow: -5px 4px 8px 0 rgba(50, 180, 55, 0.17);
    }
  }

  &.MuiAlert-filledError {
    box-shadow: -10px 8px 15px 0 rgba(247, 114, 115, 0.17);
    border: solid 1px #f77273;
    background-color: #fffafa;
    color: $primary-grey-light;

    .MuiAlert-icon {
      background: #ff2024;
      box-shadow: -5px 4px 8px 0 rgb(247 114 115 / 17%);
    }

    svg.MuiSvgIcon-root {
      color: $primary-white;
    }
  }
}

.no_data_found {
  color: $primary-blue-dark !important;
  font-weight: 700 !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: $fs-12;
}

.no_data {
  padding: 50px;
  text-align: center;
  font-size: $fs-12;
  font-weight: 500;
  color: $primary-blue-dark;
}

#total-wrapper {
  align-items: center;
}

.data_not_available {
  padding: 50px;
  text-align: center;
  font-size: $fs-14;
  font-weight: 500;
  color: $primary-blue-dark;
  margin: 50px 0;
  margin-top: 15px;
  box-shadow: 0 7px 7px 0 rgb(0 0 0 / 6%);
  background-color: $primary-white;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid $secondary-blueish-grey;
  min-height: 300px;
  position: relative;

  p {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

#dropdownMenu1 {
  &:has(.disabled) {
    .dropdown {
      color: $primary-grey-light !important;
      font-weight: 500;
    }
  }
}

.dropdown {
  z-index: 999;
}

.createInvoice .dropdown {
  z-index: unset !important;
}

.MuiButtonBase-root.MuiIconButton-root {

  &.MuiIconButton-size,
  &.MuiIconButton-sizeSmall {
    border-radius: 50%;
  }
}

.sorting_v2 {

  .sort_up_icon,
  .sort_down_icon {
    fill: $secondary-black;
  }

  &.up_icon {
    .sort_up_icon {
      fill: $primary-blue-dark;
      opacity: 1;
    }

    .sort_down_icon {
      fill: $secondary-black;
    }
  }

  &.down_icon {
    .sort_down_icon {
      fill: $primary-blue-dark;
      opacity: 1;
    }

    .sort_up_icon {
      fill: $secondary-black;
    }
  }
}

.Striped-Table {
  padding: 0 !important;
  border: 1px solid $striped-border-color !important;

  table {
    position: relative;
    width: 100%;

    tr {
      border-bottom: unset;

      &:hover {
        td {
          background-color: $global-background-blue !important;
        }
      }

      &:not(:has(.ck-filter-table-virtualization)):nth-child(odd) {
        td {
          background-color: $striped-background-grey !important;
        }
      }

      &:not(:has(.ck-filter-table-virtualization)):nth-child(even) {
        td {
          background-color: #FFF !important;
        }
      }

      td {
        font-size: 11px;
        color: $secondary-black !important;
        border-right: 1px solid #fff;
        // border-bottom: 1px solid $global-white !important;
        padding: 3.5px 5px !important;
        text-align: left;

        &:nth-last-child(2) {
          border-right: 0 !important;
        }

        &:first-child {
          font-weight: 400 !important;
          border-left: none !important;
          position: sticky;
          top: 0;
          left: 0;
          z-index: 1;
          background-color: inherit;
          font-size: 11px !important;
          text-align: left !important;
          border-right: 0 !important;

          &:after {
            position: absolute;
            width: 1px;
            height: 100%;
            top: 0;
            right: 1px;
            background-color: #fff;
          }
        }
      }

      &:last-child {
        td {
          border-bottom: none !important;
        }
      }
    }

    th {
      background-color: $header-background-blue !important;
      color: $primary-blue-dark !important;
      font-size: 11px !important;
      font-weight: 500 !important;
      border: 1px solid #FFF;
      border-top: unset;
      padding: 7.5px 5px !important;
      text-align: right !important;

      &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        bottom: -1px;
        left: 0;
        background-color: #fff;
      }

      &:first-child {
        border-left: none !important;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 4;
        text-align: left !important;
        border-right: 0;

        &:after {
          position: absolute;
          content: '';
          width: 1px;
          height: 100%;
          top: 0;
          right: 1px;
          background-color: #fff;
        }
      }

      &:nth-child(2) {
        border-left: 0 !important;
      }

      &:last-child {
        border-right: none !important;
        color: $primary-blue-dark !important;
        border-left: 0 !important;

        &:after {
          position: absolute;
          content: '';
          width: 1px;
          height: 100%;
          top: 0;
          left: 1px;
          background-color: #fff;
        }
      }

      &:nth-last-child(2) {
        border-right: 0 !important;
      }
    }

    tfoot {
      td {
        background-color: $footer-background-blue !important;
        font-weight: 500 !important;
        font-size: 11px !important;
        text-align: right;

        &:first-child {
          background-color: $primary-blue-dark !important;
          color: $global-white !important;
          // font-weight: 700 !important;
          font-size: 12px !important;
          position: sticky;
          top: 0;
          left: 0;
          z-index: 1;
          text-align: left;
          border-right: 0;

          &::after {
            right: 0;
          }
        }

        &:last-child {
          text-align: right;
        }
      }

      tr {
        &:last-child {
          td {
            &::before {
              position: absolute;
              content: '';
              width: 100%;
              height: 2px;
              top: -1px;
              left: 0;
              background-color: #fff;
            }
          }
        }
      }
    }
  }

  &.last-column-sticky {
    table {
      tr {
        th {
          color: $primary-blue-dark !important;
          font-weight: 500 !important;
        }

        &:hover {

          td,
          td:last-child {
            background-color: $global-background-blue !important;
          }
        }

        td {
          color: $secondary-black !important;
          font-weight: 400 !important;
          line-height: 9px !important;
          padding: 5px !important;

          &:last-child {
            background-color: #EAF1FF !important;
            text-align: right !important;
            border-left: 0 !important;
            color: #0A3CA2 !important;
            font-weight: 500 !important;

            &:after {
              position: absolute;
              content: '';
              width: 1px;
              height: 100%;
              top: 0;
              left: 1px;
              background-color: #fff;
            }

            &:nth-last-child(2) {
              border-right: 0 !important;
            }
          }
        }
      }

      th,
      td {
        &:last-child {
          position: sticky;
          top: 0;
          right: -1px;
          z-index: 1;
        }
      }
    }
  }
}

.billing_console_wrapper {
  &.billingConsole {
    .breadcrums_wrapper {
      li {
        a {
          svg {
            overflow: visible;
          }
        }
      }
    }
  }
}