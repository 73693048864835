.TransferListWrapper{
    .flow-icon-wrapper {
        .active {
            cursor:pointer;
        }
    }
    .selector-heading{
        font-weight: 500 !important;
        color: #000 !important;
        font-size: 12px;
    }
    .selected-item{
        color: #0A3CA2 !important;
        font-size: 11px !important;
    }
    .multi-select{
        width: 47%;
    }
    .multi-select-wrapper {
        // height: calc(100vh - 309px);
        // width: 296px;
        width: 100%;
        // overflow-y: scroll;
        &.selected{
            border: 1px solid #EAEAEA;
            box-shadow: none;
        }
    }
    .search-wrapper {
        padding: 0;
        top: 0;
        position: sticky;
        z-index: 9;
        .search-bar {
            padding: 7px 15px;
            border: none;
            margin: 0;
            border-bottom: 1px solid #EAEAEA;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            height: 30px;
            svg{
                margin-right: 10px;
            }
            input {
                font-size: 10px !important;
                line-height: 22px;
                color: #000000;
                border-radius: 0;
                border: none;
                margin-bottom: -3px;
                &::placeholder{
                    color:#00000040;
                }
            }
        }
    }
    .multi-select-dropdown{
        padding: 0;
        // max-height: unset !important;
        .option{
            border-bottom: 1px solid #EAEAEA;
            position: relative;
            height: auto;
            padding: 1px;
            word-break: break-all;
            &:hover{
                .Label-tooltip{
                    display: block;
                    z-index: 9;
                }
            }
        }
        .MuiSvgIcon-root {
            color: #D9D9D9;
            height: 16px;
            width: 16px;
        }
        .MuiFormControlLabel-label {
            color: #000;
            font-size: 10px;
        }
    }

    .multi-select.transferlist-disabled {
         .multi-select-dropdown {
            .option {
                background-color:#F8F8F8 !important;
                height: 30px;
                .MuiFormControlLabel-label {
                    color: #646464 !important;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 12px;
                    letter-spacing: 0em;
                    text-align: left;
                    pointer-events: none;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .MuiFormControlLabel-root {
                    cursor: auto !important;
                }
                .MuiCheckbox-root {
                    cursor: not-allowed;
                    opacity: 0.5;
                }
                .MuiCheckbox-root.Mui-disabled {
                    cursor: no-drop !important;
                    pointer-events: visible !important;
                }
            }
        }
    }
}