.cost_explorer{
    table{
        width: 100% !important;
    }
    span.MuiSwitch-thumb {
        background: #6e7383 !important;
      }
}

.check_Box_wrapper_external .show-filter{
    input{
        padding: 14px 12px !important;
    }
}