@import "../../../../styleguide/contant.scss";


.journey_popover {
    .MuiPaper-root.MuiPopover-paper {
        overflow-y: inherit !important;
    }
  .MuiTimeline-root.MuiTimeline-positionRight {
    // max-width: 300px;
    min-width: 100px;
    min-height: 100px;
    padding-top: 20px;
    .MuiTimelineItem-positionRight {
      min-height: 45px;

      .MuiTimelineDot-root.MuiTimelineDot-filled {
        margin: 3px 0;
      }
      .MuiTypography-root.MuiTypography-body1 {
        padding: 0 16px;
      }
    }
  }
}

.top_triangle {
  .MuiPaper-root.MuiPopover-paper {
    margin-top: 10px;
    &::before {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      border: solid 1px $secondary-blueish-grey;
      top: -5px;
      left: 50%;
      background: $primary-white;
      transform: rotate(45deg);
      border-right: 0;
      border-bottom: 0;
    }
  }
}

.bottom_triangle {
    .MuiPaper-root.MuiPopover-paper {
      margin-top: -10px;
      &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        border: solid 1px $secondary-blueish-grey;
        bottom: -5px;
        left: 50%;
        background: $primary-white;
        transform: rotate(225deg);
        border-right: 0;
        border-bottom: 0;
      }
    }
  }

  .journey_info {
    #Path_9222 {
      fill: $primary-blue-dark;
    }
  }