.disblaed {
    .MuiLinearProgress-bar {
        background-color: #898A90;
    }

    .MuiLinearProgress-root {
        background-color: #DDDFE8;

    }
}

.grey {

    //color fo progressbar
    .MuiLinearProgress-bar {
        background-color: #898A90;
    }

    //background color for progressbar
    .MuiLinearProgress-root {
        background-color: #DDDFE8;
    }
}

.green {
    .MuiLinearProgress-bar {
        background-color: #64AC56;
    }
    .MuiLinearProgress-root {
        background-color: #DDDFE8;
    }
}