.data-transfer-cost-breakdown {
  width: 100%;
  margin-top: 0 !important;
  border-radius: 5px;
  border: none;
  position: relative;
  .breakdown-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 12px 9px;
    border-bottom: 1px solid #e6ecf1;
    margin-bottom: 14px;
    p {
    }
  }
  .tabV2_wrapper {
    margin: 0px 0 14px 12px;
  }
  .total-container {
    position: relative;
    display: flex;
    margin: 0px 12px 16px;
    padding: 8px 12px;
    background: #f5f8fa;
    border-radius: 4px;
    gap: 10px;
    .total-cost {
      border-radius: 4px;
      background: #ffffff;
      padding: 10px;
      .total-value {
        font-size: 12px;
        font-weight: 600;
        color: #0c477e;
      }
    }
    .item-container {
      box-shadow: 0px 2px 5px 0px #26334d08;
      background: #ffffff;
      border-radius: 5px;
      .item-heading {
        padding: 3.5px 0px;
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        color: #0c477e;
        border-bottom: 1px solid #e6ecf1;
      }
      .item-content {
        margin: 5px 0px 6px 10px;
        border-right: 1px solid #e6ecf1;
        padding-right: 18px;
      }
      .item-content p:first-child {
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        text-align: left;
        color: #6f7584;
      }
      .item-content p:last-child {
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        text-align: left;
        color: #0c477e;
        margin-top: 5px;
      }
      .item-content:last-child {
        border-right: none;
      }
    }
  }
  .cost_breakup_wrapper.Striped-Table {
    margin: 0px !important;
  }
  .Striped-Table.last-column-sticky table tr th {
    color: #ffffff !important;
    background: #0a3ca2 !important;
    border-right: 1px solid #ffffff !important;
  }
  .Striped-Table table th:first-child:after {
    width: 0px;
  }
  .Striped-Table.last-column-sticky table tr td:last-child {
    color: #151e33 !important;
    line-height: 13px !important;
    font-weight: 600 !important;
  }
  .Striped-Table.last-column-sticky table tr td:last-child:after {
    box-shadow: -4px 0 4px 0 rgba(12, 71, 126, .349);
    width: 1px !important;
    background: transparent;
  }
  .Striped-Table.last-column-sticky table th:last-child:after {
    width: 0px !important;
  }
  .Striped-Table.last-column-sticky table tr td:first-child:after {
    background-color: transparent;
  }
  .Striped-Table table thead th::before {
    height: 0px !important;
  }

  .cost_breakup_wrapper.Striped-Table table tr {
    border-bottom: 1px solid #0a3ca2 !important;
  }
  #multilevel-headerTable {
    tbody tr:nth-child(even) td {
      background: #f8f7fc !important;
      border: 1px solid #e6ecf1;
    }
    tbody tr:nth-child(odd) td {
      background: #ffffff !important;
      border: 1px solid #e6ecf1;
    }
    tfoot #total td:first-child {
      background-color: #e4f4fb !important;
      color: #2b2b2b !important;
      font-size: 11px !important;
      font-weight: 600 !important;
      line-height: 13px !important;
    }
    tfoot #total td {
      color: #2b2b2b !important;
      font-weight: 600 !important;
      line-height: 13px !important;
      border: none;
    }
    tfoot #total td:last-of-type:after {
      box-shadow: none !important;
      border-left: 1px solid #e6ecf1;
    }
    tfoot #total td:first-child {
      border-right: 1px solid #e6ecf1 !important;
    }
    tfoot td {
      background: #e4f4fb !important;
      border: 1px solid #e6ecf1;
    }
    .remove-border::after {
      position: initial !important;
    }
  }
  .no_data_found {
    font-weight: 500 !important;
    line-height: 13px;
  }
}
