@import "../../../../styleguide/contant.scss";

.billing_console_wrapper.tagReport ol li:nth-child(5) {
    text-transform: initial;
}

.breadcrums_wrapper{
    ol{
        display: flex;
        flex-wrap: wrap;
        -webkit-box-align: center;
        align-items: center;
        padding: 0px;
        margin: 0px;
        list-style: none;
    }
    li{
        a{
            color: #555B6C;
            font-weight: 500;
        }
        p{
            margin: 0 !important;
        }
        .header_text{
            font-size: 26px;
            font-weight: 700;
        }
        .breadcrums_wrapper{
            padding: 0px 0;
            padding-bottom: 5px;
            ol{
                li.MuiBreadcrumbs-separator{
                    margin: 0;
                    svg{
                        width: 17px;
                        margin: 0 5px;
                        path{
                            stroke: white;
                            stroke-width:.8px;
                        }
                    }
                }
                li{
                    color: #7A7A92;
                    font-size: 10px;
                    font-weight: normal;
                    line-height: 11.72px;
                    text-transform: capitalize;
                    a.MuiTypography-root {
                        color: #555B6C;
                        font-size: 10px;
                        font-weight: normal;
                        text-decoration: none;
                        cursor: pointer;

                        p{
                        color: #555B6C;
                        font-size: 10px;
                        font-weight: normal;
                        &:hover {
                            color: $primary-blue-dark;
                            text-decoration: underline;
                        }
                        }
                    }
                    &:last-of-type{
                        pointer-events: none;
                    }
                }
            }
        }
    }
}

