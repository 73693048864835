@import "../../../../styleguide/contant.scss";
@import "../../../../styleguide/mixin.scss";

.page_container {
  /* background-color: red; */
  // border: 1px solid $secondary-grey;
  border-radius: 6px;

  .MuiMenuItem-root {
    background-color: unset !important;
  }

  .cancel {
    color: $primary-blue-dark;
    font-weight: 500;
    margin-right: 8px;
  }

  .table_container {
    border: none;
    border-radius: 4px;
    background-color: $primary-white;
    margin-top: 0;
    padding-top: 15px;
  }

  .title {
    text-align: center;
    font-size: $fs-14;
    margin: 0;
    font-weight: bold;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    /* position: relative; */
    /* min-height: 40vh; */
    margin: 0;
  }

  table .no_data {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .no_data p {
    font-size: $fs-18;
    font-weight: 500;
  }

  .private_pricing_td {
    font-weight: 400;

    .region-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 32px;
    }

    .Mui-checked {
      color: $primary-blue-dark !important;
    }

    &:first-child {
      padding-left: 20px;
      // white-space: nowrap;
    }
  }

  tr td {
    border: none;
    text-align: center;
    padding: 4px 5px;
    position: relative;

    &:first-child {
      text-align: left;
      min-width: 150px;
    }
  }

  .table-radio-input {
    font-size: $fs-16;
    padding: 4px 5px;
    font-family: "Poppins", sans-serif;
    border-radius: 4px;
    margin-bottom: 0;
    max-width: 120px;
    color: $secondary-black;

    &:disabled {
      background-color: $secondary-grey-light !important;
    }
  }

  .display-text {
    position: relative;
    padding-left: 19px;
    color: $secondary-black;
    font-size: 12px;
  }

  .table-radio-input.have_value {
    outline: none;
    border: 1px solid $secondary-grey !important;
    background-color: #fbfbfb;
    color: $secondary-black;
  }

  .table-radio-input:focus {
    border: 1px solid $primary-blue-dark !important;
  }

  .table_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f9f9f9;
    padding: 8px 20px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: 1px solid $secondary-grey;

    .primary-btn {
      background-color: $primary-blue-dark !important;
    }
  }

  .reset_text {
    font-size: $fs-12;
    color: $primary-blue-dark;
    margin: 0;
    cursor: pointer;
    display: flex;
    align-items: baseline;

    svg {
      margin-right: 5px;
    }
  }

  .btn_download {
    outline: 1px solid #220f5f;
  }

  .action_btn_group {
    display: flex;
    gap: 15px;
  }

  .add_icon {
    // width: 50px;
    cursor: point;
  }

  .show-more {
    border-radius: 14px;
  }

  .radio-button-group {
    margin-bottom: unset;

    .text-field {
      margin-top: 0px;
    }

    .option-wrapper {
      display: flex;
      align-items: center;
      column-gap: 10px;

      .buttonStyle {
        color: #525252;
        font-size: $fs-12;
        border: 1px solid $secondary-grey;
        border-radius: 4px;
        padding: 13px 25px;
        cursor: pointer;

        &.selected {
          box-shadow: -3px 2px 12px $primary-blue-box-shadow;
          background-color: $primary-blue-dark;
          color: $primary-white;
          position: relative;
        }
      }
    }
  }

  .customer_management_footer {
    background-color: $primary-white;
    padding: 10px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    p {
      font-size: $fs-12;
      font-weight: 500;
    }

    .custom_popover {
      position: absolute;
      bottom: 70px;
      min-height: 220px;
      min-width: 400px;
      background-color: $primary-white;
      border-radius: 10px;
      border: 1px solid #dedede;
      box-shadow: -5px 13px 31px rgba(0, 0, 0, 0.3529411765);

      .table-radio-input {
        max-width: 80px;
        border: 1px solid $secondary-grey;
        background-color: $primary-white;
      }

      .table_header {
        padding: 10px 0 0 0;
        background-color: $primary-white;
        border: unset;
      }

      th {
        border-bottom: 1px solid #dedede !important;
      }

      table td:not(:last-child),
      th:not(:last-child) {
        border-right: 1px solid #dedede !important;
      }
    }
  }

  .custom_popover_table_wrapper {
    border-radius: 4px;
    border: 1px solid $secondary-grey;

    .pop_over_table {
      .private_pricing_header {
        background-color: $modal-footer-background !important;
        color: $primary-blue-dark;
      }

      tr td {
        padding: 4px 10px;
      }
    }
  }

  .custom_popover {
    padding: 10px;
    position: relative;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      left: 18px;
      bottom: -10px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid $primary-white;
      clear: both;
    }

    .MuiFormControlLabel-root {
      margin-right: 0 !important;
    }
  }

  .dropdownContatiner {
    display: flex;
    align-items: center !important;
    justify-content: space-between;
    margin: 15px 20px;
    align-items: baseline;

    .MuiButtonBase-root .MuiMenuItem-root .MuiMenuItem-gutters .MuiMenuItem-root .MuiMenuItem-gutters {
      background-color: $primary-white !important;

      &:hover {
        background-color: $primary-white !important;
      }
    }

    .multipleSelectCheckmarks {
      flex-basis: 60%;

      .MuiFormControl-root .MuiOutlinedInput-input.MuiInputBase-input {
        padding: 9px 15px !important;
      }

      p {
        padding-bottom: 9px;
      }
    }
  }

  .legend_table {
    display: flex;
    justify-content: end;
    padding: 15px 30px 0 0;
    gap: 20px;

    .private_pricing {
      position: relative;

      &::before {
        content: "";
        height: 8px;
        width: 8px;
        position: absolute;
        background-color: $secondary-grey;
        border-radius: 50%;
        // background: url("../../../../Assets/icons/grey-disc.svg");
        left: -13px;
        top: 2px;
      }
    }

    .public_pricing {
      position: relative;

      &::before {
        content: "";
        height: 8px;
        width: 8px;
        position: absolute;
        // background: url("../../../../Assets/icons/disc-filled.svg");
        background-color: #3e81cb;
        border-radius: 50%;
        left: -13px;
        top: 2px;
      }
    }

    .pricing {
      font-size: $fs-12;
    }
  }
}

.newRegionToggle {
  padding: 6px 20px !important;
}

.yearly-commitment-container {}

.cloudfrontpricing_input:focus {
  background-color: $primary-white !important;
  color: $secondary-black !important;
}

// Styling for read only view in modal
.Non_editable {
  height: 100%;

  #yearyly_wise_contianer {
    td {
      &:first-child {
        padding-left: 18px !important;
      }
    }

    .td_with_circle {
      &::before {
        right: 0 !important;
      }

      &::after {
        left: 0 !important;
      }
    }
  }

  .reset_text {
    display: none;
  }

  .input_container {
    pointer-events: none;
    opacity: 0.6;
  }

  .buttonStyle {
    pointer-events: none;
  }

  .buttonStyle.selected {
    background-color: #747474 !important;
    color: #cbcbcb !important;
  }

  .right_container {
    max-width: 110px;
  }
}

.table_header {
  .disabled {
    color: #525252;
  }
}

.cloudfrontpricing-wrapper {
  line-height: 1;

  .page_container .dropdownContatiner {
    justify-content: flex-start;
    gap: 9px;

    .calender-wrapper {
      margin-right: 0;
      margin-bottom: 0;

      .calender {
        .header {
          width: 132px;
        }
      }
    }
  }

  .multipleSelectCheckmarks {
    p {
      padding-bottom: 9px;
      padding-left: 0;
    }
  }

  .custom_multiselect .multicheck {
    margin-top: 0;
    margin-left: 0;

    &::before {
      top: 16px;
    }
  }

  .MuiFormControl-root>.MuiInputLabel-root {
    top: 3px;
  }

  .private-pricing-body-left {}
}

// ------------------------------------------------------------

.editable_table {

  //to be removed
  // width: 70%;
  // margin: 10px;
  .editable_table_heading {
    @include editableHeading;
  }

  .reset_btn {
    @include resetBtn;
  }

  .table_container {
    margin-top: 12px;
    padding: 0;
    border: 1px solid $secondary-grey;

    // overflow: hidden;
    table {
      border-collapse: separate;

      thead {
        tr {
          background-color: #f9fbff;

          &.left_aligned_header {
            background-color: #eff4ff;

            th {
              color: $primary-blue-dark;
              padding: 5px 7.5px 5px 7.5px;
              font-size: 12px;

              &:first-child {
                padding: 5px 7.5px 5px 15px;
              }

              &:last-of-type {
                padding: 5px 15px 5px 7.5px;
              }
            }
          }

          th {
            color: $primary-grey-dark;
            padding: 6.5px 7.5px 6.5px 7.5px;
            text-align: left;
            border-bottom: 1px solid $secondary-grey;
            background: unset !important;

            p {
              font-size: 10px;
            }

            &:first-child {
              padding: 6.5px 7.5px 6.5px 15px;
            }

            &:last-of-type {
              padding: 6.5px 15px 6.5px 7.5px;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &:has(.label_input_container) {
              padding: 6px 6px !important;
            }
          }

          td {
            padding: 6px 7.5px 6px 15px !important;

            &.private_pricing_td {
              padding: 6px 7.5px 6px 7.5px !important;
              text-wrap: nowrap;

              .table-radio-input {
                max-width: 100%;
                border-color: $secondary-grey;
                font-size: 12px;
                font-weight: 500;
                line-height: 17px;
                text-align: center;
                padding: 5.5px 14px 5.5px 11px;

                &.have_value {
                  background: $primary-white;
                }
              }

              &:first-child {
                padding: 6px 7.5px 6px 15px !important;
              }

              &:last-of-type {
                padding: 6px 15px 6px 7.5px !important;
              }
            }
          }

          &:first-child {
            td {
              &.private_pricing_td {
                padding: 16px 7.5px 6px 7.5px !important;

                &:first-child {
                  padding: 16px 7.5px 6px 15px !important;
                }

                &:last-of-type {
                  padding: 16px 15px 6px 7.5px !important;
                }
              }
            }
          }

          &:last-of-type {
            td {
              &.private_pricing_td {
                padding: 6px 7.5px 16px 7.5px !important;

                &:first-child {
                  // padding: 6px 7.5px 16px 15px !important;
                }

                &:last-of-type {
                  padding: 6px 15px 16px 7.5px !important;
                }
              }
            }
          }

          &:nth-child(even) {
            background: #f7f7f7;
          }

          &.pp_regions_section,
          &.aos-section {
            background: #fff !important;

            .icon-info {
              svg {
                top: 0;
                bottom: auto;
              }
            }
          }

          &:only-child {
            td {
              &.private_pricing_td {
                padding: 16px 7.5px 16px 7.5px !important;

                &:first-child {
                  // padding: 6px 7.5px 16px 15px !important;
                }

                &:last-of-type {
                  padding: 16px 15px 16px 7.5px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.editable_table_header {
  // padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .cld_cloudfront_radio_group {
    display: flex;
    gap: 10px;

    .MuiRadio-root {
      padding: 0;
    }

    .MuiTypography-root {
      line-height: 16px;
    }
  }
}

.editable_table {
  .radio-button-group {
    .option {
      padding: 0 !important;
    }
  }

  table {
    th {
      padding: 5px;
      text-transform: inherit;
    }
  }

  .cld_pp_secondary_header {
    color: $primary-blue-dark !important;
    font-style: italic;
    font-size: 13px;
    font-weight: 400;
  }

  input {
    margin-bottom: 0 !important;

    &:disabled {
      background-color: $secondary-grey-light;
    }
  }

  .pp_regions_section {
    td {
      border-top: 1px solid $secondary-grey-light;
      border-bottom: 1px solid $secondary-grey-light;
      padding: 10px 20px;
    }
  }

  tr.error {
    td {
      border-left: 0;
      border-right: 0;

      .error {
        color: red;
      }
    }
  }
}

//----------

.commitment-section {

  // width: 348px;
  // margin: 10px;
  .title {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }

  .commitment-container {
    border: 1px solid $secondary-grey;
    background-color: #f9f9f9;
    border-radius: 4px;
    margin-top: 12px;
    padding: 15px 11px 21px;
  }

  .dropdownContatiner {
    display: flex;
    gap: 21px;
    margin-bottom: 18px;

    .counter-wrapper {
      width: 114px;
      margin-top: 7px;
      background-color: $primary-white;
      overflow: hidden;

      border-radius: 4px;
      align-items: center;
      justify-content: space-between;
      height: 45px;
      border: 1px solid $secondary-grey;

      input {
        border: none;
        font-size: 14px;
        color: $secondary-black;
      }

      .counter-btn {
        padding: 4px;

        border-radius: 0px;

        background: #f0f6ff 0% 0% no-repeat padding-box;
        min-height: inherit;
        max-height: inherit;
        display: flex;
        align-items: center;
        overflow: visible;
        display: flex;
        justify-content: center;
        height: inherit;

        &.add {
          border-radius: 0 4px 4px 0;
          border-right: none;
          height: 45px;

          border: 0px;
          border-left: 1px solid $secondary-grey;
          width: unset;
        }

        &.subtract {
          border-radius: 4px 0 0 4px;
          border-left: none;
          height: 45px;
          border: 0px;
          border-right: 1px solid $secondary-grey;
          width: unset;
        }

        button {
          width: 22px;
          height: 22px;
          border: none;
          color: $primary-blue-dark;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .calender-wrapper {
      padding: 0px;
      width: 100%;
      margin: 0;

      .calender {
        >p {
          line-height: 1.42857143;
        }

        .header {
          margin-top: 7px;
          background: $primary-white;
          height: 45px;
        }
      }
    }
  }

  .yearly-commitment-container {
    margin: 0px;

    .table_container {
      overflow: auto;
      max-height: 400px;
      border: 1px solid $secondary-grey;
      border-radius: 4px;
      margin-top: 0px;

      table {
        border-radius: 4px;

        thead {
          background-color: #eff4ff !important;
          border-bottom: 1px solid $secondary-grey;

          th {
            color: $primary-blue-dark;
            font-size: 12px;
            padding: 7.5px 6px 6.5px;
            background-color: unset !important;
            text-align: left;

            &:first-child {
              padding-left: 12px;
            }

            &:last-of-type {
              padding-right: 12px;
            }
          }
        }

        tbody {
          background-color: $primary-white;

          tr {
            &:first-child {
              td {
                padding: 20px 6px 7.5px;
              }
            }

            &:last-of-type {
              td {
                padding: 7.5px 6px 20px;
              }
            }

            &:only-child {
              td {
                padding: 20px 6px 20px;
              }
            }

            td {
              padding: 7.5px 6px 7.5px;

              div {
                position: relative;

                input {
                  margin-bottom: 0px;
                  padding: 8px 10px 9px;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 17px;
                  letter-spacing: 0px;
                  text-align: center;

                  +.errorClass {
                    position: absolute;
                    top: auto !important;
                    left: 0px;
                    font-size: 12px !important;
                    margin: 0px;
                    margin-top: 4px;
                    max-width: 184px;
                  }
                }

                .dropdown-wrapper {
                  margin-bottom: 0px;
                  padding-right: 0px !important;

                  .header {
                    height: unset;
                    padding: 7px 13px 8px 10px;
                    margin: 0px;
                    gap: 10px;

                    .truncate {
                      line-height: 17px;
                    }
                  }
                }
              }

              &:first-child {
                padding-left: 12px;

                >div {
                  min-width: 90px;
                }
              }

              &:last-of-type {
                padding-right: 12px;
              }
            }
          }
        }
      }
    }
  }

  .btn_group_container {
    margin-top: 20px;

    .radio-button-group {
      margin-bottom: 0px;

      .title {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: $secondary-black;

        .icon-info {
          svg.small {
            font-size: 13px;
            top: -7px;
            left: 0;
          }
        }
      }

      .option-wrapper {
        margin-left: 1px;

        .option {
          padding-top: 1px;

          .MuiRadio-root {
            padding: 9px;
          }
        }
      }
    }
  }

  .account-dropdown {
    margin-top: 11px;

    .title {
      margin: 0px;

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0px;
      }

      .dropdown {
        max-width: 216px;

        .header {
          height: unset;
          margin-top: 7px;
          background: $primary-white;
          border-color: $secondary-grey;
          padding: 13px 18px 13px 13px;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0.2px;
        }
      }
    }
  }
}

.media-connect {
  border-radius: 6px;

  .cloud-front-form {
    display: grid;
    grid-template-areas: "first first first" "second second second" "third fourth fifth" "sixth seventh seventh";
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    margin: 20px 18px 18px 18px;

    &.readOnlyForm {
      grid-template-areas: "first eight nine" "second second second" "third fourth fifth" "sixth seventh seventh";
      max-height: 700px;
      overflow-y: auto;

      .text-field.primary {
        color: #151e33 !important;
      }

      .mandatory {
        &::after {
          content: none;
        }
      }

      .cloudfrontpricing_name {
        background: unset;

        .info-content {
          p {
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      .commitment-section {
        .dropdownContatiner {
          .counter-wrapper {
            background: unset;

            input {
              background: unset;
              font-weight: 400;
            }
          }

          .calender-wrapper {
            .calender {
              .header {
                margin-top: 7px !important;
                height: 23px !important;
                background: unset;
                font-weight: 400;
              }
            }
          }
        }
      }

      .render-tooltip-wrapper {
        margin-top: -4px;
      }
    }

    >div {
      &.cloudfrontpricing_name {
        grid-area: first;
        width: 50%;
      }

      &.cloudfrontpricing_description {
        grid-area: second;
      }

      &.cloudfrontpricing_cbu {
        grid-area: third;
        width: 100% !important;
      }

      &.cloudfrontpricing_region {
        grid-area: third;
        width: 100% !important;
        grid-area: fourth;
      }

      &.cloudfrontpricing_pricing {
        grid-area: third;
        width: 100% !important;
        grid-area: fifth;
      }

      &.commitment-section {
        grid-area: sixth;
      }

      &.createdAndSummary {
        grid-area: eight;

        .form_ele_wrapper {
          display: flex;
          padding: 0 !important;

        }
      }

      &.editable_table,
      &.editable_table_with_tabs {
        grid-area: seventh;
      }

      .cloudfrontpricing_name {
        margin: 0;
        padding: 14px 14px 14px 15px;
        font-size: 14px;
        line-height: 16px;
      }

      .cloudfrontpricing_description {
        padding: 9px 14px;
        line-height: 17px;
        background: unset;
      }

      .counter span {
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0px;
        text-align: right;
        padding: 4.5px 5px;
      }
    }

    .invoice-customer-wrapper {
      .invoice-customer {
        position: relative;

        .header {
          margin-top: 6px !important;
          padding: 14px 10px 13px 15px;

          &.dropdown {
            box-shadow: none;
          }
        }

        .multi-select-wrapper {
          width: 100%;
          box-shadow: none;
          border-radius: 0px 0px 4px 4px;
          min-width: auto;

          .search-wrapper {
            padding: 14px 10px 13px 15px;

            .search-bar {
              margin: 0px;
              border: 0px;
              padding: 0px;

              input {
                color: $secondary-black;
              }
            }
          }

          .multi-select-dropdown {
            border-top: 1px solid #e8e6e6;
            padding: 15px 0;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-left: 13px;

            .option {
              width: 100%;

              .MuiFormControlLabel-root {
                margin-left: 0px;

                .MuiCheckbox-root {
                  padding: 0px 8px 0px 0px;
                }

                .MuiFormControlLabel-label {
                  color: #7c7c7c;
                }

                .Mui-checked {
                  &+.MuiFormControlLabel-label {
                    font-weight: 500;
                    color: $secondary-black;
                  }
                }
              }
            }
          }
        }
      }
    }

    .listSelector_nodata {
      max-width: max-content;
      width: max-content;
    }
  }
}

.active_copy {
  path {
    fill: $primary-blue-dark;
  }
}

.label_font_size {
  font-size: 11px;
}

.editable_table_with_tabs {
  .VRItabs {
    .vr-tabs {
      gap: 5px;

      .tab_button {
        background-color: $primary-white;
        padding: 10px 20px;

        &:hover {
          background-color: #f7faff;
        }

        &.active {
          border: 1.2px solid $primary-blue-dark;
          color: $primary-blue-dark;
          font-weight: 700;
          background-color: #f7faff;
        }
      }
    }
  }

  .Region_list {
    margin-top: 9px;
    width: 25%;
    border: 1px solid $secondary-grey;
    border-radius: 3px;
    height: fit-content;

    .item_list {
      position: relative;
      padding: 8px 10px;
      color: $primary-grey-dark;
      font-size: 11px;

      &:nth-child(even) {
        background-color: #f9f9f9;
      }

      &:nth-child(odd) {
        background-color: $primary-white;
      }

      &.item_header {
        color: $primary-blue-dark;
        font-weight: 500;
        font-size: 12px;
        border-bottom: 1px solid $secondary-grey;
        background-color: #eff4ff;
        padding: 5px 10px;
      }

      &.active {
        border: 1px solid $secondary-grey;
        border-left: unset;
        border-right: unset;
        background-color: #eff4ff;
        color: $primary-blue-dark;
        font-weight: 500;
      }

      &:hover {
        background-color: #eff4ff;
        color: $primary-blue-dark;
        cursor: pointer;
      }

      &:first-child:hover {
        cursor: default;
      }

      &:nth-child(2).active {
        border-top: unset;
      }

      &:last-child.active {
        border-bottom: unset;
      }
    }

    .clone_modal_wrapper {
      top: calc(100% - 8px);
      left: calc(100% - 20px);
    }
  }

  .editable_table_heading {
    @include editableHeading;
  }

  .reset_btn {
    @include resetBtn;
    gap: 0;
  }

  .table_container {
    margin-top: 9px;
    width: 75%;
    padding: 0px;
    border: 1px solid $secondary-grey;
    border-radius: 4px;

    &.empty_list {
      margin-top: 12px;
    }

    .blank {
      &>td {
        height: 0px !important;
        padding: 2px 0 !important;

        &::after {
          content: "";
          position: absolute;
          width: calc(100% + 20px);
          height: 1px !important;
          background-image: linear-gradient(90deg,
              transparent,
              transparent 50%,
              $primary-white 50%,
              $primary-white 100%),
            linear-gradient(0deg, $secondary-grey, $secondary-grey, $secondary-grey, $secondary-grey, $secondary-grey);
          background-size: 3px 3px, 4px 63%;
          border: none;
          border-radius: 0px !important;
          padding: 0px !important;
          left: -10px;
        }
      }

      &.bitRate {
        &>td {
          &::after {
            left: 0px;
            width: 100%;
          }
        }
      }
    }

    .statmux {
      >td {
        >div:not(:first-child) {
          font-weight: 400;
          font-style: italic;
          line-height: 17.4px;
        }
      }
    }

  }

  .codec-detail-table {
    border-collapse: separate;
    border-spacing: 10px;

    input {
      font-weight: 500;
      font-size: 12px;
    }

    th {
      padding: 4px 0;
    }

    td {
      text-align: left;
      border-radius: 4px;
      padding: 8px 6px;
      font-family: Roboto;
      font-size: 11px;
      font-weight: 400;
      line-height: 1;
      border-right: 0;
      border-left: 0;
      white-space: pre-wrap;
      background: $primary-white;

      &:first-child {
        min-width: auto;
      }

      div:not(:first-child) {
        font-weight: 400 !important;
        margin-top: 6px;
      }
    }

    th {
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0px;
      text-align: left;
      color: $primary-blue-dark;
      background: $primary-white !important;
      bottom: -1px;
      font-weight: 400 !important;
      padding-bottom: 1px !important;
      font-style: italic;
    }

    &.inputs {

      &.AWS_CDI_Inputs,
      &.AWS_Elemental_Link_Inputs,
      &.MPEG-2_Inputs {
        width: auto;

        .blank {
          &>td {
            &::after {
              width: calc(100% + 10px);
            }
          }
        }

        .td:not(:first-child) {
          font-size: 400;
        }
      }
    }

    &.outputs {

      &.HEVC_Outputs,
      &.Audio_Only {
        width: auto;

        .blank {
          &>td {
            &::after {
              width: calc(100% + 10px);
            }
          }

          &.frameRate {
            &>td {
              &::after {
                width: 100%;
                left: 0;
              }
            }
          }
        }
      }
    }

    &.statmux {
      width: auto;

      .blank {
        &>td {
          &::after {
            width: calc(100% + 5px);
          }
        }
      }
    }

    &.addOnFunctionality {
      width: auto;

      td {
        padding: 8px 17.5px 8px 10px;
      }
    }
  }

  .codec-child-tabs {
    .active {
      position: relative;

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 1.5px;
        background: $primary-blue-dark;
        bottom: -1px;
        left: 0;
      }
    }
  }

  .clone_modal_wrapper {
    text-wrap: nowrap;
    color: #333;
  }

  .Mui-checked {
    color: $primary-blue-dark !important;
  }

}

.read_only_form {
  .createInvoice {
    margin: 0 !important;
  }
}