input {
  // padding: 16px 15px 14px !important;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #dddfe8;
  margin-bottom: 20px;

  &::placeholder {
    line-height: 2.5;
    font-size: 12px;
    color: #898a90;
  }

  &:focus {
    outline: none;
  }
}


input[name="I_AM_Role"] {
  width: 100%;
}

.errorClass {
  position: unset;
  text-align: left !important;
  line-height: 1;
  top: 56px !important;
  left: 10px;
}