@import "../../../styleguide/contant.scss";

.config_discount_wrapper {
  background-color: $primary-white;
  padding: 20px;
  padding-bottom: 0;

  .select_discount_wrapper {
    max-width: 180px;
    min-width: 150px;

    .MuiFormControl-root .MuiInputBase-root {
      max-width: 200px;
      min-width: 150px;

      .MuiOutlinedInput-input.MuiInputBase-input {
        padding: 8px !important;
      }
    }
  }

  .section_two {
    padding: 20px;
  }

  .multipleDropdowns {
    display: flex !important;
    max-width: 400px !important;
    gap: 30px !important;

    .dropdown-wrapper {
      width: 100% !important;
    }
  }

  .section_three {
    display: flex;
    // justify-content: space-evenly;
    align-items: center;
    gap: 30px;

    .counter_wrapper {
      margin-right: 25px;
    }

    .select_template_wrapper.select_discount_wrapper {
      max-width: 240px;
      width: 100%;
      // margin-right: 25px;

      .dropdown-wrapper {
        max-width: 200px;

        .dropdown {
          min-width: 200px;

          .header.filled {
            margin-top: 0;
          }
        }
      }
    }

    .calender-wrapper {
      margin-bottom: 0;

      .calender .header {
        padding: 10px;
        margin-top: 7px;
      }
    }

    .date_picker {
      margin-right: 25px;

      .calender-wrapper {
        min-width: 200px;
        width: 100%;
      }
    }

    .multipleSelectCheckmarks {
      max-width: 286px;
      width: 100%;
      margin-right: 25px;

      p {
        padding: 5px 10px 10px 0 !important;
        color: $secondary-black !important;
      }

      .multicheck {
        margin-left: 0 !important;
      }

      .MuiFormControl-root .MuiOutlinedInput-input.MuiInputBase-input {
        padding: 9px 15px !important;
      }
    }


  }

  .commitments_configure_discount {
    margin: 20px 0 30px;

    h6 {
      color: $secondary-black;
      font-size: $fs-14;
      font-weight: 500;
    }

    .commitments_configure_content {
      display: flex;
      padding-top: 20px;
      gap: 5em;
    }
  }

  .table_wrapper {
    margin-top: 20px;

    .disabled {
      opacity: 0.5;
      background-color: $primary-grey-light;
      pointer-events: auto;
      color: $primary-white;
    }
  }

  .commitments-wrapper {
    border: 1px solid $primary-blue-dark;
    border-radius: 4px;
    margin-top: 20px;
    overflow: unset;

    .commitments-heading {
      color: $primary-white;
      font-weight: 500;
    }

    .commitments-heading {
      background-color: $primary-blue-dark;
      padding: 12px 20px;
      margin-bottom: 20px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .commitments-content {
      padding: 0 20px;
      padding-bottom: 20px;

      .content_container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: center;
        max-width: 180px;
        min-width: 150px;

        .content_label {
          font-size: $fs-13;
          width: 100%;
        }

        .content_value {
          font-weight: 500;
          height: 2.5em;
          display: flex;
          align-items: center;
        }

        input {
          width: 100%;
          height: 2.5em;
          padding: 10px;
          text-align: center;
          height: 45px !important;
          border-radius: 4px !important;
          margin-bottom: 0;
          margin-top: 5px;
        }
      }
    }
  }

  .summary_table_wrapper {
    .table-container {
      .table {
        min-width: 500px;

        thead {
          th {
            white-space: nowrap;
          }
        }

        tbody {
          tr {
            td {
              .popupList {
                flex-direction: column !important;
              }
            }
          }
        }
      }
    }
  }

  .btn_group_container {
    margin-left: 10px;

    .radio-button-group {
      margin-bottom: 12px;
    }
  }

  .radio-button-group {
    margin-bottom: 0;

    p {
      font-weight: 400;
    }

    .MuiFormControlLabel-label {
      font-size: $fs-12 !important;
    }
  }

  .tags {
    padding: 5px;
    // border-bottom: 1px solid #ccc;
    // &:last-child{
    //   border-bottom: none;
    // }
  }

  .savingsBundle-info {
    display: flex;
    align-items: center;

    svg {
      width: 12px;
      height: 12px;
      margin-right: 5px;
    }

    p {
      font-size: $fs-14;
      font-style: normal;
      font-weight: 400;
    }
  }

}

.label_font_size {
  font-size: $fs-12;
}

.counter-wrapper {
  width: 144px;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  min-height: 45px;
  max-height: 48px;
  border: 1px solid $primary-sky-blue;

  input {
    border: none;
  }

  .counter-btn {
    background: $modal-footer-background 0% 0% no-repeat padding-box;
    min-height: inherit;
    max-height: inherit;
    display: flex;
    padding: 8px;
    border-radius: 4px;
    align-items: center;

    button {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 1px solid $primary-sky-blue;
      color: $primary-sky-blue;
    }
  }
}

.counter-btn {
  background: $modal-footer-background 0% 0% no-repeat padding-box;
  min-height: inherit;
  display: flex;
  padding: 8px;
  border-radius: 4px;
  align-items: center;

  button {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid $primary-sky-blue;
    color: $primary-sky-blue;
  }
}

.tenure_tableWrapper {
  width: unset !important;

  thead {
    th {
      // background-color: unset !important;
    }
  }

  tbody tr {
    td {
      font-weight: 700;
      padding: 10px;

      input {
        padding: 10px;
        margin-bottom: unset;
      }
    }
  }
}

.external_link_configure {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  margin-top: 20px;
}

.summary_table_wrapper {
  table {
    tbody {
      tr td {
        white-space: nowrap;
      }
    }
  }
}

.configureDiscount-DialogBody.configDiscount {
  padding-top: 0 !important;

  .header {
    .heading {
      padding-left: 0 !important;
      margin-top: 0 !important;
      padding-bottom: 5px;
    }

    button.MuiButtonBase-root {
      margin-top: -15px;
    }

    .content {
      margin-bottom: 10px;
      min-height: 12px;
    }
  }

  .configureDiscountTable {
    margin: 1% auto;

    .header {
      .item {
        &:first-child {
          text-align: center;
        }
      }
    }
  }

  .info .dragIcon {
    cursor: unset !important;
    background-color: $primary-white;

    svg path {
      fill: $primary-blue-dark !important;
    }
  }
}

.multiButtons button {
  color: $primary-white;
}

.RDCommitment {
  .RDConfig_commitment {
    border: 1px solid $secondary-blueish-grey;
    padding: 10px 20px;
    border-radius: 12px;
    margin: 20px 0;
    position: relative;

    .dropdown.dropdownOpen {
      z-index: 9999 !important;
    }

    .dropdown {
      .truncate {
        width: 90%;
      }
    }

    .delete_btn {
      position: absolute;
      background-color: $primary-blue-dark;
      padding: 6px;
      right: 0;
      top: 0;
      border-radius: 0 8px;
    }
  }

  .commitments_configure_content {
    padding-top: unset !important;
    gap: unset !important;
    justify-content: space-between;
  }

  .content_container {
    gap: unset !important;

    .content_label {
      color: $secondary-black !important;
      font-size: $fs-12 !important;
    }

    input {
      height: 48px !important;
      border-radius: 4px !important;
    }
  }
}

.cloudfront-commitment {
  .content_container {
    gap: unset !important;
  }
}

.configDiscount_cpp {
  border: 1px solid $secondary-blueish-grey;
  margin-bottom: 20px;
  border-radius: 4px;


  .cloudfrontpricing-wrapper {
    padding: 0 !important;
    border: 0 !important;
  }

  .confirm_section {
    padding: 0 20px !important;
    justify-content: end;
  }
}

.discount-charges{
  .radio-button-group{
    margin-bottom: 10px;
    .text-field{
      margin-top: 15px !important;
      margin-left: 20px !important;
    }
  }
  .discount-charge-section.section_three{
    width: 100%;
    margin-left: 20px;
  }
  .one-time{
    .calender-wrapper{
      min-width: 200px;
      max-width: 300px;
      margin-left: 20px;
      .calender {
        width: 100%;
      }
    }
  }
  .Repeatative-calender{
    .calender-wrapper{
      min-width: 200px;
      max-width: 300px;
      margin-left: 20px;
      .calender {
        width: 100%;
      }
    }
  }
  .amount-section{
    clear: both;
    margin-left: 20px;
    .mandatory{
      font-size: $fs-12;
      color: $secondary-black;
      margin-bottom: 5px;
    }
    input{
      min-width: 200px;
      max-width: 300px;
      padding: 10px;
    }
  }
  .Percentage-selected{
    button.MuiButtonBase-root{
      margin-bottom: 20px;
      margin-top: 0 !important;
      margin-left: 20px;
    }
  }
  .add-button{
    .confirm_section{
      margin-left: 20px;
      margin-top: 0 !important;
    }
  }
  .commitments-content{
    .radio-button-group{
      margin-bottom: 0;
      .text-field{
        margin-left: unset !important;
      }
      .option-wrapper{
        margin-bottom: 10px;
      }
    }
  }
}

.specific-table-item-tooltip-status-history {
  >div {
    position: relative;
    display: inline-block;
    padding: 5px;

    &:hover .tooltip-box {
      display: block;
    }

    svg {
      display: inline-block;
    }

    .tooltip-box {
      background-color: #fff;
      border-radius: 4px;
      position: absolute;
      bottom: 22px;
      padding: 15px;
      z-index: 99999;
      box-shadow: -8px 12px 16px rgba(0, 0, 0, 0.15);
      border: 1px solid #DBDBDB;
      left: -22px;
      display: none;
      min-width: max-content;

      &::before {
        background: #fff;
        border: 1px solid #e6e6e6;
        border-top: 0;
        border-left: 0;
        content: "";
        height: 10px;
        left: 25px;
        position: absolute;
        bottom: -5px;
        transform: rotate(45deg);
        width: 10px;
      }

      table {
        tr {
          border-bottom: 1px dashed #DBDBDB;

          td:first-of-type {
            min-width: unset !important;
          }

          &:last-child {
            border-bottom: unset;
          }

          th {
            padding: 10px 4px;
            border: none;
            position: unset !important;
          }

          td {
            padding: 8px 4px !important;
            text-align: center;

            &:first-child {
              text-align: left;
            }
          }
        }
      }

      .tooltip-heading {
        font-weight: 500;
        font-size: 14px !important;
        text-align: left;
        color: #000 !important;
        margin-bottom: 10px;
      }

      .tooltip-content {
        width: 240px;
        line-height: 14px;
        text-align: left;
        font-weight: 400;
      }

      .tooltip-heading {
        font-weight: 500;
        font-size: 14px !important;
        text-align: left;
        color: #000 !important;
        margin-bottom: 10px;
      }

      .tooltip-content {
        width: 240px;
        line-height: 14px;
        text-align: left;
        font-weight: 400;
      }
    }
  }
}

.blue-info_icon {
  svg {
    color: #0a3ca2;
    height: 11px;
    width: 11px;
  }

  .tooltip-box {
    left: -20px !important;
    bottom: 24px !important;
  }
}

.Edp_Tooltip_term_level_summary_V2-status-history {
  border-radius: 4px;
  // max-height: 400px;
  // min-height: fit-content;
  min-width: fit-content;
  padding: 9.864px;
  display: flex;
  justify-content: center;
  align-items: center;

  
  .TermLevelSummary_tooltip_style {
      border: 1px solid #DDDFE8;
      border-radius: 4px;
      background: #FFF;
      .TermLevelSummary_tooltip_tableData {
          border-radius: 3px 3px 0px 0px;
          background: #F9F9F9;
          border-bottom: 1px solid #DDDFE8;
          color: #495057;
          text-align: center;
          font-family: Roboto;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 12px !important;
      }
      .TermLevelSummary_tooltip_tableRow {
          border-bottom: 1px solid #DDDFE8;
          text-align: center;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 12px;
          background-color: #fff !important;
      }
      .MuiLinearProgress-root {
          height: 8px;
      }
      
  }
}