.acknowledge_wrapper{

    &.inactive{
        svg{
            fill: #999CA6;
        }
        .MuiFormControlLabel-label{
            color:#898A90;
        }
    }
    .MuiCheckbox-root{
        padding-right: 5px;

    }
    svg{
        fill: #0A3CA2;
    }
    .MuiFormControlLabel-label{
        font-size: 12px;
        color: #000000;
        margin-top: 2px;
    }
}

.Create-Discount-Template{
    line-height: 1  ;
    .serviceType{
        width: 19%;
        float: unset;
        p {
            font-size: 12px !important;
            margin-top: 0.625rem;
            line-height: 1 !important;
            margin-bottom: 9px;
        }
    }
    .template_name {
        // margin-top: 15px;
        .text-area-label{
            margin-bottom: 0 !important;
        }
        .MuiInputBase-root {
            margin-bottom: 0px;
            margin-top: 0px;
        }
    }
    .text-area{
        &.resize-none{
            height: 45px;
            margin-top: 9px !important;
        }
    }
    .text-area-wrapper {
        .text-area-label{
            font-size: 12px !important;
            margin-bottom: 8px;
        }
    }
    .form_component_title{
        margin-bottom: 5px;
    }
    .vertical-line{
        margin: 0;
        margin-bottom: -5px;
        height: 18px;
    }
    .selected-option{
        svg{
            margin-left: 3px;
        }
    }
    .list_selector_Wrapper .list_selector .list_selector_heading{
        line-height: 1;
        div{
            line-height: 1;
        }
    }
    .rounded-div p{
        line-height: normal;
    }
}