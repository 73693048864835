.ck-cudos-months-cards {
  flex-grow: 1;
  background: white;
  border-radius: 5px;
  position: relative;

  .graviton-heading {
    background: #4698d3;
    border-radius: 2px 10px 10px 0px;
    width: fit-content;
    padding: 2px 7px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
  }
  .graviton {
    background: #e6f5ff;
    color: #0c477e;
    line-height: 12px;
    font-size: 12px;
    font-weight: 400;
    padding: 2px 5px;
    border-radius: 2px;
    margin-bottom: 8px;
    width: max-content;
  }
  .graviton-amount {
    line-height: 14px;
    font-size: 14px;
    color: #0c477e;
    font-weight: 700;
  }
  .divider {
    border-right: 1px solid #e6ecf1;
    padding-right: 20px;
  }
}

.rds,
.elasticache,
.data_transfer {
  .gravitonItem {
    width: calc(100% / 2);
  }
  .graviton-content {
    display: flex;
    padding: 15px 10px 20px;
    gap: 10px;
  }
}

.rds {
  .graviton-content {
    padding: 8px 12px 7px;
  }
}
