.curDataTableRadioBtn {
  .MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 12px;
    color: #000000;
    font-weight: 400;
  }

  .MuiRadio-root.Mui-checked+.MuiTypography-root {
    color: #000000 !important;
  }

  label.MuiFormControlLabel-root span.MuiRadio-root.MuiRadio-colorPrimary.Mui-checked{
    color: #0A3CA2 !important
  }
}
.cur-checkBox {
  color:#000000;
  column-gap: 8px !important;
  .checkboxClass {
    height: 14px !important;
    margin: 0 !important;
    width: 14px !important;
  }
}
.curRadioBtnStyle {
  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;

    .icon-info {
      svg.small {
        font-size: 13px;
        top: -7px;
        left: 0;
      }
    }
  }

  &.display-cards {
    padding-top: 5px;
    .option-wrapper {
      flex-direction: row;

      .option {
        border: 1px solid #B1B1B1;
        padding: 10px;
        border-radius: 5px;
      }
      .selected-group{
        background: rgba(246, 250, 255, 0.90);
        border: 1px solid #87B1E2;

      }
    }

    .MuiFormControlLabel-root .MuiFormControlLabel-label {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.85)
    }
  
    .MuiRadio-root.Mui-checked+.MuiTypography-root {
      color: #000000 !important;
    }
  
    label.MuiFormControlLabel-root span.MuiRadio-root.MuiRadio-colorPrimary.Mui-checked {
      color: #0A3CA2 !important
    }
    .MuiRadio-root{
      margin-left: 5px;
      margin-top: -4px;
    }
    label{
      margin-bottom: 0;
    }
  }

  .option-wrapper {
    flex-direction: column;
    align-items: flex-start !important;
    margin-top: 4px;
  }
}

.curRadioTopLabel {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.checkboxLabelClass {
  width: fit-content;
  cursor: pointer;
  column-gap: 5px;
  font-weight: 400;
  font-size: 12px;
  white-space: nowrap;
}

.radioGroupClass {
  column-gap: 20px;
  margin-bottom: 18px;
}

.radioOptionSubLabel {
  font-size: 11px;
  color: #000000;
  opacity: 45%;
  margin-left: 22px;
  margin-top: -4px;
}

.cur-radio-group-Class {
  .checkbox-label-disabled{
    cursor: not-allowed !important;
    column-gap: 5px;
    font-weight: 400;
    font-size: 12px;
    white-space: nowrap;
    opacity: 70%;
    color: #7c7d7e !important;
  }

}

.headingTextPrefix{
  color:#000000;
  opacity: 60%;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  
}

.headingTextSuffix{
  color:#000000;
  padding: 0px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}


.headingTextClass {
  font-size: 14px;
  color: #000000;
  font-weight: 500;
  padding-bottom: 10px;
  line-height: 22px;
}

.descriptionClass {
  padding: 0px !important;
}



.headingTextPrefix {
  color: #000000;
  opacity: 60%;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.headingTextSuffix {
  color: #000000;
  padding: 0px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}