@import "../../../styleguide/contant.scss";

.partner-settings-form {
  div#tabs-cont {
    padding-top: 1.25em;
  }
  .smtp-settings-wrapper {
    .text-field.primary {
      padding-left: 0;
    }
    .text-field.small {
      margin-bottom: 0;
    }
    .createForm {
      .upload-input-wrapper {
        width: 243px;
        margin-top: 10px;
        .partnerWidth {
          // background: red;
          span.Title {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &.manage-logos {
          .image-container {
            width: 199px;
            height: 114px;
            background: #f8f8f8 0% 0% no-repeat padding-box;
            border: 0.800000011920929px dashed $disable-text-color;
            .image-alt {
              background-color: white;
              &.Favicon {
                height: 16px;
                width: 16px;
                border: 1px solid #dedede;
                position: relative;

                &::after {
                  content: "Favicon";
                  bottom: -28px;
                  left: -10px;
                  position: absolute;
                  color: $disable-text-color;
                  font-size: $fs-11;
                }
              }
              span {
                background-color: white;
                border: 1px solid #dedede;
                color: $disable-text-color;
                font-size: $fs-11;
                padding: 15px 20px;
              }
            }
            img {
              max-width: 160px;
            }
          }
          width: 100%;
          .info-container {
            .icon-info svg {
              bottom: 3px;
              left: 0px;
            }
          }
        }
      }
      & > div.flex-wrap {
        .use-existing-checkbox {
          display: flex;
          align-items: center;
          margin-top: 16px;
        }
        .orSeperator {
          top: 7px;
        }
      }
      .input-field-container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .partner-email-settings-wrapper {
    position: relative;
    margin-top: 15px;
    padding-top: 13px;
    padding-bottom: 165px;
    input {
      width: auto;
      margin-bottom: 0;
      border: none;
    }
    .upload-label {
      font: normal normal bold #{$fs-16}/#{$fs-36} Roboto;
      letter-spacing: 0.18px;
      margin-bottom: 15px;
    }
    .partner-email-settings {
      .option {
        input[type="checkbox"],
        input[type="radio"] {
          accent-color: $primary-blue-dark;
        }
      }
      .option-label {
        margin-bottom: 0;
      }
    }
  }
  input[type="checkbox"],
  input[type="radio"] {
    accent-color: $primary-blue-dark;
  }
  .partner-email-settings {
    margin-top: -8px;
    .option-input {
      width: 338px;
      min-height: 255px;
      .key:not(:first-child) {
        margin-top: 1rem;
      }
    }
    .option ~ .option {
      .option-input {
        padding: 11px 16px;

        span.key {
          padding-top: 0;
          color: black;
        }
      }
    }
  }
  .smtp-settings-wrapper {
    .createForm {
      min-height: inherit;
    }
    .border.border-solid.rounded {
      padding-bottom: 10px;
    }
  }
  .form-footer {
    .button.primary {
      min-width: 74px;
      text-align: center;
      justify-content: center;
    }
  }
  .m-5.flex-1.relative.flex.flex-wrap {
    margin-bottom: 20px;
  }

  #tabs-cont {
    .react-tabs__tab {
      height: 46px;
      min-width: 149px;
      margin-right: 2px;
      justify-content: center;
      display: inline-flex !important;
      align-items: center;
      font-size: $fs-14;
      &--selected {
        color: $primary-grey-dark;
      }
    }
  }
  .createInvoice.widget-basic {
    position: relative;
    z-index: 9;
    border-radius: 0 4px 4px 4px;
  }
  #tabs-cont .react-tabs__tab--selected::after {
    bottom: -2px;
    left: 0;
    z-index: 10;
  }
}
.partnerWidth {
  width: 347px;
  height: 32px;
}
#smtpsettingsport {
  max-width: 82px;
  padding-left: 15px !important;
}
.flex.createForm:has(.smtp-settings-wrapper) {
  .partner-email-settings-wrapper {
    padding-bottom: 0;
  }
}
.smtp-settings-encryption {
  .option {
    label.MuiFormControlLabel-root {
      margin-top: 0 !important;
      .MuiRadio-root {
        padding-left: 5px;
      }
    }
  }
}

.disabled-customerType-action {
  svg {
    path {
      fill: #9aa6b6 !important;
    }
  }
}

.ConstructOrdering_wrapper {
  position: relative;
  .table-wrapper-V2 {
    .table-container .table {
      width: 100%;
      span {
        &.disabled-icon {
          color: #8c92a2;
          cursor: not-allowed;
          svg {
            pointer-events: none;
            fill: #8c92a2;
            path {
              stroke: #8c92a2;
            }
          }
          &.delete-icon {
            svg {
              path {
                fill: #8c92a2;
                stroke: #8c92a2;
              }
            }
          }
          &.disabled-apply {
            pointer-events: all;
            span {
              pointer-events: none;
            }
          }
        }
        &.preview-icon {
          svg {
            path {
              stroke: $primary-blue-dark;
            }
          }
        }
      }
      .edit-icon {
        svg {
          width: 15px !important;
          height: 15px !important;
        }
      }
      .preview-icon {
        svg {
          width: 20px !important;
        }
      }
    }
    .reset-icon {
      column-gap: 10px;
      position: absolute;
      width: 100%;
      top: 3px;
      background: transparent;
      span {
        position: absolute;
        top: 18px;
        left: 215px;
        &::after {
          content: "|";
          position: absolute;
          width: 1px;
          top: -1px;
          left: -10px;
          color: #dddfe8;
          font-size: 15px;
          font-weight: 400;
        }
      }
      .button.outlined.disabled {
        background-color: transparent;
        color: #8c92a2;
        border: 1px solid #8c92a2;
        &:hover {
          box-shadow: unset;
          border: 1px solid #8c92a2;
          color: #8c92a2;
        }
      }
    }

    .construct_ordering_field {
      position: relative;
      span.Mui-disabled {
        svg {
          background: #cbcbcb;
          fill: #cbcbcb;
          border: 1px solid #898a90;
          border-radius: 2px;
          height: 11px !important;
          width: 11px !important;
          margin-left: 2px;
        }
      }
      span.MuiButtonBase-root {
        padding: 4px 0;
        svg {
          height: 14px;
          width: 14px;
        }
        &.Mui-checked {
          color: $primary-blue-dark;
        }
        &.Mui-disabled {
          color: #898a90 !important;
        }
      }
      .default {
        font-size: 10px;
        font-weight: 500;
        color: #337ab7;
        padding: 4px 5px;
        background-color: #edf5ff;
        border: 0.7px solid #bddafb;
        border-radius: 2px;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .list-popup-wrapper {
      .show-more {
        background: #d8eaff;
        padding: 4px 7px;
        color: $primary-blue-dark;
        border: 1px solid $primary-blue-dark;
        border-radius: 4px;
      }
    }
  }
}

.modalWrapper-customerAaccount.apply_modal {
  .widget-basic {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
  h2 {
    font-size: 14px !important;
    background: #f0f6ff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    svg {
      display: none;
    }
  }
  .add-user-wrapper {
    margin: 0;
  }
  .calender-wrapper {
    margin-bottom: 5px;
    width: 48%;
  }
  .invoice-customer-wrapper.v2 {
    width: 100% !important;
    margin-bottom: 20px !important;
    margin-top: 10px;
    .text-field {
      color: #151e33;
    }
    .invoice-customer {
      .chip {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px 6px;
        height: 22px;
        font-size: 10px;
        border: 1px solid #dddfe8;
        border-radius: 3px;
        margin-right: 6px;
        color: #151e33;
        max-width: 120px;

        .chip-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .chip-icon {
          display: inline-block;
          margin-left: 6px;
          padding: 3px;

          svg {
            height: 8px;
            width: 8px;

            path {
              fill: #151e33;
            }
          }
        }

        &:hover {
          border: 1px solid $primary-blue-dark;

          .chip-icon {
            background-color: $primary-blue-dark;
            border-radius: 50%;

            svg {
              path {
                fill: $global-white;
              }
            }
          }
        }
      }

      .chip-counter {
        border: 1px solid $primary-blue-dark;
        border-radius: 4px;
        padding: 4px 12px;
        height: 22px;
        font-size: 10px;
        font-weight: 700;
        color: $primary-blue-dark;
        background-color: #d8eaff;
        line-height: 11px;
        position: relative;
      }
    }
    .multi-select-wrapper {
      width: 100%;
      .multi-select-dropdown {
        flex-direction: column;
      }
      .multi-select-dropdown {
        flex-direction: column;
      }
    }
  }
}

.modalWrapper-customerAaccount {
  .modalWrapperCustomerAccountModal {
    padding: 0;
    .templateInfo {
      padding-bottom: 0;
      .text-area-wrapper {
        padding-right: 0 !important;
        textarea {
          font-size: 14px;
          text-align: left;
          color: #000000;
        }
      }
      .styleTemplateInfo {
        .reset_btn {
          &[disabled] {
            svg {
              path {
                fill: #898a90 !important;
              }
            }
            span {
              color: #898a90 !important;
            }
          }
        }
      }
    }
    .default {
      font-size: 10px;
      font-weight: 500;
      color: #337ab7;
      padding: 4px 5px;
      background-color: #edf5ff;
      border: 0.7px solid #bddafb;
      border-radius: 2px;
      position: relative;
      top: 0;
      left: 4px;
    }
  }
  h2 {
    background: #f0f6ff;
    font-weight: 500;
    text-align: left;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid #e8e6e6;
    font-size: 14px !important;
    svg {
      display: none;
    }
  }
}

.ordering_template {
  .Mui-error {
    margin-top: 1px !important;
  }
}
