@import "../contant.scss";
//This is added to reset the modal css externally :
.switchModal_innerWrapper{
    .react-tabs {
        &__tab-panel {
            &--selected {
                display: block;
                padding: 0 !important;
              }
        }
    }
    .switch-modal-table{
        width: 100% !important;
    }
  }
  
  //wrapper of all the tables and loader in the dahsboard :
.new-background{
    position: relative;
    min-height: 60vh;
    box-shadow: 0 7px 7px 0 rgb(0 0 0 / 6%);
    background-color: #fff;
    border-radius: 0 4px 4px 4px;
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;
    padding: 18px;
  }

//   Download button in secondary header styling  
  button.downloadxBtn {
    border: solid 0.01px #138813 !important;
    background-color: #49af49 !important;
    width: 44.8px;
    height: 23px;
    min-width: inherit;
    display: inline-flex;
    align-items: center;
    transition: all 0.3s;
    padding: 0;
    min-width: inherit;
    &:hover {
      box-shadow: -6px 6px 7px 0 rgb(73 175 73 / 22%) !important;
    }
    span {
      height: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    span.xcelIcon {
      width: 60%;
    }
    span.xcelDownloadIc {
      width: 40%;
      border-left: solid 1px #138813;
    }
  }

  #dateRangePicker {
    margin-right: 193px;
    .clear_selection{
      top:54px;
      right:20px;
      z-index: 11;
    }
  }

  
  .clear_selection {
    // opacity: 0;
    display: none;
    // display: flex;
    align-items: center;
    position: absolute;
    top: 47px;
    right: 35px;
    z-index: 9999;
  
    p {
      color: #0a3ca2;
      font-size: 12px;
      margin-bottom: 0;
    }
  
    svg {
      fill: #0a3ca2;
      font-size: 18px;
      margin-left: 3px;
    }
  }
  
  .clear_selection.show {
    opacity: 1;
    display: flex !important;
  }

  .daterangepicker {
    padding-top: 48px!important;
}

.drp-buttons .drp-selected {
    position: absolute;
    top: 18px;
    left: 20px;
  }

  .drp-selected {
    display: inline-block;
    font-size: 12px;
    padding-right: 8px;
  }
  

  .Component_header_wrapper{
    padding: 10px 20px;
    border-bottom: 1px solid $secondary-blueish-grey;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .downArrow{
        width: 25px;
        border-left: 1px solid rgb(10 60 162 / 20%);
        padding-left: 5px;
    }
    .header_text{
        font-size: $fs-26;
        font-weight: 700;
    }
    .breadcrums_wrapper{
        padding: 0px 0;
        padding-bottom: 5px;
        ol{
            li.MuiBreadcrumbs-separator{
                margin: 0;
                svg{
                    width: 17px;
                    margin: 0 5px;
                    path{
                        stroke: white;
                        stroke-width:.8px;
                    }
                }
            }
            li{
                color: #7a7a92;
                font-size: $fs-10;
                font-weight: normal;
                text-transform: capitalize;
                a.MuiTypography-root {
                    color: #7a7a92;
                    font-size: $fs-10;
                    font-weight: normal;
                    text-decoration: none;
                    cursor: pointer;
                    
                    p{
                    color: #7a7a92;
                    font-size: $fs-10;
                    font-weight: normal;
                    &:hover {
                        color: $primary-blue-dark;
                        text-decoration: underline;
                    }
                    }
                }
                &:last-of-type{
                    pointer-events: none;
                }
            }
        }
    }
}

body {
  line-height: 1.42857143;
  color: $common-grey;
  .individual_alert_wrapper {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    .individual_alert {
      width: 49%;
      justify-content: flex-start;
      border: 1px solid #e5e7ed;
      border-radius: 6px;
      position: relative;
      &.focused {
        box-shadow: -3px 5px 7px 0 rgb(51 122 183 / 17%);
        border: 1px dashed $primary-sky-blue;
        background-color: $primary-white;
      }
      .budget_type {
        //   justify-content: flex-start;
        //   align-items: baseline;
        padding:0 10px !important;
        padding-top: 5px !important;
        label {
          font-weight: 500;
          letter-spacing: -0.14px;
          text-align: left;
          color: $primary-grey-light;
          margin-bottom: 0;
          font-size: $fs-14;
          display: flex;
          label{
            margin-right: 0;
          }
          
          span.label{
            margin-top: 10px;
            font-weight: 500;
            font-size: $fs-14;
            color: $primary-grey-light;
            padding: 0;
            &.label_focus {
              color: $primary-blue-dark;
            }
          }
        }
    }
    .individual_alert_parargraph {
      font-size: 0.8em !important;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.1px;
      text-align: left;
      color: $primary-grey-light;
      padding: 0px 31px 20px 32px;
    }
      .exampleWrapper {
        position: absolute;
        width: 100%;
        bottom: 0;
      }
      .step3_examples {
        font-size: 0.8em;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.64;
        letter-spacing: normal;
        text-align: left;
        color: $primary-grey-light;
        margin: 0;
        padding: 10px 20px;
        background-color: $secondary-blueish-grey;
        border-radius: 0 0 4px 4px;

        &.example_focus {
          background-color: #eff8ff;
          border-bottom-right-radius: 6px;
          border-bottom-left-radius: 6px;
          width: 100%;
          color: #0d4d83;
          .bold{
            color: #0d4d83;
          }
        }
      }
    }
  }
  .text_inbetween {
      font-size: $fs-14;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: $secondary-black;
      margin: 0;
      padding: 10px;
  }
  .threshold_type_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-radius: 4px;
    border: solid 0.8px #cfdde5;
    background-color: $primary-white;
    width: 100%;
    margin: 11px 0;
    padding-left: 135px;
    &:first-of-type{
      margin-top: 0;
    }
    .MuiFormGroup-root{
      input{
        padding: 10px;
        font-size: $fs-14;
        font-weight: 500;
        text-align: center;
      }
    }
    img{
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    .alert_text {
      position: absolute;
      left: 20px;
      height: 100%;
      top: 32%;
      font-size: 1em;
      color: $primary-white;
    }
    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
      // padding: 4px 0 5px;
      border: 0;
      box-sizing: content-box;
      background: none;
      height: 1.4375em;
      margin: 0;
      -webkit-tap-highlight-color: transparent;
      display: block;
      min-width: 0;
      // width: 100%;
      // -webkit-animation-name: mui-auto-fill-cancel;
      // animation-name: mui-auto-fill-cancel;
      // -webkit-animation-duration: 10ms;
      // animation-duration: 10ms;
      /* padding: 16.5px 14px; */
    }

    .deletebtn_container {
      padding: 20px;
      background-color: #eef6fe;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      cursor: pointer;
      color: $primary-blue-dark;
      border-left: solid .8px #cfdde5;
    }
    .Mui-checked + .MuiTypography-root.MuiFormControlLabel-label{
      color: $primary-blue-dark;
      font-weight: 500;
    } 
  }
  hr{
    margin: 0;
  }
}