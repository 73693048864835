@import "../../../../styleguide/mixin.scss";

.credits-ordering {
  display: flex;
  align-items: center;
  border: 1px solid #DDDFE8;
  width: max-content;
  padding: 8px 10px;
  border-radius: 4px;

  &.grey-out {
    background: #DDDFE854;

    button {
      background: #FFFFFF !important;
      border: 1px solid #CBCBCB !important;
      color: #898A90 !important;
    }
  }

  &.disabled {
    background: #DDDFE854;

    button {
      background: #FFFFFF !important;
      border: 1px solid #CBCBCB !important;
      color: #898A90 !important;
    }
  }

  button {
    height: 28px;
    font-size: 12px;
    padding: 4px 10px;
  }

  +.bottom-disclaimer {
    position: absolute;
  }

  .Mui-disabled {
    background-color: #ccc !important;
    cursor: not-allowed !important;
    border: none !important;
    color: #4f4f4f !important;
  }
}

.reset_btn {
  @include resetBtn;

  &::before {
    content: none;
  }
}

.credits-ordering-label {
  font-size: 14px;
  font-weight: 500;
}

.radio-group {
  display: inline-flex;
  align-items: center;
  margin-left: 18px;
  margin-right: 25px;

  label {
    display: flex;
    align-items: center !important;
    margin-right: 10px;
    cursor: pointer;

    input {
      margin: 0 !important;
      cursor: pointer;
    }

    .radio-label {
      color: #333;
      font-size: 12px;
      margin-left: 5px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}


.dialog-header {
  &:has(.credit_ordering_modal) {
    background-color: #F0F6FF;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    padding-top: 0;
    display: flex;
    align-items: center;
    border: 1px solid #E8E6E6 !important;
  }
}

.review_section_wrapper {
  .checkbox_label {
    font-size: 12px;
    white-space: nowrap;
    font-weight: 400;
    cursor: pointer;
  }

  input[type="checkbox"] {
    margin-top: 0;
    cursor: pointer;
    &:checked{
      accent-color: $primary-blue-dark;
    }
  }
}

.footer_wrapper {
  background-color: #F0F6FF;
  border-top: 1px solid #E8E6E6 !important;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 10px 1rem;

  button.MuiButton-filled {
    background-color: #0A3CA2;
    color: #ffffff;
  }
}

.dialog_body_order {
  padding: 10px 1rem;

  .draggable {
    border: none;
    padding: unset;

    .drag_card {
      min-width: 300px;
    }
  }
}

.wrapper-support-configuration .MuiPaper-root {
  &:has(.dialog_body_order) {
    min-width: fit-content !important;
    width: unset;

    .draggable {
      overflow-x: auto;
      padding-bottom: 20px;

      > div {
        column-gap: 10px;
        max-height: 450px;
        flex-wrap: wrap;
        row-gap: 3px;
      }
    }
  }
}

@mixin disclaimer-styles {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 5px;

  svg {
    color: #337AB7;
  }

  span {
    font-size: 11px;
    font-weight: 400;
    color: #337AB7;
  }
}

.bottom-disclaimer {
  @include disclaimer-styles;
}

.dialog_body_disclaimer {
  @include disclaimer-styles;
  border: 1px solid #E8E6E6;
  background: #FEF9EB;
  justify-content: right;
  padding: 2px 18px;

  span {
    font-size: 12px;
  }
}

.active-icon {
  position: absolute;
  top: -7px;
  right: -7px;
}