@import '../../../../../styleguide/contant.scss';

.cost_breakup_wrapper {
  margin: 30px 0;
  padding: 1px 0.5px 0.3px;
  border-radius: 3px !important;
  border: 2px solid $grey-border !important;
  background-color: #fff;
  overflow: auto;
  margin-bottom: 20px;
  margin-top: 15px;

  table {
    width: 100%;

    thead {
      th div {
        >.ck--multilevel-header-filter-block {
          position: absolute;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
          top: 0;
          gap: 5px;
          opacity: 0;
          margin-right: 0;
          display: flex;

          span {
            position: relative;
            width: auto;
          }
        }

        &:hover {
          >.ck--multilevel-header-sorting-data {
            opacity: 0;
          }

          >.ck--multilevel-header-filter-block {
            opacity: 1;
          }
        }
      }
    }
  }

  th:last-child,
  th {
    font-size: 12px;
    font-weight: 500 !important;
    color: #495057;
    white-space: nowrap !important;
    background-color: $heading-bg-color;
  }

  td {
    &:first-child {
      font-weight: 500 !important;
    }
  }

  td:last-child,
  td {
    font-size: 11px;
    color: #495057;
    font-weight: normal !important;
    border: none;
    white-space: nowrap !important;
  }

  tr {
    border-bottom: 1px solid #fff !important;
  }

  tfoot #total {
    // background-image: linear-gradient(to bottom, #e0f1ff, #f6fbff 50%, #ffffff)  !important;
    // background-color: #f8f8f8 !important;
    border-bottom: 0 !important;
  }

  tfoot #total td {
    background-color: #EEF6FE;
    color: $primary-blue-dark !important;
    font-size: 12px !important;
    font-weight: 500 !important;

    &:last-of-type {
      font-weight: 500 !important;
    }
  }

  .secondarytotal {
    display: none;
  }

  &.lens-plus-customer {
    border-radius: 5px !important;
    .remove-border::after {
      position: absolute !important;
    }

        * {
          font-family: Inter !important;
        }
    &#cost_breakup_table {
      border: 1px solid #e6ecf1 !important;
    }

    tr:first-child th:nth-child(2) {
      border-right: 1px solid #fff !important;

    }

    tr:first-child th:nth-child(3) {
      border-left: 1px solid #fff !important;

    }

    tr:nth-child(2) th:nth-child(2) {
      border-right: 1px solid #fff !important;

    }

    tr:nth-child(2) th:nth-child(3) {
      border-left: 1px solid #fff !important;
    }

    table:first-child {
      &>thead {
        &>tr {
          border: none !important;

          &>th.move-right {
            &>div {
              display: block !important;
              width: 100%;
              text-align: right;
            }
          }

          &>th {
            background-color: #0A3CA2 !important;
            padding: 2px 8px !important;
            color: white !important;
            height: 24px !important;

            &:first-child {
              &::after {
                right: -1px;
              }
            }

            div {
              div:has(svg) {
                display: none;
              }
            }

            span {
              margin-left: 0px !important;
              font-weight: 600;
              line-height: 13.31px;
            }

            &:last-child {
              &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                box-shadow: -4px 0px 4px 0px rgba(12, 71, 126, 0.3490196078);
                width: 1px;
              }
            }


          }
        }

        &:has(tr:nth-child(2)) {
          &>tr>th:first-child {
            &::after {
              right: -1px !important;
            }
          }
        }

        &:has(tr:nth-child(3)) {
          &>tr>th:first-child {
            &::after {
              right: -1px !important;
            }
          }
        }
      }

      &>tbody {
        tr {
          td {
            border-right: 1px solid #e6ecf1 !important;
            border-bottom: 1px solid #e6ecf1 !important;
            padding: 4px 8px !important;
            font-weight: 500 !important;
            line-height: 14px !important;
            color: #2B2B2B !important;
            
              &:first-child {
                border-right: 0px !important;
            
                &::after {
                  background: #e6ecf1 !important;
                  right: -1px;
                }
            }

          }

          td:last-child {
            font-weight: 600 !important;
            color: #151E33 !important;
            border-left: 1px solid #e6ecf1 !important;
            border-right: 0px !important;
            line-height: 13.31px !important;


            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              box-shadow: -2px 0px 4px 0px rgba(12, 71, 126, 0.3490196078);
              border-left: 1px solid #e6ecf1 !important;
              width: 1px;
            }



          }

          &:nth-child(odd) {
            td {

              background-color: #FFFF !important
            }
          }

          &:nth-child(even) {
            td {
              background-color: #F8F7FC !important;
            }
          }
        }
      }

      &>tfoot {
        tr#total {
          td {
            border-bottom-width: 0px;
            background-color: #E4F4FB !important;
            color: #2B2B2B !important;
            font-size: 11px !important;
            font-weight: 600 !important;
            padding: 2px 8px !important;
            height: 24px !important;
            line-height: 13.31px !important;

            &:not(:last-child)::after {
              display: none;
            }

            &:first-child {
              padding: 2px 10px !important;

            }

            &:last-child {
              border-left: 1px solid #e6ecf1 !important;
              border-right: 0px !important;

              &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                box-shadow: -2px 0px 4px 0px rgba(12, 71, 126, 0.3490196078);
                border-left: 1px solid #e6ecf1 !important;
                width: 1px;
              }
            }

            &:not(:empty):not(:first-child):not(:last-child) {
              border-left: 1px solid #e6ecf1 !important;
              border-right: 0px !important;
            }

            &:empty {
              border-right: 0px !important;
            }
          }
        }

        tr:first-child#others {
          &>td:last-child {
            border-left: 1px solid #e6ecf1 !important;
            border-right: 0px !important;

            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              box-shadow: -2px 0px 4px 0px rgba(12, 71, 126, 0.3490196078);
              border-left: 1px solid #e6ecf1 !important;
              width: 1px;
            }
          }

          &.odd-row-color {
            td {
              background-color: #F8F7FC !important;
            }
          }

          &.even-row-color {
            td {
              background-color: white !important;
            }
          }

          &>td {
            padding: 2px 8px !important;
            font-weight: 500 !important;
            line-height: 14px !important;
            font-size: 11px !important;
            border-right: 1px solid #e6ecf1 !important;
            color: #2B2B2B !important;

            &:last-child {
              font-weight: 600 !important;
            }
          }
        }
      }
    }
  }
}

#multilevel-headerTable {
  .right-td-align {
    text-align: right !important;
  }

  .left-td-align {
    text-align: left !important;
  }

  #total-other {
    text-align: right !important;

    &:first-child {
      text-align: left !important;
    }
  }

  #individual_total {
    text-align: right !important;

    &:first-child {
      text-align: left !important;
      z-index: 100 !important;
    }
  }

  th,
  td {
    text-align: center;
    text-transform: unset !important;

    span {
      display: inline-block;
      margin-left: 4px;
    }

    .cell {
      .cellData {
        display: inline-block;
      }

      svg {
        color: $primary-blue-dark;
      }
    }
  }

  td:first-child,
  th:first-child {
    text-align: left;
  }
}

// .cost_breakup_wrapper_v2 {
//   border-radius: 3px;
//   border: solid 1px #E7ECF5;
//   background-color: #fff;
//   overflow: auto;
//   margin-bottom: 20px;
//   max-height: 65vh;

//   table {
//     width: 100%;
//   }

//   th {
//     font-size: 11px;
//     font-weight: 500;
//     height: 21px;
//     padding: 1px 5px !important;
//     text-align: left !important;
//     color: $primary-blue-dark;
//     white-space: nowrap !important;
//     background-color: $global-background-blue !important;
//     border-bottom: 1px solid $global-white;

//     &:not(:last-child) {
//       border-right: 1px solid $global-white;
//     }
//   }

//   tbody {
//     tr {
//       &:nth-child(odd) {
//         td {
//           background-color: $heading-bg-color !important;
//         }
//       }

//       td {
//         background-color: $global-white !important;
//         padding: 2px 5px !important;
//         text-align: left !important;
//         height: 21px;
//         font-size: 11px;
//         color: $secondary-black;
//         font-weight: 400 !important;
//         white-space: nowrap;
//         border-bottom: 1px solid $global-white;

//         &:not(:last-child) {
//           border-right: 1px solid $global-white;
//         }
//       }

//     }
//   }

//   tfoot #total {
//     border-bottom: 0;
//   }

//   tfoot #total td {
//     background-color: #EEF6FE;
//     color: $primary-blue-dark;
//     font-size: 12px;
//     font-weight: 500;

//     &:last-of-type {
//       font-weight: 500;
//     }
//   }

//   .secondarytotal {
//     display: none;
//   }
// }

.cost_breakup_wrapper.Striped-Table {
  max-height: 65vh;
  margin: 2px 14px 0px 0px !important;
  border: none !important;

  table {
    thead {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 9;

      th {
        height: 28px;
        line-height: 0;
        padding: 7.5px 5px !important;
        text-align: center !important;

        &::before {
          height: 1px !important;
        }

        &:last-child {
          &:after {
            left: 0px !important;
            text-align: right !important;
          }
        }
      }
    }

    tfoot {
      #total {
        position: sticky;
        bottom: 0;
        left: 0;
        z-index: 9;

        td {
          font-size: 11px !important;
          font-weight: 500 !important;

          &:first-child {
            background-color: $primary-blue-dark !important;
            color: $global-white !important;
            font-size: 12px !important;
            text-align: left;
            padding-top: 5px !important;
          }
        }
      }

      #others {
        td {
          color: #000000 !important;
          font-weight: 400px !important;

          &:not(:first-child) {
            text-align: right !important;
          }

          // &:last-child {
          //   color: $primary-blue-dark !important;
          //   font-weight: 500 !important;
          //   background-color: #EFF4FF !important;
          // }
        }

        &:hover {

          td,
          td:last-child {
            background-color: $global-background-blue !important;
          }
        }
      }

      .even-row-color {
        td {
          background-color: #F7F7F7 !important;
        }
      }

      .odd-row-color {
        td {
          background-color: #FFFFFF !important;
        }
      }

      td {
        color: $secondary-black;
        height: 20px;
        padding: 3.5px 5px !important;
        font-size: 11px !important;
        font-weight: 400 !important;
        background-color: inherit;
      }

      tr {
        &:last-child {
          td {
            &::before {
              height: 1px;
            }
          }
        }
      }
    }
  }

  &.last-column-sticky {
    table {
      tr {
        td {
          line-height: 9px !important;
          padding: 5px !important;

          &:last-child {
            background-color: #EAF1FF !important;

            &:after {
              position: absolute;
              content: '';
              width: 1px;
              height: 100%;
              top: 0;
              left: 1px;
              background-color: #fff;
            }

            &:nth-last-child(2) {
              border-right: 0 !important;
            }
          }

          &:first-child {
            &:after {
              position: absolute;
              content: '';
              width: 1px;
              height: 100%;
              top: 0;
              background-color: #fff;
            }
          }
        }
      }

      th,
      td {
        &:last-child {
          position: sticky;
          top: 0;
          right: -1px;
          z-index: 1;
          width: 75px;
          text-align: right !important;

          &:after {
            position: absolute;
            content: '';
            width: 1px !important;
            height: 100%;
            top: 0;
            left: 0px !important;
            background-color: #fff;
          }
        }
      }

      th {
        &:last-child:not(.no-background-color) {
          background-color: #0A3CA2 !important;
          color: white !important;
        }
      }
    }
  }

  &.only-first-column-sticky {
    table {
      tr {
        td {
          line-height: 9px !important;
          padding: 5px !important;

          &:last-child {

            &:after {
              position: absolute;
              content: '';
              width: 1px;
              height: 100%;
              top: 0;
              left: 1px;
              background-color: #fff;
            }

            &:nth-last-child(2) {
              border-right: 0 !important;
            }
          }

          &:first-child {
            &:after {
              position: absolute;
              content: '';
              width: 1px;
              height: 100%;
              top: 0;
              background-color: #fff;
            }
          }

          &:last-child {
            color: #000000;
          }
        }
      }

      th,
      td {
        &:last-child {
          border-left: none !important;
          position: relative !important;
          top: 0;
          right: 0px;
          z-index: 1;
          width: 75px;
          text-align: right !important;

          &:after {
            position: absolute;
            content: '';
            width: 1px !important;
            height: 100%;
            top: 0;
            left: 0px !important;
            background-color: #fff;
          }
        }
      }
    }
  }

  .display {
    height: 100px;
    border-bottom: 1px solid#C3D8FF;
    border-left: 1px solid #C3D8FF;
    border-right: 1px solid #C3D8FF;

    &:hover {
      background: none !important;
    }

    &>td {
      table {
        .utilization-table-wrapper {
          width: calc(100% + 26px);
          margin-left: -13px;
          margin-bottom: 0;

          tr {
            th {
              &:first-child {
                width: 170px;
              }
            }
          }
        }
      }
    }
  }
}

.remove-border {
  border: none !important;
}

.remove-border::after {
  position: unset !important;
}

.add-border::after {
  position: absolute !important;
  content: "";
  width: 1px !important;
  height: 100%;
  top: 0;
  background-color: #fff;
}

.no_table-data-found {
  color: $primary-blue-dark;
  font-weight: 500;
  font-size: $fs-12;
  height: 142px;
  min-width: 355px;
  display: flex;
  justify-content: center;
  align-items: center;
}