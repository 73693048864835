@import "../../../../styleguide/contant.scss";

.MuiDialog-container {
  align-items: flex-start !important;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;

  .MuiPaper-root {
    top: 30px;

    .delete-dialog-title,
    .delete-dialog-body,
    .delete-dialog-footer {
      display: flex;
      justify-content: center;
    }

    .delete-dialog-title {
      padding: 24px 0 10px 0;
      font: normal normal bold #{$fs-23}/#{$fs-26} Roboto;
    }

    .delete-dialog-body {
      margin: 0 auto;
      width: 70%;

      p {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: black;
        font: normal normal normal #{$fs-14}/#{$fs-19} Roboto;
        text-align: center;
        margin-bottom: 15px;

        svg {
          margin-bottom: 15px;
        }
      }
    }

    .delete-dialog-footer {
      background: $modal-footer-background;
      border-radius: 0px 0px 4px 4px;
      padding: 15px 0;
      border-top: 1px solid #dedede;
    }

    .place_order_popup {
      padding: 15px 20px 14px;
      justify-content: flex-end;

      button {
        font-weight: 500;
      }
    }
  }

  .MuiPaper-root[aria-labelledby="alert-delete-dialog"] {
    min-width: 289px;
  }

  .MuiPaper-root[aria-labelledby="alert-delete-dialog vri_popup"] {
    min-width: unset !important;
    width: 402px !important;
    height: 319px !important;

    .delete-dialog-body {
      p {
        svg {
          height: 111px;
        }
      }
    }
  }
}

.V2-container .MuiDialog-container {
  align-items: flex-start !important;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;

  .MuiPaper-root {
    top: 0;
    max-width: 633px;
    height: 336px;
    align-self: center;

    .delete-dialog-title {
      background-color: #0A3CA2;
      justify-content: flex-start !important;
      padding-left: 20px !important;
      color: #fff;
      border-radius: 4px 4px 0px 0px;
      font-size: 20px !important;
      font-weight: 700 !important;
      line-height: 34px;
      letter-spacing: -0.01em;
      text-align: left;
      height: 51px;
    }

    .delete-dialog-title,
    .delete-dialog-body,
    .delete-dialog-footer {
      display: flex;
      justify-content: center;
    }

    .delete-dialog-body {
      margin-top: 45px !important;
      width: 633px;
      height: 155px;
      top: 355px;
      left: 461px;
      border: 1px;
    }

    .delete-dialog-title {
      padding: 13px 0 10px 0;
      font: normal normal bold 23px/26px Roboto;
    }

    .delete-dialog-body {
      margin: 0 auto;

      p {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: black;
        font: normal normal normal 14px/19px Roboto;
        text-align: center;
        margin-bottom: 15px;
        font-weight: 700 !important;

        svg {
          margin-bottom: 15px;
        }
      }

      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: -0.01em;
        text-align: center;

      }
    }

    .delete-dialog-footer {
      background: #f0f6ff;
      border-radius: 0px 0px 4px 4px;
      padding: 15px 0;
      border-top: 1px solid #dedede;
      padding: 10px 0;

      button {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        height: 34px;
      }
    }

    .delete-dialog-footer {
      justify-content: flex-end !important;
      padding-right: 20px !important;
    }
  }

  .MuiPaper-root[aria-labelledby="V2-container"] {
    min-width: 633px;
    height: 338px;
    display: flex;
    justify-content: center;
    align-self: center;
    margin-top: 0;
    top: 0px;
  }

  .MuiPaper-root[aria-labelledby="V2-container"] {
    min-width: 633px;
    height: 338px;
    display: flex;
    justify-content: center;
    align-self: center;
    margin-top: 0;
    top: 0px;
  }
}

.aws-onboard {
  .MuiDialog-paper {
    padding: 40px 30px 30px 30px !important;
    align-self: center;
    top: 0;
    margin: 0;

    svg {
      margin-bottom: 30px !important;
    }

    h2 {
      padding: 0 !important;
      margin-bottom: 20px !important;
      font-weight: 700 !important;
      font-size: 24px !important;
      line-height: 24px !important;
    }

    p {
      font-weight: 500 !important;
      font-size: 14px !important;
    }

    .delete-dialog-footer {
      background: none !important;
      border-top: none !important;
    }

    .delete-dialog-body {
      width: 100%;
    }

    button {
      width: 100%;
      padding: 17px;
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
      word-spacing: 2px;
      height: 50px;
    }
  }
}

#delete_popup {
  .delete-dialog-footer {
    justify-content: center;
    padding: 15px 0;
  }
}