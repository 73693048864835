@import "../../../styleguide/contant.scss";

.filter_Section{
  padding: 5px;
  margin: 10px 0;
  border-radius: 3px;
  border: solid 1.2px #e6e6e6;
  background-color: #f8f8f8;
  .MuiFormControl-root{
      // width: calc(100% / 6 - 10px);
      // margin: 5px;
      // min-width: 190px;
      margin: 10px;
      .MuiSvgIcon-root{
          font-size: 1.2rem;
          margin-top: 2px;
          margin-right: 5px;
      }
      &>p{
          font-size: 11px;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          color: #151e33;
          margin-bottom: 3px;
      }
      .MuiOutlinedInput-root{
          line-height: 1;
          color: #898a90;
          .MuiSelect-select{
              font-size: 12px;
              min-height: 32px;
              padding: 0px 5px;
              box-sizing: border-box;
              line-height: 32px;
              border: solid 1px #dddfe8;
              border-radius: 4px;
              background: #fff;
              padding-right: 15px;
              display: flex;
              align-items: center;
          }
          fieldset.MuiOutlinedInput-notchedOutline{
              display: none;
          }
        
      }
  }
}
.tags_container.filterTags{
position: sticky;
top: 0;
z-index: 9;
border-bottom: solid 1px #A6BCC9;
&:empty{
  border-bottom: 0;
}
}
// .filter_Section{
  ul[aria-labelledby="multiSelect_checkboxList"]{
      max-width: 214px;
      padding-right: 0 !important;
      li{
          .MuiListItemIcon-root{
              min-width: unset;
              span.MuiButtonBase-root {
                  padding: 0;
                  color: #999ca6;
                  &:hover{background: none;}
                  .MuiTouchRipple-root{
                      color: #337ab7;
                  }
                  .MuiIconButton-label{
                      svg{
                          font-size: 1.2rem;
                      }
                      input[type=checkbox] {
                        margin: 0 !important;
                      }
                  }
                  &.Mui-checked{
                      color: #337ab7;
                  }
              }
          }
          .MuiListItemText-root{
              margin: 0 5px;
              .MuiTypography-root {
                  font-size: 12px;
                  letter-spacing: normal;
                  text-align: left;
                  color: #555b6c;
                  max-width: 140px;
                  margin: 0 !important;
                  display: block;
                  // text-overflow: ellipsis;
                  // overflow: hidden;
                  overflow-wrap: break-word;
                  line-height: 1.2;
                  font-weight: 400;
      
              }
          }
      }
      .makeStyles-indeterminateColor-2{
          color:#337ab7 !important ;
          .MuiListItemText-root{
              .MuiTypography-root{
                font-weight: 500;
                color: #000;  
              }
          }
      }
      .makeStyles-selectedAll-4{
          .MuiListItemText-root{
              .MuiTypography-root{
                font-weight: 500;
                color: #000;  
              }
          }
      }
      .MuiListItem-gutters{
         padding: 4px 10px;
          background: transparent;
          &.Mui-selected{
              .MuiTypography-root {
                  color: #000;
                  font-weight: 500;
              }
          }
      }
      }
// }



div#menu-{
  .MuiPaper-elevation8.MuiPaper-rounded{
      border-radius: 3px;
  box-shadow: #00000014 0px 9px 10px 0px;
  border: 1px solid rgb(166, 188, 201);
  background-color: rgb(255, 255, 255);
  }
  &.MuiPopover-root{

  }
}
.tags_container {
display: flex;
align-items: center;
span.tags {
    padding: 4px 5px 3px;
    border-radius: 4px;
    border: solid 0.5px #c5e2fa;
    background-color: #f0f5fa;
    line-height: 1;
    margin-right: 0px;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: -0.2px;
    text-align: center;
    color: #151e33;
    max-width: 62px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    white-space: nowrap;
}
span.tags_length{
    padding: 4px 5px 3px;
    border-radius: 3px;
    background-color: #0a3ca2;
    border: solid 0.5px #0a3ca2;
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    line-height: 1;
}
}
.placeholder_text{
  position: absolute;
  top: 22px;
  z-index: 9;
  left: 6px;
  padding: 4px 5px 3px;
  border-radius: 3px;
  font-size: 10px;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  color: #acadb1;
  line-height: 1;
}

.full_length.tags{
  overflow: unset !important;
  text-overflow: unset !important;
  max-width: unset !important;
}

// .search_filter{
//   margin: 10px;
//   padding: 8px 5px;
//   max-width: 90%;
//   border-radius: 4px;
//   border: 1px solid #dddfe8;
// }

#includeBox {
  position: absolute;
  right: 14px;
  top: 53px;
  font-size: 1rem;
  .MuiFormControl-root {
      width: 100%;
      margin-bottom: 0 !important;
      .MuiSelect-select.MuiSelect-select {
          padding: 4px 0 !important;
          font-size: 12px;
          text-align: right;
          padding-right: 24px !important;
          padding-left: 0;
          background: #fff;
      }
  }
}

.MuiListItem-button.MuiMenuItem-gutters{
      font-size: 12px !important
}


[id^="filter_div"]{
  display: inline-flex;
  margin-left: 5px;
  position: relative;
  width: 10px;
  height: 10px;
  margin-right: 4px;
  svg{
    cursor: pointer;
  }
  .MuiFormControl-root {
      width: 10px;
      position: absolute;
      left: 0;
      .open_filters{
        position: fixed;
        z-index: 9999;
      }
      .placeholder_text,.tags_container{
          display: none;
      }
      
      .MuiSelect-nativeInput{
          width: 0;
          height: 0;
          padding: 0;
          margin: 0;
          border: 0;
      }
     
      .MuiSelect-select{
          background: none;
          border: 0;
          min-height: inherit;
          position: relative;
          // border-top: 8px solid gray;
          // box-sizing: border-box;
          // border-right: 6px solid transparent;
          // border-left: 6px solid transparent;
          width: 10px;
          height: 12px;
          // box-shadow: inset 0 14px grey;
          padding: 2px !important;
      }
     
      .MuiOutlinedInput-root{
          border: 0;
          // background: red;
          &>svg{
              transform: scale(0.95);
              top: 4px !important;
              left: 0;
              margin: 0;
              height: 13px;   
              width: 10px;    
              color: #0A3CA2;
              // path{
              //     fill: none;
              //     stroke: currentColor;
              //     stroke-width: 0.2;
              // }
          }
          &>svg.MuiSelect-iconOpen{color: #bedcf5;}
      } 
      .MuiSelect-select[aria-expanded="true"]{
          // border-top-color: black;
          // box-shadow: inset 0 4px black;
          
      }
  }
  &.custom_position {
      display: inline;
  }
  &>div.MuiFormControl-root{
    .MuiOutlinedInput-root {
      &>svg{
        top: 0;
      }
    }
  }
  &.hide-seperator{
    margin-left: 0 !important;
    &::after{
      display: none;
    }
  }
}
.numeric_filter{
.MuiPopover-paper{
  overflow: unset;
  .MuiBox-root{
    padding: 10px 20px;
  }
  .MuiSlider-root{
    padding: 7px 0;
    margin-top: 3px;
    .MuiSlider-thumb{
      width: 15px;
      height: 15px;
    }
    .MuiSlider-valueLabel{
      font-size: 12px;
      line-height: 1;
      padding: 0.25rem 0.45rem;
    }
    
  }
}
}
.numeric_filter .MuiPopover-paper {
overflow: unset !important;
}
.numberFilter {
svg {
  color: transparent;
  transform: scale(0.95) !important;
  height: 12px;
  width: 10px;
  margin-top: -1px;
  &[aria-describedby="simple-popover"]{
    color: #bedcf5;
  }
}
}

.number_filter_wrapper{
  display: flex;
  max-width: 260px;
  .MuiTextField-root{
    flex: 1;
  }
}



.filters_options{
  position: sticky;
  bottom: 0;
  background: #ffffff;
  padding: 8px;
  border-top: 1px solid #e6e6e6;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 99999;
  button.MuiButtonBase-root{
    text-transform: capitalize;
    font-weight: 500 !important;
    font-size: 12px !important;
    min-height: 32px !important;
    height: 32px;
    border-radius: 4px;
    margin-right: 10px;
    &.apply_btn{
      background: #0A3CA2 !important;
      color: #fff !important;
      border:1px solid #0A3CA2;
      padding: 0;
      margin-right: 0;
    }
    &.disabled_apply_btn{
      background: #555b6c !important;
      color: #9aa6b6 !important;
      box-shadow: none;
      padding: 0;
    }
  }
  // &:after{
  //   position: absolute;
  //   content: '';
  //   width: 100%;
  //   height: 2px;
  //   background: #fff;
  //   bottom: -1px;
  //   left: 0;
  // }
}

.MuiList-padding{
  padding-bottom: 0 !important;
  text-align: right;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded{
  // padding: 20px 15px;
  h5{
      font-weight: 500;
      font-size: 14px;
  }
  p:not(.ri_tenure){
      font-size: 12px;
      color: #7C7D7E;
      margin-top: 5px;
  }
}

#outlined-basic{
  // border: 1px solid #8b8b8b !important;
  padding: 3px !important;
  width: 100px;
}

.values_wrapper{
width: 100%;
margin-top: 15px;
&:first-child{
    margin-right: 20px;
}
.MuiFormControl-root{
    width: 100px;
    margin-top: 8px;
}
#outlined-basic {
  // border: 1px solid #8b8b8b!important;
  padding: 3px !important;
  width: 100px;
}
input{
  margin-bottom: 0 !important;
}
}

// .css-187mznn-MuiSlider-root{
//     color: #151e33 !important;
// }

.controller_wrapper{
  padding: 12px 15px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.filterTags{
// padding: 5px;
// border-bottom: solid 1px #A6BCC9;
background: #fff;
border-radius: 4px 4px 0 0;
outline-offset: -1px;
width: 100%;
span.tags{
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}
span.tags_length{
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
}



}



[id^=menu-]{
&.MuiPopover-root{
  .MuiPaper-root.MuiPaper-rounded{
    box-shadow: 0px 9px 10px #00000015;
    border: 1px solid #e6e6e6 !important;
    max-height: 400px !important;
    max-width: 200px;
    .filterInputContainer{
      text-align: left;
      .search_filter{
        margin: 10px;
      }
    }
    // .search_filter{
    //   height: 34px;
    //   // max-width: 88%;
    //   font-size: 12px;
    //   min-width: 170px;
    //   margin-bottom: 7px;
    // }
    &>ul{
      padding-top: 0;
      border: 0 !important;
      &>.multiButtons{
        border: 0 !important;
        border-top: solid 1px #e6e6e6 !important;
      }
    }
    #includeBox {
      position: static;
      max-width: 94px;
      margin-left: auto;
      margin-right: 13px;
      top: auto;
      margin-bottom: 5px;
  }
  }
}
}
div#includeBox{
.MuiInput-underline{
  border-bottom: solid 1px #e6e6e6;
  background: #fff !important;
  &.Mui-focused{
    background: #fff !important;
  }
  &::after,&::before{
    display: none;
  }

}
}
[id^=menu-] ~ [id^=menu-]{
&.MuiPopover-root{
  .MuiPaper-root.MuiPaper-rounded{
    box-shadow: 0px 9px 10px #00000015;
    border: 1px solid #e6e6e6;}
  }
 
  }



  [id^=menu-].MuiPopover-root .MuiPaper-root.MuiPaper-rounded{
    .MuiMenuItem-root{
      white-space: unset !important;
    }
  }
  .tableBodyIcons {
    .MuiButtonBase-root {
      position: absolute !important;
    }
  }
  
  .dates_filter.MuiModal-root{
    .dateRange_pickerBtn{
      height: inherit;
      &.doubleCalendar{
        .clear_selection{
          top: 20px !important;
          left: 500px;
          white-space: nowrap;
          width: 100px;
          &.left {
            left: -104px;
          }
          p{
            margin-top: 0;
            color: #0a3ca2;
          }
        }
        .daterangepicker{min-width: 625px;}
      }
    }
  }

  .open_filters{
    max-height: 284px;
    background-color: #ffffff;
    width: 284px;
    border: 1px solid #dedede;
    box-shadow:-3px 5px 27px 0 rgba(0,0,0,.22);
    overflow-y: auto ;
    margin-top: 6px;
    margin-left: 6px;
    overflow-x: clip;
    z-index: 9999;
    position: fixed;
    border-radius: 4px;
    .filters_options{
      position: sticky;
      bottom: -2px;
      left: 0;
      background-color: #f0f6ff;
      button{
        padding: 6px 10px;
        height: unset;
      }
    }
    .checkbox_container{
      display: flex;
      align-items: center;
      padding: 4px 10px;
      flex-direction: row-reverse;
      justify-content: start;
      .checkbox_label{
        color: #000;
        display: block;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 1.2;
        margin: 0 0 0 27px !important;
        max-width: 234px;
        overflow-wrap: break-word;
        text-align: left;
        text-transform: initial;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      input[type=checkbox]{
        width: 20px;
        margin: 0;
        margin-right: 10px;
      }
    }
      .Selected_list {
              display: flex;
              align-items: center;
              padding: 5px;
              border-bottom: 1px solid #a6bcc9;
              position: sticky;
              top: 0;
              background: #ffffff;
              z-index: 9;
              span.tags {
                  padding: 4px 5px 3px;
                  border-radius: 4px;
                  border: solid 0.5px #c5e2fa;
                  background-color: #f0f5fa;
                  line-height: 1;
                  margin-right: 5px;
                  font-size: 10px;
                  font-weight: 500;
                  letter-spacing: -0.2px;
                  text-align: center;
                  color: #151e33;
                  max-width: 62px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  text-transform: initial;
              }
              span.tags_length{
                  padding: 4px 5px 3px;
                  border-radius: 3px;
                  background-color: #0a3ca2;
                  border: solid 0.5px #0a3ca2;
                  font-size: 10px;
                  font-weight: 500;
                  text-align: center;
                  color: #fff;
                  line-height: 1;
              }
          }
  }

  .checkbox_container {
    display: block;
    position: relative;
    padding-left: 35px;
    // margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .checkbox_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    left: 12px;
    height: 15px;
    width: 15px;
    border:  1px solid #0a3ca2;
    border-radius: 4px;
    background-color: #ffffff;
    &:hover {
      background-color: #0a3ca2!important;
    }
  }
  
  /* On mouse-over, add a grey background color */
  .checkbox_container:hover input ~ .checkmark {
    background-color: #0a3ca2;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox_container input:checked ~ .checkmark {
    background-color: #0a3ca2;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox_container input:checked ~ .checkmark:after {
    display: block;
  }

  .checkbox_container input:checked ~ .checkbox_label{
    font-weight: 500 !important;
    color: #000000 !important;
  }
  
  /* Style the checkmark/indicator */
  .checkbox_container .checkmark:after {
    left: 4px;
    top: 0;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(45deg);
}

.select_box_container{
  display: flex;
  justify-content: end;
  margin: 8px;
  padding: 5px;
  select{
    background-color: #ffffff;
    border-bottom: 1px solid #dddfe8;
    color: #0A3CA2;
    font-size: 10px;
    cursor: pointer;
  }
}

.filter_no_data_wrapper{
  height: 200px;
  position : relative ;
  .info_icon{
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);     
    svg{
      height: 30px;
      margin-bottom: 20px;
      width: 100%;
    }   
  }
}

.filterInputContainer{
  text-align: left;
  .search_filter{
    margin: 10px;
    padding: 5px 10px;
    width: calc(100% - 20px);
  }
}

.select_box_container{
  width: calc(100% - 10px);
}
.select_box_container.ck-filter-mode-selector--wrapper {
  justify-content: space-between !important;
  margin-bottom: 7px !important;
  width: 100%;
  padding: 0px 12px !important;
}

.ck-filter--clear {
  color: #333;
  font-size: 11px;

  &:hover {
    text-decoration: underline;
  }
}