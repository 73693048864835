.ck-dynamicfields-wrapper {
    .add-icon {
        &:hover {
            path {
                fill: white;
                stroke: white;
                stroke-width: 1.5px;

                &:first-child {
                    fill: #0A3CA2;
                    stroke: #0A3CA2;
                }
            }
        }
    }
}