@import "../../../../styleguide/contant.scss";

.label_input_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 312px;

  .right_container {
    .input_container {
      position: relative;
      max-width: 120px;
      margin: 0 auto;

      .input_label {
        border: 1px solid$secondary-grey;
        font-size: $fs-12;
        margin-bottom: unset;
        padding: 6px 5px;
        padding-right: 35.10px;
        text-align: center;

        &::placeholder {
          font-size: $fs-12;
        }

        .sub_heading {
          font-weight: 400;
          font-size: $fs-10;
          line-height: 12px;
        }
      }

      .right_container {
        .input_label {
          background-color: transparent;
          border: 1px solid$secondary-grey;

          &::placeholder {
            font-size: $fs-12;
          }
        }
      }

      .input_box_suffix {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1px;
        font-size: $fs-12;
        // background-color: #f8f8f8;
        border-left: 1px solid $secondary-blueish-grey;
        padding: 5px 6px;
        height: calc(100% - 2px);
        color: $disable-text-color;
        // padding-top: 10px;
        display: flex;
        align-items: center;
      }

    }
  }

  .infoIcon {
    font-size: $fs-10;
  }
}

.text_area_content {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.text-area {
  min-height: 45px;
}

.truncate {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.read-only-view {
  border: 0.8px solid #E0F1FF;
  background: #FDFEFF;
  border-bottom: 0;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.dropdown-wrapper.disabled {
  .refresh_template {
    svg {
      display: inline-block;
      margin-right: 5px;

      path {
        fill: #8C92A2;
      }
    }

    label {
      font-size: 11px;
      font-weight: 300;
      color: #8C92A2;
      margin-top: 3px;
    }

  }

  .dropdown {
    .header.filled {
      background-color: #ebeef7;
      color: #898a90;
      font-weight: 500;
    }

    background-color: #ebeef7;
    border-radius: 4px;
    cursor: not-allowed;
  }
}

.multi-select.list-disabled {
  .multi-select-dropdown {
    min-height: 206px;
    max-height: 206px;

    .option {
      background-color: #F8F8F8 !important;
      height: 30px;

      .MuiFormControlLabel-label {
        color: #646464 !important;
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
        pointer-events: none;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .MuiFormControlLabel-root {
        cursor: auto !important;
      }

      .MuiCheckbox-root {
        cursor: not-allowed;
        opacity: 0.5;
      }

      .MuiCheckbox-root.Mui-disabled {
        cursor: no-drop !important;
        pointer-events: visible !important;
      }
    }
  }
}


.group_multiSelect {
  .search-wrapper {
    padding: 0 !important;
    border-radius: 0 !important;

    .search-bar {
      width: 100%;
      max-width: 100% !important;
      margin: 0 !important;
      padding: 0 8px 0 0 !important;
      border: none;

      input {
        border-radius: 0 !important;
        padding: 11px 13px;
      }
    }
  }

  .option {
    position: relative;
    width: 100% !important;
    padding: 7px 13px !important;

    &:has(.tag) {
      border-top: 1px dashed #DDDFE8;
    }

    .tag {
      position: absolute;
      display: flex;
      align-items: center;
      right: 5px;
      top: 4px;
      background-color: #E0F1FF;
      border: 1px solid #CFE5F6;
      border-radius: 4px;
      padding: 2px 4px;
      padding-right: 12px;

      &::after {
        position: absolute;
        content: "";
        height: 6px;
        width: 6px;
        background-color: #24A148;
        border-radius: 50%;
        right: 3px;
        top: 4px;
      }

      span {
        font-size: 9px;
      }

      .group_name {
        font-weight: 500;
      }
    }

    .MuiFormControlLabel-root {
      &.Mui-disabled {
        margin-left: 0;
      }
    }

  }

  .multi-select-dropdown {
    flex-direction: column !important;
  }

  position: relative;

  span.Mui-disabled {
    svg {
      background: #cbcbcb;
      fill: #cbcbcb;
      border: 1px solid #898a90;
      border-radius: 2px;
      height: 11px !important;
      width: 11px !important;
      margin-left: 2px;
    }
  }

  span.MuiButtonBase-root {
    padding: 4px 0;

    svg {
      height: 14px;
      width: 14px;
    }

    &.Mui-checked {
      color: $primary-blue-dark;
    }

    &.Mui-disabled {
      color: #898a90 !important;
    }
  }

  .default {
    font-size: 10px;
    font-weight: 500;
    color: #337ab7;
    padding: 4px 5px;
    background-color: #edf5ff;
    border: 0.7px solid #bddafb;
    border-radius: 2px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.readonly_text_area {
  min-height: unset !important;

  textarea {
    border: none !important;
    padding: 0 !important;
    min-height: unset !important;
  }
}