.potentialDashboard{
    .heading{
        border-bottom: 0 !important;
        margin: 0 !important;
        color: #000!important;
        font: normal normal 700 24px/30px Roboto!important;
    }
    svg > g[class$="parentgroup"] {
        display: inline !important;
    }
    svg > g[class^="raphael-group-"] {
        display: none;
    }
    .potential_graph_container{
        .fusion_chart_wrapper{
            padding: 10px;
        }
        [class$=-dataset-Label-group] {
            text{
                font-size: 10px;
                font-weight: 400;
            }
        }
    }
}