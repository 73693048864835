@import "../../../../styleguide/contant.scss";

.header-comp-div{
    background-color: #EBF1FE;
  }
  
  .form-border-seperator{
    border-bottom: 1px solid #F2F4F8;
    margin: 12px 0px;
  }
  
  .header-component-div{
    box-shadow: -7px 14px 38px #343a4015;
  }

  .multi-header-tooltip-class{
    .MuiTooltip-tooltip{
      max-width: 700px !important;
    }
    
  }

  .add-btn-wrapper{
    display: flex;
    font-size: 12px;
    background: white;
    padding: 2px 5px;
    border: 1px solid #0a3ca2;
    color: #0a3ca2;
    border-radius: 4px;
    align-items: center;
  }
  .customer-wrapper {
    > div {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      > * {
        flex: 0 0 auto;
        width: 31.5% !important;
      }
    }
  }
  fieldset {
    display: block;
    margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start: 0.35em;
    padding-inline-start: 0.75em;
    padding-inline-end: 0.75em;
    min-inline-size: min-content;
   border: 1px solid #dddfe8;
   border-radius: 4px;
   margin-bottom: 20px;
  }

legend {
  width: unset !important;
  display: block;
  padding-inline-start: 2px;
  padding-inline-end: 2px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  font-size: 14px;
  font-weight: 700 !important;
}
