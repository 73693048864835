.v2_label_style {
    color: #0A3CA2;
    font-family: Roboto;
    font-size: 10px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
}

.download_button_style {
    border-radius: 4px;
    border: 1px solid var(--Blue, #0A3CA2);
}

.v2_custom_select {
    padding-top: 9px !important;
    padding-bottom: 5px !important;
    min-width: 128.118px !important;
}

.buttonGrpCustomClass {
    margin-right: 1px !important;

    .group_btn {
        padding: 5px 10px !important;
        width: auto !important;
        cursor: pointer;
    }
}

.excelWrapperClass {
    .options_list {
        z-index: 1000 !important;
        right: 16px !important;
        top: 49px !important;
        min-width: 119px !important;
        box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.15);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        max-height: 300px;
        overflow: auto;
    }

    .options_list {
        p {
            padding: 0px 15px !important;

            .Mui-checked {
                svg {
                    path {
                        fill: #0a3ca2 !important;
                    }
                }
            }

            svg {
                fill: #D9D9D9 !important;
            }

            label {
                span:last-child {
                    color: #000000 !important;
                }
            }
        }

        p.selected {
            background-color: #F5F5F5 !important;

            label {
                span:last-child {
                    font-weight: 500 !important;
                    color: #0A3CA2 !important;
                }
            }
        }

        p:first-child {
            padding-top: 2px !important;
        }

        p:last-child {
            padding-bottom: 2px !important;
        }

        span {
            font-size: 12px !important;
            font-weight: 400 !important;
        }
    }

    #download_now {
        margin: 0px !important;
        font-weight: 500;
    }
}

.excelWrapperClass {
    .download_v2{
        padding-right: 6px !important;
        padding-left: 6px !important;
    }
    .disable_v2 {
        background-color: #E9E9E9 !important;
        border: 1px solid #E9E9E9 !important;
        color: #8B8B8B !important;
    }
    .excel-dwnld-custom{
        margin-right: 0px !important;
    }
}