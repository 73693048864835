.section-list-wrapper {
  padding: 6px 10px 10px 10px;
  background-color: #F7F7F7;
  position: relative;
  min-height: 120px;
  border-radius: 4px;

  .section-item-list {
    display: flex;
    gap: 10px;
    overflow-x: auto;

    .group {
      border: 1px solid #DDDFE8;
      min-width: 278px;
      border-radius: 4px;
      overflow: hidden;
      background: #fff;

      .group-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px;
        background: #F0F6FF;
        border-bottom: 1px solid #DDDFE8;

        .group-title {
          font-size: 11px;
          font-weight: 500;
          text-align: left;
          display: flex;
          align-items: center;
          gap: 4px;

          .item-count {
            color: #337AB7;
          }
        }

        .remove-group-btn {
          color: #0A3CA2;
          cursor: pointer;
          font-size: 11px;
          font-weight: 500;
          text-align: left;
        }

      }

      .item-list {
        list-style-type: none;
        padding: 0;
        max-height: 123.56px;
        overflow-y: auto;

        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 4.5px 6px;
          font-size: 11px;
          font-weight: 400;
          text-align: left;

          &:nth-child(even) {
            background-color: #F7F7F7;
          }
        }
      }
    }



    .item:first-child {
      border-top: none;
    }

    .item button {
      background: none;
      border: none;
      color: #d9534f;
      cursor: pointer;
      margin-left: 5px;
    }

    .item button:hover {
      color: #c9302c;
    }
  }
}