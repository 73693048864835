@import "../../styleguide/contant.scss";

.tuner-specific-css {
  * {
    @include inter-font;
  }
  .ck-tuner-onboarding {
    * {
      font-family: "Roboto", sans-serif !important;
    }
    .ck-tuner-went-wrong {
      * {
        font-family: "Inter", sans-serif !important;
      }
    }
    .ck-tuner-automated-scheduling-wrapper {
      * {
        font-family: "Inter", sans-serif !important;
      }
    }
  }

  .clear_selection.show {
    top: 50px !important;
  }
  .daterangepicker.show-calendar {
    padding-top: 48px !important;
    right: 8px !important;
    top: 56px !important;
    .ranges {
      margin-top: 0;
      width: 100%;
      position: absolute;
      bottom: 35px;
      left: 0;

      ul {
        display: block !important;
        width: 100%;
        padding: 10px 20px;
        padding-left: 93px;
        position: relative;
        padding-left: 102px;

        &::before {
          position: absolute;
          content: "Auto-Select:";
          left: 20px;
          top: 43%;
          transform: translateY(-50%);
          color: $primary-grey-light;
          font-size: $fs-12;
          font-weight: 700;
        }

        li {
          margin-right: 10px;
          margin-bottom: 10px;
          cursor: pointer;
          width: auto;
          height: 20px;
          border-radius: 6px;
          border: solid 1px $secondary-blueish-grey;
          background-color: #f5f5f5;
          font-size: $fs-11;
          text-transform: uppercase;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: $primary-grey-light;
          font-weight: 700;

          &:hover {
            background-color: #eee;
          }

          &:last-of-type {
            display: none;
          }
        }

        li#customActive {
          border-radius: 6px;
          box-shadow: -4px 7px 17px 0 rgb(10 60 162 / 25%);
          border: solid 1px $primary-blue-dark;
          background-color: #eef6fe;
          color: $primary-blue-dark;
          font-weight: bold;
          padding: 8px;
        }
      }
    }

    .drp-calendar {
      display: block;
      padding: 0px !important;
      width: 50% !important;
      max-width: 100%;
      border: 0;
      border-radius: 4px;
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 15px;
      min-width: 294px;
    }

    .drp-calendar.left {
      padding-right: 5px !important;
      border-left: 0 !important;
    }

    .drp-calendar.right {
      padding-left: 5px !important;
    }

    .drp-selected {
      display: inline-block;
      font-size: $fs-12;
      padding-right: 8px;
      font-weight: 700;
    }

    .calendar-table {
      padding: 0 !important;
      font-family: "Roboto", sans-serif;

      table {
        border-spacing: 0;
        border-collapse: separate;
        border: solid 1px $secondary-blueish-grey;
        border-radius: 4px;
        max-width: 293px;
        background-color: #fff;

        td:last-child,
        th:last-child {
          font-size: $fs-11;
          color: currentColor !important;
        }

        td {
          font-size: $fs-10;
          border-radius: 50%;
          font-weight: 500 !important;
          color: $secondary-black !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        td.active:not(.off.ends) {
          color: $primary-white !important;
          background: #338ddb !important;
        }

        td.disabled {
          color: #b9becc !important;
          font-weight: normal !important;
        }

        tbody {
          tr {
            padding: 2px 10px;

            &:first-of-type {
              padding-top: 10px;
            }

            &:last-of-type {
              padding-bottom: 10px;
            }
          }
        }

        tr {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          width: 100%;
        }

        th,
        td {
          height: 23px;
          max-width: 23px;
          max-height: 23px;
          width: 23px;
          padding: 0;
          min-width: inherit;
          color: $primary-grey-light;
        }

        thead {
          tr {
            th.next,
            th.prev {
              padding: 0;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              color: rgb(53, 93, 177);
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              font-size: $fs-12 !important;
              background: rgb(224, 241, 255);
              border-width: 1px;
              border-style: solid;
              border-color: rgb(53, 93, 177);
              -o-border-image: initial;
              border-image: initial;
              border-radius: 50%;

              span {
                padding: 2px;
                border-color: #355db1;
                margin-top: 1px;
              }
            }

            th {
              background: transparent !important;
            }

            th.month {
              height: 23px;
              text-transform: uppercase;
              color: rgb(0, 0, 0);
              font-weight: 500;
              font-size: $fs-12 !important;
              -webkit-box-flex: 1;
              -ms-flex: 1 1 0%;
              flex: 1 1 0%;
              max-width: inherit;
              margin-top: -2px;
            }
          }

          tr:first-of-type {
            background-image: -webkit-gradient(
              linear,
              left bottom,
              left top,
              from(rgb(244, 244, 244)),
              to(rgb(255, 255, 255))
            );
            background-image: -o-linear-gradient(bottom, rgb(244, 244, 244), rgb(255, 255, 255));
            background-image: linear-gradient(to top, rgb(244, 244, 244), rgb(255, 255, 255));
            padding: 10px;
            border-radius: 3px;

            th.prev {
              padding-left: 3px !important;
              background: $primary-white !important;
            }

            th.next {
              padding-right: 3px !important;
              background: $primary-white !important;
            }

            th.next.available,
            th.prev.available {
              background: #eef6fe !important;
              border-color: rgb(53, 93, 177);
              color: rgb(53, 93, 177);
            }

            th:first-of-type,
            th:last-of-type {
              height: 20px;
              max-width: 20px !important;
              width: 20px !important;
            }
          }

          tr:last-of-type {
            padding: 4px 10px;
            border-bottom: 1px solid rgb(230, 230, 230);

            th {
              text-transform: uppercase;
              margin-bottom: 0;
              color: $primary-grey-light !important;
              background: transparent !important;
              font-size: $fs-10 !important;
              font-weight: 400 !important;

              &:hover {
                color: $secondary-black !important;
              }
            }
          }
        }
      }
    }
  }

  button.dateRange_pickerBtn {
    border-radius: 4px;
    border: solid 1px $secondary-grey;
    background-color: $primary-white;
    line-height: 1;
    cursor: pointer;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    font-size: $fs-11;
    font-weight: 500;
    font-style: normal;
    text-align: left;
    color: $secondary-black;
    height: 30px;
    padding: 0;
    outline: none;

    label.addOn {
      background: #f0f1f5;
      width: 34px;
      height: 28px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      cursor: pointer;
    }

    .dateText {
      padding: 0 10px;
      font-size: $fs-11;
      font-weight: 500;
      font-family: "Roboto", sans-serif;
    }
    svg {
      display: block;
    }
  }

  .option_renderer {
    background-color: #f7f7f7 !important;
  }
  #filter_div {
    path {
      &:first-child {
        fill: #fff;
      }
    }
    &.active_filter {
      path {
        fill: #fff;
      }
    }
  }
  .sorting_v2 {
    .sort_up_icon,
    .sort_down_icon {
      fill: #fff;
    }

    &.up_icon {
      .sort_up_icon {
        fill: #fff;
        opacity: 1;
      }

      .sort_down_icon {
        fill: #fff;
      }
    }

    &.down_icon {
      .sort_down_icon {
        fill: #fff;
        opacity: 1;
      }

      .sort_up_icon {
        fill: #fff;
      }
    }
  }
  .ck-tuner-scheduler-cell {
    display: flex;
    align-items: center;
    .MuiSwitch-root {
      width: 36px;
      height: 13px;
      padding: 0;
      margin-right: 6px;
      .MuiSwitch-switchBase {
        color: #ffffff !important;
        padding: 0px;
        top: 1px;
        left: 2px;
        & + span.MuiSwitch-track {
          background: #b6b6b6 !important;
        }
        input[type="checkbox"] {
          &:checked {
            left: -200%;
          }
          width: 450%;
          margin: 0;
        }
        &.Mui-checked {
          -webkit-transform: translateX(22px);
          -moz-transform: translateX(22px);
          -ms-transform: translateX(22px);
          transform: translateX(22px);
          span.MuiSwitch-thumb {
            background: #fff !important;
          }
          & + span.MuiSwitch-track {
            background: #0a3ca2 !important;
            opacity: 1 !important;
          }
          &.Mui-disabled {
            & + span.MuiSwitch-track {
              background: #5f5f5f !important;
              opacity: 1 !important;
            }
          }
        }
      }
      span.MuiSwitch-thumb {
        color: #ffffff;
        background-color: #ffffff;
        background-image: none;
        width: 10px;
        height: 10px;
      }
    }
  }
  .breadcrumb-wrapper.mb-1 {
    background: #f5f7f9;
    margin-bottom: 0;
    &.tuner-crumb {
      padding: 12px;
      padding-bottom: 0;
      //  background-color: transparent !important;
    }
  }
  .no_onboarded_accounts {
    background-color: white;
    height: 380px;
    .no_onboarded_content_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .text {
        font-weight: 600;
        font-size: 16px;
        color: #2b2b2b;
        margin-bottom: 8px;
      }
      .subText {
        font-weight: 400;
        font-size: 13px;
        color: #707070;
        margin-bottom: 20px;
      }
      .no_data_cta {
        background-color: #0a3ca2;
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        height: 28px;
        width: 132px;
        border-radius: 4px;
        padding: 10px 15px;
      }
    }
  }
  .ck-tuner-dashboard-title {
    font-size: 22px !important;
    color: #2b2b2b;
    font-weight: 600;
    padding: 8px 0 0 12px;
    background: #f7f7f7;
    margin: 0;
  }
  p {
    &.tuner-paragraph {
      font-weight: 500;
      color: #2b2b2b;
    }
  }
  .main_container {
    .option_renderer {
      .option_wrapper {
        &:has(.ck-tuner-onboarding) {
          background-color: #f5f7f9 !important;
        }
      }
    }
  }
  .verification-failed-infotext {
    width: fit-content;
    color: #2b2b2b;
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    padding: 6px 8px 6px 24px;
    margin-bottom: 12px;
    background-color: #e8f1ff;
    position: relative;
    border-radius: 4px;
    &::before {
      position: absolute;
      content: "";
      background-image: url(../../Assets/images/ic-info.svg);
      background-repeat: no-repeat;
      width: 12px;
      height: 12px;
      background-size: 100%;
      left: 6px;
      top: 8px;
    }
    .v3-link {
      font-weight: 500;
      color: #0a3ca2 !important;
      text-decoration: underline !important;
      vertical-align: baseline;
      font-size: 10px;
    }
  }
  .tuner-extension-cta {
    display: flex;
    color: #0a3ca2 !important;
    border: 1px solid #0a3ca2;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    padding: 9px 16px;
    background-color: #f1f7ff;
    margin-right: 16px;
    font-size: 12px;
    font-weight: 500;
    gap: 8px;
    &:hover {
      background-color: #f2f9ff;
      box-shadow: 0px 5px 5px rgba(10, 60, 162, 0.1019607843);
    }
    &.tuner-extension-cta-disabled {
      background-color: hsla(0, 0%, 93%, 1);
      border: none;
      color: hsla(0, 0%, 17%, 1) !important;
    }
  }
  .tuner-account-table-button {
    text-decoration: none;
    padding: 1px 4px !important;
    font-size: 11px;
  }
}
.tuner-tab-truncate-tooltip {
  padding: 12px 8px !important;
  font-size: 12px;
  color: #2b2b2b;
  max-width: 300px;
  word-break: break-all;
}

#ck-tuner-popover .MuiPaper-root.MuiPopover-paper {
  &::before {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    border: solid 1px #e6e6e6;
    background: #fff;
    border-right: 0;
    border-bottom: 0;
    top: -2px !important;
    left: 50.5%;
    transform: rotate(405deg) translateX(-50%) !important;
    &:has(.table_header_tooltip) {
      margin-top: 5px !important;
    }
  }
}
