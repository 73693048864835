@import "../../../styleguide/contant.scss";

.multipleSelectCheckmarks {
  display: flex;
  flex-direction: column;

  .MuiChip-deleteIcon {
    margin: 0 !important;
  }

  label[data-shrink="true"] {
    display: none;
  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: $fs-12;
    line-height: 8px;
    /* identical to box height */

    padding: 10px;
    color: $primary-grey-dark;
  }

  .MuiChip-deleteIcon {
    color: $primary-blue-dark !important;
    margin-left: 5px !important;
    font-size: $fs-18 !important;
  }
}

.multiButtons {
  width: 100%;
  margin-top: 10px;
  padding: 15px;
  position: relative;
  border-top: 1px solid $modal-footer-background;
  display: flex;
  justify-content: flex-end;
  background: $modal-footer-background;
  border-top: 1px solid #e8e6e6;

  button {
    height: 32px;
    margin-left: 10px;
  }

  #close-filter {
    background: $primary-white;
  }
}

.MuiPaper-root
  ul.MuiList-root[aria-labelledby="demo-multiple-checkbox-label"]
  li
  .MuiListItemText-root
  span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: $fs-12 !important;
  line-height: 14px;
  color: $secondary-black;
}

ul.MuiList-root[aria-labelledby="demo-multiple-checkbox-label"].MuiList-padding.MuiMenu-list {
  padding: 5px 0;

  li {
    padding-left: 12px !important;
  }
}

div#menu- .MuiPaper-elevation8.MuiPaper-rounded {
    border: 0;
    border-radius: 4px !important;
    box-shadow: unset !important;
}

.custom_multiselect {
  &:has(.MuiSelect-iconOpen) {
    .multicheck {
      &:before {
        transform: rotate(180deg);
      }
    }
  }
  .multicheck {
    position: relative;

    .MuiChip-root.MuiChip-filled.optext-length {
      padding: 3px 8px !important;
      &::after {
        background: none !important;
      }
    }

    &:before {
      content: "";
      position: absolute;
      background: url("../../../Assets/icons/dropdown_arrow.svg");
      height: 7px;
      width: 12px;
      right: 13px;
      top: 10px;
    }
  }
  svg.MuiSelect-icon {
    display: none;
  }
}

.custom_select.Mui-selected {
  background-color: $primary-white !important;

  .MuiTypography-root,
  .Mui-checked .MuiTypography-root {
    font-weight: 500 !important;
    font-size: $fs-12;
    line-height: 14px;
    color: $secondary-black !important;
  }

  span.MuiCheckbox-root svg {
    fill: $primary-blue-dark !important;
  }
}

.custom_select {
  .Mui-checked {
    svg {
      path {
        fill: $primary-blue-dark !important;
      }
    }
  }
  .MuiTypography-root {
    color: $disable-text-color !important;
  }

  .Mui-checked + .MuiListItemText-root {
    .MuiTypography-root {
      font-weight: 500 !important;
      font-size: $fs-12;
      line-height: 14px;
      color: $secondary-black !important;
    }

    span.MuiCheckbox-root svg {
      fill: $primary-blue-dark;
    }
  }
}

.no_multiCheckListMessage {
  text-align: center !important;
  font-weight: 500 !important;
  color: $secondary-black !important;
  padding: 20px;
}

div.MuiPaper-root{
  &:has(.custom_select){
    overflow-y: unset !important;
    position: relative !important;

    ul.MuiList-root{
      position: unset;
      overflow-y: auto !important;
      height: 250px;
      margin-bottom: 58px;
      position: unset;
    }
    .multiButtons{
      position: absolute;
      bottom: 0;
      border : solid 1px $secondary-blueish-grey;
      // border-top: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}