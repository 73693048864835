@import "../../styleguide/contant.scss";
@import "../../styleguide/mixin.scss";

.cld_customer_linked_acc_wrapper_v2{
    max-width: 650px;
    // width: 100%;
    border: 1px solid  #DDDFE8;
    background-color:#F7F7F8 ;
    padding: 10px;
    .multi-select{
        background-color: #ffffff;
        border: 1px solid #DDDFE8;
        border-radius: 4px;
    }
    .cld_multiselect_heading{
        padding: 6px;
        background-color:  #EFF4FF;
        width: 100%;
    }
    .multi-select-wrapper{
        margin-top: 0;
        border: none;
    }
    .search-wrapper{
        background: #EFF4FF;
        padding: 3px 0;
        .search-label{
            @include createFont(12px, 500);
        }
    }
}