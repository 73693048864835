.billing_console_header.disabled {
  label {
    color: #6a6a6a;
    font-family: Roboto;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  select {
    color: #6a6a6a;
  }
}
.ck-tuner-homepage-header {
  border: none;
  padding: 12px;
  #dateRangePicker {
    margin-right: 0;
    //position: relative;
    padding: 0px;
    box-shadow: 0px 0px 0px 1px #868fa029;
    border-radius: 4px;
    .daterangepicker {
    }
    .dateRange_pickerBtn {
      border: none;
      height: 33px;
      //  width: 212px;
      justify-content: space-between;
      .drp-inputForm.show {
        .input-field-container {
          span {
            font-size: 10px !important;
          }
        }
      }
    }
    label {
      background-color: #e9f4ff;
      border: none;
      height: 31px;
      width: 44px;
      margin-bottom: 0;
    }
    .addOn img {
      width: 18px;
      height: 19px;
      color: #203358;
    }
  }
}
