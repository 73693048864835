.cudos-tabs-wrapper {
  .tab-btn {
    padding: 9px 20px !important;
    color: #000000 !important;
    border-left: 1px solid #D5DDEC !important;
    border-top: 1px solid #D5DDEC !important;
    border-bottom: 1px solid #D5DDEC !important;
    border-right-width: 0px !important;
    font-size: 12px !important;
    line-height: 14.06px !important;
    height: 33px !important;
  }

  .tab-btn.active {
    background: #E8F1FF !important;
    font-weight: 600 !important;
    color: #253E66 !important;
    border-color: #D5DDEC !important;
    border-right-width: 0px !important;
  }

  .tab-btn:first-child {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }

  .tab-btn:last-child {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-right: 1px solid #D5DDEC !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .tab-btn:only-child {
    border-radius: 5px !important;
  }

  .tab-btn:not(:first-child):not(:last-child) {
    border-radius: 0px !important;
  }

  &:has(label:first-child) {
    .tab-btn:nth-child(2) {
      border-top-left-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
    }
  }

}